<template>
  <div class="full" v-on:contextmenu.prevent="doNothing">
    <b-navbar toggleable type="dark" variant="info">
      <b-navbar-brand>WyneCellar</b-navbar-brand>
      <b-navbar-brand align="right">Add Cellar to Registration</b-navbar-brand>
    </b-navbar>
  <b-container class="space-2 space-3--lg">
    <br>
    <b-form>
      <b-row>
        <b-col sm="7" lg ="4" class="d-table">
          <div style="text-align: center" v-if="cellar.image_files.length > 0">
            <img 
              class="fluid-grow"
              style="border-radius: 25px;object-fit: cover"
              width="300"
              height="200"
              v-bind:src="cellar.image_files[0].dataURL"
              alt="Cellar Picture comes HERE"
            />
          </div>
          <div style="text-align: center" v-else>
            <img
              class="fluid-grow"
              style="border-radius: 25px;object-fit: cover"
              width="300"
              height="200"
              src="../assets/img/tastings.jpg"
              alt="Cellar Picture comes HERE"
            />
          </div>
          <br>
          <vue-dropzone 
            ref="imgDropzone" id="dropzone" 
            :options="dropzoneOptions"
            @vdropzone-thumbnail="vfileAdded" 
            @vdropzone-removed-file="vfileRemoved"
          >
          </vue-dropzone>
          <p style="font-size:0.8rem" class="text-center">The best pictures have an aspect ratio of 3:2<br>(eg 300x200, 600x6400)</p>
        </b-col>
        <b-col sm="5" lg ="8" class="d-table">
          <h1>Hello {{user.firstname}}</h1>
          <p>Welcome back. </p> 
          <p>You are registered for the WyneMaker application, as owner of {{winery.name}}</p>
          <p>We understand you want to add access to WyneCellar application to manage your private cellar too.</p>
          <hr>
          <b-form-group> <!-- NAME -->
            <b-row>
              <b-col md="4" class="text-left align-self-center">
                  <label>NAME:</label>
              </b-col>
              <b-col md="8">
                <label>{{user.firstname}} {{user.lastname}}</label>
              </b-col>
            </b-row>
          </b-form-group> 

          <b-form-group> <!-- EMAIL -->
            <b-row>
              <b-col md="4" class="text-left align-self-center">
                  <label>EMAIL:</label>
              </b-col>
              <b-col md="8">
                <label>{{user.email}}</label>
              </b-col>
            </b-row>
          </b-form-group>    
        
          <b-form-group> <!-- WINERY NAME -->
            <b-row>
              <b-col md="4" class="text-left align-self-center">
                  <label>WINERY NAME:</label>
              </b-col>
              <b-col md="8">
                <label>{{winery.name}}</label>
              </b-col>
            </b-row>
          </b-form-group>

          <hr>

          <b-form-group> <!-- CELLAR NAME -->
            <b-row>
              <b-col md="4" class="text-left align-self-center">
                  <label for="input-name">CELLAR NAME:</label>
              </b-col>
              <b-col md="8">
                <b-form-input
                id="input-cellarname"
                class = "wm-input"
                v-model="cellar.name"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.cellarnameValid}"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Enter cellar name"
                ></b-form-input>

                <b-form-invalid-feedback id="input-live-feedback">
                  {{cellarnameFeedback}}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-form-group>
          
          <hr>
          
          <b-row>
            <b-col  class="text-left">
              <b-button v-on:click="onRegister()">Register</b-button>
            </b-col>
            <b-col class="text-right">
              <b-button v-on:click="onReset()">Reset</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';

  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import Users from '../Users.js';
  import Mail from '../Mail.js';
  import Cellars from '../Cellars.js';
  import Wineries from '../Wineries.js';

  export default {
    name: 'AddCellar2Registration',
    components:{
      vueDropzone: vue2Dropzone,
    },
    data() {
      return {
        debug : true,

        //Working Objects
        user: {},
        user_id:0,

        profile_picture:'',
        
        cellar: {
            name : '',
            user_id : '',
            niche_ids:[],
            entry_ids: [],
            image_files: [],
            number_of_bottles : 0,
        },
        cellar_id:0,

        winery: {},
        winery_id:0,

        // Validation Logic
        cellarnameValid : true,
        cellarnameFeedback : '',

        formValid : false,

        // dropzone variables
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          addRemoveLinks: true,
          thumbnailWidth: 100,
          thumbnailHeight: 150,
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : 'Drop you Profile Picture here.',
          maxFilesize: 2,
          headers: { "My-Awesome-Header": "header value" }
        },
      }
    },
    async mounted() {
      this.user_id = this.$route.query.user_id;      
      if (this.user_id == null) return;

      await Users.getone(this.user_id)
      .then(response=>{
        if (this.debug) console.log("AddCellar2Registration in created : user succesfully retrieved form DB - ", response)
        this.user = response;
        delete this.user._id;
      })
      .catch(error=>{
        if (this.debug) console.log("AddCellar2Registration in created : catch Users.getone - ", error)
      })

      this.winery_id  = this.user.winery_id
      if ( this.winery_id != null) {
        this.isMaker = true;
        await Wineries.getone(this.winery_id)
        .then(response=>{
          if (this.debug) console.log("AddCellar2Registration in created : winery succesfully retrieved form DB - ", response)
          this.winery = response;
          delete this.winery._id;
        })
        .catch(error=> {
          if (this.debug) console.log("AddCellar2Registration in created : catch Winery.getone - ", error)
        })
      }
    },
    methods: {
        async validate(){
          //chack validity of name fields
          if (this.cellar.name.length < 3) {
            this.cellarnameValid = false
            this.cellarnameFeedback = 'Cellar Name should be at least 3 characters long'
          } else {
             this.cellarnameValid = true
          }
          this.formValid = this.cellarnameValid
          if (this.debug) console.log("AddCellar2Registration in validate(): form.valid=",this.formValid);
        },

        async onRegister() {
          if (this.debug) console.log("AddCellar2Registration in onRegister: Register pressed");

          await this.validate()
          if (this.debug) console.log("AddCellar2Registration in onRegister: form.valid=",this.formValid);
          if (this.formValid) {
            // HERE COMES THE DB STUFF
            this.cellar.user_id = this.user_id
            await Cellars.create(this.cellar)
            .then(response=>{
              if (this.debug) console.log("AddCellar2Registration in onRegister: Cellar succesfully created - ",response);
              this.user.cellar_id = response
            })
            .catch(error=>{
              if (this.debug) console.log("AddCellar2Registration in onRegister: catch Cellar.create - ",error);
            })

            await Users.update(this.user_id,this.user)
            .then(response=>{
              if (this.debug) console.log("AddCellar2Registration in onRegister: User succesfully updated - ",response);
            })
            .catch(error=>{
              if (this.debug) console.log("AddCellar2Registration in onRegister: catch User.update - ",error);
            })

            let this_html = ""
            this_html += "<p>Hi " + this.user.firstname + "</p>"
            this_html += "<p>Next to your existing subscription to WyneMaker, you are now subscribed to WyneCellar.</p>"
            this_html += "<p>Enjoy !</p>"
            this_html += "<p>The Wyne Team</p>"
    
            let postdata = {};
            postdata.email = this.user.email;
            postdata.subject = "Welcome to WyneCellar";
            postdata.html = this_html;
            if (this.debug) console.log("AddCellar2Registration in onRegister - Mailing postdata = ", JSON.stringify(postdata))

            await Mail.sendCellar(postdata)
            .then(response=>{
              if (this.debug) console.log("AddCellar2Registration in onRegister - successfully transfered postdata to Mail - from Cellar ", response)
            })
            .catch(error=>{
              if (this.debug) console.log("AddCellar2Registration in onRegister - catch Mail.sendCellar - ", error)
              return;
            })

            try {
              console.log("this.user = ", this.user, " this.user_id = ", this.user_id)
              await this.$store.commit('login')
              await this.$store.commit('setUser',this.user)
              await this.$store.commit('setUserID',this.user_id)
              if ( this.user.image_files.length > 0) {
                this.profile_picture = await cc64(this.user.image_files[0].dataURL,1,1)
              }
              await this.$store.commit('setProfilePicture',this.profile_picture)
              await this.$store.commit('setPage','Home')
              await this.$router.push({path:'/Home', query: {user_id: this.user_id, cellar_id:this.user.cellar_id }} );
            } catch(err) {
              alert("ERROR : ", err.message)
            }
          } 
        },
      onReset() {
        // Reset our form values
        if ( this.cellar.image_files.length > 0) {
          var old_image_file = this.cellar.image_files.pop()
          this.$refs.imgDropzone.removeFile(old_image_file)
        }
        this.cellar.image_files = []
        
        this.cellar.name = ''
      },
      vfileAdded(new_image_file) {
        if (this.cellar.image_files.length > 0) {
          var old_image_file = this.cellar.image_files.pop()
          this.$refs.imgDropzone.removeFile(old_image_file)
        }
        this.cellar.image_files.push(new_image_file)
      },
      vfileRemoved(image_file) {
        this.cellar.image_files.pop(image_file);
      },

      doNothing() {
      },
    }
  }
</script>

<style scoped>
.full {
  height: 100% !important;
  width:100% !important;
}

.full::after {
  content: "";
  background-image: url("../assets/img/wynetaster.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}
.bg-info {
    background-color: rgb(234,202,81) !important;
} 

.nav-link {
    color: rgba(0, 0, 0, 1) !important;
}

.nav-link.hover{
    color: rgba(0, 255, 0, 1) !important;
}
</style>

<style>
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:rgba(0, 255, 0, 1);
  opacity: 1; /* Firefox */
}

label {
  font-family:inherit;
  font-size:0.8rem;
  color:grey;
  margin:0;
  padding:0
}
input {
  color:black;
  font-family:Avenir
}

.custom-control-label {
 color:grey;
  font-size: 1 rem
}
.form-control {
  color:black;
}

.carousel-inner img {
  margin: auto;
}

.dropzone {
  background-color: lightgrey !important;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: auto;
  padding: 5px;
}

.dropzone .dz-preview {
  width: auto;
  display: inline-block
}
.dropzone .dz-details {
  width:auto;
}
.dropzone .dz-preview .dz-image .dz-image-preview .dz-processing .dz-complete{
  height: 40px;
  object-fit: cover;
  margin:0;
}
.dropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}
.dz-remove {
  margin:0.5 em !important
}

.dropzone .dz-preview .dz-image > img {
  width: 100%;
}
.dropzone .dz-message {
  margin : 3.5em 0em 0em 0em;
}
.dz-filename {
  font-size:0.1 em !important;
}

.customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity .2s linear;
  text-align: center;
}
.customdropzone .dz-success-mark, .dz-error-mark, .dz-remove {
  display: none;
}

.wm-input {
  background-color: rgba(234,202,81,0.1) !important;
  border-color: rgb(234,202,81) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(234,202,41,0.5) !important;
}
.wm-input:focus {
  border-color: rgb(234,202,81) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(234,202,41,0.5) !important; 
}
</style>
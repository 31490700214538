<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>    
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col xs="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col sm="10" style="padding:0">
            <br>
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;" v-if="tasting_id == 0" >tastings > add tasting</p>
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;" v-else>tastings > edit tasting</p>
            <div v-if="tasting_id == 0" style="width: 90%; margin: 2vh 5vw 0 5vw !important">
              <b-tabs  class="w-tabs" v-model="tabIndex" fill>
                <b-tab class="w-tab" title="from code" active>
                  <div class="w-tabbox">
                    <br>
                    <b-form aria-autocomplete="off">
                      <b-form-input
                        id="input-code"
                        class="w-input"
                        width="10px"
                        v-model="code"
                        v-bind:class="{'form-control':true, 'is-invalid' : !this.isValidCode}"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="Enter a 6 digit code"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{codeFeedback}}
                      </b-form-invalid-feedback>
                    </b-form>
                    <br>
                    <b-row>
                      <b-col  class="text-left">
                        <b-button class="w-button-pink" v-on:click="onBack()" >back</b-button>
                      </b-col>
                      <b-col class="text-right">
                        <b-button class="w-button-green" v-on:click="onRetrieve()">retrieve</b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
                <b-tab class="w-tab" title="no code">
                  <div class="w-tabbox">
                    <b-row style="margin-left:15px;margin-right:15px">
                      <b-col sm="5" class="text-center" style="padding:0px">
                        <div class="w-tile text-center" style="width:300px;height:200px"> 
                            <div v-if="tasting.image_files[0]" 
                              class="c-image-part" 
                              v-bind:style="{ 'background-image': 'url(' + tasting.image_files[0].dataURL +')' }"
                            >
                            </div>
                            <div v-else
                              class="c-image-part"
                            />
                            <div class="c-text-part">
                              <p class="c-title" v-if="tasting.code">{{tasting.code}} - {{tasting.name}} - {{tasting.isOrganised}}</p>
                              <p class="c-title" v-else>{{tasting.name}}<span v-if="tasting.organiser" style="font-weight:normal;font-size: 11px;"> (by {{ tasting.organiser }})</span></p>
                              <p class="c-subtitle">{{tasting.nice_date}} <span v-if="tasting.description">- {{tasting.description}}</span></p>
                              <p class="c-bottles">{{tasting.tasting_note_ids.length}}</p>
                            </div>
                          <div class="dropbox-overlay text-center" style="width:300px;height:200px">
                            <vue-dropzone 
                              ref="imgDropzone" id="profiledropzone" 
                              :options="dropzoneOptions"
                              @vdropzone-thumbnail="vfileAdded" 
                            >
                            </vue-dropzone>    
                          </div>
                        </div>
                      </b-col>
                      <b-col sm="1">
                      </b-col>

                      <b-col sm="6" style="margin-left:0;padding-left:0px" class="d-table">
                        <b-form autocomplete="false">
                          <b-form-group>
                            <label class="w-label w-required" for="input-name">name</label>
                            <b-form-input
                              id="input-firstname"
                              class = "w-input"
                              v-model="tasting.name"
                              v-bind:class="{'is-invalid' : !this.nameValid}"
                              aria-describedby="input-live-help input-live-feedback"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-feedback">
                              {{nameFeedback}}
                            </b-form-invalid-feedback>
                          </b-form-group>

                          <b-form-group>
                            <label class="w-label" for="input-organiser">organiser</label>
                            <b-form-input
                              id="input-organiser"
                              class = "w-input"
                              v-model="tasting.organiser"
                              v-bind:class="{'is-invalid' : !this.organiserValid}"
                              aria-describedby="input-live-help input-live-feedback"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-feedback">
                              {{organiserFeedback}}
                            </b-form-invalid-feedback>
                          </b-form-group>

                          <b-form-group>
                            <label class="w-label" for="input-date">date</label>
                            <b-form-datepicker 
                              class="w-datepicker mb-2" 
                              id="input-date" 
                              v-model="tasting.date"
                              @input="changeDate()"
                              v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
                            >
                            </b-form-datepicker>
                            <b-form-invalid-feedback id="input-live-feedback">
                              {{dateFeedback}}
                            </b-form-invalid-feedback>
                          </b-form-group>                    
                                    
                          <b-form-group>
                            <label class="w-label" for="input-description">description</label>
                            <b-form-input
                              id="input-description"
                              class = "w-input"
                              v-model="tasting.description"
                              v-bind:class="{'is-invalid' : !this.descriptionValid}"
                              aria-describedby="input-live-help input-live-feedback"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-feedback">
                              {{descriptionFeedback}}
                            </b-form-invalid-feedback>
                          </b-form-group>

                          <hr>

                          <!-- <b-form-group>
                              <b-form-checkbox class="w-checkbox d-inline" style="margin-left:0; padding-left:25px !important" v-model="tasting.isBlind" name="ordered-check">
                              </b-form-checkbox>
                              <label class="w-checkbox d-inline" style="margin-left:0 !important; padding-left:0 !important" for="ordered-check">blind</label>
                          </b-form-group>                    

                          <b-form-group>
                              <b-form-checkbox class="w-checkbox d-inline" style="margin-left:0; padding-left:25px !important" v-model="tasting.isOrdered" name="ordered-check">
                              </b-form-checkbox>
                              <label class="w-checkbox d-inline" style="margin-left:0 !important; padding-left:0 !important" for="ordered-check">ordered</label>
                          </b-form-group> -->
                        </b-form>
                        <b-row>
                          <b-col  class="text-left">
                            <b-button class="w-button-pink" v-on:click="onBack()" >back</b-button>
                          </b-col>
                          <b-col class="text-right">
                            <b-button class="w-button-green" v-on:click="onSave()">save</b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
            <div v-else>
              <div v-if="tasting.code">
                <div class="w-tabbox">
                  <b-row style="margin-left:15px;margin-right:15px">
                    <b-col sm="5" class="justify-content-center" style="padding:0px">
                      <div class="w-tile text-center" style="width:300px;height:200px"> 
                          <div v-if="tasting.image_files[0]" 
                            class="c-image-part" 
                            v-bind:style="{ 'background-image': 'url(' + tasting.image_files[0].dataURL +')' }"
                          >
                          </div>
                          <div v-else
                            class="c-image-part"
                          />
                          <div class="c-text-part">
                            <p class="c-title" v-if="tasting.code">{{tasting.code}} - {{tasting.name}}</p>
                            <p class="c-title" v-else>{{tasting.name}}<span v-if="tasting.organiser" style="font-weight:normal;font-size: 11px;"> (by {{ tasting.organiser }})</span></p>
                            <p class="c-subtitle"><span v-if="tasting.date != ''">{{tasting.nice_date}}</span>  <span v-if="tasting.description">- {{tasting.description}}</span></p>
                            <p class="c-bottles">{{tasting.tasting_note_ids.length}}</p>
                          </div>
                        <div class="dropbox-overlay text-center" style="width:300px;height:200px">
                          <vue-dropzone 
                            ref="imgDropzone" id="profiledropzone" 
                            :options="dropzoneOptions"
                            @vdropzone-thumbnail="vfileAdded" 
                          >
                          </vue-dropzone>    
                        </div>
                      </div>
                    </b-col>
                    <b-col sm="1">
                    </b-col>
                    <b-col v-if="tasting.isOrganised" sm="6" style="margin-left:0;padding-left:0px" class="d-table">
                      <p class="ww-title"><b>Welcome to the organised tasting '{{tasting.name}}'.</b></p>
                      <hr>
                      <p class="ww-text">This tasting<span v-if="tasting.description"> about {{tasting.description}}</span> is organised by <b>{{tasting.organiser}}</b> on <b>{{tasting.nice_date}}</b>.</p>
                      <p class="ww-text" v-if="tasting.isOpen">It's an <b>open</b> tasting. This means that the organiser offers a catalogue of bottles from which you can choose what to taste.</p>
                      <p class="ww-text" v-if="tasting.isBlind && tasting.isOrdered">It's a <b>ordered blind</b> tasting.</p>
                      <p class="ww-text" v-if="!tasting.isBlind && tasting.isOrdered">It's a <b>ordered</b> tasting.</p>
                    </b-col>
                    <b-col v-else sm="6" style="margin-left:0;padding-left:0px" class="d-table">
                      <b-form autocomplete="false">
                        <b-form-group>
                          <label class="w-label" for="input-organiser">code</label>
                          <b-form-input
                            id="input-organiser"
                            class = "w-input"
                            v-model="tasting.code"
                            aria-describedby="input-live-help input-live-feedback"
                            disabled
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group>
                          <label class="w-label w-required" for="input-name">name</label>
                          <b-form-input
                            id="input-firstname"
                            class = "w-input"
                            v-model="tasting.name"
                            v-bind:class="{'is-invalid' : !this.nameValid}"
                            aria-describedby="input-live-help input-live-feedback"
                            disabled
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{nameFeedback}}
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group>
                          <label class="w-label" for="input-organiser">organiser</label>
                          <b-form-input
                            id="input-organiser"
                            class = "w-input"
                            v-model="tasting.organiser"
                            v-bind:class="{'is-invalid' : !this.organiserValid}"
                            aria-describedby="input-live-help input-live-feedback"
                            disabled
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{organiserFeedback}}
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group>
                          <label class="w-label w-required" for="input-date">date</label>
                          <b-form-datepicker 
                            class="w-datepicker mb-2" 
                            id="input-date" 
                            v-model="tasting.date"
                            @input="changeDate()"
                            v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
                            disabled
                          >
                          </b-form-datepicker>
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{dateFeedback}}
                          </b-form-invalid-feedback>
                        </b-form-group>                    
                                  
                        <b-form-group>
                          <label class="w-label" for="input-description">description</label>
                          <b-form-input
                            id="input-description"
                            class = "w-input"
                            v-model="tasting.description"
                            v-bind:class="{'is-invalid' : !this.descriptionValid}"
                            aria-describedby="input-live-help input-live-feedback"
                            disabled                            
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{descriptionFeedback}}
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <hr>

                        <b-form-group>
                            <b-form-checkbox 
                              class="w-checkbox d-inline" 
                              style="margin-left:0; padding-left:25px !important" 
                              v-model="tasting.isBlind" 
                              name="ordered-check"
                              disabled
                            >
                            </b-form-checkbox>
                            <label class="w-checkbox d-inline" style="margin-left:0 !important; padding-left:0 !important" for="ordered-check">blind</label>
                        </b-form-group>                    

                        <b-form-group>
                            <b-form-checkbox 
                              class="w-checkbox d-inline" 
                              style="margin-left:0; padding-left:25px !important" 
                              v-model="tasting.isOrdered" 
                              name="ordered-check"
                              disabled                              
                            >
                            </b-form-checkbox>
                            <label class="w-checkbox d-inline" style="margin-left:0 !important; padding-left:0 !important" for="ordered-check">ordered</label>
                        </b-form-group>
                      </b-form>
                      <b-row>
                        <b-col  class="text-left">
                          <b-button class="w-button-pink" style="margin-left:15px" v-on:click="onBack()" >back</b-button>
                        </b-col>
                        <b-col class="text-right">
                          <b-button class="w-button-green" style="margin-right:15px" v-on:click="onSave()">save</b-button>
                        </b-col> 
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-else style="width: 90%; margin: 2vh 5vw 0 5vw !important">
                <div class="w-tabbox">
                  <b-row style="margin-left:15px;margin-right:15px">
                    <b-col sm="5" class="justify-content-center" style="padding:0px">
                      <div class="w-tile text-center" style="width:300px;height:200px"> 
                          <div v-if="tasting.image_files[0]" 
                            class="c-image-part" 
                            v-bind:style="{ 'background-image': 'url(' + tasting.image_files[0].dataURL +')' }"
                          >
                          </div>
                          <div v-else
                            class="c-image-part"
                          />
                          <div class="c-text-part">
                            <p class="c-title" v-if="tasting.code">{{tasting.code}} - {{tasting.name}}</p>
                            <p class="c-title" v-else>{{tasting.name}}<span v-if="tasting.organiser" style="font-weight:normal;font-size: 11px;"> (by {{ tasting.organiser }})</span></p>
                            <p class="c-subtitle"><span v-if="tasting.date != ''">{{tasting.nice_date}}</span>  <span v-if="tasting.description">- {{tasting.description}}</span></p>
                            <p class="c-bottles">{{tasting.tasting_note_ids.length}}</p>
                          </div>
                        <div class="dropbox-overlay text-center" style="width:300px;height:200px">
                          <vue-dropzone 
                            ref="imgDropzone" id="profiledropzone" 
                            :options="dropzoneOptions"
                            @vdropzone-thumbnail="vfileAdded" 
                          >
                          </vue-dropzone>    
                        </div>
                      </div>
                    </b-col>
                    <b-col sm="1">
                    </b-col>
                    <b-col sm="6" style="margin-left:0;padding-left:0px" class="d-table">
                      <b-form autocomplete="false">
                        <b-form-group>
                          <label class="w-label w-required" for="input-name">name</label>
                          <b-form-input
                            id="input-firstname"
                            class = "w-input"
                            v-model="tasting.name"
                            v-bind:class="{'is-invalid' : !this.nameValid}"
                            aria-describedby="input-live-help input-live-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{nameFeedback}}
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group>
                          <label class="w-label" for="input-organiser">organiser</label>
                          <b-form-input
                            id="input-organiser"
                            class = "w-input"
                            v-model="tasting.organiser"
                            v-bind:class="{'is-invalid' : !this.organiserValid}"
                            aria-describedby="input-live-help input-live-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{organiserFeedback}}
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group>
                          <label class="w-label w-required" for="input-date">date</label>
                          <b-form-datepicker 
                            class="w-datepicker mb-2" 
                            id="input-date" 
                            v-model="tasting.date"
                            v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
                          >
                          </b-form-datepicker>
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{dateFeedback}}
                          </b-form-invalid-feedback>
                        </b-form-group>                    
                                  
                        <b-form-group>
                          <label class="w-label" for="input-description">description</label>
                          <b-form-input
                            id="input-description"
                            class = "w-input"
                            v-model="tasting.description"
                            v-bind:class="{'is-invalid' : !this.descriptionValid}"
                            aria-describedby="input-live-help input-live-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{descriptionFeedback}}
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <hr>

                        <b-form-group>
                            <b-form-checkbox class="w-checkbox d-inline" style="margin-left:0; padding-left:25px !important" v-model="tasting.isBlind" name="ordered-check">
                            </b-form-checkbox>
                            <label class="w-checkbox d-inline" style="margin-left:0 !important; padding-left:0 !important" for="ordered-check">blind</label>
                        </b-form-group>                    

                        <b-form-group>
                            <b-form-checkbox class="w-checkbox d-inline" style="margin-left:0; padding-left:25px !important" v-model="tasting.isOrdered" name="ordered-check">
                            </b-form-checkbox>
                            <label class="w-checkbox d-inline" style="margin-left:0 !important; padding-left:0 !important" for="ordered-check">ordered</label>
                        </b-form-group>
                      </b-form>
                      <b-row>
                        <b-col  class="text-left">
                          <b-button class="w-button-pink" style="margin-left:15px" v-on:click="onBack()" >back</b-button>
                        </b-col>
                        <b-col class="text-right">
                          <b-button class="w-button-green" style="margin-right:15px" v-on:click="onSave()">save</b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>        
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import formatDate from '../assets/js/formatDate.js';
  import cc64 from '../assets/js/cc64.js';
  import wait from '../assets/js/wait.js';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuDesktop.vue'

  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  
  import Users from '../Users.js';
  import Tastings from '../Tastings.js';
  import TastingNotes from '../TastingNotes.js'

  import WineLists from '../WineLists.js'
  import Items from '../Items.js'
  import Bottles from '../Bottles.js'
  
  import fromWineList from '../fromWineList.js'
  import fromTasting from '../fromTasting.js'

  export default {
    name: 'TastingEdit',
    components:{
      NoAccess,
      Menu,
      vueDropzone: vue2Dropzone
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      currentPage() {
        return this.$store.state.page
      },
      generateCode() {
        return 'AA AA AA'
      }
    },
    data() {
      return {
        debug: false,
        isLoading: true,
        
        winelist: {},
        winelist_id:0,

        tasting: {
          user_id:0,

          isOrganised:false,
          organiser_id:0,
          winelist_id:0,
          
          name:'',
          organiser:'',
          description:'',
          date:'',
          image_files: [],

          isOpen: false,
          isBlind: false,
          isOrdered: false,
          code:'',

          tasting_note_ids: [],
        },
        tasting_id:0,

        items: [],
        tasting_notes:[],
        // items:[],

        user:{},
        user_id:0,

        code:'',
        isValidCode:true,
        codeFeedback:'',

        isValid : true,
        nameValid: true,
        nameFeedback:'',
        organiserValid: true,
        organiserFeedback: '',
        dateValid: true,
        dateFeedback: '',
        descriptionValid: true,
        descriptionFeedback: '',

        tastingExists : false,

        tasting_codes:[],

        // dropzone data
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          addRemoveLinks: true,
          thumbnailWidth: 100,
          thumbnailHeight: 100,
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : 'Drop your Tasting Picture here.',
          maxFilesize: 10,
          headers: { "My-Awesome-Header": "header value" }
        },
        dropzoneFilled: false,

        submitted : false,

        tabIndex:0,

      }
    },
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("TastingEdit.mounted: START")

      this.user_id = this.currentUserID;
      this.user = this.currentUser;

      var today = new Date();
      this.tasting.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      // format date yyyy-mm-dd
      let dateArray = this.tasting.date.split("-");
      if (dateArray[1].length == 1) {
        dateArray[1] = "0" + dateArray[1]
      }
      if (dateArray[2].length == 1) {
        dateArray[2] = "0" + dateArray[2]
      }
      this.tasting.date = dateArray[0] + "-" + dateArray[1] + "-" + dateArray[2]
      this.tasting.nice_date = formatDate(new Date(this.tasting.date))

      if (typeof this.$route.query.tasting_id !== "undefined") {
        await Tastings.getone(this.$route.query.tasting_id)
        .then(response=> {
          response.nice_date = formatDate(new Date(response.date))
          this.tasting = response;
          this.tasting_id = this.tasting._id;
          delete this.tasting._id;
          this.tastingExists = true;
          if (this.debug) console.log( "TastingEdit.mounted: Tasting "+ this.tasting.name+ " succesfully fetched from DB");
          this.code = this.printCode(this.tasting.code);
        })
        .catch(error=> {
          if (this.debug) console.log("TastingEdit.mounted: await Tasting.getone", error);
        })
      } else {
        if (this.debug) console.log("TastingEdit.mounted: NO this.$route.query.tasting_id = ", this.$route.query.tasting_id, " we created a new one.");
      }

      if (typeof this.user.tasting_ids !== "undefined" && this.user.tasting_ids.length !== 0) {
        for (let i=0; i < this.user.tasting_ids.length; i++) {
          if (this.debug) console.log("TastingEdit.mounted: i = ", i, " this.user.tasting_ids[",i,"] = ",this.user.tasting_ids[i])
          await Tastings.getone(this.user.tasting_ids[i])
          .then(response => {
            if (typeof response !== "undefined" && response !== "" && response !== null) {
              if (typeof response.code !== "undefined" && response.code !== "" && response.code !== null) {
                this.tasting_codes.push(response.code);
                if (this.debug) console.log("TastingEdit.mounted: Tasting code " + response.code + " succesfully added to tasting_codes.");
              }
            } else {
              if (this.debug) console.log("TastingEdit.mounted: Tasting "+ this.user.tasting_ids[i] + " not found in DB");
            }
          })
          .catch(error => {
            if (this.debug) console.log("TastingEdit.mounted: await Tasting i=", i , " ", error);
          })
        }
      }
      
      if (this.debug) console.log("TastingEdit.mounted: INFO tabIndex = ", this.tabIndex);
      if (this.debug) console.log("TastingEdit.mounted: INFO tasting_id = ", this.tasting_id);
      if (this.debug) console.log("TastingEdit.mounted: STOP")

      this.isLoading = false;
    },
    methods: {
      validate(){
        if( this.tasting.name.length >= 3) {
            this.nameValid = true;
            this.nameFeedback = ""
        } else {
            this.nameValid = false
            this.nameFeedback = "name should at least contain 3 characters, nothing done"
        }
        // format date yyyy-mm-dd
        if (this.tasting.date != "") {
          let dateArray = this.tasting.date.split("-");
          if (dateArray[1].length == 1) {
            dateArray[1] = "0" + dateArray[1]
          }
          if (dateArray[2].length == 1) {
            dateArray[2] = "0" + dateArray[2]
          }
          this.tasting.date = dateArray[0] + "-" + dateArray[1] + "-" + dateArray[2]
        }
        this.isValid = this.nameValid
      },

      async onSave() {
        let local_debug = false
        if (local_debug || this.debug) console.log("");
        if (local_debug || this.debug) console.log("TastingEdit.onSave: START");
        this.validate();
        this.user_id = this.currentUserID;
        this.user = this.currentUser;

        this.tasting.user_id = this.user_id;

        if(this.isValid){
          if (this.tastingExists) {
            if (local_debug || this.debug) console.log("TastingEdit.onSave: Tasting exists, and has id=", this.tasting_id)
            await Tastings.update(this.tasting_id,this.tasting)
            .then(response=>{
              if (local_debug || this.debug) console.log("TastingEdit.onSave: Tasting '",this.tasting,"' succesfully updated with response ", response);
            })
            .catch(error=>{
              if (local_debug || this.debug) console.log("TastingEdit.onSave: catch await Tasting.update ", error);
            })
          } else {
            if (local_debug || this.debug) console.log("TastingEdit.onSave: Tasting does NOT exists, but will be created.")
            await Tastings.create(this.tasting)
            .then(response=>{
              this.tasting_id = response;
              if (local_debug || this.debug) console.log("TastingEdit.onSave: Tasting ",this.tasting, " succesfully created with response ", response);
            })
            .catch(error=>{
              if (local_debug || this.debug) console.log("TastingEdit.onSave: catch await Tastings.create ", error);
            })
            
            if (typeof this.user.tasting_ids === 'undefined') {
              this.user.tasting_ids = [];
            }
            this.user.tasting_ids.push(this.tasting_id);
      
            await Users.update(this.user_id,this.user)
            .then(response=>{
              if (local_debug || this.debug) console.log("TastingEdit.onSave: Organsised Tasting succesfully added to user : id = ", this.user, " with response ", response);
            })
            .catch(error=>{
              if (local_debug || this.debug) console.log("TastingEdit.onSave: catch await Users.update ", error);
            }) 
            this.$store.commit('setUser',this.user)
          }
       
          this.submitted = true;
          this.$router.push({path:'/TastingsOverview', query: {user_id:this.$route.query.user_id}});
          await this.$forceUpdate();
        } 
        if (local_debug || this.debug) console.log("TastingEdit.onSave: STOP");
      },

      onReset() {
        // Reset our form values
        this.tasting.name = ''
        this.tasting.code = ''
        this.tasting.organiser = ''
        this.tasting.description = ''
      },

      onDelete() {
        if(this.isValid){
          console.log("NicheEdit in onDelete - Delete Button pressed");
        } 
      },

      onBack() {
        this.$router.push({path:'/TastingsOverview', query: {user_id:this.$route.query.user_id}});
        this.$store.commit('setPage','TastingsOverview')
      },

      printCode(this_code) {
        let print_code = this_code.toUpperCase()
        print_code = print_code.replace(/\s+/g, '')  
        print_code = print_code.substring(0,2) + ' ' + print_code.substring(2,4) + ' ' + print_code.substring(4,6)
        return print_code;
      },

      shortCode(this_code) {
        let short_code = this_code.toUpperCase()
        short_code = short_code.replace(/\s+/g, '')  
        return short_code;
      },

      async onRetrieve() {
        let local_debug = false
        if (this.debug || local_debug) console.log("");
        if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: START");
        this.code = this.code.toUpperCase()
        this.code = this.code.replace(/\s+/g, '')  
        if (this.code.length != 6) {
          this.isValidCode = false;
          this.codeFeedback = 'your code is '+ this.code.length +' digits long - should be 6 - try again'
        } else {
          this.code = this.code.substring(0,2) + ' ' + this.code.substring(2,4) + ' ' + this.code.substring(4,6)
        }

        let challenge = {"code": this.shortCode(this.code)}
        await fromWineList.getToken(challenge)
        .then(response => {
          if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: winelist_id ", response, " succesfully fetched from DB");
          this.winelist_id = response; 
        })
        .catch(error => {
          if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: await fromWineList - ", error);
        })

        if (this.winelist_id == 0) {
          if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: Tasting ",this.code," does not exists");
          this.isValidCode = false;
          this.codeFeedback = "Invalid code - try again with other tasting code."
          return
        }

        let short_code = this.shortCode(this.code)
        for (let i=0; i < this.tasting_codes.length; i++) {
          if (this.tasting_codes[i] == short_code) {
            if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: Tasting was already subscribed from this Winelist ", short_code)
            let existing_tasting_id = 0;

            let challenge = {"code": short_code}
            await fromTasting.getToken(challenge)
            .then(response => {
              if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: tasting_id ", response, " succesfully fetched from DB");
              existing_tasting_id = response; 
            })
            .catch(error => {
              if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: await fromTasting - ", error);
            })
            this.isValidCode = false;
            this.codeFeedback = "You already subscribed to this tasting. In 5 seconds we forward you to it.";
            if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: ", this.codeFeedback, " ",short_code)
            alert("You already subscribed to this tasting. Close this window to go to it.")
            this.$router.push({path:'/TastingShow', query: {tasting_id:existing_tasting_id}});
            return;
          }
        }
        
        this.isValidCode = true;
        this.codeFeedback = "";

        await WineLists.getone(this.winelist_id)
        .then(response => {
          if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: winelist ", response, " succesfully fetched from DB");
          this.winelist = response;
          delete this.winelist._id
        })
        .catch(error => {
          if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: await WineLists - ", error);
        })

        this.tasting.user_id = this.currentUserID;

        this.tasting.isOrganised = true;
        this.tasting.organiser_id = this.winelist.user_id;
        this.tasting.winelist_id = this.winelist_id

        this.tasting.name = this.winelist.name;
        this.tasting.organiser = this.winelist.organiser;
        this.tasting.description = this.winelist.description;
        this.tasting.date = this.winelist.date;
        this.tasting.image_files = this.winelist.image_files;

        this.tasting.isOpen = this.winelist.isOpen;
        this.tasting.isOrdered = this.winelist.isOrdered;
        this.tasting.isBlind = this.winelist.isBlind;
        this.tasting.code = this.winelist.code;

        this.tasting.tasting_note_ids = [];

        // Create the tasting with this temporary information
        await Tastings.create(this.tasting)
        .then(response => {
          this.tasting_id = response;
          if(this.debug || local_debug) console.log("TastingEdit.onRetrieve: Tasting succesfully created : id = ", this.tasting_id);
        })
        .catch(error =>{
          if(this.debug || local_debug) console.log("TastingEdit.onRetrieve: catch await Tastings.create: ", error);
        })

        // Retrieve items associated to WineList
        let tmp_item
        let tmp_tasting_note
        if (!this.winelist.isOpen) {
          for (let i = 0 ; i < this.winelist.nodes.length ; i++) {
            tmp_item = {}
            tmp_tasting_note = {}
            if (this.winelist.nodes[i].isLeaf) {
              tmp_item = this.winelist.nodes[i].value
              tmp_item._id = this.winelist.nodes[i]._id
              this.items.push(tmp_item)
              
              tmp_tasting_note.user_id = this.currentUserID;
              tmp_tasting_note.name = tmp_item.name;
              tmp_tasting_note.color = tmp_item.color;
              tmp_tasting_note.date = this.tasting.date;
              tmp_tasting_note.owner = this.currentUser.firstname + ' ' + this.currentUser.lastname
              tmp_tasting_note.appearance = '';
              tmp_tasting_note.nose = '';
              tmp_tasting_note.palate = '';
              tmp_tasting_note.aftertaste = '';
              tmp_tasting_note.conclusion = '';
              tmp_tasting_note.score = 0;
              tmp_tasting_note.item_id = tmp_item._id;
              tmp_tasting_note.isDisclosed = tmp_item.isDisclosed;
              tmp_tasting_note.entry_id = 0;
              tmp_tasting_note.tasting_id = this.tasting_id;   

              await TastingNotes.create(tmp_tasting_note)
              .then(response => {
                this.tasting.tasting_note_ids.push(response)
                if(this.debug || local_debug) console.log("TastingEdit.onRetrieve: TastingNote succesfully created : id = ", response);
              })
              .catch(error => {
                if(this.debug || local_debug) console.log("TastingEdit.onRetrieve: catch await TastingNotes.create: ", error);
              })
              
            if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: nodes[",i,"].value.name =", this.winelist.nodes[i].value.name," adding to this.tasting.tasting_notes")
            } else {
              if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: Only Open Tastings can have multiple levels.")
            }
          }
        } else {
          alert("This is an OPEN tasting. You will have to choose which bottles you want to taste from a catalogue.")
        }

        // update Tasting with final information
        await Tastings.update(this.tasting_id,this.tasting)
        .then(response => {
          if(this.debug || local_debug) console.log("TastingEdit.onRetrieve: Tasting succesfully updated : id = ", this.tasting_id, " with response ", response);
        })
        .catch(error =>{
          if(this.debug || local_debug) console.log("TastingEdit.onRetrieve: catch await Tastings.update: ", error);
        })

        // Adding new tasting to user.tasting_ids list.
        this.user.tasting_ids.push(this.tasting_id)
        await Users.update(this.user_id,this.user)
        .then(response => {
          if(this.debug || local_debug) console.log("TastingEdit.onRetrieve: User succesfully updated: id = ", this.user_id, " with response ", response);
          this.$store.commit('setUser',this.user)
        })
        .catch(error => {
          if(this.debug || local_debug) console.log("TastingEdit.onRetrieve: catch await Users.update: ", error);
        })

        // Adding new tasting to winelist.tasting_ids list.
        this.winelist.tasting_ids.push(this.tasting_id)
        await WineLists.update(this.winelist_id,this.winelist)
        .then(response => {
          if(this.debug || local_debug) console.log("TastingEdit.onRetrieve: WineList succesfully updated: id = ", this.winelist_id, " with response ", response);
          this.$store.commit('setWinelist',this.winelist)
        })
        .catch(error => {
          if(this.debug || local_debug) console.log("TastingEdit.onRetrieve: catch await WineLists.update: ", error);
        })

        if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: STOP");
      
        this.$router.push({path:'/TastingShow', query: {tasting_id:this.tasting_id}});          
        if (this.debug || local_debug) console.log("TastingEdit.onRetrieve: STOP");
      },

      changeDate() {
        this.tasting.nice_date = formatDate(new Date(this.tasting.date))
      },

      async vfileAdded(new_image_file) {
        let local_debug = false
        if ( !new_image_file.manuallyAdded ) {
          if (local_debug)  console.log("tasting picture URL: ", new_image_file);
          if (this.tasting.image_files.length > 0) {
            var old_image_file = this.tasting.image_files.pop();
            this.$refs.imgDropzone.removeFile(old_image_file);
          }
          let org_base64 = new_image_file.dataURL;
          if (local_debug)  console.log("org_base64: ", org_base64.substring(0,20));
          let cc_base64 = await cc64(org_base64,3,2);
          new_image_file.dataURL = cc_base64;
          if (local_debug)  console.log("cc_base64: ", cc_base64.substring(0,20));
          this.tasting.image_files.push(new_image_file);
          if (local_debug)  console.log("this.tasting.image_files[0].dataURL: ", this.tasting.image_files[0].dataURL.substring(0,20));
          if (local_debug)  console.log("this.tasting: ", this.tasting);
          this.hasChanged = true;
        }
      },
      vfileRemoved(image_file) {
        // this.tasting.image_files.pop(image_file);
        for( var i = 0; i < this.tasting.image_files.length; i++){
          if ( this.tasting.image_files[i].dataURL === image_file.dataURL) { 
            this.tasting.image_files.splice(i, 1); 
          }
        }
        this.hasChanged = true;
      },
      template: function () {
         return `<div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                      <div class="dz-thumbnail" data-dz-thumbnail-bg></div>
                      <div class="thumbnail-overlay">
                        <div class="text" style="text-align: center; margin-top: 30px" data-dz-remove>remove</div>
                      </div>
                  </div>
                  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                  <div class="dz-error-message"><span data-dz-errormessage></span></div>
                  <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                  <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                </div>`;
      },
      thumbnail: function(file, dataUrl) {
        var j, len, ref, thumbnailElement;
        if (file.previewElement) {
            file.previewElement.classList.remove("dz-file-preview");
            ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
            for (j = 0, len = ref.length; j < len; j++) {
                thumbnailElement = ref[j];
                thumbnailElement.alt = file.name;
                thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
            }
            return setTimeout(((function() {
                return function() {
                    return file.previewElement.classList.add("dz-image-preview");
                };
            })(this)), 1);
        }
      },
      
      doNothing() {
      }, 
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
#profiledropzone {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;

  background-color: transparent !important;
  border-style: none;
  border-radius: 5px;

  color:white;

  transition: background-color .2s linear;
}

.c-image-part {
  background-image: url("../assets/img/tastings.jpg");
}
</style>
<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0">
            <div class="menu-top" style="text-align:center">
              <p style="margin-bottom:0"><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
              <p style="position: relative; top: -5px;font-size: 10px; color: rgb(109,122,82);margin-bottom:0">organise</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
          </b-col>
        </b-row>        
        <b-row>
          <b-col style="padding:0">     
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">organise > winelists > search</p>
            <br><br>
            <h1 class="main-title"><b><span style="color: rgb(109,122,82)">WYNE</span></b><span style="color: black">TASTER</span></h1>
            <br>
            <div style="margin:0 15px 0 15px">
              <b-form-group>
                <b-form-input
                  id="input-search"
                  class = "w-input"
                  style = "background-color:white !important; padding-left: 40px"
                  v-model="search_string"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.search_stringValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  v-on:keydown.enter.prevent="onSearch">
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{search_stringFeedback}}
                </b-form-invalid-feedback>                
              </b-form-group>
              <div v-if="search_stringFeedback==''" style="width:20px; position: relative; float:left; top: -42px; left: 10px; margin: 0; padding:0">
                <font-awesome-icon 
                  style="font-size:17px;color: rgb(109,122,82);" 
                  icon="search" />
              </div>
              <div v-else style="width:20px; position: relative; top: -65px; margin: 0; left: -47%;">
                <font-awesome-icon 
                  style="font-size:17px;color: rgb(109,122,82);" 
                  icon="search" />
              </div>
              <div style="position:relative;top:-10px">
                <b-button v-if="search_string.length >0" v-on:click="onClear()" class="w-button-pink-full">clear</b-button>
                <b-button v-else v-on:click="onBack()" class="w-button-pink-full">back</b-button>
              </div>
              <div v-if="ready4Search" style="position:relative;top:-10px; margin-top:10px">
                <b-button v-on:click="onSearch()" class="w-button-green-full">search</b-button>
              </div>
              <div v-else style="position:relative;top:-10px; margin-top:10px">
                <b-button class="w-button-green-full">
                  searching 
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </b-button>
              </div>  
            </div>

            <hr v-if="showResultZone" style="margin-top:10px">
            <b-row
              v-for="(result, index) in results"
              v-bind:item="result"
              v-bind:index="index"
              v-bind:key="result._id" 
            >
              <b-col 
                v-on:click="onSelect(result)"  
              >
                <b-row style="margin-left:15px; margin-right:15px">
                  <b-col cols="1">
                    <b-img left
                      v-if="result.image_files[0]"
                      class="fluid-grow"
                      max-width="20"
                      height="60"
                      v-bind:src="result.image_files[0].dataURL"
                    ></b-img>
                  </b-col>
                  <b-col>
                    <p class="t-title" style="text-align: left; margin-top:0">{{result.name}} <span v-if="result.type"> - {{result.type}} </span>{{result.vintage}}</p>
                    <div
                      v-for="(hit, index) in result.hits"
                      v-bind:item="hit"
                      v-bind:index="index"
                      v-bind:key="hit.center"
                    >
                      <p v-if="(hit.success == true)" class="t-text" style="text-align: left;">hit: {{hit.left}}<span><b>{{hit.center}}</b></span>{{hit.right}}</p>
                      <p v-else class="t-text" style="text-align: left;">missing: <s>{{hit.center}}</s></p>
                    </div>
                  </b-col>
                </b-row>
                <hr>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p style="margin-bottom:0"><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
              <p style="position: relative; top: -5px;font-size: 10px; color: rgb(109,122,82);margin-bottom:0">organise</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr style="position: relative; top: -10px">
            <Menu/>
          </template>
        </b-sidebar>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import cleanForSearch from '../assets/js/cleanForSearch.js';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuMobile_Organise.vue';

  import Users from '../Users.js'

  import WineLists from '../WineLists.js'

  import Bottles from '../Bottles.js'
  import Wines from '../Wines.js'
  import C_Wines from '../C_Wines.js'

  export default {
    name: "WinelistsSearch",
    components: {
      NoAccess,
      Menu,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      searchState() {
        return this.$store.state.search_state
      },
    },
    data() {
      return {
        debug: false,
        isLoading : true,

        isValid : true,
        inEdit: false,

        user:{},
        user_id:0,

        search_string:'',
        search_stringValid:true,
        search_stringFeedback:'',
        keywords:[],

        search_state:{},

        winelists:[],
        items:[],

        resultsLoaded:false,

        results:[],

        showResultZone: false,

        // searching
        ready4Search: true, 
      }
    }, 
    async mounted() {
      if (this.debug) console.log("");
      if (this.debug) console.log("WinelistsSearch.mounted: START");

      this.user_id = this.currentUserID;
      this.user = this.currentUser;

      if (this.debug) console.log("WinelistsSearch.mounted: current user = ", this.user.email);
      if (this.debug) console.log("WinelistsSearch.mounted: has ", this.user.winelist_ids.length, "  winelists.");

      for (let i=0 ; i < this.user.winelist_ids.length ; i++) {
        await WineLists.getone(this.user.winelist_ids[i])
        .then(response=> {
          this.winelists.push(response);
          if (this.debug) console.log( "WinelistsSearch.mounted: Tasting ", response, " succesfully fetched from DB");
        })
        .catch(error=> {
          if (this.debug) console.log("WinelistsSearch.mounted: await Tastings.getone", error);
        })
      }
      if (this.debug) console.log("WinelistsSearch.mounted: =======================================");

      if (this.previousPage == 'WinelistShow' && this.searchState != {}) {
        console.log("TMP there is a search_state")
        this.search_string = this.searchState.search_string
        this.results = this.searchState.results
      } else {
        this.onClear()
      }

      this.isLoading = false;
      if (this.debug) console.log("WinelistsSearch.mounted: STOP");
    },
    methods: {
      validate(){
        let local_debug = false
        if (local_debug) console.log("====> WinelistsSearch.validate: this.search_string.length = ", this.search_string.length)
        if(this.search_string.length >= 3) {
            this.search_stringValid = true;
            this.search_stringFeedback='';
        } else {
            this.search_stringValid = false;
            this.search_stringFeedback='search should have minimum 3 characters';
        }
        this.isValid = this.search_stringValid
      },
      async onSearch() {
        let local_debug = false
        if (this.debug) console.log("")
        if (this.debug) console.log("WinelistsSearch.onSearch - START")
        if (this.debug) console.log("WinelistsSearch.onSearch: search_string = ", this.search_string)

        this.search_state = {}
        this.$store.commit('setSearchState',this.search_state)
        this.results = []

        this.validate()
        if ( !this.isValid) {
          if (this.debug) console.log("WinelistsSearch.onSearch - STOP because invalid search_string")
          return
        }

        this.ready4Search = false

        this.keywords = this.search_string.split(/[ ,]+/);        

        if (this.resultsLoaded == false) {
          if (this.debug) console.log("WinelistsSearch.onSearch: NOT resultsLoaded - retrieving Items");
          let k = 0
          for (let i=0 ; i < this.winelists.length ; i++) {
            if (this.debug) console.log("WinelistsSearch.onSearch: Tasting ", this.winelists[i].name, " has ", this.winelists[i].nodes.length, " nodes.");
            for (let n1=0 ; n1 < this.winelists[i].nodes.length ; n1++) {
              if (local_debug) console.log("=> TMP n1 = ", n1, " winelist.nodes =", this.winelists[i].nodes[n1])
              if (local_debug) console.log("=> TMP n1 = ", n1, " winelist.nodes.value =", this.winelists[i].nodes[n1].value)
              if (local_debug) console.log("=> TMP n1 = ", n1, " winelist.nodes.isLeaf =", this.winelists[i].nodes[n1].isLeaf)
              if (this.winelists[i].nodes[n1].isLeaf == true) {
                this.items[k] = new Object() 
                this.items[k] = this.winelists[i].nodes[n1].value
                await this.complete_item(k)
                this.challenge(this.items[k])
                k++  
              } else {
                for (let n2=0 ; n2 < this.winelists[i].nodes[n1].nodes.length ; n2++) {
                  if (local_debug) console.log("===> TMP n2 = ", n1, " winelist.nodes =", this.winelists[i].nodes[n1].nodes[n2])
                  if (local_debug) console.log("===> TMP n2 = ", n1, " winelist.nodes.value =", this.winelists[i].nodes[n1].nodes[n2].value)
                  if (local_debug) console.log("===> TMP n2 = ", n1, " winelist.nodes.isLeaf =", this.winelists[i].nodes[n1].nodes[n2].isLeaf)
                  if (this.winelists[i].nodes[n1].nodes[n2].isLeaf == true) {
                    this.items[k] = new Object() 
                    this.items[k] = this.winelists[i].nodes[n1].nodes[n2].value
                    await this.complete_item(k)
                    this.challenge(this.items[k])
                    k++
                  } else {
                    for (let n3=0 ; n3 < this.winelists[i].nodes[n1].nodes[n2].nodes.length ; n3++) {
                      if (local_debug) console.log("=====> TMP n3 = ", n1, " winelist.nodes =", this.winelists[i].nodes[n1].nodes[n2].nodes[n3])
                      if (local_debug) console.log("=====> TMP n3 = ", n1, " winelist.nodes.value =", this.winelists[i].nodes[n1].nodes[n2].nodes[n3].value)
                      if (local_debug) console.log("=====> TMP n3 = ", n1, " winelist.nodes.isLeaf =", this.winelists[i].nodes[n1].nodes[n2].nodes[n3].isLeaf)
                      if (this.winelists[i].nodes[n1].nodes[n2].nodes[n3].isLeaf == true) {
                        this.items[k] = new Object()
                        this.items[k] = this.winelists[i].nodes[n1].nodes[n2].nodes[n3].value
                        await this.complete_item(k)
                        this.challenge(this.items[k])
                        k++
                      } else {
                        for (let n4=0 ; n4 < this.winelists[i].nodes[n1].nodes[n2].nodes[n3].nodes.length ; n4++) {
                          if (local_debug) console.log("=====> TMP n4 = ", n1, " winelist.nodes =", this.winelists[i].nodes[n1].nodes[n2].nodes[n3].nodes[n4])
                          if (local_debug) console.log("=====> TMP n4 = ", n1, " winelist.nodes.value =", this.winelists[i].nodes[n1].nodes[n2].nodes[n3].nodes[n4].value)
                          if (local_debug) console.log("=====> TMP n4 = ", n1, " winelist.nodes.isLeaf =", this.winelists[i].nodes[n1].nodes[n2].nodes[n3].nodes[n4].isLeaf)
                          if (this.winelists[i].nodes[n1].nodes[n2].nodes[n3].nodes[n4].isLeaf == true) {
                            this.items[k] = new Object()
                            this.items[k] = this.winelists[i].nodes[n1].nodes[n2].nodes[n3].nodes[n4].value
                            await this.complete_item(k)
                            this.challenge(this.items[k])
                            k++
                          } else {
                            if (this.debug) console.log("WinelistsSearch.onSearch - SEVERE ERROR - winelist has more that 4 levels.")
                            alert("WinelistsSearch.onSearch - SEVERE ERROR - winelist has more that 4 levels.")
                          }
                        }
                      }
                    }
                  }
                }
              }            
            }
          }
          this.resultsLoaded = true;
        } else {
          if (this.debug) console.log("WinelistsSearch.onSearch: resultsLoaded !!!");
          for (let k = 0; k < this.items.length; k++) {
            this.challenge(this.items[k])
          }
        }

        this.search_state.search_string = this.search_string
        this.search_state.results = this.results
        this.$store.commit('setSearchState',this.search_state)

        this.ready4Search = true
        if (this.debug) console.log("TastingsSearch.onSearch - STOP")
      },
      onClear() {
        this.search_string = ''
        this.results = []
        this.search_state = []
        this.search_stringValid = true;
        this.search_stringFeedback = '';
        this.showResultZone = false
        this.$store.commit('setSearchState',this.search_state)
      },
      onSelect(item) {
        if (this.debug) console.log("")
        if (this.debug) console.log("WinelistsSearch.onSelect: START")
        if (this.debug) console.log("WinelistsSearch.onSelect: item = ", item)
        // this.$store.commit('setPage','TastingShow')
        // this.$router.push({path:'/WinelistShow', query: {winelist_id:winelist_id})
        if (this.debug) console.log("WinelistsSearch.onSelect: STOP")
      },

      onBack() {
        let local_debug = true
        if (typeof this.previousPage != "undefined") {
          this.$router.push({path:'/'+ this.previousPage})
          this.$store.commit('setPage',this.previousPage)
          if (local_debug) console.log("====> WinelistsSearch.onBack: going back to previous page: ", this.previousPage)
        } else {
          if (local_debug) console.log("====> WinelistsSearch.onBack: ERROR previousPage is ", typeof this.previousPage)
          alert("ERROR in WinelistsSearch.onBack: previousPage is "+ typeof this.previousPage)
        }
      },
      async complete_item(k){
        let local_debug = false
        if (local_debug) console.log( "==> WinelistsSearch.complete_item:  k = ",k," Item ", this.items[k] );
        await Bottles.getone(this.items[k].bottle_id)
        .then(response=> {
          if (local_debug) console.log( "==> WinelistsSearch.complete_item: Bottle ", response, " succesfully fetched from DB");
          for (const [key, value] of Object.entries(response)) {
            if (key == 'vintage') {
              this.items[k][key] = value
            }
            if (key == 'c_wine_id') {
              this.items[k][key] = value
            }
            if (key == 'wine_id') {
              this.items[k][key] = value
            }
          }
        })
        .catch(error=> {
          if (local_debug) console.log("==> WinelistsSearch.complete_item: await Items.getone", error);
        })
        if (this.items[k].c_wine_id !=0 && typeof this.items[k].c_wine_id != "undefined") {
          await C_Wines.getone(this.items[k].c_wine_id)
          .then(response=> {
            if (local_debug) console.log( "==> WinelistsSearch.complete_item: C_Wines ", response, " succesfully fetched from DB");
            for (const [key, value] of Object.entries(response)) {
            if (key != '_id') {
              this.items[k][key] = value
            }
          } 
          })
          .catch(error=> {
            if (local_debug) console.log("==> WinelistsSearch.complete_item: await C_Wines.getone", error);
          })
        } else if (this.items[k].wine_id !=0 && typeof this.items[k].wine_id != "undefined") {
          await Wines.getone(this.items[k].wine_id)
          .then(response=> {
            if (local_debug) console.log( "==> WinelistsSearch.complete_item: Wines ", response, " succesfully fetched from DB");
            for (const [key, value] of Object.entries(response)) {
            if (key != '_id') {
              this.items[k][key] = value
            }
          } 
          })
          .catch(error=> {
            if (local_debug) console.log("==> WinelistsSearch.complete_item: await Wines.getone", error);
          })
        } else {
          alert("SEVERE ERROR: c_wine_id = 0 && wine_id = 0")
        }

        this.items[k].searchZone = ''
        let sep = ':'
        if (typeof this.items[k].name != 'undefined') this.items[k].searchZone = this.items[k].searchZone + cleanForSearch(this.items[k].name)
        if (typeof this.items[k].type != 'undefined' &&  this.items[k].type != '') this.items[k].searchZone =  this.items[k].searchZone + sep + cleanForSearch(this.items[k].type)
        if (typeof this.items[k].vintage != 'undefined') this.items[k].searchZone = this.items[k].searchZone +  sep + cleanForSearch(this.items[k].vintage)
        if (typeof this.items[k].country != 'undefined') this.items[k].searchZone = this.items[k].searchZone +  sep + cleanForSearch(this.items[k].country)
        if (typeof this.items[k].region != 'undefined' &&  this.items[k].region != '') this.items[k].searchZone = this.items[k].searchZone +  sep + cleanForSearch(this.items[k].region)
        if (typeof this.items[k].appellation != 'undefined' &&  this.items[k].appellation != '') this.items[k].searchZone = this.items[k].searchZone +  sep + cleanForSearch(this.items[k].appellation)
        if (typeof this.items[k].classification != 'undefined' &&  this.items[k].classification != '') this.items[k].searchZone = this.items[k].searchZone +  sep + cleanForSearch(this.items[k].classification)

        if (typeof this.items[k].grapes != 'undefined' &&  this.items[k].grapes != '') this.items[k].searchZone = this.items[k].searchZone +  sep + cleanForSearch(this.items[k].grapes)
        if (typeof this.items[k].longevity != 'undefined' && this.items[k].longevity != '') this.items[k].searchZone = this.items[k].searchZone +  sep + cleanForSearch(this.items[k].longevity)
        if (typeof this.items[k].vines != 'undefined' && this.items[k].vines != '') this.items[k].searchZone = this.items[k].searchZone +  sep + cleanForSearch(this.items[k].vines)
        if (typeof this.items[k].yields != 'undefined' && this.items[k].yields != '') this.items[k].searchZone = this.items[k].searchZone +  sep + cleanForSearch(this.items[k].yields)
        if (typeof this.items[k].vinification != 'undefined' && this.items[k].vinification != '') this.items[k].searchZone = this.items[k].searchZone +  sep + cleanForSearch(this.items[k].vinification)
        if (local_debug) console.log( "-----------------------------------------------");
      },
      challenge(item) {
        let local_debug = false
        // Simple pass on all keywords
        if (local_debug) console.log("") 
        if (local_debug) console.log("==> WinelistsSearch.challenge: START") 
        if (local_debug) console.log("==> WinelistsSearch.challenge = ", item)
        item.number_of_hits = 0
        item.hits = []
        for ( let l=0 ; l < this.keywords.length ; l++ ) {
          let clean_keyword = cleanForSearch(this.keywords[l])
          if (local_debug ) console.log("==> WinelistsSearch.challenge: l = ",l," clean_keyword = ", clean_keyword)
          
          item.hits.push({"left":0,"center":clean_keyword, "right":0})
        
          let max = Math.trunc(100/this.keywords.length)
          let success = item.searchZone.includes(clean_keyword)
          if (local_debug) console.log("==> WinelistsSearch.challenge: Trying ",item.searchZone, " success = ", success)
          if(success) {
            item.number_of_hits++;
            item.success = true;
            item.hits[l].success = true;                
            if (local_debug) console.log("==> WinelistsSearch.challenge: BINGO on ",item.searchZone)
            let i0 = 0
            let i1 = item.searchZone.indexOf(clean_keyword)
            if ( i1 > max/2-clean_keyword.length/2) {
              i0 = Math.trunc(i1 - max/2 - clean_keyword.length/2)
            }
            let i2 = item.searchZone.indexOf(clean_keyword) + clean_keyword.length
            let i3 = item.searchZone.length
            if ( i3 > i0 + max ) {
              i3 = i0 + max
            }
            item.hits[l].left = item.searchZone.substring(i0,i1)
            item.hits[l].center = clean_keyword
            item.hits[l].right = item.searchZone.substring(i2,i3)
            if (local_debug) console.log("==> WinelistsSearch.challenge: SUCCESS: item.hits[",l,"] = ", item.hits[l])
          } else {
            item.hits[l].success = false
            item.hits[l].left = 0
            item.hits[l].center = clean_keyword
            item.hits[l].right = 0
          }
        }
        if (item.number_of_hits > 0) {
          this.results.push(item)
          this.showResultZone = true
          this.results.sort(function(a, b) {
            if(a.number_of_hits < b.number_of_hits) { return 1; }
            if(a.number_of_hits > b.number_of_hits) { return -1; }
            return 0;
          });
        }
        if (local_debug) console.log("==> WinelistsSearch.challenge: STOP")
      },

      doNothing() {
      },
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.c-image-part {
  background-image: url("../assets/img/tastings.jpg")
}
</style>
import { API } from './API.js';
import axios from 'axios';

const debug = false;
const url = API + '/tasting_notes_from_item_id/';

class fromItemID {
    static getTastingNotes(postdata) {
    return axios.post(url, postdata)
        .then(response => {
            if (debug) console.log('fromItemID.getTastingNotes: ',response.data)
            return response.data
        })
        .catch(error => {
            console.log('fromItemID.getTastingNotes: ',error)
        })
    }
}

export default fromItemID

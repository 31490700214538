<template>
<div>
  <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
  </b-container>
  <div v-else>
    <div class="w-menu">
      <br>
      <b-row class="w-menu-avatar w-cursor-click" @click="gotoProfile()">
        <b-col>
          <b-avatar v-if="currentProfilePicture" 
            size="50px" 
            v-bind:src="currentProfilePicture"
          >
          </b-avatar>
          <b-avatar v-else 
            size="50px" 
            :src="require('@/assets/img/profile-picture.jpg')"
          >
          </b-avatar>
        </b-col>
      </b-row>
      <b-row class="w-menu-under-avatar">
        <b-col style="padding:0" class="text-center">
            <p class="w-menu-name">{{currentUser.firstname}} {{currentUser.lastname}}</p>
            <p class="w-menu-email">{{currentUser.email}}</p>
        </b-col>
      </b-row>      
      <b-row class="d-flex flex-row justify-content-center" style="width:100%; margin:0">  
        <div class="justify-content-left">
          <p class="w-menuitem-1" style="top:120px;color:white">organise</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:157px" @click="gotoWineListsOverview()">winelists</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:194px" @click="gotoWineListsOverview()">overview</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:231px" @click="gotoWineListsSearch()">search</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:268px" @click="gotoAboutUs()">about us</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:305px" @click="logOut()">log off</p>
        </div>
        <div v-if="this.currentPage=='WineListsOverview'" class="w-current-item" style="top:192px"/>
        <div v-if="this.currentPage=='WineListsSearch'" class="w-current-item" style="top:229px"/>
        <div v-if="this.currentPage=='AboutUs'" class="w-current-item" style="top:266px"/>
      </b-row>
      <div class="w-menu-footer">
        <p><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    computed : {
      currentUser() {
        return this.$store.state.user
      },
      currentProfilePicture() {
        return this.$store.state.profile_picture
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      }
    },
    data() {
      return {
        isLoading : true,
      }
    },
    async mounted() {
      this.isLoading = false
    },
    methods:{
      gotoProfile() {
        if (this.currentPage != 'Profile') {
          this.$store.commit('setPage','Profile')
          this.$router.push({path:'/Profile'})
        }
      },
      gotoWineListsOverview() {
        if (this.currentPage != 'WineListsOverview') {
          this.$store.commit('setPage','WineListsOverview')
          this.$router.push({path:'/WineListsOverview'})
        }
      },

      gotoWineListsSearch() {
        if (this.currentPage != 'WineListsSearch') {
          this.$store.commit('setPage','WineListsSearch')
          this.$router.push({path:'/WineListsSearch'})
        }
      },

      gotoAboutUs() {
        if (this.currentPage != 'AboutUs') {
          this.$store.commit('setPage','AboutUs')
          if (this.debug) console.log("MenuDesktop.gotoAboutUs")
          this.$router.push({path:'/AboutUs'});
        }
      }, 

      logOut() {
        this.$store.commit('logout')
        this.$router.push({path:'/'})
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
</style>
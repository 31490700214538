import { API } from './API.js';
import axios from 'axios';

const url = API + '/users/'

class Connection {
    static test() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);               
                resolve(true);
                console.log('Connection.test: Connection OK')
            } catch(err) {
                resolve(false);
                console.log('Connection.test: No Connection')
            }
        })
    }
}

export default Connection
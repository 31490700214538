<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0" align="center">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>LOVER</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
            <div class="w-box text-center" style="width:180px;height:180px"> 
              <b-avatar v-if="user.image_files.length" 
                size="100px" 
                v-bind:src="user.image_files[0].dataURL"
              >
              </b-avatar>
              <b-avatar v-else 
                size="100px" 
                :src="require('@/assets/img/profile-picture.jpg')"
              >
              </b-avatar>
              <p ref="pictureComment" class="profile-name">{{pictureComment}}</p>
              <div class="dropbox-overlay text-center" style="width:180px;height:180px">
                <vue-dropzone 
                  ref="imgDropzone" id="profiledropzone" 
                  :options="dropzoneOptions"
                  @vdropzone-thumbnail="vfileAdded" 
                >
                </vue-dropzone>    
              </div>
            </div>
            <b-tooltip target="avatar" triggers="hover">
              click or drag and drop your picture here
            </b-tooltip>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-table">
            <div class="m-box">
              <h1 class="w-subtitle" style="text-align:right">personal information</h1>
              <b-form-group>
                <label class="w-label w-required" for="input-name">first name</label>
                <b-form-input
                id="input-firstname"
                class = "w-input"
                v-model="user.firstname"
                @change="changeName"
                v-bind:class="{'is-invalid' : !this.firstnameValid}"
                aria-describedby="input-live-help input-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{firstnameFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>
                        
              <b-form-group>
                <label class="w-label w-required" for="input-lastname">last name</label>
                <b-form-input
                id="input-lastname"
                class = "w-input"
                v-model="user.lastname"
                @change="changeName"
                v-bind:class="{'is-invalid' : !this.lastnameValid}"
                aria-describedby="input-live-help input-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{lastnameFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>
              
              <b-form-group style="text-align:left;margin-bottom:0">
                <label class="w-label w-required" for="genderList">gender</label>                                    
                <model-select
                  class="w-select"
                  :options="genders"
                  v-model="user.gender"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.genderValid}"
                >
                </model-select>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{genderFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group>
                <label class="w-label w-required" for="input-birthdate">date of birth</label>
                <b-form-input
                id="input-birthdate"
                class = "w-input"
                v-model="user.birthdate"
                v-bind:class="{'is-invalid' : !this.birthdateValid}"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="YYYY-MM-DD"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{birthdateFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="m-box">
              <h1 class="w-subtitle" style="text-align:right">wyne information</h1>
              
              <b-form-group v-if="isMaker">
                <label class="w-label-inactive" for="input-wineryname">winery name</label>
                <b-form-input
                id="input-wineryname"
                class = "w-input-inactive"
                v-model="winery.name"
                v-bind:class="{'is-invalid' : !this.winerynameValid}"
                aria-describedby="input-live-help input-live-feedback"
                disabled
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{winerynameFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>
              
              <b-form-group v-if="isCellar">
                <label class="w-label w-required" for="input-cellarname">cellar name</label>
                <b-form-input
                id="input-cellarname"
                class = "w-input"
                v-model="cellar.name"
                v-bind:class="{'is-invalid' : !this.cellarnameValid}"
                aria-describedby="input-live-help input-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{cellarnameFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="m-box">
                <h1 class="w-subtitle" style="text-align:right">account information</h1>
                <b-form-group> 
                  <label class="w-label w-required" for="input-name">email</label>
                  <b-form-input
                  id="input-email"
                  class = "w-input"
                  v-model="new_email"
                  v-bind:class="{'is-invalid' : !this.emailValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{emailFeedback}}
                  </b-form-invalid-feedback>
                </b-form-group>

              <b-form-checkbox 
                id="toggle"
                v-model="changePassword"
              >
                <p class="w-label">I want to change my password.</p>
              </b-form-checkbox>
              
              <b-form-group v-if="changePassword">
                <label class="w-label" for="input-oldpassword">old password</label>
                <b-form-input
                  id="input-oldpassword"
                  class = "w-input"
                  type="password"
                  v-model="old_password"
                  v-bind:class="{'is-invalid' : !this.old_passwordValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  >
                </b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{old_passwordFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group v-if="changePassword">
                <label class="w-label" for="input-newpassword">new password</label>
                <b-form-input
                  id="input-newpassword"
                  class = "w-input"
                  type="password"
                  v-model="new_password"
                  v-bind:class="{'is-invalid' : !this.new_passwordValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  >
                </b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{new_passwordFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group v-if="changePassword">
                <label class="w-label" for="input-name">confirm new password</label>
                  <b-form-input
                  id="input-confirm_password"
                  class = "w-input"
                  type="password"
                  v-model="confirm_password"
                  v-bind:class="{'is-invalid' : !this.new_passwordValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{new_passwordFeedback}}
                  </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="m-box">
              <b-button class="w-button-green-full" v-on:click="onUpdate()">update</b-button>
              <b-button class="w-button-pink-full" style="margin-top:10px" v-on:click="onBack()" >back</b-button>
            </div>
          </b-col>
        </b-row>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>LOVER</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <Menu/>
          </template>
        </b-sidebar>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';
  import leapYear from '../assets/js/leapYear.js';
  
  import NoAccess from './NoAccess.vue'
  import Menu from './MenuMobile.vue'

  import { ModelSelect } from 'vue-search-select';
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  
  import Users from '../Users.js';
  import UserID_from_Email from '../UserID_from_Email.js';
  import Authenticate from '../Authenticate.js';
  import ChangeEmail from '../ChangeEmail.js';

  import Wineries from '../Wineries.js';
  import Cellars from '../Cellars.js';

  export default {
    name: 'Profile',
    components:{
      NoAccess,
      Menu,
      ModelSelect,
      vueDropzone: vue2Dropzone,
    },

    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentProfilePicture() {
        return this.$store.state.profile_picture
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      previousPage() {
        return this.$store.state.previous_page
      }
    },
    data() {
      return {
        debug: false,
        isLoading: true,
        
        user: {
            firstname : '',
            lastname : '',
            email : '',
            hashed_password : '',
            cellar_id : '',
            winery_id : '',
            image_files : [],
            tasting_ids : [],
            profile_picture : '',
            isConfirmed : false,
            hasAgreedTerms : false,
        },
        user_id: 0,

        cellar: {
            name : '',
            user_id : '',
            niche_ids: [],
            image_files: []
        },
        cellar_id: 0,

        winery: {
            name : '',
            user_id : '',
            wine_ids: [],
            image_files: []
        },
        winery_id: 0,

        pictureComment: 'name comes here',

        isMaker : false,
        isCellar : false,

        isUpdate: false,

        // Validation Logic
        firstnameValid : true,
        firstnameFeedback : '',

        lastnameValid : true,
        lastnameFeedback : '',

        cellarnameValid : true,
        cellarnameFeedback : '',

        winerynameValid : true,
        winerynameFeedback : '',

        genderValid : true,
        genderFeedback : '',

        birthdateValid : true,
        birthdateFeedback : '',

        changePassword:false,
        old_password:'',
        new_password : '',
        confirm_password : '',
        
        new_email : '',

        emailValid : true,
        emailFeedback : '',

        userValid : true,

        old_passwordValid : true,
        old_passwordFeedback : '',
        
        new_passwordValid : true,
        new_passwordFeedback : '',

        formValid : false,
        gotoHome : false,

        genders:[{text:'Male',value:'M'},{text:'Female',value:'F'},{text:'Non-Binary',value:'X'}],

        // dropzone variables
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : '',
          maxFilesize: 10,
        },
      }
    },
    async mounted() {
      if (this.debug) console.log("Profile.mounted: START");

      this.user_id = this.currentUserID;
      if (this.user_id.length == 24) {
        this.isUpdate = true;
        this.user = this.currentUser;
        if (this.debug) console.log("Profile.mounted: user = ", this.user);
        this.new_email = this.user.email;
        this.changeName();
      } else {
        this.isUpdate = false;
        if (this.debug) console.log("Profile.mounted: This is a new user.");
      }

      if (typeof this.user.winery_id != "undefined" && this.user.winery_id.length == 24) {
        console.log("TMP BINGO 1B")
        if (this.debug) console.log("Profile.mounted: Fetching winery from DB "+ this.user.winery_id);
        await Wineries.getone(this.user.winery_id)
        .then(response=>{
          if (typeof response == 'object') {
            this.isMaker = true;
            this.winery = response;
            this.winery_id = this.winery._id
            delete this.winery._id
            if (this.debug) console.log( "Profile.mounted: Winery ", response , " succesfully fetched from DB");
          } else {
            if (this.debug) console.log( "Profile.mounted: Winery with id ", this.user.winery_id , " does NOT exist in DB");
          }
        })
        .catch(error=>{
          if (this.debug) console.log("Profile.mounted: catch await Winery", error);
        })
      }

      if (typeof this.user.cellar_id != "undefined" && this.user.cellar_id.length == 24) {
        if (this.debug) console.log("Profile.mounted: Fetching cellar from DB "+ this.user.cellar_id);
        await Cellars.getone(this.user.cellar_id)
        .then(response=>{
          if (typeof response == 'object') {
            this.isCellar = true;
            this.cellar = response;
            this.cellar_id = this.cellar._id;
            delete this.cellar._id;
            if (this.debug) console.log( "Profile.mounted: Cellar ", response, " succesfully fetched from DB");
          } else {
            if (this.debug) console.log( "Profile.mounted: Cellar with id ", this.user.cellar_id , " does NOT exist in DB");
          }
        })
        .catch(error=>{
          if (this.debug) console.log("Profile.mounted: catch await Cellar", error);
        })
      }

      this.isLoading = false
      if (this.debug) console.log("Profile.mounted: STOP");
    },
    methods: {
      validateEmail() {
        let hasAt = false;
        let hasDot = false;
        for (let i=0;i < this.new_email.length;i++) {
            if (this.new_email[i] === '@') {
                hasAt = true;
            }
            if (this.new_email[i] === '.') {
                hasDot = true;
            }
        }
        this.emailValid = hasAt && hasDot
        if ( ! this.emailValid) {
            this.emailValid = false
            this.emailFeedback='This is an invalid email format';
        }
      },

      async validateUser() {
        this.userValid = true;
        this.emailValid = true;
        
        if ( this.new_email != this.user.email) {
          let challenge = {"email": this.new_email};
          let userExists = false;
          await UserID_from_Email.getToken(challenge)
          .then(response => {
            if (response === 0) {
              if (this.debug) console.log("Profile in validateUser: user with email ", this.new_email, " has no id and does NOT exists in DB");
              userExists = false;
            } else {
              if (this.debug) console.log("Profile in validateUser: user with email ", this.new_email, " has id = ", response, " and exists in DB");
              userExists = true;
            }
          })
          .catch(error => {
            userExists = true;
            if (this.debug) console.log("Profile in validateUser: await UserID_from_Email - ", error);
          })

          if (userExists === false ) {
            if (this.debug) console.log("Profile in validateUser : User " + this.new_email + " does not exists.")
          } else {
            this.emailFeedback = 'User ' + this.new_email + ' already exists and is linked to another account. Nothing done.'
            this.userValid = false;
            this.emailValid = false;
            if (this.debug) console.log("Profile in validateUser :"  + this.emailFeedback)
          }
        }
      },

      validatebirthdate() {
        this.birthdateValid = true
        this.birthdateFeedback=''
        
        if (!this.user.birthdate) {
          this.user.birthdate = ''
        }
        if (this.user.birthdate.length != 10) {
          this.birthdateValid = false
          this.birthdateFeedback='date should be of format YYYY-MM-DD';
          return
        }
        let arr = this.user.birthdate.split("-");
        let year = parseInt(arr[0])
        let month = parseInt(arr[1])
        let day = parseInt(arr[2])

        let this_year =  new Date().getFullYear();
        let d30 = [4,6,9,11]

        if ( day < 1 || day > 31 ) {
          this.birthdateValid = false
          this.birthdateFeedback='invalid day';
        }
        else if ( d30.includes(month) && day > 30) {
          this.birthdateValid = false
          this.birthdateFeedback='invalid day';
        }                                 
        else if ( month==2 ) {
            if ( leapYear(year) ) {
            if ( day > 29 ) {
              this.birthdateValid = false
              this.birthdateFeedback='invalid day';
            }
            } else {
            if ( day > 28 ) {
              this.birthdateValid = false
              this.birthdateFeedback='invalid day';
            }
            } 
        }

        if ( month < 1 || month > 12 ) {
          this.birthdateValid = false
          if (this.birthdateFeedback.length ==0 ) {
            this.birthdateValid = false
            this.birthdateFeedback='invalid month';
          } else {
            this.birthdateValid = false
            this.birthdateFeedback = this.birthdateFeedback + ' - invalid month'
          }
        } 

        if ( this_year - year > 122 || year > this_year) {
          if (this.birthdateFeedback.length ==0 ) {
            this.birthdateValid = false
            this.birthdateFeedback='invalid year';
          } else {
            this.birthdateValid = false
            this.birthdateFeedback = this.birthdateFeedback + ' - invalid year'
          }
          console.log("TMP: birthdateValid = ", this.birthdateValid)
        }
      },

      async validateOldPassword() {  
        let challenge = {"email": this.user.email, "hashed_password": this.CryptoJS.SHA256(this.old_password).toString()}
        if(this.emailValid) {
          await Authenticate.getToken(challenge)
          .then(response=>{     
            if (this.debug) console.log("Profile in validateOldPassword: response ", response, "successfully fetched from DB");
            if (response === 0) {
              if (this.debug) console.log("Profile in validateOldPassword: Unexcepted reply from Authenticate - 'email is unknown, maybe you need to register'" )
              this.old_passwordValid = false;
            } else if (response === 1) {
              this.old_passwordValid = false;
              this.old_passwordFeedback = 'password incorrect';
              if (this.debug) console.log("Profile in validateOldPassword: user with email ", this.user.email, " - ", this.old_passwordFeedback);
            } else if (response === 2) {
              if (this.debug) console.log("Profile in validateOldPassword: Unexcepted reply from Authenticate - 'email is registered, but not confirmed, please check you mail'" )
              this.old_passwordValid = false;
            } else {
              this.user_id = response;
              this.old_passwordValid = true;
              if (this.debug) console.log("Profile in validateOldPassword: user with email ", this.user.email, " - password correct.");
            }
          })
          .catch(error=>{
            if (this.debug) console.log("Profile in validateOldPassword: await Authenticate.getToken - ", error);
          })
        }
      },

      validateNewPassword() {   
        let hasUpper = false
        let hasLower = false
        let hasDigit = false
        let min8char = false

        if (this.new_password.length > 8) {
          min8char = true;
        }
        for (let i=0; i < this.new_password.length;i++) {
          let c = this.new_password[i]
          if (c === c.toUpperCase() ) {
            hasUpper = true; 
          }
          if (c === c.toLowerCase() ) {
            hasLower = true; 
          }
          if (c >= '0' && c <= '9') {
            hasDigit = true;
          }
        }
        this.new_passwordValid = min8char && hasUpper && hasLower && hasDigit
        if ( !this.new_passwordValid ) {
          this.new_passwordFeedback = 'Password needs at least'
          if( !min8char ) {
            this.new_passwordFeedback += ' 8 characters'
          }
          if( !hasUpper ) {
            this.new_passwordFeedback += ' one uppercase'
          }
          if( !hasLower ) {
            this.new_passwordFeedback += ' one lowercase'
          }
          if( !hasDigit) {
            this.new_passwordFeedback += ' one digit'
          }
          if (this.debug) console.log("validateNewPassword: START")
          if (this.debug) console.log("validateNewPassword: min8char = ", min8char)
          if (this.debug) console.log("validateNewPassword: hasUpper = ", hasUpper)
          if (this.debug) console.log("validateNewPassword: hasLower = ", hasLower)
          if (this.debug) console.log("validateNewPassword: hasDigit = ", hasDigit)
          if (this.debug) console.log("validateNewPassword: --------------------")
          if (this.debug) console.log("validateNewPassword: new_passwordFeedback = ", this.new_passwordFeedback)
          if (this.debug) console.log("validateNewPassword: STOP")
        }
      },

      async validate(){
        //check validity of gender
        if (!this.user.gender) {
          this.user.gender = ''
        }
        if (this.user.gender.length == 0) {
          this.genderValid = false
          this.genderFeedback = 'select gender'
        } else {
            this.genderValid = true
        }

        //check validity of birthdate
        this.validatebirthdate()

        //chack validity of name fields
        if (this.cellar.name.length < 3) {
          this.cellarnameValid = false
          this.cellarnameFeedback = 'Cellar Name should be at least 3 characters long'
        } else {
            this.cellarnameValid = true
        }
        //check validity of email field
        this.validateEmail()
        //check if User Exists
        await this.validateUser()
        
        if (this.changePassword) {
          this.validateOldPassword();
          if (this.old_passwordValid) {
            this.validateNewPassword()
            if (this.new_password!== this.confirm_password) {
              this.new_passwordValid = false
              this.new_passwordFeedback = 'The passwords did not correspond. Try again.'
            }
          } 
        }
        
        if (this.debug) console.log("Profile in validate: SUMMARY")
        if (this.debug) console.log("Profile in validate:  this.cellarnameValid = ",  this.cellarnameValid)
        if (this.debug) console.log("Profile in validate:  this.emailValid = ",  this.emailValid)
        if (this.debug) console.log("Profile in validate:  this.userValid = ",  this.userValid)
        if (this.debug) console.log("Profile in validate:  this.new_passwordValid = ",  this.new_passwordValid)
        if (this.debug) console.log("Profile in validate:  this.old_passwordValid = ",  this.old_passwordValid)

        this.formValid = this.winerynameValid && this.cellarnameValid && this.emailValid && this.userValid  && this.genderValid && this.birthdateValid &&this.new_passwordValid && this.old_passwordValid; 
        if (this.debug) console.log("Profile in validate: this.formValid = ",this.formValid);
        if (this.debug) console.log("Profile in validate: DONE");
      },
    
      async onUpdate() {
        if (this.debug) console.log("Profile in onUpdate: Update pressed");
        await this.validate()
        if (this.debug) console.log("Profile in onUpdate: formValid=",this.formValid);

        if (this.formValid) {
          if (this.new_email != this.user.email) {
            let postdata = {"id": this.user_id, "email": this.new_email}
            await ChangeEmail.go(postdata)
            .then(response=>{
              if (this.debug) console.log("Profile in onUpdate: An email is sent to " + this.new_email + " to validate this change. Nothing will be changed until this is done - ", response)  
              this.emailFeedback = 'An email is sent to ' + this.new_email + ' to validate this change. Nothing will be changed until this is done.'
              this.emailValid = false;
              this.gotoHome = false;
            })
            .catch(error => {
              if (this.debug) console.log('Profile in onUpdate: ', error)
            })
          } else {
            this.gotoHome = true;
          }
          this.user.new_email = this.new_email
          this.new_email = this.user.email          

          // HERE COMES THE DB STUFF
          if (this.debug) console.log('Profile in onUpdate : 1 this.user =',this.user)
          if (this.changePassword) {
            if (typeof this.new_password!== "undefined") {
              this.user.hashed_password = this.CryptoJS.SHA256(this.new_password).toString();
              delete this.new_password;
              delete this.confirm_password;
              if (this.debug) console.log('Profile in onUpdate : 2 this.user =',this.user)
            }
          } 

          await Users.update(this.user_id,this.user)
          .then(response => {
            if (this.debug) console.log("Profile in onUpdate : User ", response , " succesfully updated!")
            this.$store.commit('setUser',this.user)
          })
          .catch(error => {
            if (this.debug) console.log("Profile in onUpdate : Catch is await Users error:", error)
          })

          if ( typeof this.cellar_id != "undefined" && this.cellar_id != 0 && this.cellar_id != '' ) {
            await Cellars.update(this.cellar_id,this.cellar)
            .then(response => {
              if (this.debug) console.log("Profile in onUpdate : Cellar ", response, " succesfully updated!")
            })
            .catch(error => {
              if (this.debug) console.log("Profile in onUpdate : Cellar is await Cellars error:", error)
            })
          }

          try {
            this.$store.commit('setUser',this.user)
            this.$store.commit('setUserID',this.user_id)
            this.$store.commit('setCellar',this.cellar)
            this.$store.commit('setCellarID',this.cellar_id)
            if ( this.user.image_files.length > 0) {
              this.user.profile_picture = await cc64(this.user.image_files[0].dataURL,1,1)
            }
            this.$store.commit('setProfilePicture',this.user.profile_picture)
            if (this.gotoHome) {
              this.$router.push({path:'/'+this.previousPage})
              this.$store.commit('setPage',this.previousPage)
            }
          } catch(err) {
            alert("ERROR : ", err)
          }
        }
      },

      async onBack() {
        let newPath = "/" + this.previousPage;
        this.$store.commit('setPage',this.previousPage)
        this.$router.push({path:newPath});
      },

      changeName() {
        let local_debug = true
        if (local_debug) console.log("Profile.changeName: START")
        this.pictureComment = '';
        if (this.user.firstname.length > 0) {
          this.pictureComment += this.user.firstname;
        }
        if (this.user.lastname.length > 0) {
          if (this.user.firstname.length >0) {
            this.pictureComment += ' ';
          }
          this.pictureComment += this.user.lastname;
        }
        if (local_debug) console.log("Profile.changeName: STOP")
      },

      async vfileAdded(new_image_file) {
        this.pictureComment = 'loading';
        this.$refs.pictureComment.$forceUpdate;
        if (this.user.image_files.length > 0) {
          var old_image_file = this.user.image_files.pop()
          this.$refs.imgDropzone.removeFile(old_image_file)
        }
        let org_base64 = new_image_file.dataURL;
        let cc_base64 = await cc64(org_base64,1,1);
        new_image_file.dataURL = cc_base64;
        this.user.image_files.push(new_image_file);

        this.user.profile_picture = await cc64(org_base64,1,1);
        this.$refs.imgDropzone.removeFile(new_image_file);
        this.changeName();
      },

      template: function () {
         return `<div class="dz-preview">
                </div>`;
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
#profiledropzone {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;

  background-color: transparent !important;
  border-style: none;
  border-radius: 5px;

  color:#696969;

  transition: background-color .2s linear;
}
</style>
<template>
<div>
  <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
  </b-container>
  <div v-else>
    <div class="w-menu">
      <br>
      <b-row v-if="this.currentDatabase.name=='Test'" style="position:relative;bottom:20px">
        <p class="w-subtitle">{{currentDatabase.name}}</p>
      </b-row>
      <b-row class="w-menu-avatar w-cursor-click" @click="gotoProfile()">
        <b-col>
          <b-avatar v-if="currentProfilePicture" 
            size="50px" 
            v-bind:src="currentProfilePicture"
          >
          </b-avatar>
          <b-avatar v-else 
            size="50px" 
            :src="require('@/assets/img/profile-picture.jpg')"
          >
          </b-avatar>
        </b-col>
      </b-row>
      <b-row class="w-menu-under-avatar">
        <b-col style="padding:0" class="text-center">
            <p class="w-menu-name">{{currentUser.firstname}} {{currentUser.lastname}}</p>
            <p class="w-menu-email">{{currentUser.email}}</p>
        </b-col>
      </b-row>      
      <b-row class="d-flex flex-row justify-content-center" style="width:100%; margin:0">  
        <div class="justify-content-left">
          <p class="w-menuitem-1" style="top:120px;color:white">menu</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:157px" @click="gotoTastingsOverview()">tastings</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:194px" @click="gotoTastingsOverview()">overview</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:231px" @click="gotoTastingsSearch()">search</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:268px" @click="gotoWishList()">wishlist</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:305px" @click="gotoAboutUs()">about us</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:342px" @click="logOut()">log off</p>
        </div>
        <div v-if="this.currentPage=='TastingsOverview'" class="w-current-item" style="top:192px"/>
        <div v-if="this.currentPage=='TastingsSearch'" class="w-current-item" style="top:229px"/>
        <div v-if="this.currentPage=='Wishlist'" class="w-current-item" style="top:266px"/>
        <div v-if="this.currentPage=='AboutUs'" class="w-current-item" style="top:303px"/>
      </b-row>
      <div class="w-menu-footer">
        <p><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    computed : {
      currentDatabase() {
        return this.$store.state.database
      },
      currentUser() {
        return this.$store.state.user
      },
      currentProfilePicture() {
        return this.$store.state.profile_picture
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      }
    },
    data() {
      return {
        debug: false,
        isLoading : true,
      }
    },
    async mounted() {
      if (this.debug) console.log("MenuDesktop.mounted: current database = ", this.currentDatabase.name)
      this.isLoading = false
    },
    methods:{
      gotoProfile() {
        if (this.currentPage != 'Profile') {
          this.$store.commit('setPage','Profile')
          this.$router.push({path:'/Profile'})
        }
      },
      gotoTastingsOverview() {
        if (this.currentPage != 'TastingsOverview') {
          this.$store.commit('setPage','TastingsOverview')
          if (this.debug) console.log("MenuDesktop.gotoTastingsOverview")
          this.$router.push({path:'/TastingsOverview'})
        }
      },

      gotoTastingsSearch() {
        if (this.currentPage != 'TastingsSearch') {
          this.$store.commit('setPage','TastingsSearch')
          if (this.debug) console.log("MenuDesktop.gotoTastingsSearch")
          this.$router.push({path:'/TastingsSearch'})
        }
      },

      gotoWishList() {
        if (this.currentPage != 'Wishlist') {
          this.$store.commit('setPage','Wishlist')
          if (this.debug) console.log("MenuDesktop.gotoWishList")
          this.$router.push({path:'/Wishlist'} );
        }
      },

      gotoAboutUs() {
        if (this.currentPage != 'AboutUs') {
          this.$store.commit('setPage','AboutUs')
          if (this.debug) console.log("MenuDesktop.gotoWishList")
          this.$router.push({path:'/AboutUs'});
        }
      },      

      logOut() {
        this.$store.commit('logout')
        this.$router.push({path:'/'})
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
</style>
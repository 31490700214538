<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container> 
    <div v-else>
      <b-container v-if="this.inSession">
        <!-- <div v-if="tasting.isOrganised"> -->
          <b-row v-if="entry_id !=0">
            <b-col cols="3">
              <div v-if="entry.image_files.length > 0" class="bottle-borderless-box"> 
                <b-img  
                  v-bind:src="entry.image_files[0].dataURL"
                >
                </b-img>
              </div>
              <div v-else class="bottle-borderless-box"> 
                <b-img  
                  src="../../img/bottle.png"
                >
                </b-img>
              </div>
            </b-col>
            <b-col sm="9">
              <div>
                <b-row style="display:flex">
                  <b-col cols="9">
                    <p class="item-title" style="text-align:left">{{this.entry.name}}</p>
                  </b-col>
                  <b-col cols="3">
                    <p class="item-title" v-if="this.entry.vintage != '-'" style="text-align:right">{{this.entry.vintage}}</p>
                  </b-col>                
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <p v-if="this.entry.type != ''" class="item-subtitle" style="text-align:left">{{this.entry.type}}</p>
                    <p class="item-text" style="text-align:left">{{this.entry.appellation}} <span  v-if="this.entry.classification !=''">- {{this.entry.classification}}</span></p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="9">
                    <p class="item-text" style="text-align:left">{{this.entry.country}} <span  v-if="this.entry.region !=''">- {{this.entry.region}}</span></p>
                  </b-col>
                  <b-col cols="3">
                    <p v-if="entry.price" style="text-align:right;margin-bottom:0">{{this.entry.price}}</p>
                  </b-col>
                </b-row>
              </div>
              <!-- <b-row>
                <b-col cols="9">
                  <p v-if="this.entry.type != ''" class="item-subtitle" style="text-align:left">{{this.entry.type}}</p>
                  <p class="item-text" style="text-align:left">{{this.entry.appellation}} <span  v-if="this.entry.classification !=''">- {{this.entry.classification}}</span></p>
                  <p class="item-text" style="text-align:left">{{this.entry.country}} <span  v-if="this.entry.region !=''">- {{this.entry.region}}</span></p>
                </b-col>
                <b-col cols="3">
                  <p class="item-subtitle" style="color:white">blanc</p>
                  <p v-if="entry.price" style="text-align:right;margin-bottom:0">{{this.entry.price}}</p>
                </b-col>
              </b-row> -->
              <div v-if="this.entry.grapes.length > 0 || this.entry.longevity.length > 0 || this.entry.vines.length > 0 || this.entry.yields.length > 0 || this.entry.vinification.length > 0"> 
                <hr>
                <p class="item-text" style="text-align:left" v-if="this.entry.grapes !=''"><b>grapes:</b> {{this.entry.grapes}}</p>
                <p class="item-text" style="text-align:left" v-if="this.entry.longevity !=''"><b>longevity:</b> {{this.entry.longevity}}</p>              
                <p class="item-text" style="text-align:left" v-if="this.entry.vines !=''"><b>vines:</b> {{this.entry.vines}}</p>   
                <p class="item-text" style="text-align:left" v-if="this.entry.yields !=''"><b>yields:</b> {{this.entry.yields}}</p>
                <p class="item-text" style="text-align:left" v-if="this.entry.vinification !=''"><b>vinification:</b> {{this.entry.vinification}}</p>   
              </div>
              <div v-if="tasting_note_id != 0">
                <hr>
                <p v-if="tasting_note.appearance" class="item-text"><b>appearence: </b>{{this.tasting_note.appearance}}</p>
                <p v-if="tasting_note.nose" class="item-text"><b>nose: </b>{{this.tasting_note.nose}}</p>
                <p v-if="tasting_note.palate" class="item-text"><b>palate: </b>{{this.tasting_note.palate}}</p>
                <p v-if="tasting_note.aftertaste" class="item-text"><b>aftertaste: </b>{{this.tasting_note.aftertaste}}</p>
                <p v-if="tasting_note.conclusion" class="item-text"><b>conclusion: </b>{{this.tasting_note.conclusion}}</p>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <p class="w-pagetitle">There is no wine yet associated with this tasting note.</p>  
          </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
    <b-modal id="addBottle" title="add bottle" hide-footer>
      <b-tabs v-if="renderChild" class="w-tabs" v-model="tabIndex" fill>
        <div>
          <b-tab class="w-tab" title="from database" active>
            <LibrarySearchDesktop
              @refreshRequest="refresh"/>
          </b-tab>
          <b-tab class="w-tab" title="from scratch">
            <FullDefineDesktop 
              @refreshRequest="refresh"/>
          </b-tab>
        </div>
      </b-tabs>
    </b-modal>
    <b-modal id="deleteBottle" title="delete bottle" @ok="deleteBottle">
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>  
    </b-modal>    
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue'

  import LibrarySearchDesktop from './LibrarySearch_Desktop.vue';
  import FullDefineDesktop from './FullDefine_Tasting_Desktop.vue';

  export default {
    name: "TastingNote",
    components: {
      NoAccess,
      LibrarySearchDesktop,
      FullDefineDesktop,
    },
    props: ['tastingNote','tastingnoteId'],
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      number_of_notes() {
        return this.tasting.tasting_note_ids.length;
      },
      nice_appellation() {
        let result = ''
        if (this.wine_id != 0 ) {
          if (this.wine.country != '') result = this.wine.country
          if (this.wine.region != '') {
            result = this.wine.region + ' - ' + result;
          }
          if (this.wine.appellation != '') {
            result = this.wine.appellation + ' - ' + result;
          }
        }
        else if (this.c_wine_id != 0 ) {
          if (this.c_wine.country != '') result = this.c_wine.country
          if (this.c_wine.region != '') {
            result = this.c_wine.region + ' - ' + result;
          }
          if (this.c_wine.appellation != '') {
            result = this.c_wine.appellation + ' - ' + result;
          }
        }
        return result
      },
    },
    data() {
      return {
        debug: false,
        isLoading: true,
        
        isValid: true,

        user:{},
        user_id:0,

        tasting:{},
        tasting_id:0,

        tasting_note:{},
        tasting_note_id:0,

        entry:{},
        entry_id:0,

        // tasting_note form
        selected: 'short',
        options: [
          { text: 'short', value: 'short' },
          { text: 'long', value: 'long' },
        ],
        dateValid: true,
        dateFeedback: '',
        scoreValid:true,
        scoreFeedback:'',

        tasting_note_isValid: true,

        // paging
        pageCount: 0,
        maxPage: 3,
        leftVisible: false,
        rightVisible: true,

        //Tabs
        tabIndex:0,

        //Refresh
        refreshList: 0,
        refreshChild: 0,
        renderChild: true,
      }
    }, 
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("=====> Entry.mounted: START")

      this.user_id = this.currentUserID;
      this.user = this.currentUser;

      this.$store.commit('setSource',"TastingNote")
      
      if (this.$store.getters.getTastingID != 0) {
        this.tasting_id = this.$store.getters.getTastingID
        this.tasting = this.$store.getters.getTasting
        if (this.debug) console.log("=====> Entry.mounted: tasting ", this.tasting, " with id ", this.tasting_id,  " found in store.")
      } else {
        if (this.debug) console.log("=====> Entry.mounted: NO tasting found in store.")
      }

      if (this.$store.getters.getTastingnoteID != 0) {
        this.tasting_note_id = this.$store.getters.getTastingnoteID
        this.tasting_note = this.$store.getters.getTastingnote
        if (this.debug) console.log("=====> Entry.mounted: tasting_note ", this.tasting_note, " with id ", this.tasting_note_id,  " found in store.")
      } else {
        if (this.debug) console.log("=====> Entry .mounted: NO tasting_note found in store.")
      }

      if (this.$store.getters.getEntryID != 0) {
        this.entry_id = this.$store.getters.getEntryID
        this.entry = this.$store.getters.getEntry
        if (this.debug) console.log("=====> Entry.mounted: entry ", this.entry, " with id ", this.entry_id,  " found in store.")
      } else {
        if (this.debug) console.log("=====> Entry.mounted: NO entry found in store.")
      }      

      this.isLoading = false

      if (this.debug) console.log("=====> Entry.mounted: STOP")
    },
    methods: {
      onLeft() {
        this.pageCount--;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },
      onRight() {
        this.pageCount++;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },      
      
      refresh() {
        //Update Parent
        this.$emit('refreshRequest');
      },

      showModal_addBottle() {
        if (this.debug) console.log("TastingShow.showModal_addBottle: START");
        this.$root.$emit("bv::show::modal", "addBottle");
        if (this.debug) console.log("TastingShow.showModal_addBottle: STOP");
      },

      showModal_deleteBottle() {
        if (this.debug) console.log("TastingShow.showModal_deleteBottle: START");
        this.$root.$emit("bv::show::modal", "deleteBottle");
        if (this.debug) console.log("TastingShow.showModal_deleteBottle: STOP");
      },

      addBottle() {
        this.$emit('refreshRequest');
      },

      deleteBottle() {
        this.$emit('refreshRequest');
      }
    },
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.item-title {
  font-family: 'Segoe UI';
  color:rgb(112,112,112);
  font-size: clamp(16px,2vw,20px) !important;
  font-weight:bold;
  margin-bottom:0;
}

.item-subtitle {
  font-family: 'Segoe UI';
  color:rgb(112,112,112);
  font-size: clamp(12px,1.4vw,16px) !important;
  margin-bottom:0;
}

.item-text {
  font-family: 'Segoe UI';
  color:rgb(112,112,112);
  font-size: clamp(10px,1.4vw,14px) !important;
  margin-bottom:0;
}
</style>

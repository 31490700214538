<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-row style="height:40vh;margin:0" class="welcome-picture">
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" style="height:100%">
          <div class="welcome-overlay">
            <h1 class="main-title"><span style="color: rgb(195,147,130)">WYNE</span>LOVER</h1>
            <!-- <p class="main-subtitle">about us</p> -->
            <p class="main-subtitle">made by wine lovers - for wine lovers</p>
          </div> 
        </b-col>
      </b-row> 
      <b-row>
         <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8">
          <p class="w-title">We are a group of people passionate about wine. <p>
          <p class="w-text">Our goal is to improve the digital wine experience in an open non-commercial way; we called this project ... <b>WyneLover</b>.</p>
          <p class="w-text">In a first phase we are developping a trilogy of online applications: WyneMaker, WyneCellar & WyneTaster.</p>
          <b-row>
            <b-col sm="3">
              <p class="w-text text-left"><a style="color: rgb(112,112,112)" href="http://www.wynemaker.com"><b>www.wynemaker.com</b></a></p>
            </b-col>
            <b-col>
              <p class="w-text text-left">for wine producers to share information on their winery and wines</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <p class="w-text text-left"><a style="color: rgb(112,112,112)" href="http://www.wynecellar.com"><b>www.wynecellar.com</b></a></p>
            </b-col>
            <b-col>
              <p class="w-text text-left">for wine lovers to manage their wine cellar</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <p class="w-text text-left"><a style="color: rgb(112,112,112)" href="http://www.wynetaster.com"><b>www.wynetaster.com</b></a></p>
            </b-col>
            <b-col>
              <p class="w-text text-left">to organize or participate to wine tasting events and manage your wine tasting notes</p>
            </b-col>
          </b-row>
          <p class="w-text">Once the WyneDatabase is suffiently populated by winemakers using the WyneMaker application, we intent to share it with the rest of the world through API.</p>
          <hr>
          <p class="w-title">How can you help us?</p>
          <p class="w-subtitle">Active support.</p>
          <p class="w-text">To finish and promote the project, we are looking for passionate volunteers with a great variety of skills: marketing skills, wine knowledge from a specific country, wine knowledge from all kinds, web design skills, ui/ux skills, language skills & communication skills in different countries, contacts with wine producers, wine producer associations, wine magazines … to join our team of volunteers to accomplish this common goal; provide great digital tools that will greatly support your journey as wine lover.</p>
          <p class="w-text">Interested to become a WyneBassador?</p>
          <p class="w-text">Drop a mail to <b>admin@wynelover.com</b> with your specific skill that could be useful for this project.</p>
          
          <p class="w-subtitle">Passive support.</p>
          <p class="w-text">We intent to keep this project non-commercial. That means that we will live from donations. If you like what you see, please press the donate button below.</p>
          <hr>
          <b-row>
            <b-col  class="text-left">
              <b-button v-on:click="onBack()" class="w-button-pink">back</b-button>
            </b-col>
            <b-col  class="text-right">
              <b-button v-on:click="onDonate()" class="w-button-green">donate</b-button>
            </b-col>
          </b-row>
          <br>    
        </b-col>
      </b-row>
      <br>  
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WineryWelcome',
     computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentProfilePicture() {
        return this.$store.state.profile_picture
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      previousPage() {
        return this.$store.state.previous_page
      },
    },
    data() {
      return {
        debug: true, 
        isLoading: true,
      }
    }, 
    mounted() {
      this.isLoading = false
    },
    methods: {
      onBack() {
        if (this.debug) console.log("AboutUs.onBack: previousPage = ",this.previousPage)

        if (this.previousPage.substring(0,7) == 'Tasting') {
          this.$router.push({path:'/TastingsOverview'})
          this.$store.commit('setPage','TastingsOverview')
        } else if (this.previousPage.substring(0,8) == 'WineList') {
          this.$router.push({path:'/WineListsOverview'})
          this.$store.commit('setPage','WineListsOverview')
        } else {
          alert("SEVER ERROR: this.previousPage = " + this.previousPage)
        }
      },
      
      onDonate() {
        window.open('https://www.paypal.com/donate/?hosted_button_id=968LLYKG8YBP6');
      },

      doNothing() {
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}
</style> 
<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container> 
    <div v-else>
      <b-container v-if="this.inSession">
        <b-row>
          <b-col sm="10" style="padding:0">        
            <p class="w-title text-center">Catalogue</p>
          </b-col>
        </b-row>

        <RecycleScroller class="scroller" :items="catalogue_list" :item-size="32">
          <template #default="{ item }">
            <p v-if="item.level == 0" 
              class="w-level0" 
            >
              {{ item.name }}
            </p>
            <div v-else-if="item.level == 1">
              <div class="w-node w-level1" style="width:70%">
                <p style="order:1" v-if="item.type=='group'" class="w-group w-cursor-click"></p>
                <p style="order:1" v-if="item.type=='item' && item.color=='Red'" class="w-red-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.type=='item' && item.color=='White'" class="w-white-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.type=='item' && item.color=='Rosé'" class="w-rose-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.type=='item'" class="w-item w-cursor-click"></p>
                <p style="order:2; margin:0 5px 0 0">{{item.name}}</p>
                <div
                  style="order:3; display:flex; margin-left:auto" 
                >
                  <b-form-group v-if="item.type=='item'" style="text-align:left; margin:0">
                    <b-form-checkbox 
                      v-model="item.select"
                      @change="clickCheckbox(item.position)"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div v-else-if="item.level == 2" style="width:70%">
              <div class="w-node w-level2">
                <p style="order:1" v-if="item.type=='group'" class="w-group w-cursor-click"></p>
                <p style="order:1" v-if="item.type=='item' && item.color=='Red'" class="w-red-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.type=='item' && item.color=='White'" class="w-white-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.type=='item' && item.color=='Rosé'" class="w-rose-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.type=='item'" class="w-item w-cursor-click"></p>
                <p style="order:2; margin:0 5px 0 0">{{item.name}}</p>
                <div
                  style="order:3; display:flex; margin-left:auto" 
                >
                  <b-form-group v-if="item.type=='item'" style="text-align:left; margin:0">
                    <b-form-checkbox 
                      v-model="item.select"
                      @change="clickCheckbox(item.position)"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div v-else-if="item.level == 3" style="width:70%">
              <div class="w-node w-level3">
                <p style="order:1" v-if="item.type=='group'" class="w-group w-cursor-click"></p>
                <p style="order:1" v-if="item.type=='item' && item.color=='Red'" class="w-red-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.type=='item' && item.color=='White'" class="w-white-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.type=='item' && item.color=='Rosé'" class="w-rose-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.type=='item'" class="w-item w-cursor-click"></p>
                <p style="order:2; margin:0 5px 0 0">{{item.name}}</p>
                <div
                  style="order:3; display:flex; margin-left:auto" 
                >
                  <b-form-group v-if="item.type=='item'" style="text-align:left; margin:0">
                    <b-form-checkbox 
                      v-model="item.select"
                      @change="clickCheckbox(item.position)"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="w-node w-level4" style="width:70%">
                <p style="order:1" v-if="item.color=='Red'" class="w-red-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.color=='White'" class="w-white-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.color=='Rosé'" class="w-rose-bottle w-cursor-click"></p>
                <p style="order:1" v-else-if="item.type=='item'" class="w-item w-cursor-click"></p>
                <p style="order:2; margin:0 5px 0 0">{{item.name}}</p>
                <div
                  style="order:3; display:flex; margin-left:auto" 
                >
                  <b-form-group v-if="item.type=='item'" style="text-align:left; margin:0">
                    <b-form-checkbox 
                      v-model="item.select"
                      @change="clickCheckbox(item.position)"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </div>                               
          </template>
        </RecycleScroller>
        
        <template #modal-footer="{ ok, cancel }">
            <b-row style="width:100%">  
              <b-col class="text-left" style="padding:0">
                <b-button class="w-button-pink" @click="cancel()">
                  cancel
                </b-button>
              </b-col>
              <b-col class="text-right" style="padding:0">
                <b-button class="w-button-green" @click="ok()">
                  ok
                </b-button>
              </b-col>
            </b-row>
          </template>   
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue'

  export default {
    name: "Catalogue",
    components: {
      NoAccess,
    },
    computed: {
      inSession() {
        return this.$store.state.inSession
      },
      Winelist() {
        return this.$store.state.winelist
      },
      WinelistID() {
        return this.$store.state.winelist_id
      },
      Tasting() {
        return this.$store.state.tasting
      },
      TastingID() {
        return this.$store.state.tasting_id
      },
    },
    data() {
      return {
        debug: false,
        isLoading: true,

        winelist:{},
        winelist_id:0,

        tasting:{},
        tasting_id:0,

        refreshList:0,

        catalogue_list:[],
      }
    }, 
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("Catalog.mounted: START")
      
      this.winelist = this.Winelist
      console.log("TMP - this.winelist = ", this.winelist)
      this.winelist_id = this.WinelistID
      this.tasting = this.Tasting
      console.log("TMP - this.tasting = ", this.tasting)
      this.tasting_id = this.TastingID 

      this.catalogue_list.push({
        'id': 'root',
        'name': this.winelist.name,
        'type': 'group',
        'level': 0
      })
      for (let i=0 ; i < this.winelist.nodes.length ; i++) {
          if (this.winelist.nodes[i].isLeaf) {
            this.catalogue_list.push({
              'id': i,
              'name': this.winelist.nodes[i].value.name,
              'type': 'item',
              'level': 1,
              'color': this.winelist.nodes[i].value.color,
              'select': this.winelist.nodes[i].select,
              'position':[i]
            })
          } else {
            this.catalogue_list.push({
              'id': i,
              'name': this.winelist.nodes[i].value.name,
              'type': 'group',
              'level': 1
            })
            for (let j=0 ; j < this.winelist.nodes[i].nodes.length ; j++) {
              if (this.winelist.nodes[i].nodes[j].isLeaf) {
                this.catalogue_list.push({
                  'id': i+ " "+j,
                  'name': this.winelist.nodes[i].nodes[j].value.name,
                  'type': 'item',
                  'level': 2,
                  'color': this.winelist.nodes[i].nodes[j].value.color,
                  'select': this.winelist.nodes[i].nodes[j].select,
                  'position':[i,j]
                })
              } else {
                this.catalogue_list.push({
                  'id': i+ " "+j,
                  'name': this.winelist.nodes[i].nodes[j].value.name,
                  'type': 'group',
                  'level': 2
                })
                for (let k=0 ; k < this.winelist.nodes[i].nodes[j].nodes.length ; k++) {
                  if (this.winelist.nodes[i].nodes[j].nodes[k].isLeaf) {
                    this.catalogue_list.push({
                      'id': i+ " "+j+ " "+k,
                      'name': this.winelist.nodes[i].nodes[j].nodes[k].value.name,
                      'type': 'item',
                      'level': 3,
                      'color': this.winelist.nodes[i].nodes[j].nodes[k].value.color,
                      'select': this.winelist.nodes[i].nodes[j].nodes[k].select,
                      'position':[i,j,k]
                    })
                  } else {
                    this.catalogue_list.push({
                      'id': i+ " "+j+ " "+k,
                      'name': this.winelist.nodes[i].nodes[j].nodes[k].value.name,
                      'type': 'group',
                      'level': 3
                    })
                    for (let l=0 ; l < this.winelist.nodes[i].nodes[j].nodes[k].nodes.length ; l++) {
                      if (this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].isLeaf) {
                        this.catalogue_list.push({
                          'id': i+ " "+j+ " "+k+ " "+l,
                          'name': this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].value.name,
                          'type': 'item',
                          'level': 4,
                          'color': this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].value.color,
                          'select': this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].select,
                          'position':[i,j,k,l]
                        })
                      } else {
                        alert("ERROR: Level 4 group NOT permitted")
                      }
                    }
                  }
                }
              }
            }
          }
      }    
      

      // for (let i=0 ; i < this.winelist.nodes.length ; i++) {
      //     console.log(" i = ", i)
      //     if (this.winelist.nodes[i].isLeaf) {
      //       this.catalogue_list.push(this.winelist.nodes[i].value.name)
      //     } else {
      //       this.catalogue_list.push(this.winelist.nodes[i].value.name)
      //       for (let j=0 ; j < this.winelist.nodes[i].nodes.length ; j++) {
      //         console.log(" j = ", j)
      //         if (this.winelist.nodes[i].nodes[j].isLeaf) {
      //           this.catalogue_list.push(this.winelist.nodes[i].nodes[j].value.name)
      //         } else {
      //           this.catalogue_list.push(this.winelist.nodes[i].nodes[j].value.name)
      //           for (let k=0 ; k < this.winelist.nodes[i].nodes[j].nodes.length ; k++) {
      //             console.log(" k = ", k)
      //             if (this.winelist.nodes[i].nodes[j].nodes[k].isLeaf) {
      //               this.catalogue_list.push(this.winelist.nodes[i].nodes[j].nodes[k].value.name)
      //             } else {
      //               this.catalogue_list.push(this.winelist.nodes[i].nodes[j].nodes[k].value.name)
      //               for (let l=0 ; l < this.winelist.nodes[i].nodes[j].nodes[k].nodes.length ; l++) {
      //                 if (this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].isLeaf) {
      //                   this.catalogue_list.push(this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].value.name)
      //                 } else {
      //                   alert("ERROR: Level 4 group NOT permitted")
      //                 }
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }     
      // }

      console.log("TMP this.catalogue_list = ", this.catalogue_list)

      this.isLoading = false
      if (this.debug) console.log("Catalog.mounted: STOP")
    },
    methods: {
      async clickCheckbox(position) {
        if (this.debug) console.log("")
        if (this.debug) console.log("Catalogue.clickCheckbox: START")
        if (this.debug) console.log("Catalogue.clickCheckbox: node = ", position)

        let node = {}
        if ( position.length == 1) {
          node = this.winelist.nodes[position[0]]
        } else if (position.length == 2) {
          node = this.winelist.nodes[position[0]].nodes[position[1]]
        } else if (position.length == 3) {
          node = this.winelist.nodes[position[0]].nodes[position[1]].nodes[position[2]]
        } else if (position.length == 4) {
          node = this.winelist.nodes[position[0]].nodes[position[1]].nodes[position[2]].nodes[position[3]]
        } else {
          alert("ERROR position = " + position)
        }

        if (this.debug) console.log("Catalogue.clickCheckbox: node = ", node)
        if (this.debug) console.log("Catalogue.clickCheckbox: node.select = ", node.select)

        if (node.select == true) {
          // create tasting_note for this item
          if (this.debug) console.log("Catalogue.clickCheckbox: creating tasting_note")
          let tmp_tasting_note = this.tasting_note
          tmp_tasting_note.tasting_id = this.tasting_id
          tmp_tasting_note.item_id = node._id
          tmp_tasting_note.name = node.value.name
          tmp_tasting_note.color = node.value.color
          await TastingNotes.create(tmp_tasting_note)
          .then(response => {
            tmp_tasting_note._id = response
            this.tasting.tasting_note_ids.push(response)
            this.tasting_notes.push(tmp_tasting_note)
            if (this.debug) console.log("Catalogue.clickCheckbox: TastingNote ", tmp_tasting_note, " succesfully created : id = ", response);
          })
          .catch(error => {
            if (this.debug) console.log("Catalogue.clickCheckbox: catch await TastingShow.create ", error);
          })
          await this.isActiveNote(this.tasting.tasting_note_ids[this.tasting.tasting_note_ids.length-1])
          await this.selectNote(this.tasting.tasting_note_ids[this.tasting.tasting_note_ids.length-1])
        } else {
          if (this.debug) console.log("Catalogue.clickCheckbox: tasting_note exists - we delete")
          if (this.debug) console.log("Catalogue.clickCheckbox: node._id = ", node._id)
          let id
          for (let i = 0 ; i < this.tasting_notes.length ; i++ ) {
            if (this.debug) console.log("Catalogue.clickCheckbox: trying this.tasting_notes[",i,"].item_id = ", this.tasting_notes[i].item_id)
            if (this.tasting_notes[i].item_id == node._id) {
              id = this.tasting_notes[i]._id
              // delete the tasting note
              await TastingNotes.delete(id)
              .then(response => {
                if (this.debug) console.log("Catalogue.clickCheckbox: TastingNote succesfully deleted with response ", response);
              })
              .catch(error => {
                if (this.debug) console.log("Catalogue.clickCheckbox: catch await TastingShow.delete ", error);
              })
              // delete the tasting note for tasting_notes array
              this.tasting_notes.splice(i, 1);
              if (this.debug) console.log("Catalogue.clickCheckbox: TastingNote succesfully deleted from tasting_notes array");
            }
          }

          // delete the tasting note id for in tasting.tasting_note_ids
          let index = this.tasting.tasting_note_ids.indexOf(id)
          this.tasting.tasting_note_ids.splice(index,1)
          if (this.debug) console.log("Catalogue.clickCheckbox: TastingNote id succesfully deleted from tasting.tasting_note_ids array");
          
          // set active note
          if (this.tasting.tasting_note_ids.length > 0) {
            await this.isActiveNote(this.tasting.tasting_note_ids[0])
            await this.selectNote(this.tasting.tasting_note_ids[0])
          } else {
            await this.isActiveNote(0)
            await this.selectNote(0)
          }
        }

        await Tastings.update(this.tasting_id,this.tasting)
        .then(response => {
          if (this.debug) console.log("Catalogue.clickCheckbox: Tasting ", this.tasting," succesfully updated with response ", response);
        })
        .catch(error => {
          if (this.debug) console.log("Catalogue.clickCheckbox: catch await Tastings.update ", error);
        })

        this.refreshList++
        if (this.debug) console.log("Catalogue.clickCheckbox: tasting_notes = ", this.tasting_notes)
        if (this.debug) console.log("Catalogue.clickCheckbox: refreshList = ", this.refreshList)
        if (this.debug) console.log("Catalogue.clickCheckbox: STOP")
      },
    },
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

import { API } from './API.js';

import axios from 'axios';

const url = API + '/confirm_taster/'

class Confirm {
    static sendEmail(id) {
        console.log('Confirm.sendEmail: id=',id)
        return axios.post(`${url}${id}`)
        .then(response => {
            console.log('Confirm.sendEmail: ',response)
        })
        .catch(error => {
            console.log('Confirm.sendEmail: ',error)
        })
    }
}

export default Confirm
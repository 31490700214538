<template>
  <!-- <div v-on:contextmenu.prevent="doNothing"> -->
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container v-if="this.inSession">
        <b-row>
          <b-col style="padding:0" align="center">
            <div class="menu-top" style="text-align:center">
              <p style="margin-bottom:0"><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
              <p style="position: relative; top: -5px;font-size: 10px; color: rgb(109,122,82);margin-bottom:0">organise</p>
            </div> 
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
          </b-col>   
        </b-row>
        <b-row>          
          <b-col style="padding-left:0;padding-right:0">
            <div 
              v-if="winelist.image_files.length >0"
              class="w-background"
              v-bind:style="{ 'background-image': 'url(' + winelist.image_files[0].dataURL +')' }"
            >
              <p class="w-pagetitle" style="color:white;margin-right:15px; padding-top: 10px; text-align:right;">
                  organise >
                  <span v-on:click="gotoWineListsOverview()" class="w-cursor-click" ><u> winelists </u></span> > 
                  <span v-on:click="gotoWineListsOverview()" class="w-cursor-click" ><u> overview </u></span> > 
                  {{winelist.name}} 
                </p>
              <b-row style="margin:0">
                <b-col cols="8">
                  <p class="preview-title" style="color:white !important;text-align:left"> {{winelist.name}} </p>
                </b-col>
                <b-col cols="4" style="padding-left:0">
                  <p class="preview-title" style="color:white !important;text-align:right">{{code}} </p>
                </b-col>
              </b-row>
              <b-row style="margin:0">
                <b-col cols="8">
                  <p class="preview-subtitle" style="color:white !important;text-align:left"> {{c_wineatDate}} - {{winelist.number_of_bottles}} bottles</p>
                </b-col>
                <b-col cols="4" style="padding-left:0">
                  <p class="preview-subtitle" style="color:white !important;text-align:right"> {{winelist.organiser}} </p>
                </b-col>
              </b-row>
            </div>
            <div 
              v-else
              class="no-background"
            >
              <p class="w-pagetitle" style="color:white;margin-right:15px; padding-top: 10px; text-align:right;">organise > winelists > show winelist</p>
              <b-row style="margin:0">
                <b-col cols="8">
                  <p class="preview-title" style="color:white !important;text-align:left"> {{winelist.name}} </p>
                </b-col>
                <b-col cols="4" style="padding-left:0">
                  <p class="preview-title" style="color:white !important;text-align:right">{{code}} </p>
                </b-col>
              </b-row>
              <b-row style="margin:0">
                <b-col cols="6">
                  <p class="preview-subtitle" style="color:white !important;text-align:left"> {{c_wineatDate}} - {{winelist.number_of_bottles}} bottles</p>
                </b-col>
                <b-col cols="6">
                  <p class="preview-subtitle" style="color:white !important;text-align:right"> {{winelist.organiser}} </p>
                </b-col>
              </b-row>                  
            </div>
          </b-col>
        </b-row>
        <!-- <p>selected_node: {{selected_node}}</p> -->
        <b-row style="padding-left:0;padding-right:0;margin-right:10px">
          <b-col style="margin-top: 14px" :key="refreshList">
            <b-row>
              <b-col>
                <p
                  class="w-level0"
                >
                  {{winelist.name}} 
                </p>
                <div 
                  v-for="(node_level1,index_level1) in winelist.nodes"
                  v-bind:index="index_level1" 
                  :key="node_level1._id"
                >                    
                  <b-row v-if="!node_level1.nodes">
                    <div 
                      class="w-node w-level1"
                      v-on:click="onClickNode([index_level1])"
                      v-bind:class="{ 'w-active' : isActiveNode([index_level1])}"
                    >
                      <p style="order:1" class="w-item w-cursor-click"></p>
                      <p style="order:2; margin:0 5px 0 0"><span v-if="winelist.isOrdered == true && winelist.isOpen == false">{{index_level1+1}}. </span>{{node_level1.value.name}}</p>
                      <div
                        style="order:3; display:flex; margin-left:auto" 
                        v-if="isCurrentNode([index_level1])"
                      >
                        <p
                          style="text-align:right;margin-left:5px;flex-basis: 33%"
                          class="w-item-end w-cursor-click"
                          v-on:click.stop="upNode([index_level1])"
                          v-if="index_level1 != 0"
                        >
                          <font-awesome-icon style="font-size:17px" icon="caret-up" />
                        </p>
                        <p v-else
                          style="text-align:right;margin-left:5px;flex-basis: 33%"
                          class="w-item-end"
                        >
                          <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                        </p>
                        <p
                          style="text-align:right;margin-left:5px;flex-basis: 33%"
                          class="w-item-end w-cursor-click"
                          v-on:click.stop="downNode([index_level1])"
                          v-if="index_level1 != winelist.nodes.length-1"
                        >
                          <font-awesome-icon style="font-size:17px" icon="caret-down" />
                        </p>
                        <p v-else
                          style="text-align:right;margin-left:5px;flex-basis: 33%"
                          class="w-item-end"
                        >
                          <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                        </p>
                        <p 
                          style="text-align:right;margin-left:5px;flex-basis: 33%"
                          class="w-item-end w-cursor-click"
                          v-on:click.stop="deleteNode([index_level1])"
                        >
                          <font-awesome-icon icon="trash-alt" />
                        </p>
                      </div>
                    </div>
                  </b-row>
                  <div v-else>
                    <b-row>
                      <div 
                        class="w-node w-level1"
                        v-on:click="onClickNode([index_level1])"
                        v-bind:class="{ 'w-active' : isActiveNode([index_level1])}"
                      >
                        <p style="order:1" class="w-group w-cursor-click"></p>
                        <p style="order:2; margin:0 5px 0 0">{{node_level1.value.name}}</p>
                        <div
                          style="order:3; display:flex; margin-left:auto" 
                          v-if="isCurrentNode([index_level1])"
                        >
                          <p
                            style="text-align:right;margin-left:5px;flex-basis: 33%"
                            class="w-item-end w-cursor-click"
                            v-on:click.stop="upNode([index_level1])"
                            v-if="index_level1 != 0"
                          >
                            <font-awesome-icon style="font-size:17px" icon="caret-up" />
                          </p>
                          <p v-else
                            style="text-align:right;margin-left:5px;flex-basis: 33%"
                            class="w-item-end"
                          >
                            <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                          </p>
                          <p
                            style="text-align:right;margin-left:5px;flex-basis: 33%"
                            class="w-item-end w-cursor-click"
                            v-on:click.stop="downNode([index_level1])"
                            v-if="index_level1 != winelist.nodes.length-1"
                          >
                            <font-awesome-icon style="font-size:17px" icon="caret-down" />
                          </p>
                          <p v-else
                            style="text-align:right;margin-left:5px;flex-basis: 33%"
                            class="w-item-end"
                          >
                            <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                          </p>
                          <p 
                            style="text-align:right;margin-left:5px;flex-basis: 33%"
                            class="w-item-end w-cursor-click"
                            v-on:click.stop="deleteNode([index_level1])"
                          >
                            <font-awesome-icon icon="trash-alt" />
                          </p>
                        </div>
                      </div>
                    </b-row>
                    <div v-if="node_level1.isVisible">
                      <div
                        v-for="(node_level2,index_level2) in node_level1.nodes"
                        v-bind:index="index_level2" 
                        :key="node_level2._id"
                      >
                        <b-row v-if="!node_level2.nodes">
                          <div 
                            class="w-node w-level2"
                            v-on:click="onClickNode([index_level1,index_level2])"
                            v-bind:class="{ 'w-active' : isActiveNode([index_level1,index_level2])}"
                          >
                            <p style="order:1" class="w-item w-cursor-click"></p>
                            <p style="order:2; margin:0 5px 0 0">{{node_level2.value.name}}</p>
                            <div
                              style="order:3; display:flex;margin-left:auto" 
                              v-if="isCurrentNode([index_level1,index_level2])"
                            >
                              <p
                                style="text-align:right;margin-left:5px;flex-basis: 33%"
                                class="w-item-end w-cursor-click"
                                v-on:click.stop="upNode([index_level1,index_level2])"
                                v-if="index_level2 != 0"
                              >
                                <font-awesome-icon style="font-size:17px" icon="caret-up" />
                              </p>
                              <p v-else
                                style="text-align:right;margin-left:5px;flex-basis: 33%"
                                class="w-item-end"
                              >
                                <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                              </p>
                              <p
                                style="text-align:right;margin-left:5px;flex-basis: 33%"
                                class="w-item-end w-cursor-click"
                                v-on:click.stop="downNode([index_level1,index_level2])"
                                v-if="index_level2 != winelist.nodes[index_level1].nodes.length-1"
                              >
                                <font-awesome-icon style="font-size:17px" icon="caret-down" />
                              </p>
                              <p v-else
                                style="text-align:right;margin-left:5px;flex-basis: 33%"
                                class="w-item-end"
                              >
                                <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                              </p>
                              <p 
                                style="text-align:right;margin-left:5px;flex-basis: 33%"
                                class="w-item-end w-cursor-click"
                                v-on:click.stop="deleteNode([index_level1,index_level2])"
                              >
                                <font-awesome-icon icon="trash-alt" />
                              </p>
                            </div>
                          </div>                            
                        </b-row>
                        <div v-else>
                          <b-row>
                            <div 
                              class="w-node w-level2"
                              v-on:click="onClickNode([index_level1,index_level2])"
                              v-bind:class="{ 'w-active' : isActiveNode([index_level1,index_level2])}"
                            >
                              <p style="order:1" class="w-group w-cursor-click"></p>
                              <p style="order:2; margin:0 5px 0 0">{{node_level2.value.name}}</p>
                              <div
                                style="order:3; display:flex; margin-left:auto" 
                                v-if="isCurrentNode([index_level1,index_level2])"
                              >
                                <p
                                  style="text-align:right;margin-left:5px;flex-basis: 33%"
                                  class="w-item-end w-cursor-click"
                                  v-on:click.stop="upNode([index_level1,index_level2])"
                                  v-if="index_level2 != 0"
                                >
                                  <font-awesome-icon style="font-size:17px" icon="caret-up" />
                                </p>
                                <p v-else
                                  style="text-align:right;margin-left:5px;flex-basis: 33%"
                                  class="w-item-end"
                                >
                                  <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                                </p>
                                <p
                                  style="text-align:right;margin-left:5px;flex-basis: 33%"
                                  class="w-item-end w-cursor-click"
                                  v-on:click.stop="downNode([index_level1,index_level2])"
                                  v-if="index_level2 != winelist.nodes[index_level1].nodes.length-1"
                                >
                                  <font-awesome-icon style="font-size:17px" icon="caret-down" />
                                </p>
                                <p v-else
                                  style="text-align:right;margin-left:5px;flex-basis: 33%"
                                  class="w-item-end"
                                >
                                  <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                                </p>
                                <p 
                                  style="text-align:right;margin-left:5px;flex-basis: 33%"
                                  class="w-item-end w-cursor-click"
                                  v-on:click.stop="deleteNode([index_level1,index_level2])"
                                >
                                  <font-awesome-icon icon="trash-alt" />
                                </p>
                              </div>
                            </div>                          
                          </b-row>
                          <div v-if="node_level2.isVisible">
                            <div
                              v-for="(node_level3,index_level3) in node_level2.nodes"
                              v-bind:index="index_level3" 
                              :key="node_level3._id"
                            >
                              <b-row v-if="!node_level3.nodes">
                                <div 
                                  class="w-node w-level3"
                                  v-on:click="onClickNode([index_level1,index_level2,index_level3])"
                                  v-bind:class="{ 'w-active' : isActiveNode([index_level1,index_level2,index_level3])}"
                                >
                                  <p style="order:1" class="w-item w-cursor-click"></p>
                                  <p style="order:2; margin:0 5px 0 0">{{node_level3.value.name}}</p>
                                  <div
                                    style="order:3; display:flex; margin-left:auto" 
                                    v-if="isCurrentNode([index_level1,index_level2,index_level3])"
                                  >
                                    <p
                                      style="text-align:right;margin-left:5px;flex-basis: 33%"
                                      class="w-item-end w-cursor-click"
                                      v-on:click.stop="upNode([index_level1,index_level2,index_level3])"
                                      v-if="index_level3 != 0"
                                    >
                                      <font-awesome-icon style="font-size:17px" icon="caret-up" />
                                    </p>
                                    <p v-else
                                      style="text-align:right;margin-left:5px;flex-basis: 33%"
                                      class="w-item-end"
                                    >
                                      <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                                    </p>
                                    <p
                                      style="text-align:right;margin-left:5px;flex-basis: 33%"
                                      class="w-item-end w-cursor-click"
                                      v-on:click.stop="downNode([index_level1,index_level2,index_level3])"
                                      v-if="index_level3 != winelist.nodes[index_level1].nodes[index_level2].nodes.length-1"
                                    >
                                      <font-awesome-icon style="font-size:17px" icon="caret-down" />
                                    </p>
                                    <p v-else
                                      style="text-align:right;margin-left:5px;flex-basis: 33%"
                                      class="w-item-end"
                                    >
                                      <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                                    </p>
                                    <p 
                                      style="text-align:right;margin-left:5px;flex-basis: 33%"
                                      class="w-item-end w-cursor-click"
                                      v-on:click.stop="deleteNode([index_level1,index_level2,index_level3])"
                                    >
                                      <font-awesome-icon icon="trash-alt" />
                                    </p>
                                  </div>
                                </div>                                   
                              </b-row>
                              <div v-else>
                                <b-row>
                                  <div 
                                    class="w-node w-level3"
                                    v-on:click="onClickNode([index_level1,index_level2,index_level3])"
                                    v-bind:class="{ 'w-active' : isActiveNode([index_level1,index_level2,index_level3])}" 
                                  >
                                    <p style="order:1" class="w-group w-cursor-click"></p>
                                    <p style="order:2; margin:0 5px 0 0">{{node_level3.value.name}}</p>
                                    <div
                                      style="order:3; display:flex; margin-left:auto" 
                                      v-if="isCurrentNode([index_level1,index_level2,index_level3])"
                                    >
                                      <p
                                        style="text-align:right;margin-left:5px;flex-basis: 33%"
                                        class="w-item-end w-cursor-click"
                                        v-on:click.stop="upNode([index_level1,index_level2,index_level3])"
                                        v-if="index_level3 != 0"
                                      >
                                        <font-awesome-icon style="font-size:17px" icon="caret-up" />
                                      </p>
                                      <p v-else
                                        style="text-align:right;margin-left:5px;flex-basis: 33%"
                                        class="w-item-end"
                                      >
                                        <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                                      </p>
                                      <p
                                        style="text-align:right;margin-left:5px;flex-basis: 33%"
                                        class="w-item-end w-cursor-click"
                                        v-on:click.stop="downNode([index_level1,index_level2,index_level3])"
                                        v-if="index_level3 != winelist.nodes[index_level1].nodes[index_level2].nodes.length-1"
                                      >
                                        <font-awesome-icon style="font-size:17px" icon="caret-down" />
                                      </p>
                                      <p v-else
                                        style="text-align:right;margin-left:5px;flex-basis: 33%"
                                        class="w-item-end"
                                      >
                                        <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                                      </p>
                                      <p 
                                        style="text-align:right;margin-left:5px;flex-basis: 33%"
                                        class="w-item-end w-cursor-click"
                                        v-on:click.stop="deleteNode([index_level1,index_level2,index_level3])"
                                      >
                                        <font-awesome-icon icon="trash-alt" />
                                      </p>
                                    </div>
                                  </div>                                      
                                </b-row>
                                <div v-if="node_level3.isVisible">
                                  <div
                                    v-for="(node_level4,index_level4) in node_level3.nodes"
                                    v-bind:index="index_level4" 
                                    :key="node_level4._id"
                                  >
                                    <b-row>
                                      <div 
                                        class="w-node w-level4"
                                        v-on:click="onClickNode([index_level1,index_level2,index_level3,index_level4])"
                                        v-bind:class="{ 'w-active' : isActiveNode([index_level1,index_level2,index_level3,index_level4])}"
                                      >
                                        <p style="order:1" class="w-item w-cursor-click"></p>
                                        <p style="order:2; margin:0 5px 0 0">{{node_level4.value.name}}</p>
                                        <div
                                          style="order:3; display:flex; margin-left:auto" 
                                          v-if="isCurrentNode([index_level1,index_level2,index_level3,index_level4])"
                                        >
                                          <p
                                            style="text-align:right;margin-left:5px;flex-basis: 33%"
                                            class="w-item-end w-cursor-click"
                                            v-on:click.stop="upNode([index_level1,index_level2,index_level3,index_level4])"
                                            v-if="index_level4 != 0"
                                          >
                                            <font-awesome-icon style="font-size:17px" icon="caret-up" />
                                          </p>
                                          <p v-else
                                            style="text-align:right;margin-left:5px;flex-basis: 33%"
                                            class="w-item-end"
                                          >
                                            <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                                          </p>
                                          <p
                                            style="text-align:right;margin-left:5px;flex-basis: 33%"
                                            class="w-item-end w-cursor-click"
                                            v-on:click.stop="downNode([index_level1,index_level2,index_level3,index_level4])"
                                            v-if="index_level4 != winelist.nodes[index_level1].nodes[index_level2].nodes[index_level3].nodes.length-1"
                                          >
                                            <font-awesome-icon style="font-size:17px" icon="caret-down" />
                                          </p>
                                          <p v-else
                                            style="text-align:right;margin-left:5px;flex-basis: 33%"
                                            class="w-item-end"
                                          >
                                            <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                                          </p>
                                          <p 
                                            style="text-align:right;margin-left:5px;flex-basis: 33%"
                                            class="w-item-end w-cursor-click"
                                            v-on:click.stop="deleteNode([index_level1,index_level2,index_level3,index_level4])"
                                          >
                                            <font-awesome-icon icon="trash-alt" />
                                          </p>
                                        </div>
                                      </div>       
                                    </b-row>
                                  </div>
                                  <b-row>
                                    <div style="display:grid">
                                        <p 
                                          class="w-level4 w-new w-cursor-click"
                                          v-bind:class="{ 'w-active' : isActiveNode([index_level1,index_level2,index_level3,'new'])}"
                                          style="grid-column: 1; grid-row: 1;"
                                          v-on:click="newNode('item',[index_level1,index_level2,index_level3,'new'])"
                                        >
                                          new item
                                        </p>
                                    </div>
                                  </b-row>                                      
                                </div>
                              </div>
                            </div>
                            <b-row>
                              <p class="w-level3 w-new w-cursor-click">
                                <span 
                                  v-on:click="newNode('item',[index_level1,index_level2,'new'])"
                                  v-bind:class="{ 'w-active' : isActiveNode([index_level1,index_level2,'new']) && activeItem == 'item'}">
                                  new item
                                </span>
                                <span v-if="winelist.isOpen" 
                                  v-on:click="newNode('group',[index_level1,index_level2,'new'])"
                                  v-bind:class="{ 'w-active' : isActiveNode([index_level1,index_level2,'new']) && activeItem == 'group'}">
                                  - new group
                                </span>
                              </p>
                            </b-row> 
                          </div>
                        </div>
                      </div>
                      <b-row>
                        <p class="w-level2 w-new w-cursor-click">
                          <span 
                            v-on:click="newNode('item',[index_level1,'new'])"
                            v-bind:class="{ 'w-active' : isActiveNode([index_level1,'new']) && activeItem == 'item'}">
                            new item
                          </span>
                          <span v-if="winelist.isOpen" 
                            v-on:click="newNode('group',[index_level1,'new'])"
                            v-bind:class="{ 'w-active' : isActiveNode([index_level1,'new']) && activeItem == 'group'}">
                            - new group
                          </span>
                        </p>
                      </b-row> 
                    </div>
                  </div>
                </div>
                <b-row>
                  <p class="w-level1 w-new w-cursor-click">
                    <span 
                      v-on:click="newNode('item',['new'])"
                      v-bind:class="{ 'w-active' : isActiveNode(['new']) && activeItem == 'item'}">
                      new item
                    </span>
                    <span v-if="winelist.isOpen" 
                      v-on:click="newNode('group',['new'])"
                      v-bind:class="{ 'w-active' : isActiveNode(['new']) && activeItem == 'group'}">
                      - new group
                    </span>
                  </p>
                </b-row>
              </b-col> 
            </b-row>    
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="activeItem == 'item'" style="padding-left:0;padding-right:0" :key="refreshTabs">
            <br>
            <b-tabs v-if="renderChild" class="w-tabs" v-model="tabIndex" fill>
              <div v-if="bottle_id == 0" class="w-tabbox" style="padding: 0 10px !important">
                <b-tab class="w-tab" title="from database" active>
                  <LibrarySearch
                    @refreshRequest="refresh_All"/>
                </b-tab>
                <b-tab class="w-tab" title="from scratch">
                  <FullDefine
                    @refreshRequest="refresh_All"/>  
                </b-tab>
              </div>
              <div v-else class="w-tabbox" style="padding: 0 0 20px 0 !important">
                <b-tab v-if="bottle.wine_id !=0" class="w-tab" title="from database" active>
                  <Item/>
                </b-tab>
                <b-tab v-else-if="bottle.c_wine_id !=0 && c_wine.user_id != currentUserID" class="w-tab" title="from database" active>
                  <Item/>
                </b-tab>
                <b-tab v-else-if="bottle.c_wine_id !=0 && c_wine.user_id == currentUserID" class="w-tab" title="from scratch" active>
                  <Item/>
                </b-tab>
                <b-tab v-else class="w-tab" title="error" active>
                  <p>UNKNOWN CONDITION</p>
                  <p>bottle.wine_id = {{ bottle.wine_id }}</p>
                  <p>bottle.c_wine_id = {{ bottle.c_wine_id }}</p>
                  <p>c_wine.user_id = {{ c_wine.user_id  }}</p>
                  <p>currentUserID = {{ currentUserID  }}</p>
                </b-tab>  
              </div>
            </b-tabs>                  
          </b-col>
          <b-col v-if="activeItem == 'group'" style="padding-left:0;padding-right:0">
            <hr>
            <div>
              <p class="w-subtitle text-right" style="margin-right:15px">group</p>
              <b-form-group style="text-align:left; padding-left:15px; padding-right:15px">
                <label class="w-label w-required" for="input-name">name</label>
                <b-form-input
                  id="input-name"
                  class="w-input"
                  v-model="groupName"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.nameValid}"
                  aria-describedby="input-live-help input-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                {{nameFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- <p>selected_node = {{selected_node}}</p>
              <p>selected_id = {{selected_id}}</p> -->
              <br>
              <div style="padding-left:15px; padding-right:15px">
                <b-button v-on:click="onSaveGroup()" class="w-button-green-full">save</b-button>
              </div>
            </div>
          </b-col>
          <b-col v-if="activeItem == 'none'" style="margin-left:0;margin-right:0">
            <hr>
            <div>
              <p class="ww-title"><b>Welcome to the winelist of the organised tasting called '{{winelist.name}}'.</b></p>
              <hr>
              <p class="ww-text" v-if="winelist.isOpen">This tasting is an <b>open tasting</b>. This means that the organiser offers a catalogue of bottles from which the attendant can choose which bottles he/she wants to taste.</p>
              <p class="ww-text" v-else>The organiser invites you to taste <b>all</b> bottles.</p>
              <p class="ww-text" v-if="winelist.isBlind">It is a <b>blind</b> tasting. This means that the bottles will be wrapped during the tasting and uncovered once the organiser is ready to disclose the nature of the bottle.</p>
              <p class="ww-text" v-if="winelist.isOrdered && !winelist.isBlind">It is an <b>ordered</b> tasting. This means that the organiser invites the attendants to taste the bottles in a particular order.</p>
              <p class="ww-text" v-if="winelist.isOrdered && winelist.isBlind">And finally it is an <b>ordered</b> tasting. This means that the organiser invites the attendants to taste the bottles in a particular order.</p>
              <p class="ww-text">The wine list at your left contains:</p>
              <p class="w-item w-level2" style="display:block">bottles</p>
              <p class="w-group w-level2" v-if="winelist.isOpen" style="display:block">lists of bottles</p>
              <br>
              <p class="ww-text">Please click on the items to see the details.</p>
              <br>
            </div>
          </b-col>
        </b-row>
        <br>
        <div>
          <b-button v-on:click="onBack()" class="w-button-pink-full">back</b-button>
        </div>    
        <br>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
        <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p style="margin-bottom:0"><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
              <p style="position: relative; top: -5px;font-size: 10px; color: rgb(109,122,82);margin-bottom:0">organise</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr style="position: relative; top: -10px">
            <Menu/>
          </template>
        </b-sidebar>            
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue'
  import Menu from './MenuMobile_Organise.vue'

  import LibrarySearch from './LibrarySearch_Mobile.vue';
  import FullDefine from './FullDefine_WineList_Mobile.vue';
  import Item from './Item_Mobile.vue';

  import WineLists from '../WineLists.js'
  import Items from '../Items.js'
  import Bottles from '../Bottles.js'
  import Wines from '../Wines.js';
  import C_Wines from '../C_Wines.js';
  import fromItemID from '@/fromItemID';

  export default {
    name: "WineListShow",
    components: {
      NoAccess,
      Menu,
      LibrarySearch,
      FullDefine,
      Item,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession;
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      searchState() {
        return this.$store.state.search_state
      },
      // number_of_bottles() {
      //   return this.winelist.item_ids.length;
      // },
      c_wineatDate() {
        let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        let y = this.winelist.date.substring(0,4);
        let n1 = this.winelist.date.indexOf("-");
        let n2 = this.winelist.date.lastIndexOf("-");
        let m = this.winelist.date.substring(n1+1,n2);
        let s = this.winelist.date.length;
        let d = this.winelist.date.substring(n2+1,s);
        if (d[0] == '0'){
          d = d.substring(2,1)
        }

        let c_wineat_date = months[m-1] + ", " + d + "th " + y;
        return c_wineat_date;
      },
    },
    data() {
      return {
        debug: false,
        isLoading: true,
      
        winelist: {
          user_id:0,
          
          name:'',
          organiser:'',
          date:'',
          description:'',
          image_files: [],
          isOpen: true,
          isBlind: false,
          isOrdered: false,
          code:'',

          tasting_note_ids:[],
          nodes:[]
        },
        winelist_id:0,

        code:'',

        items: [],

        up_visible: true,
        down_visible: true,

        item:{
          name:'',          
          bottle_id:0,
          origin:'',
          price:'',
          comment:'',
          winelist_ids:[],
        },
        item_id:0,

        bottle:{
          name:'',
          wine_id:0,
          c_wine_id:0,
          vintage:0,
          winelist_ids:[],
          tasting_note_ids:[]
        },
        bottle_id:0,

        c_wine:{
          name:'',
          type:'',
          country_id:0,
          country:'',
          region_id:0,
          region:'',
          appellation_id:0,
          appellation:'',
          classification_id:0,
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          longevity:'',
          vines:'',
          yields:'',
          vinification:'',
          image_files:[],
          user_id:0,
        },
        c_wine_id:0, 

        scoreValid : true,
        scoreFeedback :'',

        // Table
        fields: [
          {key: 'name',sortable: true},
          {key: 'type',sortable: false},
          {key: 'region',sortable: true}
        ],
        wines: [],

        wine: {
          name:'',
          type:'',
          country_id:'',
          region:'',
          appellation_id:'',
          appellation:'',
          classification_id:'',
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          longevity:'',
          vines:'',
          yields:'',
          vinification:'',
          image_files:[],
          winery_id:''
        },
        wine_id :0,
        
        //Tabs
        tabIndex :0,

        //Refresh
        refreshList: 0,
        refreshTabs: 10000,
        refreshChild: 0,
        renderChild: false,

        selected_node:[],
        selected_id:0,

        groupID:0,
        groupName:"",
        countGroup:0,
        nameValid:true,
        nameFeedback:"",

        activeItem:"none",

        // Move item stuff
        from_group:{
          _id:0,
          isLeaf:false,
          value: {
            name:''
          },
          parent_id:0,
        },
        from_group_id:0,
        to_group:[],
        to_group_id:0,
        toGroupValid:true,
        toGroupFeedback: '',
      }
    }, 
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("WineListShow.mounted - START")
    
      this.$store.commit('setSource',"WineList")
      
      if (this.debug) console.log("WineListShow.mounted - Loading Winelist")

      if (typeof this.$route.query.winelist_id !== "undefined") {
        await WineLists.getone(this.$route.query.winelist_id)
        .then(response=> {
          this.winelist = response;
          this.winelist_id = this.winelist._id;
          delete this.winelist._id;
          if (this.debug) console.log( "WineListShow.mounted: Winelist ", this.winelist, " succesfully fetched from DB");
        })
        .catch(error=> {
          if (this.debug) console.log("WineListShow.mounted: await WineLists.getone", error);
        })
      } else {
        if (this.debug) console.log("WineListShow.mounted: ERROR this.$route.query.winelist_id = ", this.$route.query.winelist_id);
      }
      this.code = this.printCode(this.winelist.code);

      let challenge = 0
      for (let i=0; i < this.winelist.nodes.length; i++ ) {
        if (!this.winelist.nodes[i].isLeaf) {
          challenge = parseInt(this.winelist.nodes[i]._id.substring( this.winelist.nodes[i]._id.indexOf('_') + 1))
          if (challenge > this.countGroup) this.countGroup = challenge 
          for (let j=0; j < this.winelist.nodes[i].nodes.length; j++ ) {
            if (!this.winelist.nodes[i].nodes[j].isLeaf) {
              challenge = parseInt(this.winelist.nodes[i].nodes[j]._id.substring( this.winelist.nodes[i].nodes[j]._id.indexOf('_') + 1))
              if (challenge > this.countGroup) this.countGroup = challenge
              for (let k=0; k < this.winelist.nodes[i].nodes[j].nodes.length; k++ ) {
                if (!this.winelist.nodes[i].nodes[j].nodes[k].isLeaf) {
                  challenge = parseInt(this.winelist.nodes[i].nodes[j].nodes[k]._id.substring( this.winelist.nodes[i].nodes[j].nodes[k]._id.indexOf('_') + 1))
                  if (challenge > this.countGroup) this.countGroup = challenge
                }
              }
            }
          }
        }
      }
      this.countGroup++
      if (this.debug) console.log( "WineListShow.mounted: countGroup = ", this.countGroup);
      
      this.$store.commit('setWinelist',this.winelist)
      this.$store.commit('setWinelistID',this.winelist_id)
      if (this.debug) console.log("WineListShow.mounted: winelist ", this.winelist, " winelist_id ", this.winelist_id, " is added to STORE")
      if (this.debug) console.log("WineListShow.mounted - STOP")

      // this.isActiveItem(0)
      // await this.selectItem(0)
      this.renderChild = true
      this.isLoading = false
    },
    methods: {      
      printCode(this_code) {
        let print_code = this_code.toUpperCase()
        print_code = print_code.replace(/\s+/g, '')  
        print_code = print_code.substring(0,2) + ' ' + print_code.substring(2,4) + ' ' + print_code.substring(4,6)
        return print_code;
      },

      async onClickNode(node) {
        let local_debug = false
        if (this.debug) console.log ("WineListShow.onClickNode: START")
        if (node.length == 0) {
          if (this.debug) console.log ("WineListShow.onClickNode: node not valid")
        } else if (node.length == 1) {
          if ( this.winelist.nodes[node[0]].isLeaf) {     
              if (local_debug) console.log("WineListShow.onClickNode: node level 1 is leaf - NOTHING DONE")
          } else {   
            if (local_debug) console.log("WineListShow.onClickNode: node level 1 has subnodes: ",this.winelist.nodes[node[0]].nodes)
            await this.toggleVisibility(this.winelist.nodes[node[0]])
          }
          await this.selectNode(node)
        } else if (node.length == 2) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].isLeaf) {     
            if (local_debug) console.log("WineListShow.onClickNode: node level 2 is leaf - NOTHING DONE")
          } else {  
            if (local_debug) console.log("WineListShow.onClickNode: node level2 has subnodes: ",this.winelist.nodes[node[0]].nodes[node[1]].nodes)
            await this.toggleVisibility(this.winelist.nodes[node[0]].nodes[node[1]])
          }
          await this.selectNode(node)   
        } else if (node.length == 3) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].isLeaf) {     
            if (local_debug) console.log("WineListShow.onClickNode: node level 3 is leaf - NOTHING DONE")
          } else {   
            if (local_debug) console.log("WineListShow.onClickNode: node level 3 has subnodes: ",this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes)
            await this.toggleVisibility(this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]])
          }
          await this.selectNode(node)
        } else if (node.length == 4) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]].isLeaf) {     
            if (local_debug) console.log("WineListShow.onClickNode: node level 4 is leaf - NOTHING DONE")
          } else {   
            if (this.debug) console.log ("WineListShow.onClickNode: ERROR maximum node depth is 4.")
          }
          await this.selectNode(node)        
        } else {
          if (this.debug) console.log ("WineListShow.onClickNode: ERROR maximum node depth is 4.")
        }
        if (this.debug) console.log ("WineListShow.onClickNode: STOP")  
      },

      async showModal_moveBottle(node) {
        if (this.debug) console.log("")
        if (this.debug) console.log("WineListShow.showModal_moveBottle: START")
        let local_debug = false

        // this.from_group = {
        //   _id:0,
        //   isLeaf:false,
        //   value: {
        //     name:''
        //   },
        //   parent_id:0,
        // }
    
        if (this.debug) console.log("WineListShow.showModal_moveBottle this.winelist = ", this.winelist)
        if (node.length == 0) {
          if (this.debug) console.log ("WineListShow.showModal_moveBottle: node not valid")
        } else if (node.length == 1) {
          if ( this.winelist.nodes[node[0]].isLeaf) {     
            if (local_debug) console.log("WineListShow.showModal_moveBottle: node level 1 is leaf.")
            this.from_group = this.winelist
            this.from_group = {
              _id: this.winelist_id,
              isLeaf:false,
              value: {
                name: this.winelist.name
              },
              nodes: this.winelist.nodes,
              parent_id:0,
              isVisible:true
            }
            this.from_group_id = this.winelist_id
            if (this.debug) console.log("showModal_moveBottle: this.from_group = ", this.from_group)
          }
          this.selectNode(node)
        } else if (node.length == 2) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].isLeaf) {     
            if (local_debug) console.log("WineListShow.showModal_moveBottle: node level 2 is leaf.")
            this.from_group = this.winelist.nodes[node[0]]
            this.from_group_id = this.winelist.nodes[node[0]]._id
            if (this.debug) console.log("showModal_moveBottle: this.from_group = ", this.from_group)
          }
          this.selectNode(node)   
        } else if (node.length == 3) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].isLeaf) {     
            if (local_debug) console.log("WineListShow.showModal_moveBottle: node level 3 is leaf.")
            this.from_group = this.winelist.nodes[node[0]].nodes[node[1]]
            this.from_group_id = this.winelist.nodes[node[0]].nodes[node[1]]._id
            if (this.debug) console.log("showModal_moveBottle: this.from_group = ", this.from_group)
          }
          this.selectNode(node)
        } else if (node.length == 4) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]].isLeaf) {     
            if (local_debug) console.log("WineListShow.showModal_moveBottle: node level 4 is leaf.")
            this.from_group = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]]
            this.from_group_id = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]]._id
            if (this.debug) console.log("showModal_moveBottle: this.from_group = ", this.from_group)
          }
          this.selectNode(node)        
        } else {
          if (this.debug) console.log ("WineListShow.showModal_moveBottle: ERROR maximum node depth is 4.")
        }

        this.to_group = [{value:this.winelist_id,text:this.winelist.name}]
        this.to_group_id = 0
        for ( let i=0 ; i < this.winelist.nodes.length ; i++) {
          if (!this.winelist.nodes[i].isLeaf) {
            let text = '=> ' +this.winelist.nodes[i].value.name
            if (local_debug) console.log("WineListShow.showModal_moveBottle: adding ",{value:this.winelist.nodes[i]._id,text:text}," to to_group.")
            this.to_group.push({value:this.winelist.nodes[i]._id,text:text})
            for ( let j=0 ; j < this.winelist.nodes[i].nodes.length ; j++ ) {
              if (!this.winelist.nodes[i].nodes[j].isLeaf) {
                let text = '==> ' + this.winelist.nodes[i].nodes[j].value.name
                if (local_debug) console.log("WineListShow.showModal_moveBottle: adding ",{value:this.winelist.nodes[i].nodes[j]._id,text:text}," to to_group.")
                this.to_group.push({value:this.winelist.nodes[i].nodes[j]._id,text:text})
                for ( let k=0 ; k < this.winelist.nodes[i].nodes[j].nodes.length ; k++ ) {
                  if (!this.winelist.nodes[i].nodes[j].nodes[k].isLeaf) {
                    let text = '===> ' + this.winelist.nodes[i].nodes[j].nodes[k].value.name
                    if (local_debug) console.log("WineListShow.showModal_moveBottle: adding ",{value:this.winelist.nodes[i].nodes[j].nodes[k]._id,text:text}," to to_group.")
                    this.to_group.push({value:this.winelist.nodes[i].nodes[j].nodes[k]._id,text:text})
                    for ( let l=0 ; l < this.winelist.nodes[i].nodes[j].nodes[k].nodes.length ; l++ ) {
                      if (!this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].isLeaf) {
                        let text = '====> ' + this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].value.name
                        if (local_debug) console.log("WineListShow.showModal_moveBottle: adding ",{value:this.winelist.nodes[i].nodes[j].nodes[k].nodes[l]._id,text:text}," to to_group.")
                        this.to_group.push({value:this.winelist.nodes[i].nodes[j].nodes[k].nodes[l]._id,text:text})
                      }
                    }
                  }
                }
              }
            }
          } 
        }

        this.$root.$emit("bv::show::modal", "moveBottle");
        
        if (this.debug) console.log("WineListShow.showModal_moveBottle: STOP")
      },

      async moveBottle() {
        if (this.debug) console.log("")
        if (this.debug) console.log("WineListShow.moveBottle: START")
        if (local_debug) console.log("WineListShow.moveBottle: START")
        let local_debug = false

        let current_node = {}
        let to_group_node = []

        if (local_debug) console.log("WineListShow.moveBottle from_group_id = ", this.from_group_id," to_group_id = ", this.to_group_id)
        if (local_debug) console.log("WineListShow.moveBottle selected_node = ", this.selected_node," selected_id = ", this.selected_id)

        if (this.selected_node.length == 1) {
          current_node = this.winelist.nodes[this.selected_node[0]]
        } else if (this.selected_node.length == 2) {
          current_node = this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]]
        } else if (this.selected_node.length == 3) {
          current_node = this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]]
        } else if (this.selected_node.length == 4) {
          current_node = this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]].nodes[this.selected_node[3]]
        } else {
          alert("WineListShow.moveBottle: SEVERE ERROR - this.selected_node.length = ", this.selected_node.length)
        }

        if (local_debug) console.log("WineListShow.moveBottle OLD current_node = ", current_node)

        let index_table = []
        for ( let i=0 ; i < this.winelist.nodes.length ; i++ ) {
          if ( !this.winelist.nodes[i].isLeaf ) {
            let node = []
            node.push(i)
            if (local_debug) console.log("WineListShow.moveBottle pushing ",{id:this.winelist.nodes[i]._id,node:JSON.stringify(node)} )
            index_table.push({id:this.winelist.nodes[i]._id,node:node})
            for ( let j=0 ; j < this.winelist.nodes[i].nodes.length ; j++ ) {
              if ( !this.winelist.nodes[i].nodes[j].isLeaf ) {
                let node = []
                node.push(i)
                node.push(j)
                if (local_debug) console.log("WineListShow.moveBottle pushing ",{id:this.winelist.nodes[i].nodes[j]._id,node:JSON.stringify(node)} )
                index_table.push({id:this.winelist.nodes[i].nodes[j]._id,node:node})
                for ( let k=0 ; k < this.winelist.nodes[i].nodes[j].nodes.length ; k++ ) {
                  if ( !this.winelist.nodes[i].nodes[j].nodes[k].isLeaf ) {
                    let node = []
                    node.push(i)
                    node.push(j)
                    node.push(k)
                    if (local_debug) console.log("WineListShow.moveBottle pushing ",{id:this.winelist.nodes[i].nodes[j].nodes[k]._id,node:JSON.stringify(node)} )
                    index_table.push({id:this.winelist.nodes[i].nodes[j].nodes[k]._id,node:node})
                  }
                }
              }
            }
          }
        }

        for ( let i=0 ; i<index_table.length ; i++) {
          if (local_debug) console.log("WineListShow.moveBottle ",index_table[i].id," ",JSON.stringify(index_table[i].node) )
          if (index_table[i].id == this.to_group_id) {
            to_group_node = index_table[i].node
          }
        }
        if (local_debug) console.log("WineListShow.moveBottle to_group_node = ", to_group_node)

        // Adapt current_node
        if (to_group_node.length == 0) {
          current_node.parent_id = 0
        } else {
          current_node.parent_id = this.to_group_id
        }
        if (local_debug) console.log("WineListShow.moveBottle NEW current_node = ", current_node)
       
        // Push current_node to to_group_node
        if (to_group_node.length == 0) {
          this.winelist.nodes.push(current_node)
        } else if (to_group_node.length == 1) {
          this.winelist.nodes[to_group_node[0]].nodes.push(current_node)
        } else if (to_group_node.length == 2) {
          this.winelist.nodes[to_group_node[0]].nodes[to_group_node[1]].nodes.push(current_node)
        } else if (to_group_node.length == 3) {
          this.winelist.nodes[to_group_node[0]].nodes[to_group_node[1]].nodes[to_group_node[2]].nodes.push(current_node)
        } else {
          alert("WineListShow.moveBottle: SEVERE ERROR - to_group_node.length = ", to_group_node)
        }

        // Remove node from selected_node parent        
        if (this.selected_node.length == 1) {
          if (local_debug) console.log("WineListShow.moveBottle splice: OLD this.winelist.nodes = ", this.winelist.nodes)
          this.winelist.nodes.splice(this.selected_node[0],1)
          if (local_debug) console.log("WineListShow.moveBottle splice: NEW this.winelist.nodes = ", this.winelist.nodes)
        } else if (this.selected_node.length == 2) {
          if (local_debug) console.log("WineListShow.moveBottle splice: OLD this.winelist.nodes[",this.selected_node[0],"].nodes = ", this.winelist.nodes[this.selected_node[0]].nodes)
          this.winelist.nodes[this.selected_node[0]].nodes.splice(this.selected_node[1],1)
          if (local_debug) console.log("WineListShow.moveBottle splice: NEW this.winelist.nodes[",this.selected_node[0],"].nodes = ", this.winelist.nodes[this.selected_node[0]].nodes)
        } else if (this.selected_node.length == 3) {
          this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes.splice(this.selected_node[2],1)
        } else if (this.selected_node.length == 4) {
          this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]].nodes.splice(this.selected_node[3],1)
        } else {
          alert("WineListShow.moveBottle: SEVERE ERROR - this.selected_node.length = ", this.selected_node.length)
        }

        if (local_debug) console.log("WineListShow.moveBottle this.winelist = ", this.winelist)

        // Update winelist
        await WineLists.update(this.winelist_id, this.winelist)
        .then(response => {
          if (this.debug) console.log( "WineListShow.moveBottle: Winelist ", this.winelist, " succesfully updated with response ", response);
        })
        .catch(error =>{
          if (this.debug) console.log("WineListShow.moveBottle: ERROR await WineLists.update - ", error);
        })
        this.$store.commit('setWinelist',this.winelist)
        this.refreshList++

        if (local_debug) console.log("WineListShow.moveBottle: to_group_node = ", to_group_node)
        let new_node = to_group_node
        if (to_group_node.length == 0) {
          new_node.push(this.winelist.nodes.length-1)
        } else if (to_group_node.length == 1) {
          new_node.push(this.winelist.nodes[to_group_node[0]].nodes.length-1)
        } else if (to_group_node.length == 2) {
          new_node.push(this.winelist.nodes[to_group_node[0]].nodes[to_group_node[1]].nodes.length-1)
        } else if (to_group_node.length == 3) {
          new_node.push(this.winelist.nodes[to_group_node[0]].nodes[to_group_node[1]].nodes[to_group_node[2]].nodes.length-1)
        } else  {
          alert("WineListShow.moveBottle: SEVERE ERROR - to_group_node.length = ", to_group_node)
        }
        if (local_debug) console.log("WineListShow.moveBottle: new_node = ", new_node)
        this.selectNode(new_node) 

        if (this.debug) console.log("WineListShow.moveBottle: STOP")
        if (local_debug) console.log("WineListShow.moveBottle: STOP")
      },

      async toggleVisibility(node) {
        let local_debug = false
        if (local_debug) console.log("=====> WineListShow.toggleVisibility: START - node = ", node)
        if (node.isVisible) {
          node.isVisible = false
          if (local_debug) console.log("=====> WineListShow.toggleVisibility: toggle isVisible to false")
        } else {
          node.isVisible = true
          if (local_debug) console.log("=====> WineListShow.toggleVisibility: toggle isVisible to true")
        }
        if (local_debug) console.log("=====> WineListShow.toggleVisibility: STOP")    
      },

      async selectItem(item_id) {
        let local_debug = false
        if (this.debug || local_debug) console.log ("")
        if (this.debug || local_debug) console.log ("WineListShow.selectItem: START")
        if (this.debug || local_debug) console.log ("WineListShow.selectItem: item_id = ", item_id)

        this.init_item();
        this.init_bottle();
        this.init_wine();
        this.init_c_wine();

        this.item_id = item_id
        this.selected_id = item_id;
        this.renderChild = false

        if (this.item_id != 0) {
          if (this.debug || local_debug) console.log('WineListShow.selectItem: Fetching Item from DB ');
          await Items.getone(this.item_id)
          .then(response=>{
            if (typeof response == "object") {
              this.item = response;
              delete this.item._id;
              if (this.debug || local_debug) console.log("WineListShow.selectItem: item ", this.item, " with id ",this.item_id," succesfully fetched from DB");
            } else {
              if (this.debug || local_debug) console.log("WineListShow.selectItem: item with id ", this.item_id, " not found in DB");
              this.init_item()
              alert("ERROR: item with id " + this.item_id + " not found in DB - NOTHING DONE");
              return
            }
          })
          .catch(error=>{
            if (this.debug || local_debug) console.log("WineListShow.selectItem: catch await Items.getone()", error);
            alert("ERROR: catch await Items.getone" + error +" - NOTHING DONE");
            return
          })

          if (this.item.bottle_id != 0) {
            await Bottles.getone(this.item.bottle_id)
            .then(response => {
              if (typeof response == "object") {
                this.bottle = response;
                this.bottle_id = this.bottle._id;
                delete this.bottle._id;
                if (this.debug || local_debug) console.log("WineListShow.selectItem: bottle ", this.bottle, "with id = ",this.bottle_id," succesfully fetched from DB");
              } else {
                if (this.debug || local_debug) console.log("WineListShow.selectItem: bottle with id ", this.bottle_id, " not found in DB");
                this.init_bottle()                
              } 
            })
            .catch(error => {
              if (this.debug || local_debug) console.log("WineListShow.selectItem: catch await Bottles.getone()", error);
            })
          } else {
            if (this.debug || local_debug) console.log("WineListShow in selectItem : No associated bottle for this item.");
            this.init_bottle();
          }

          if (this.bottle.wine_id != 0 ) {
            await Wines.getone(this.bottle.wine_id)
            .then(response => {
              if (typeof response == "object") {
                this.wine = response;
                this.wine_id = this.wine._id;
                delete this.wine._id;
                if (this.debug || local_debug) console.log("WineListShow.selectItem: wine ", this.wine, "with id = ",this.wine_id," succesfully fetched from DB");
              } else {
                if (this.debug || local_debug) console.log("WineListShow.selectItem: wine with id ", this.wine_id, " not found in DB");
                this.init_wine()
              }
            })
            .catch(error => {
              if (this.debug || local_debug) console.log("WineListShow.selectItem: catch await Wines.getone()", error);
            })
          } else if (this.bottle.c_wine_id !=0)  {
            await C_Wines.getone(this.bottle.c_wine_id)
            .then(response => {
              if (typeof response == "object") {
                this.c_wine = response;
                this.c_wine_id = this.c_wine._id;
                delete this.c_wine._id;
                if (typeof this.c_wine.sweet == "undefined") {
                  this.c_wine.sweet = false
                }
                if (this.debug || local_debug) console.log("WineListShow.selectItem: c_wine ", this.c_wine, "with id = ",this.c_wine_id," succesfully fetched from DB");
              } else {
                if (this.debug || local_debug) console.log("WineListShow.selectItem: c_wine with id ", this.wine_id, " not found in DB");
                this.init_c_wine()
              }
            })
            .catch(error => {
              if (this.debug || local_debug) console.log("WineListShow.selectItem: catch await C_Wines.getone()", error);
            })
          } else {
            if (this.debug || local_debug) console.log("WineListShow.selectItem: No associated wine or c_wine for this bottle.");
              this.init_wine();
              this.init_c_wine();
          }
        } else {
          if (this.debug || local_debug) console.log("WineListShow.selectItem: this.item_id = ", this.item_id, " NOTHING DONE !!!")
          // this.init_item();
          // this.init_bottle();
          // this.init_wine();
          // this.init_c_wine();
        }

        // let store_node = this.selected_node
        let store_node = []
        for (let i=0; i < this.selected_node.length; i++) {
          store_node.push(this.selected_node[i])
        }
        if (store_node[0] == 'new') {
          store_node[0] = this.winelist.nodes.length
        } else if (store_node[1] == 'new') {
          store_node[1] = this.winelist.nodes[store_node[0]].nodes.length
        } else if (store_node[2] == 'new') {
          store_node[2] = this.winelist.nodes[store_node[0]].nodes[store_node[1]].nodes.length
        } else if (store_node[3] == 'new') {
          store_node[3] = this.winelist.nodes[store_node[0]].nodes[store_node[1]].nodes[store_node[2]].nodes.length
        } 

        if (this.debug || local_debug) console.log("WineListShow.selectItem: Updating Store")
        await this.$store.commit('setStoreNode',store_node)

        await this.$store.commit('setItem',this.item)
        await this.$store.commit('setItemID',this.item_id)

        await this.$store.commit('setBottle',this.bottle)
        await this.$store.commit('setBottleID',this.bottle_id)

        await this.$store.commit('setWine',this.wine)
        await this.$store.commit('setWineID',this.wine_id)

        await this.$store.commit('setCWine',this.c_wine)
        await this.$store.commit('setCWineID',this.c_wine_id)


        if (this.debug || local_debug) console.log("WineListShow.selectItem: item ", this.item, " item_id ", this.item_id)
        if (this.debug || local_debug) console.log("WineListShow.selectItem: this.$store.getters.getItemID ", this.$store.getters.getItemID)
        if (this.debug || local_debug) console.log("WineListShow.selectItem: this.$store.getters.getItem ", this.$store.getters.getItem)

        if (this.debug || local_debug) console.log("WineListShow.selectItem: bottle ", this.bottle, " bottle_id ", this.bottle_id)
        if (this.debug || local_debug) console.log("WineListShow.selectItem: this.$store.getters.getBottleID ", this.$store.getters.getBottleID)
        if (this.debug || local_debug) console.log("WineListShow.selectItem: this.$store.getters.getBottle ", this.$store.getters.getBottle)

        if (this.debug || local_debug) console.log("WineListShow.selectItem: wine ", this.wine, " wine_id ", this.wine_id)
        if (this.debug || local_debug) console.log("WineListShow.selectItem: this.$store.getters.getWineID ", this.$store.getters.getWineID)
        if (this.debug || local_debug) console.log("WineListShow.selectItem: this.$store.getters.getWine ", this.$store.getters.getWine)

        if (this.debug || local_debug) console.log("WineListShow.selectItem: c-wine ", this.c_wine, " c_wine_id ", this.c_wine_id)
        if (this.debug || local_debug) console.log("WineListShow.selectItem: this.$store.getters.getCWineID ", this.$store.getters.getCWineID)
        if (this.debug || local_debug) console.log("WineListShow.selectItem: this.$store.getters.getCWine ", this.$store.getters.getCWine)        
        
        this.refreshChild++;
        this.renderChild = true        
        if (this.debug || local_debug) console.log("WineListShow.selectItem: refreshChild = ", this.refreshChild)
        if (this.debug || local_debug) console.log("WineListShow.selectItem: STOP")
      },

      async selectGroup(group_id) {
        if (this.debug) console.log ("")
        if (this.debug) console.log ("WineListShow in selectGroup: START")
        if (this.debug) console.log ("WineListShow in selectGroup: group_id = ", group_id)
        this.selected_id = group_id;
        if (group_id !=0) {

        } else {

        }
        if (this.debug) console.log ("WineListShow in selectGroup: STOP")
      },

      async selectNode(node) {
        let local_debug = false;
        if (this.debug || local_debug) console.log ("")
        if (this.debug || local_debug) console.log ("WineListShow.selectNode: START")
        if (this.debug || local_debug) console.log ("WineListShow.selectNode: node=",node)
      
        this.selected_node = node
        await this.$store.commit('setStoreNode',this.selected_node)

        if (this.debug || local_debug) console.log ("WineListShow.selectNode: this.selected_node=",this.selected_node)
        
        if (node.length == 0) {
          if (this.debug || local_debug) console.log ("WineListShow.selectNode: node not valid")
        }
        else if (node.length == 1) {
          if ( this.winelist.nodes[node[0]].isLeaf) {  
            if (local_debug) console.log("WineListShow.selectNode: node level 1 is leaf.")
            this.activeItem="item"
            await this.selectItem(this.winelist.nodes[node[0]]._id)
          } else {   
            if (local_debug) console.log("WineListShow.selectNode: node level 1 has subnodes: ",this.winelist.nodes[node[0]].nodes)
            this.groupName = this.winelist.nodes[node[0]].value.name
            this.activeItem="group"
            await this.selectGroup(this.winelist.nodes[node[0]]._id)
          }
        }
        else if (node.length == 2) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].isLeaf) {     
            if (local_debug) console.log("WineListShow.selectNode: node level 2 is leaf.")
            this.activeItem="item"
            await this.selectItem(this.winelist.nodes[node[0]].nodes[node[1]]._id)
          } else {  
            if (local_debug) console.log("WineListShow.selectNode: node level2 has subnodes: ",this.winelist.nodes[node[0]].nodes[node[1]].nodes)
            this.groupName = this.winelist.nodes[node[0]].nodes[node[1]].value.name
            this.activeItem="group"
            await this.selectGroup(this.winelist.nodes[node[0]].nodes[node[1]]._id)
          }   
        }
        else if (node.length == 3) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].isLeaf) {     
            if (local_debug) console.log("WineListShow.selectNode: node level 3 is leaf.")
            this.activeItem="item"
            await this.selectItem(this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]]._id)
          } else {   
            if (local_debug) console.log("WineListShow.selectNode: node level 3 has subnodes: ",this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes)
            this.groupName = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].value.name
            this.activeItem="group"
            await this.selectGroup(this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]]._id)
          }     
        }
        else if (node.length == 4) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]].isLeaf) {     
            if (local_debug) console.log("WineListShow.selectNode: node level 4 is leaf.")
            this.activeItem="item"
            await this.selectItem(this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]]._id)
          } else {   
            if (local_debug) console.log("WineListShow.selectNode: node level 4 has subnodes (and should not exist): ",this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]].nodes)
            this.groupName = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]].value.name
            this.activeItem="item"
            await this.selectGroup(this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]]._id)
          }     
        } else {
          if (this.debug || local_debug) console.log ("WineListShow.selectNode: ERROR maximum node depth is 4.")
        }                                      
        // await this.$forceUpdate();
        this.refreshList++;
        this.refreshTabs++;
        if (this.debug || local_debug) console.log ("WineListShow.selectNode: this.selected_node=",this.selected_node)
        if (this.debug || local_debug) console.log ("WineListShow.selectNode: STOP")
      },

      isActiveNode(node) {
        let local_debug = false
        if (local_debug) console.log ("")
        if (local_debug) console.log ("WineListShow.isActiveNode: START")
        let return_value = true
        
        let sssss='['
        for (let k=0; k < node.length; k++) {
          if (k == 0) sssss = sssss + node[k]  
          if (k > 0) sssss = sssss + ','+ node[k]
        }
        sssss = sssss + ']'
        let ttttt='['
        for (let k=0; k < this.selected_node.length; k++) {
          if (k == 0) ttttt = ttttt + this.selected_node[k]  
          if (k > 0) ttttt = ttttt + ','+ this.selected_node[k]
        }
        ttttt = ttttt + ']'

        if (this.selected_node[this.selected_node.length-1] == "new") {
          if (node.length == this.selected_node.length) {
            for (let i=0;i < this.selected_node.length;i++) {
              return_value = return_value && node[i]==this.selected_node[i]
            }
            if (local_debug) console.log("WineListShow.isActiveNode: new at the end, equal length => ", return_value)
          } else {
            for (let i=0; i < node.length; i++) {
              if (node[i] == this.selected_node[i]) {
                return_value = return_value && (node[i] == this.selected_node[i])
              } else {
                return_value = false
                break
              }
            }
            if (local_debug) console.log("WineListShow.isActiveNode:  new at the end, different length  => ", return_value)
          }
        } else {
          if (node.length == this.selected_node.length) {
            for (let i=0;i < this.selected_node.length;i++) {
              return_value = return_value && (node[i]==this.selected_node[i]) 
            }
            if (local_debug) console.log("WineListShow.isActiveNode:  full node, equal length => ", return_value)
          } else if(node.length < this.selected_node.length) {
            for (let i=0;i < node.length;i++) {
              if (node[i]==this.selected_node[i])  {
                return_value = return_value && (node[i]==this.selected_node[i])
              } else {
                return_value = false
                break
              }
            }
            if (local_debug) console.log("WineListShow.isActiveNode: full node, different length => ", return_value)
          } else {
            return_value = false
          }
        }
        if (local_debug) console.log ("WineListShow.isActiveNode: node=",sssss," this.selected_node=",ttttt," => return_value=",return_value)
        if (local_debug && return_value) console.log ("WineListShow.isActiveNode:  BINGO")
        if (local_debug && return_value) console.log ("")
        if (local_debug) console.log ("WineListShow.isActiveNode: STOP")
      
        return return_value
      },

      isCurrentNode(node) {
        let local_debug = false
        if (local_debug) console.log ("")
        if (local_debug) console.log ("WineListShow.isCurrentNode: START")
        let return_value = true
        
        let sssss='['
        for (let k=0; k < node.length; k++) {
          if (k == 0) sssss = sssss + node[k]  
          if (k > 0) sssss = sssss + ','+ node[k]
        }
        sssss = sssss + ']'
        let ttttt='['
        for (let k=0; k < this.selected_node.length; k++) {
          if (k == 0) ttttt = ttttt + this.selected_node[k]  
          if (k > 0) ttttt = ttttt + ','+ this.selected_node[k]
        }
        ttttt = ttttt + ']'

        if (this.selected_node[this.selected_node.length-1] == "new") {
          return_value = false
        } else {
          if (node.length == this.selected_node.length) {
            for (let i=0;i < this.selected_node.length;i++) {
              return_value = return_value && node[i]==this.selected_node[i]
            }
          } else {
            return_value = false
          }
        }
        if (local_debug) console.log ("WineListShow.isCurrentNode: node=",sssss," this.selected_node=",ttttt," => return_value=",return_value)
        if (local_debug && return_value) console.log ("WineListShow.isCurrentNode: BINGO")
        if (local_debug && return_value) console.log ("")
        if (local_debug) console.log ("WineListShow.isCurrentNode: STOP")
      
        return return_value
      },

      async newNode(type,node) {
        let local_debug = false
        if (local_debug) console.log("WineListShow.newNode: type=", type,"node =", node)
        this.selected_node = node
        if (type == 'item') {
          this.activeItem = 'item'
          await this.selectItem(0)
        } else if (type == 'group') {
          this.activeItem = 'group'
          this.groupName = ""
          await this.selectGroup(0)
        } else if (type == 'none') {
          this.activeItem = 'none'
        } else {
          if (this.debug) console.log ("WineListShow.newNode: ERROR invalid type: ", type)
        }
      },

      async deleteNode(node) {
        let local_debug = true;
        let canbeDeleted = false;

        if (this.debug || local_debug) console.log ("")
        if (this.debug || local_debug) console.log ("WineListShow.deleteNode: START")
        if (this.debug || local_debug) console.log ("WineListShow.deleteNode: node=",node)

        if (node.length == 0) {
          if (this.debug || local_debug) console.log ("WineListShow.deleteNode: node not valid")
        } else if (node.length == 1) {
          if ( this.winelist.nodes[node[0]].isLeaf) {     
            if (local_debug) console.log("WineListShow.deleteNode: node level 1 is leaf.")

            let challenge = {"item_id": this.winelist.nodes[node[0]]._id}
            await fromItemID.getTastingNotes(challenge)
            .then(response => {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: number of tasting_notes with this item= ", response.length);
              if (response.length == 0) {
                canbeDeleted = true
              }
            })
            .catch(error => {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: await fromItemID - ", error);
            })

            if (canbeDeleted) {
              await Items.delete(this.winelist.nodes[node[0]]._id)
              .then(response => {
                if (this.debug || local_debug) console.log("WineListShow.deleteNode: item with id ", this.winelist.nodes[node[0]]._id, " deleted with response : ", response)
              })
              .catch(error => {
                if (this.debug || local_debug) console.log("WineListShow.deleteNode: catch await Items.delete ERROR : " + error);
              })
            } else {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: item with id ", this.winelist.nodes[node[0]]._id, " NOT deleted bacause it appears on other tasting_notes.")
            }


            this.winelist.nodes.splice(node[0],1)
            if (this.debug || local_debug) console.log("WineListShow.deleteNode: node ", node[0], "succesfully removed from this.winelist.nodes = ", this.winelist.nodes)
          } else {   
            if (local_debug) console.log("WineListShow.deleteNode: node level 1 is NOT a leaf.")
            if (this.winelist.nodes[node[0]].nodes.length == 0) {
              if (local_debug) console.log("WineListShow.deleteNode: node level 1 has 0 subnodes.")
              this.winelist.nodes.splice(node[0],1)
              if (local_debug) console.log("WineListShow.deleteNode: node level 1 has been sucesfully deleted.")
            } else {
              if (local_debug) console.log("WineListShow.deleteNode: node level 1 has ",this.winelist.nodes[node[0]].nodes.length," subnodes.")
              if (this.winelist.nodes[node[0]].nodes.length > 0) {
                if (local_debug) console.log("WineListShow.deleteNode: Cannot delete group because it's not empty.")
                alert("Cannot delete group because it's not empty.") 
              }
            }
          }
          if (this.selected_node[0] > 0) {
            this.selected_node = [this.selected_node[0]-1]
            await this.selectNode(this.selected_node)
          } else {
            this.selected_node = ['new']
            await this.newNode('item',this.selected_node)
          }
        } else if (node.length == 2) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].isLeaf) {     
            if (local_debug) console.log("WineListShow.deleteNode: node level 2 is leaf.")

                        let challenge = {"item_id": this.winelist.nodes[node[0]]._id}
            await fromItemID.getTastingNotes(challenge)
            .then(response => {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: number of tasting_notes with this item= ", response.length);
              if (response.length == 0) {
                canbeDeleted = true
              }
            })
            .catch(error => {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: await fromItemID - ", error);
            })

            if (canbeDeleted) {
              await Items.delete(this.winelist.nodes[node[0]].nodes[node[1]]._id)
              .then(response => {
                if (this.debug || local_debug) console.log("WineListShow.deleteNode: item with id ", this.winelist.nodes[node[0]].nodes[node[1]]._id, " deleted with response : ", response)
              })
              .catch(error => {
                if (this.debug || local_debug) console.log("WineListShow.deleteNode: catch await Items.delete ERROR : " + error);
              })
            } else {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: item with id ", this.winelist.nodes[node[0]]._id, " NOT deleted bacause it appears on other tasting_notes.")
            }
            
            this.winelist.nodes[node[0]].nodes.splice(node[1],1)
            if (this.debug || local_debug) console.log("WineListShow.deleteNode: node ", node[1], "succesfully removed from this.winelist.nodes[node[0]].nodes = ", this.winelist.nodes[node[0]].nodes)
          } else {
            if (local_debug) console.log("WineListShow.deleteNode: node level 2 is NOT a leaf.")
            if (this.winelist.nodes[node[0]].nodes[node[1]].nodes.length == 0) {
              if (local_debug) console.log("WineListShow.deleteNode: node level 2 has 0 subnodes.")
              this.winelist.nodes[node[0]].nodes.splice(node[1],1)
              if (local_debug) console.log("WineListShow.deleteNode: node level 2 has been sucesfully deleted.")
            } else {
              if (local_debug) console.log("WineListShow.deleteNode: node level 2 has ",this.winelist.nodes[node[0]].nodes[node[1]].nodes.length," subnodes.")
              if (this.winelist.nodes[node[0]].nodes[node[1]].nodes.length > 0) {
                if (local_debug) console.log("WineListShow.deleteNode: Cannot delete group because it's not empty.")
                alert("Cannot delete group because it's not empty.") 
              }
            }
          }
          if (this.selected_node[1] > 0) {
            this.selected_node = [this.selected_node[0],this.selected_node[1]-1]
            await this.selectNode(this.selected_node)
          } else {
            this.selected_node = [this.selected_node[0],'new']
            await this.newNode('item',this.selected_node)
          }
        } else if (node.length == 3) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].isLeaf) {     
            if (local_debug) console.log("WineListShow.deleteNode: node level 3 is leaf.")

            let challenge = {"item_id": this.winelist.nodes[node[0]]._id}
            await fromItemID.getTastingNotes(challenge)
            .then(response => {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: number of tasting_notes with this item= ", response.length);
              if (response.length == 0) {
                canbeDeleted = true
              }
            })
            .catch(error => {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: await fromItemID - ", error);
            })

            if (canbeDeleted) {
              await Items.delete(this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]]._id)
              .then(response => {
                if (this.debug || local_debug) console.log("WineListShow.deleteNode: item with id ", this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]]._id, " deleted with response : ", response)
              })
              .catch(error => {
                if (this.debug || local_debug) console.log("WineListShow.deleteNode: catch await Items.delete ERROR : " + error);
              })
            } else {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: item with id ", this.winelist.nodes[node[0]]._id, " NOT deleted bacause it appears on other tasting_notes.")
            }
            
            this.winelist.nodes[node[0]].nodes[node[1]].nodes.splice(node[2],1)
            if (this.debug || local_debug) console.log("WineListShow.deleteNode: node ", node[2], "succesfully removed from this.winelist.nodes[node[0]].nodes[node[1]].nodes = ", this.winelist.nodes[node[0]].nodes[node[1]].nodes)
          } else {
            if (local_debug) console.log("WineListShow.deleteNode: node level 3 is NOT a leaf.")
            if (this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes.length == 0) {
              if (local_debug) console.log("WineListShow.deleteNode: node level 3 has 0 subnodes.")
              this.winelist.nodes[node[0]].nodes[node[1]].nodes.splice(node[2],1)
              if (local_debug) console.log("WineListShow.deleteNode: node level 3 has been sucesfully deleted.")
            } else {
              if (local_debug) console.log("WineListShow.deleteNode: node level 3 has ",this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes.length," subnodes.")
              if (this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes.length > 0) {
                if (local_debug) console.log("WineListShow.deleteNode: Cannot delete group because it's not empty.")
                alert("Cannot delete group because it's not empty.") 
              }
            }              
          }
          if (this.selected_node[2] > 0) {
            this.selected_node = [this.selected_node[0],this.selected_node[1],this.selected_node[2]-1]
            await this.selectNode(this.selected_node)
          } else {
            this.selected_node = [this.selected_node[0],this.selected_node[1],'new']
            await this.newNode('item',this.selected_node)
          }
        } else if (node.length == 4) {
          if ( this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]].isLeaf) {     
            if (local_debug) console.log("WineListShow.deleteNode: node level 4 is leaf.")

            let challenge = {"item_id": this.winelist.nodes[node[0]]._id}
            await fromItemID.getTastingNotes(challenge)
            .then(response => {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: number of tasting_notes with this item= ", response.length);
              if (response.length == 0) {
                canbeDeleted = true
              }
            })
            .catch(error => {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: await fromItemID - ", error);
            })

            if (canbeDeleted) {
              await Items.delete(this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]]._id)
              .then(response => {
                if (this.debug || local_debug) console.log("WineListShow.deleteNode: item with id ", this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]]._id, " deleted with response : ", response)
              })
              .catch(error => {
                if (this.debug || local_debug) console.log("WineListShow.deleteNode: catch await Items.delete ERROR : " + error);
              })

            } else {
              if (this.debug || local_debug) console.log("WineListShow.deleteNode: item with id ", this.winelist.nodes[node[0]]._id, " NOT deleted bacause it appears on other tasting_notes.")
            }
            
            this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes.splice(node[3],1)
            if (this.debug || local_debug) console.log("WineListShow.deleteNode: node ", node[3], "succesfully removed from this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes = ", this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes)
          } else {
            // This scenario should be impossible
            if (local_debug) console.log("WineListShow.deleteNode: node level 4 is NOT a leaf.")
            if (local_debug) console.log("WineListShow.deleteNode: Invalid node = ", node)
            alert("Invalid node = "+ node)    
          }
          if (this.selected_node[3] > 0) {
            this.selected_node = [this.selected_node[0],this.selected_node[1],this.selected_node[2],this.selected_node[3]-1]
            await this.selectNode(this.selected_node)
          } else {
            this.selected_node = [this.selected_node[0],this.selected_node[1],this.selected_node[2],'new']
            await this.newNode('item',this.selected_node)
          }
        } else {   
          if (this.debug || local_debug) console.log("WineListShow.deleteNode: node not valid node = ", node)
        }

        this.calcNumberofBottles()
        await WineLists.update(this.winelist_id, this.winelist)
        .then(response => {
          if (this.debug || local_debug) console.log( "WineListShow.deleteNode: Winelist ", this.winelist, " succesfully updated with response ", response);
        })
        .catch(error =>{
          if (this.debug || local_debug) console.log("WineListShow.deleteNode: ERROR await WineLists.update - ", error);
        })
        this.$store.commit('setWinelist',this.winelist)

        this.refreshList++
        // await this.selectNode(this.selected_node)
        if (this.debug || local_debug) console.log ("WineListShow.deleteNode: STOP")
      },

      async upNode(node) {
        let local_debug = false;
        if (this.debug || local_debug) console.log ("")
        if (this.debug || local_debug) console.log ("WineListShow.upNode: START")
        if (this.debug || local_debug) console.log ("WineListShow.upNode: node=",node)
        
        // 1. swap ids in this.winelist.nodes
        if (node.length == 0) {
          if (this.debug || local_debug) console.log ("WineListShow.upNode: invalid node = ", node)
        } else if (node.length == 1) {  
          let item_id = this.winelist.nodes[node[0]]._id
          for (let i=0; i < this.winelist.nodes.length; i++){
            if (this.winelist.nodes[i]._id == item_id) {
              let tmp_id = this.winelist.nodes[i-1]
              this.winelist.nodes[i-1] = this.winelist.nodes[i]
              this.winelist.nodes[i] = tmp_id
              this.selected_node = [i-1]
              break
            }
          }
        } else if (node.length == 2) {
          let item_id = this.winelist.nodes[node[0]].nodes[node[1]]._id
          for (let i=0; i < this.winelist.nodes[node[0]].nodes.length; i++){
            if (this.winelist.nodes[node[0]].nodes[i]._id == item_id) {
              let tmp_id = this.winelist.nodes[node[0]].nodes[i-1]
              this.winelist.nodes[node[0]].nodes[i-1] = this.winelist.nodes[node[0]].nodes[i]
              this.winelist.nodes[node[0]].nodes[i] = tmp_id
              this.selected_node = [node[0],i-1]
              break
            }
          }
        } else if (node.length == 3) {
          let item_id = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]]._id
          for (let i=0; i < this.winelist.nodes[node[0]].nodes[node[1]].nodes.length; i++){
            if (this.winelist.nodes[node[0]].nodes[node[1]].nodes[i]._id == item_id) {
              let tmp_id = this.winelist.nodes[node[0]].nodes[node[1]].nodes[i-1]
              this.winelist.nodes[node[0]].nodes[node[1]].nodes[i-1] = this.winelist.nodes[node[0]].nodes[node[1]].nodes[i]
              this.winelist.nodes[node[0]].nodes[node[1]].nodes[i] = tmp_id
              this.selected_node = [node[0],node[1],i-1]
              break
            }
          }
        } else if (node.length == 4) {
          let item_id = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]]._id
          for (let i=0; i < this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes.length; i++){
            if (this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[i]._id == item_id) {
              let tmp_id = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[i-1]
              this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[i-1] = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[i]
              this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[i] = tmp_id
              this.selected_node = [node[0],node[1],node[2],i-1]
              break
            }
          }
        } else {
          if (this.debug || local_debug) console.log( "WineListShow.upNode: invalid node = ", node)
        }

        // 2. update winelist in DB
        await WineLists.update(this.winelist_id, this.winelist)
        .then(response => {
          if (this.debug || local_debug) console.log( "WineListShow.upNode: Winelist ", this.winelist, " succesfully updated with response ", response);
        })
        .catch(error =>{
          if (this.debug || local_debug) console.log("WineListShow.upNode: ERROR await WineLists.update - ", error);
        })
        // 3. refresh winelist rendering
        this.refreshList++
        if (this.debug || local_debug) console.log ("WineListShow.upNode: this.refreshList=", this.refreshList)
        if (this.debug || local_debug) console.log ("WineListShow.upNode: STOP")
      },

      async downNode(node) {
        let local_debug = false;
        if (this.debug || local_debug) console.log ("")
        if (this.debug || local_debug) console.log ("WineListShow.downNode: START")
        if (this.debug || local_debug) console.log ("WineListShow.downNode: node=",node)

        // 1. swap ids in winelist.nodes
        if (node.length == 0) {
          if (this.debug || local_debug) console.log( "WineListShow.downNode: invalid node = ", node)
        } else if (node.length == 1) {
          let item_id = this.winelist.nodes[node[0]]._id
          for (let i=0; i < this.winelist.nodes.length; i++){
            if (this.winelist.nodes[i]._id == item_id) {
              let tmp_id = this.winelist.nodes[i]
              this.winelist.nodes[i] = this.winelist.nodes[i+1]
              this.winelist.nodes[i+1] = tmp_id
              this.selected_node = [i+1]
              break
            }
          }
        } else if (node.length == 2) {
          let item_id = this.winelist.nodes[node[0]].nodes[node[1]]._id
          for (let i=0; i < this.winelist.nodes[node[0]].nodes.length; i++){
            if (this.winelist.nodes[node[0]].nodes[i]._id == item_id) {
              let tmp_id = this.winelist.nodes[node[0]].nodes[i]
              this.winelist.nodes[node[0]].nodes[i] = this.winelist.nodes[node[0]].nodes[i+1]
              this.winelist.nodes[node[0]].nodes[i+1] = tmp_id
              this.selected_node = [node[0],i+1]
              break
            }
          }
        } else if (node.length == 3) {
          let item_id = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]]._id
          for (let i=0; i < this.winelist.nodes[node[0]].nodes[node[1]].nodes.length; i++){
            if (this.winelist.nodes[node[0]].nodes[node[1]].nodes[i]._id == item_id) {
              let tmp_id = this.winelist.nodes[node[0]].nodes[node[1]].nodes[i]
              this.winelist.nodes[node[0]].nodes[node[1]].nodes[i] = this.winelist.nodes[node[0]].nodes[node[1]].nodes[i+1]
              this.winelist.nodes[node[0]].nodes[node[1]].nodes[i+1] = tmp_id
              this.selected_node = [node[0],node[1],i+1]
              break
            }
          }
        } else if (node.length == 4) {
          let item_id = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[node[3]]._id
          for (let i=0; i < this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes.length; i++){
            if (this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[i]._id == item_id) {
              let tmp_id = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[i]
              this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[i] = this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[i+1]
              this.winelist.nodes[node[0]].nodes[node[1]].nodes[node[2]].nodes[i+1] = tmp_id
              this.selected_node = [node[0],node[1],node[2],i+1]
              break
            }
          }          
        } else {
          if (this.debug || local_debug) console.log( "WineListShow.downNode: invalid node = ", node)
        }

        // 2. update winelist in DB
        await WineLists.update(this.winelist_id, this.winelist)
        .then(response => {
          if (this.debug || local_debug) console.log( "WineListShow.downNode: Winelist ", this.winelist, " succesfully updated with response ", response);
        })
        .catch(error =>{
          if (this.debug || local_debug) console.log("WineListShow.downNode: ERROR await WineLists.update - ", error);
        })
        // 3. refresh winelist rendering
        this.refreshList++
        if (this.debug || local_debug) console.log ("WineListShow.downNode: this.refreshList=", this.refreshList)        
        if (this.debug || local_debug) console.log ("WineListShow.downNode: STOP")
      },

      init_item() {
        this.item={
            name:'',
            bottle_id:0,
            origin:'',
            comment:'',
            isDisclosed:false,
            winelist_ids:[],
          },
        this.item_id = 0;
      },

      init_bottle() {
        this.bottle={
            wine_id:0,
            c_wine_id:0,
            vintage:0,
            winelist_ids:[],
            tasting_note_ids:[]
          },
        this.bottle_id = 0;
      },

      init_wine() {
        this.wine = {
          name:'',
          type:'',
          country_id:'',
          region:'',
          appellation_id:'',
          appellation:'',
          classification_id:'',
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          longevity:'',
          vines:'',
          yields:'',
          vinification:'',
          image_files:[],
          winery_id:''
        };
        this.wine_id = 0;
      },
      
      init_c_wine() {
        this.c_wine={
          name:'',
          type:'',
          country_id:0,
          country:'',
          region_id:0,
          region:'',
          appellation_id:0,
          appellation:'',
          classification_id:0,
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          longevity:'',
          vines:'',
          yields:'',
          vinification:'',
          image_files:[],
        };
        this.c_wine_id = 0;
      },     

      async refresh_All() {
        let local_debug = true
        if (local_debug) console.log("Local WineListShow.refresh_All: before selected_node =", this.selected_node)
        
        let new_node = []
        
        if (this.selected_node.length == 0) {
          console.log("WineListShow.refresh_All: ERROR selected_node.length = 0")
        } else if (this.selected_node.length == 1) {
          if (this.selected_node[0] == 'new') {
            new_node.push(this.winelist.nodes.length-1)
          }
        } else if (this.selected_node.length == 2) {
          if (this.selected_node[1] == 'new') {
            new_node.push(this.selected_node[0],this.winelist.nodes[this.selected_node[0]].nodes.length-1)
          }
        } else if (this.selected_node.length == 3) {
          if (this.selected_node[2] == 'new') {
            new_node.push(this.selected_node[0],this.selected_node[1],this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes.length-1)
          }
        } else if (this.selected_node.length == 4) {
          if (this.selected_node[3] == 'new') {
            new_node.push(this.selected_node[0],this.selected_node[1],this.selected_node[2],this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]].nodes.length-1)
          }
        } else {
          console.log("WineListShow.refresh_All: ERROR selected_node.length > 4")
        }

        this.selected_node = new_node
        await this.selectNode(new_node)

        if (local_debug) console.log("Local WineListShow.refresh_All: after selected_node =", this.selected_node)

        this.activeItem = "item"
        this.refreshList++;
        this.refreshTabs++;
      },

      onBack() {
        let local_debug = false
        if (local_debug) console.log("WinelistsShow.onBack: previousPage = ", this.previousPage)
        if ( this.previousPage == 'WinelistsSearch' ) {
          if (local_debug) console.log("WinelistsShow.onBack: going back to WinelistsSearch.")
          this.$router.push({path:'/WinelistsSearch'})
          this.$store.commit('setPage','WinelistsSearch')
        } else {
          if (local_debug) console.log("WinelistsShow.onBack: going back to ", this.previousPage,"!")
          this.$router.push({path:'/'+this.previousPage})
          this.$store.commit('setPage',this.previousPage)
        }
      },

      async onSaveGroup() {
        let local_debug = false
        if (this.debug) console.log("")
        if (this.debug) console.log("WineListShow.onSaveGroup - START")

        if (this.groupName.length < 3) {
          this.nameValid = false
          this.nameFeedback = "group name shoul at least containg 3 characters"
          if (this.debug) console.log("WineListShow.onSaveGroup - STOP- invalid name")
          return
        } else {
          this.nameValid = true
        }

        if (this.debug) console.log("WineListShow.onSaveGroup: this.selected_node = ",this.selected_node )
        if (this.debug) console.log("WineListShow.onSaveGroup: this.selected_id = ",this.selected_id )
        if (this.debug) console.log("WineListShow.onSaveGroup: this.winelist = ",this.winelist )
        
        if (this.selected_id == 0) {
          if (local_debug) console.log("WineListShow.onSaveGroup: new group - this.selected_node.length=",this.selected_node.length)
          if ( this.selected_node.length == 0) {
            if (this.debug) console.log ("WineListShow.onSaveGroup: node not valid")
          }
          else if ( this.selected_node.length == 1) {
            if (local_debug) console.log("WineListShow.onSaveGroup: level 1")
            if (local_debug) console.log("WineListShow.onSaveGroup: this.selected_node = ",this.selected_node)
            this.groupID = this.generateGroupID()
            if (local_debug) console.log("WineListShow.onSaveGroup: this.winelist.nodes =", this.winelist.nodes)
            if (local_debug) console.log("WineListShow.onSaveGroup:{_id:this.groupID,isLeaf:false,value:{name:this.groupName},parent_id:0,nodes:[],isVisible:true} =", {_id:this.groupID,isLeaf:false,value:{name:this.groupName},parent_id:"0",nodes:[],isVisible:true})
            this.winelist.nodes.push({_id:this.groupID,isLeaf:false,value:{name:this.groupName},parent_id:0,nodes:[],isVisible:true})
            if (local_debug) console.log("WineListShow.onSaveGroup:this.winelist.nodes =", this.winelist.nodes)
            if (local_debug) console.log("WineListShow.onSaveGroup: this.winelist.nodes.length=", this.winelist.nodes.length)
            this.selectNode([this.winelist.nodes.length-1])
          }
          else if ( this.selected_node.length == 2) {
            if (local_debug) console.log("WineListShow.onSaveGroup: level 2")
            if (local_debug) console.log("WineListShow.onSaveGroup: this.selected_node = ",this.selected_node)
            if (local_debug) console.log("WineListShow.onSaveGroup: before this.winelist = ",this.winelist)
            if (local_debug) console.log("WineListShow.onSaveGroup: suggested parent_id is this.winelist.nodes[",this.selected_node[0],"]._id= ",this.winelist.nodes[this.selected_node[0]]._id)
            this.groupID = this.generateGroupID()
            this.winelist.nodes[this.selected_node[0]].nodes.push({_id: this.groupID,isLeaf:false,value:{name:this.groupName},parent_id:this.winelist.nodes[this.selected_node[0]]._id,nodes:[],isVisible:true})
            if (local_debug) console.log("WineListShow.onSaveGroup: added to tree= ",this.winelist)
            if (local_debug) console.log("WineListShow.onSaveGroup: this.winelist.nodes[this.selected_node[0]].nodes.length= ", this.winelist.nodes[this.selected_node[0]].nodes.length)
            if (local_debug) console.log("passing this.selected_node[0] =", this.selected_node[0])
            if (local_debug) console.log("passing this.winelist.nodes[this.selected_node[0]].nodes.length-1 =", this.winelist.nodes[this.selected_node[0]].nodes.length-1)
            this.selectNode([this.selected_node[0],this.winelist.nodes[this.selected_node[0]].nodes.length-1])
          }
          else if ( this.selected_node.length == 3) {
            if (local_debug) console.log("WineListShow.onSaveGroup: level 3")
            if (local_debug) console.log("WineListShow.onSaveGroup: this.selected_node = ",this.selected_node)
            if (local_debug) console.log("WineListShow.onSaveGroup: before this.winelist = ",this.winelist)
            if (local_debug) console.log("WineListShow.onSaveGroup: suggested parent_id is this.winelist.nodes[",this.selected_node[0],",","].nodes[",this.selected_node[1],"]._id= ")
            if (local_debug) console.log("WineListShow.onSaveGroup: suggested parent_id is this.winelist.nodes[",this.selected_node[0],",","].nodes[",this.selected_node[1],"]._id= ",this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]]._id)
            this.groupID = this.generateGroupID()
            this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes.push({_id:this.groupID,isLeaf:false,value:{name:this.groupName},parent_id:this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]]._id,nodes:[],isVisible:true})
            if (local_debug) console.log("WineListShow.onSaveGroup: added to tree= ",this.winelist)
            if (local_debug) console.log("WineListShow.onSaveGroup: this.winelist.nodes[this.selected_node[0]].nodes.length-1 =", this.winelist.nodes[this.selected_node[0]].nodes.length-1)
            if (local_debug) console.log("passing this.selected_node[0] =", this.selected_node[0])
            if (local_debug) console.log("passing this.selected_node[1] =", this.selected_node[1])
            if (local_debug) console.log("passing this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes.length-1 =", this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes.length-1)
            if (local_debug) console.log("this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]]=",this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]])
            this.selectNode([this.selected_node[0],this.selected_node[1],this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes.length-1])
          }
          else if ( this.selected_node.length == 4) {
            if (local_debug) console.log("WineListShow.onSaveGroup: level 4")
            if (local_debug) console.log("WineListShow.onSaveGroup: this.selected_node = ",this.selected_node)
            if (local_debug) console.log("WineListShow.onSaveGroup: before this.winelist = ",this.winelist)
            if (local_debug) console.log("WineListShow.onSaveGroup: suggested parent_id is this.winelist.nodes[",this.selected_node[0].nodes[this.selected_node[1]],"]._id= ",this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]]._id)
            this.groupID = this.generateGroupID()
            this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]].nodes.push({_id:this.groupID,isLeaf:false,value:{name:this.groupName},parent_id:this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]]._id,nodes:[],isVisible:true})
            if (local_debug) console.log("WineListShow.onSaveGroup: added to tree= ",this.winelist)
            if (local_debug) console.log("WineListShow.onSaveGroup: this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]].length =", this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]].length-1)
            this.selectNode([this.selected_node[0],this.selected_node[1],this.selected_node[2],this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]].length])
          } else {
            alert("WineListShow.onSaveGroup: No more than 4 level groups")
          }
        } else {
          if ( this.selected_node.length == 0) {
            if (this.debug) console.log ("WineListShow.onSaveGroup: node not valid")
          }
          else if ( this.selected_node.length == 1) {
            if (this.debug) console.log("WineListShow.onSaveGroup: change name of: ",this.winelist.nodes[this.selected_node[0]])
            this.winelist.nodes[this.selected_node[0]].value.name = this.groupName
            if (this.debug) console.log("WineListShow.onSaveGroup: name changed: ",this.winelist.nodes[this.selected_node[0]])
          }
          else if ( this.selected_node.length == 2) {
            if (this.debug) console.log("WineListShow.onSaveGroup: change name of: ",this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]])
            this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].value.name = this.groupName
            if (this.debug) console.log("WineListShow.onSaveGroup: name changed: ",this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]])
          }
          else if ( this.selected_node.length == 3) {
            if (this.debug) console.log("WineListShow.onSaveGroup: change name of: ",this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]])
            this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]].value.name = this.groupName
          }
          else if ( this.selected_node.length == 4) {
            if (this.debug) console.log("WineListShow.onSaveGroup: change name of: ",this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]].nodes[this.selected_node[3]])
            this.winelist.nodes[this.selected_node[0]].nodes[this.selected_node[1]].nodes[this.selected_node[2]].nodes[this.selected_node[3]].value.name = this.groupName
          } else {
            if (this.debug) console.log ("WineListShow.onSaveGroup: ERROR maximum node depth is 4.")
          }
        }

        await WineLists.update(this.winelist_id,this.winelist)
        .then(response=>{
          if (this.debug) console.log("WineListShow.onSaveGroup: winelist ", this.winelist, " succesfully updated with response ", response);
        })
        .catch(error=>{
          if (this.debug) console.log("WineListShow.onSaveGroup: catch await Winelist.update ", error);
        })  

        // await this.$forceUpdate();
        this.refreshList++
        this.refreshTabs++ 
        if (this.debug) console.log("WineListShow.onSaveGroup - STOP")
      },

      generateGroupID() {
        let return_value = 'groupID_'+ this.countGroup
        this.countGroup++
        return return_value
      },

      calcNumberofBottles() {
        let num = 0;
        if (this.debug) console.log("");
        if (this.debug) console.log("==> WineListShow.calcNumberofBottles: START")
        if (this.debug) console.log("==> WineListShow.calcNumberofBottles: this.winelist = ", this.winelist)
        for (let i = 0 ; i < this.winelist.nodes.length ; i++) {
          if (this.winelist.nodes[i].isLeaf) {
            num++
            if (this.debug) console.log("==> WineListShow.calcNumberofBottles: num = ",num," nodes[",i,"].value.name =", this.winelist.nodes[i].value.name)
          } else {
            for (let j = 0 ; j < this.winelist.nodes[i].nodes.length ; j++) {
              if (this.winelist.nodes[i].nodes[j].isLeaf) {
                num++
                if (this.debug) console.log("==> WineListShow.calcNumberofBottles: num = ",num," nodes[",i,"].nodes[",j,"].value.name =", this.winelist.nodes[i].nodes[j].value.name)
              } else {
                for (let k = 0 ; k < this.winelist.nodes[i].nodes[j].nodes.length ; k++) {
                  if (this.winelist.nodes[i].nodes[j].nodes[k].isLeaf) {
                    num++
                    if (this.debug) console.log("==> WineListShow.calcNumberofBottles: num = ",num," nodes[",i,"].nodes[",j,"].nodes[",k,"].value.name =", this.winelist.nodes[i].nodes[j].nodes[k].value.name)
                  } else {
                    for (let l = 0 ; l < this.winelist.nodes[i].nodes[j].nodes[k].nodes.length ; l++) {
                      if (this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].isLeaf) {
                        num++
                        if (this.debug) console.log("==> WineListShow.calcNumberofBottles: num = ",num," nodes[",i,"].nodes[",j,"].nodes[",k,"].nodes[",l,"].value.name =", this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].value.name)
                      } else {
                        alert("ERROR - FORTH LEVEL")
                      }
                    }
                  }
                }
              }
            }
          }
        }
        this.winelist.number_of_bottles = num
        if (this.debug) console.log("==> WineListShow.calcNumberofBottles: num = ", num)
        if (this.debug) console.log("==> WineListShow.calcNumberofBottles: STOP")
        if (this.debug) console.log("");
      },

      gotoWineListsOverview() {
        this.$store.commit('setPage','WineListsOverview')
        this.$router.push({path:'/WineListsOverview'} );
      },

      async getTastingNotes_fromItemID(item_id) {
        let local_debug = false

        let challenge = {"item_id": item_id}
        await fromItemID(challenge)
        .then(response => {
          if (this.debug || local_debug) console.log("TastingEdit.getTastingNotes_fromItemID: tasting_notes = ", JSON.stringify(response));
        })
        .catch(error => {
          if (this.debug || local_debug) console.log("TastingEdit.getTastingNotes_fromItemID: await fromItemID - ", error);
        })
      },

      doNothing() {
      },      
    }
  }     
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
/* @import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css") */

.w-background {
  background-position: center;
  background-size: cover;
}
.no-background {
  /* background-color:rgb(112,112,112); */
  background-position: center;
  background-size: cover;
  background-image: url("../assets/img/organise.jpg");
}

.my-dropdown /deep/ .dropdown-menu { 
  color:red;
}
.my-dropdown /deep/ .dropdown-menu .dropdown-item  { 
  color:rgb(195,147,130);
  background-color:white;
  font-family: 'Segoe UI';
  font-size: 14px;
}
.my-dropdown /deep/ > button  {
  color:white;
  font-family: 'Segoe UI';
  font-size: 14px;
  padding:0;
  border:none;
}
.my-dropdown /deep/ > button:focus  {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
}

</style> 
import {isEqualAoA} from './AoA.js';

export let wset2 = {
  'level':'wset2',
  'version':'Issue 2, 2023',
  'SAT':[
    {
      'property': 'Appearance',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property': 'Clarity',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['clear','hazy (faulty?)']
        },
        {
          'property': 'Intensity',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['pale','medium','deep']
        },
        {
          'property': 'Colour',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': true,
          'values':[
            {
              'property':'white',
              'clickable': false,
              'oneOption': true,
              'goesDeeper': false,
              'values':['lemon-green','gold','amber','brown'],
            },
            {
              'property':'rosé',
              'clickable': false,
              'oneOption': true,
              'goesDeeper': false,
              'values':['pink','pink orange','orange'],
            },
            {
              'property':'red',
              'clickable': false,
              'oneOption': true,
              'goesDeeper': false,
              'values':['purple','ruby','garnet','tawny','brown'],
            },                            
          ],
        }
      ]
    },
    {
      'property':'Nose',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property':'Condition',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['clean','unclean (faulty?)']
        },
        {
          'property': 'Intensity',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['light','medium','pronounced']
        },
        {
          'property': 'Aroma characteristics',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['open WSET Level 2 wine-lexicon']
        },
      ]
    },
    {
      'property':'Palate',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property': 'Sweetness',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['dry','off-dry','medium','sweet']
        },
        {
          'property': 'Acidity',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['low','medium','high']
        },
        {
          'property': 'Tannins',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['low','medium','high']
        },
        {
          'property': 'Alcohol',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['low','medium','high']
        },
        {
          'property': 'Body',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['light','medium','full']
        },
        {
          'property': 'Flavour intensity',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['light','medium','pronounced']
        },
        {
          'property': 'Flavour charactertistics',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['open WSET Level 2 wine-lexicon']
        },
        {
          'property': 'Finish',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['short','medium','long']
        }, 
      ]
    },
    {
      'property':'Conclusions',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property':'Quality level',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['faulty','poor','acceptable','good','very good','outstanding']
        }
      ]
    }
  ],
  'wine_lexicon':[
    {
      'property':'Primary',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property':'Floral',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['blossom','rose','violet']
        },
        {
          'property':'Green fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['apple','pear','gooseberry','grape']                        
        },
        {
          'property':'Citrus fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['grapefruit','lemon','lime','orange']   
        },
        {
          'property':'Stone fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['peach','apricot','nectarine']   
        },
        {
          'property':'Tropical fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['banana','lychee','mango','melon','passion fruit','pineapple']   
        },
        {
          'property':'Red fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['redcurrant','cranberry','raspberry','strawberry','red cherry','red plum']   
        },
        {
          'property':'Black fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['blackcurrant','blackberry','blueberry','black cherry','black plum']   
        },
        {
          'property':'Herbaceous',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['green bell pepper (capsicum)','grass','tomato leaf','asparagus']   
        },
        {
          'property':'Herbal',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['eucalyptus','mint','fennel','dill','dried herbs']   
        },
        {
          'property':'Spice',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['black/white pepper','liquorice']   
        },
        {
          'property':'Fruit ripeness',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['unripe fruit','ripe fruit','dried fruit','cooked fruit']   
        },
        {
          'property':'Other',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['wet stones','candy']   
        },                            
      ],
    },
    {
      'property':'Secondary',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property':'Yeast',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['biscuit','pastry','bread','toasted bread','bread dough','cheese','yogurt']
        },
        {
          'property':'Malolactic conversion',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['butter','cheese','cream']
        },
        {
          'property':'Oak',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['vanilla','cloves','coconut','cedar','charred wood','smoke','chocolate','coffee']
        },
      ],
    },
    {
      'property':'Tertiary',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property':'Red wine',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['dried fruit','leather','earth','mushroom','meat','tobacco','wet leaves','forest floor','caramel']
        },
        {
          'property':'White wine',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['dried fruit','orange marmalade','petrol (gasoline)','cinnamon','ginger','nutmeg','almond','hazelnut','honey','caramel']
        },
        {
          'property':'Deliberate oxidation',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['almond','hazelnut','walnut','chocolate','coffee','caramel']
        },
      ],
    }
  ]
}

export function wset2_lookup(index) {
  let local_debug = false
  let return_value = ""

  if (local_debug) console.log("")
  if (local_debug) console.log("wset2_lookup: index = ", index)
  if (local_debug) console.log("wset2_lookup: index.length = ", index.length)

    if (!isInWSET2(index)) {
      return "index is NOT in wset2"
    }
    
    if (index.length == 1) {
        return_value = wset2.SAT[index[0]].property
    } else if (index.length == 2) {
        if (wset2.SAT[index[0]].goesDeeper) {
          return_value = wset2.SAT[index[0]].values[index[1]].property
        } else {
          return_value = wset2.SAT[index[0]].values[index[1]]
        }
    } else if (index.length == 3) {
      if (wset2.SAT[index[0]].values[index[1]].goesDeeper == true) {
        return_value = wset2.SAT[index[0]].values[index[1]].values[index[2]].property
      } else {
        return_value = wset2.SAT[index[0]].values[index[1]].values[index[2]]
      }
    } else if (index.length == 4) {
      if (wset2.SAT[index[0]].values[index[1]].values[index[2]].goesDeeper == true) {
        return_value = wset2.SAT[index[0]].values[index[1]].values[index[2]].values[index[3]].property
      } else {
        return_value = wset2.SAT[index[0]].values[index[1]].values[index[2]].values[index[3]]
      }
    } else if (index.length == 5) {
      if (isEqualAoA([index[0],index[1]],[1,2]) || isEqualAoA([index[0],index[1]],[2,6])){
        if (wset2.wine_lexicon[index[2]].values[index[3]].goesDeeper == true) {
          return_value = wset2.wine_lexicon[index[2]].values[index[3]].values[index[4]].property
        } else {
          return_value = wset2.wine_lexicon[index[2]].values[index[3]].values[index[4]]
        }
      }
    } else {
      return_value = "index out of reach"
    }

  if (local_debug) console.log("wset2_lookup: return_value = ", return_value)

  return return_value
}

function isInWSET2(index) {
  let local_debug = false
  let return_value
  if (local_debug) console.log("isInWSET2: index = ",index)
  if ( index.length == 1 ) {
    return_value =  index[0] < wset2.SAT.length
  } else if ( index.length == 2 ) {
    return_value =  (index[0] < wset2.SAT.length) && 
                    (typeof wset2.SAT[index[0]].values != "undefined") &&
                    (index[1] < wset2.SAT[index[0]].values.length)
  } else if ( index.length == 3 ) {
    return_value =  (index[0] < wset2.SAT.length) && 
                    (typeof wset2.SAT[index[0]].values != "undefined") &&
                    (index[1] < wset2.SAT[index[0]].values.length) &&
                    (typeof wset2.SAT[index[0]].values[index[1]].values != "undefined") &&
                    (index[2] < wset2.SAT[index[0]].values[index[1]].values.length)
  } else if ( index.length == 4 ) {
    return_value =  (index[0] < wset2.SAT.length) && 
                    (typeof wset2.SAT[index[0]].values != "undefined") &&
                    (index[1] < wset2.SAT[index[0]].values.length) &&
                    (typeof wset2.SAT[index[0]].values[index[1]].values != "undefined") &&
                    (index[2] < wset2.SAT[index[0]].values[index[1]].values.length) &&
                    (typeof wset2.SAT[index[0]].values[index[1]].values[index[2]].values != "undefined") &&
                    (index[3] < wset2.SAT[index[0]].values[index[1]].values[index[2]].values.length)
  } else if ( index.length == 5 ) {
    return_value =  (index[0] < wset2.SAT.length) && 
                    (typeof wset2.SAT[index[0]].values != "undefined") &&
                    (index[1] < wset2.SAT[index[0]].values.length) &&
                    (typeof wset2.SAT[index[0]].values[index[1]].values != "undefined") &&
                    (index[2] < wset2.wine_lexicon.length) &&
                    (typeof wset2.wine_lexicon[index[2]].values != "undefined") &&
                    (index[3] < wset2.wine_lexicon[index[2]].values.length) &&
                    (typeof wset2.wine_lexicon[index[2]].values[index[3]].values != "undefined") &&
                    (index[4] < wset2.wine_lexicon[index[2]].values[index[3]].values.length)           
  } else {
    return_value = false
  }
  if (local_debug) console.log("isInWSET2: return_value = ",return_value)
  return return_value
}
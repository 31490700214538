import {isEqualAoA} from './AoA.js';

export let wset3 = {
  'level':'wset3',
  'version':'Issue 2, 2022',
  'SAT':[
    {
      'property': 'Appearance',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property': 'Clarity',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['clear','hazy (faulty?)']
        },
        {
          'property': 'Intensity',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['pale','medium','deep']
        },
        {
          'property': 'Colour',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': true,
          'values':[
            {
              'property':'white',
              'clickable': false,
              'oneOption': true,
              'goesDeeper': false,
              'values':['lemon-green','lemon','gold','amber','brown'],
            },
            {
              'property':'rosé',
              'clickable': false,
              'oneOption': true,
              'goesDeeper': false,
              'values':['pink','salmon','orange'],
            },
            {
              'property':'red',
              'clickable': false,
              'oneOption': true,
              'goesDeeper': false,
              'values':['purple','ruby','garnet','tawny','brown'],
            },                            
          ],
        }
      ]
    },
    {
      'property':'Nose',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property':'Condition',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['clean','unclean (faulty?)']
        },
        {
          'property': 'Intensity',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['light','medium(-)','medium','medium(+)','pronounced']
        },
        {
          'property': 'Aroma characteristics',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['open WSET Level 3 wine-lexicon']
        },
        {
          'property': 'Development',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['youthful','developing','fully developed','tired/past its best']
        },
      ]
    },
    {
      'property':'Palate',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property': 'Sweetness',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['dry','off-dry','medium-dry','medium-sweet','sweet','luscious']
        },
        {
          'property': 'Acidity',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['low','medium(-)','medium','medium(+)','high']
        },
        {
          'property': 'Tannins',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['low','medium(-)','medium','medium(+)','high']
        },
        {
          'property': 'Alcohol',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['low','medium','high']
        },
        {
          'property': 'Body',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['low','medium(-)','medium','medium(+)','full']
        },
        {
          'property': 'Mousse',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['delicate','creamy','aggressive']
        },
        {
          'property': 'Flavour intensity',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['light','medium(-)','medium','medium(+)','pronounced']
        },
        {
          'property': 'Flavour charactertistics',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['open WSET Level 3 wine-lexicon']
        },
        {
          'property': 'Finish',
          'clickable': false,
          'oneOption': true,
          'goesDeeper': false,
          'values':['short','medium(-)','medium','medium(+)','long']
        }, 
      ]
    },
    {
      'property':'Conclusions',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property':'Quality level',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['faulty','poor','acceptable','good','very good','outstanding']
        },
        {
          'property':'Level of readiness for drinking/potential for ageing',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['too young','can drink now but has potential for ageing','drink now: not suitable for ageing or further ageing ','too old']
        }
      ]
    },
  ],
  'wine_lexicon':[
    {
      'property':'Primary',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property':'Floral',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['acacia','honeysuckle','chamomile','elderflower','geranium','blossom','rose','violet']
        },
        {
          'property':'Green fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['apple','gooseberry','pear','pear drop','quince','grape']                        
        },
        {
          'property':'Citrus fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['grapefruit','lemon','lime juice','lime zest','orange peel', 'lemon peel']   
        },
        {
          'property':'Stone fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['peach','apricot','nectarine']   
        },
        {
          'property':'Tropical fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['banana','lychee','mango','melon','passion fruit','pineapple']   
        },
        {
          'property':'Red fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['redcurrant','cranberry','raspberry','strawberry','red cherry','red plum']   
        },
        {
          'property':'Black fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['blackcurrant','blackberry','bramble','blueberry','black cherry','black plum']   
        },
        {
          'property':'Dried/cooked fruit',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['fig','prune','raisin','sultana','kirsch','jamminess','baked/stewed fruits','preserved fruits']   
        },
        {
          'property':'Herbaceous',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['green bell pepper (capsicum)','grass','tomato leaf','asparagus','blackcurrant leaf']   
        },
        {
          'property':'Herbal',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['eucalyptus','mint','medicinal','lavender','fennel','dill']   
        },
        {
          'property':'Pungent spice',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['black/white pepper','liquorice']   
        },
        {
          'property':'Other',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['flint','wet stones','wet wool']   
        },                            
      ],
    },
    {
      'property':'Secondary',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property':'Yeast (lees, autolysis)',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['biscuit','bread','toast','pastry','brioche','bread dough','cheese']
        },
        {
          'property':'Malolactic conversion',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['butter','cheese','cream']
        },
        {
          'property':'Oak',
          'clickable': true,
          'oneOption': false,
          'goesDeeper': false,
          'values':['vanilla','cloves','nutmeg','coconut','butterscotch','toast','cedar','charred wood','smoke','chocolate','coffee','resinous']
        },
      ],
    },
    {
      'property':'Tertiary',
      'clickable': false,
      'oneOption': false,
      'goesDeeper': true,
      'values':[
        {
          'property':'Deliberate oxidation',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['almond','marzipan','hazelnut','walnut','chocolate','coffee','caramel']
        },
        {
          'property':'Fruit development (white)',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['dried apricot','marmalade','dried apple','dried banana']
        },
        {
          'property':'Fruit development (red)',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['fig','prune','tar','dried blackberry','dried cranberry', 'cooked blackberry','cooked red plum']
        },
        {
          'property':'Bottle age (white)',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['petrol','kerosene','cinnamon','ginger','nutmeg', 'toast','nutty','mushroom','hay','honey']
        },
        {
          'property':'Bottle age (red)',
          'clickable': false,
          'oneOption': false,
          'goesDeeper': false,
          'values':['leather','forest floor','earth','mushroom','game', 'tobacco','vegetal','wet leaves','savoury','meaty','farmyard']
        },
      ],
    }
  ]
}

export function wset3_lookup(index_array) {
  let local_debug = false
  let return_value = ""

  if (local_debug) console.log("")
  if (local_debug) console.log("wset3_lookup: index_array = ", index_array)
  if (local_debug) console.log("wset3_lookup: index_array.length = ", index_array.length)

    if (!isInWSET3(index_array)) {
      return "index_array is NOT in wset3"
    }
    
    if (index_array.length == 1) {
        return_value = wset3.SAT[index_array[0]].property
    } else if (index_array.length == 2) {
        if (wset3.SAT[index_array[0]].goesDeeper) {
          return_value = wset3.SAT[index_array[0]].values[index_array[1]].property
        } else {
          return_value = wset3.SAT[index_array[0]].values[index_array[1]]
        }
    } else if (index_array.length == 3) {
      if (wset3.SAT[index_array[0]].values[index_array[1]].goesDeeper == true) {
        return_value = wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].property
      } else {
        return_value = wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]]
      }
    } else if (index_array.length == 4) {
      if (wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].goesDeeper == true) {
        return_value = wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]].property
      } else {
        return_value = wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values[index_array[3]]
      }
    } else if (index_array.length == 5) {
      if (isEqualAoA([index_array[0],index_array[1]],[1,2]) || isEqualAoA([index_array[0],index_array[1]],[2,7])){
        if (wset3.wine_lexicon[index_array[2]].values[index_array[3]].goesDeeper == true) {
          return_value = wset3.wine_lexicon[index_array[2]].values[index_array[3]].values[index_array[4]].property
        } else {
          return_value = wset3.wine_lexicon[index_array[2]].values[index_array[3]].values[index_array[4]]
        }
      }
    } else {
      return_value = "index_array out of reach"
    }

  if (local_debug) console.log("wset3_lookup: return_value = ", return_value)

  return return_value
}

function isInWSET3(index_array) {
  let local_debug = false
  let return_value
  if (local_debug) console.log("isInWSET3: index_array = ",index_array)
  if ( index_array.length == 1 ) {
    return_value =  index_array[0] < wset3.SAT.length
  } else if ( index_array.length == 2 ) {
    return_value =  (index_array[0] < wset3.SAT.length) && 
                    (typeof wset3.SAT[index_array[0]].values != "undefined") &&
                    (index_array[1] < wset3.SAT[index_array[0]].values.length)
  } else if ( index_array.length == 3 ) {
    return_value =  (index_array[0] < wset3.SAT.length) && 
                    (typeof wset3.SAT[index_array[0]].values != "undefined") &&
                    (index_array[1] < wset3.SAT[index_array[0]].values.length) &&
                    (typeof wset3.SAT[index_array[0]].values[index_array[1]].values != "undefined") &&
                    (index_array[2] < wset3.SAT[index_array[0]].values[index_array[1]].values.length)
  } else if ( index_array.length == 4 ) {
    return_value =  (index_array[0] < wset3.SAT.length) && 
                    (typeof wset3.SAT[index_array[0]].values != "undefined") &&
                    (index_array[1] < wset3.SAT[index_array[0]].values.length) &&
                    (typeof wset3.SAT[index_array[0]].values[index_array[1]].values != "undefined") &&
                    (index_array[2] < wset3.SAT[index_array[0]].values[index_array[1]].values.length) &&
                    (typeof wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values != "undefined") &&
                    (index_array[3] < wset3.SAT[index_array[0]].values[index_array[1]].values[index_array[2]].values.length)
  } else if ( index_array.length == 5 ) {
    return_value =  (index_array[0] < wset3.SAT.length) && 
                    (typeof wset3.SAT[index_array[0]].values != "undefined") &&
                    (index_array[1] < wset3.SAT[index_array[0]].values.length) &&
                    (typeof wset3.SAT[index_array[0]].values[index_array[1]].values != "undefined") &&
                    (index_array[2] < wset3.wine_lexicon.length) &&
                    (typeof wset3.wine_lexicon[index_array[2]].values != "undefined") &&
                    (index_array[3] < wset3.wine_lexicon[index_array[2]].values.length) &&
                    (typeof wset3.wine_lexicon[index_array[2]].values[index_array[3]].values != "undefined") &&
                    (index_array[4] < wset3.wine_lexicon[index_array[2]].values[index_array[3]].values.length)           
  } else {
    return_value = false
  }
  if (local_debug) console.log("isInWSET3: return_value = ",return_value)
  return return_value
}
<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0">
            <div class="menu-top" style="text-align:center">
              <p style="margin-bottom:0"><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
              <p style="position: relative; top: -5px;font-size: 10px; color: rgb(109,122,82);margin-bottom:0">organise</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="10" style="padding:0">          
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">organise > winelists > overview</p>
             <b-row class="justify-content-center">
              <p class="t-title" style="text-align:center;margin-left:20px;margin-right:20px;">overview of all your tasting events you organised.</p>
            </b-row>
            <b-row class="justify-content-center">
              <p class="t-subtitle">to select one, click - to add one, click on the plus sign</p>
            </b-row>     
            <b-row class="justify-content-center" style="margin:0">
              <b-col 
                  class="w-tile w-cursos-click m-2 d-inline-flex justify-content-center"
                  xs="12" sm="6" md="4" lg="2" xl="2"
                  v-on:click="onEdit()"
                  style ="display:block; margin:20px !important"
              >
                <b-img
                  width=300px
                  height=200px
                  :src="require('@/assets/img/plus.png')"
                >
                </b-img>
              </b-col>
              
              <b-col
                v-for="(winelist, index) in winelists"
                v-bind:item="winelist"
                v-bind:index="index"
                v-bind:key="winelist._id"
                class="w-tile w-cursos-click m-2 d-inline-flex justify-content-center"
                xs="12" sm="6" md="4" lg="2" xl="2"
                style ="display:block; margin:20px !important"
              >  
                <b-row  
                  style="width:100%"
                  v-on:click="onSelect(winelist._id)"
                >
                  <b-col class="justify-content-center" style="height:100%;width:auto;padding:0;position:sticky">
                    <div v-if="winelist.image_files[0]" 
                      class="c-image-part" 
                      v-bind:style="{ 'background-image': 'url(' + winelist.image_files[0].dataURL +')' }"
                    >
                    </div>
                    <div v-else
                      class="c-image-part"
                    />
                    <div class="c-text-part">
                      <p class="c-title">{{winelist.name}}<span v-if="winelist.organiser" style="font-weight:normal;font-size: 11px;"> (by {{ winelist.organiser }})</span></p>
                      <p class="c-subtitle">{{winelist.nice_date}}<span v-if="winelist.description"> - {{winelist.description}}</span></p>
                      <p class="c-bottles">{{winelist.number_of_bottles}}</p>
                    </div>
                    <b-button pill
                      class="edit-button"
                      v-on:click.stop="onEdit(winelist._id)"
                      v-b-tooltip.hover title="edit winelist"
                    >
                      <font-awesome-icon class="edit-symbol" icon="edit" />
                    </b-button>
                    <b-button pill
                      v-if="winelist.number_of_bottles == 0"
                      class="delete-button"
                      v-on:click.stop="onDelete(index)"
                      v-b-tooltip.hover title="delete winelist"
                    >
                      <font-awesome-icon class="delete-symbol" icon="trash-alt" />
                    </b-button>
                  </b-col>
                </b-row> 
              </b-col>
            </b-row>         
          </b-col>
        </b-row>
        <b-row>
          <b-col style="text-align:center;padding:0 15px 0 15px">
            <b-button v-on:click="onBack()" class="w-button-pink-full">back</b-button>
          </b-col>
        </b-row>        
        <br>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p style="margin-bottom:0"><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
              <p style="position: relative; top: -5px;font-size: 10px; color: rgb(109,122,82);margin-bottom:0">organise</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr style="position: relative; top: -10px">
            <Menu/>
          </template>
        </b-sidebar>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>

    <b-modal id="deleteWineList" ref="deleteWineList" title="delete winelist" @ok="deleteWineList"> 
      <b-row v-if="current_index >=0">
        <div style="width:300px;margin-left:auto;margin-right:auto">
        <b-col
          class="w-tile m-2 d-inline-flex justify-content-center"
          style ="margin:20px 0 20px 0!important"
        >
            <div v-if="winelists[current_index].image_files[0]" 
              class="c-image-part" 
              v-bind:style="{'background-image': 'url(' + winelists[current_index].image_files[0].dataURL +')' }"
            >
            </div>
            <div v-else
              class="c-image-part"
            />           
            <div class="c-text-part">
              <p class="c-title" v-if="winelists[current_index].nice_date">{{winelists[current_index].nice_date}} - {{winelists[current_index].name}}</p>
              <p class="c-title" v-else>{{winelists[current_index].name}}</p>
              <p class="c-subtitle" v-if="winelists[current_index].description">{{winelists[current_index].description}}</p>
              <p class="c-bottles">{{winelists[current_index].number_of_bottles}}</p>
            </div>
        </b-col>
        </div>
      </b-row>
      <p class="t-title text-center">ARE YOU SHURE YOU WANT TO DELETE THIS WINELIST?</p>
      
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="keepWineList" ref="keepWineList" title="cannot delete winelist"> 
      <b-row v-if="current_index >=0">
        <div style="width:300px;margin-left:auto;margin-right:auto">
        <b-col
          class="w-tile m-2 d-inline-flex justify-content-center"
          style ="margin:20px 0 20px 0!important"
        >
            <div v-if="winelists[current_index].image_files[0]" 
              class="c-image-part" 
              v-bind:style="{'background-image': 'url(' + winelists[current_index].image_files[0].dataURL +')' }"
            >
            </div>
            <div v-else
              class="c-image-part"
            />
            <div class="c-text-part">
              <p class="c-title">{{winelists[current_index].name}}</p>
              <p class="c-subtitle">{{winelists[current_index].nice_date}}<span v-if="winelists[current_index].description"> - {{winelists[current_index].description}}</span></p>
              <p class="c-bottles">{{winelists[current_index].number_of_bottles}}</p>
            </div>           
        </b-col>
        </div>
         <p class="t-title text-center" style="width:100%">THIS WINELIST IS LINKED TO {{winelists[current_index].tasting_ids.length}} TASTINGS.</p>
      </b-row>
      
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import formatDate from '../assets/js/formatDate.js';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuMobile_Organise.vue';

  import WineLists from '../WineLists.js'
  import Users from '../Users.js'

  export default {
    name: "Organise",
    components: {
      NoAccess,
      Menu,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      }
    },
    data() {
      return {
        debug: false,
        isLoading : true,

        isValid : true,
        inEdit: false,

        user:{},
        user_id:0,

        winelists:[],

        current_index:-1,
      }
    }, 
    async mounted() {
      if (this.debug) console.log("WinelistsOverview.mounted - START");

      this.user_id = this.currentUserID;
      this.user = this.currentUser;

      if (typeof this.user.winelist_ids === "undefined" || this.user.winelist_ids === null) {
        this.user.winelist_ids = [];
      }

      if (this.user.winelist_ids.length !== 0) {
        for (let i=0; i < this.user.winelist_ids.length; i++) {
          await WineLists.getone(this.user.winelist_ids[i])
          .then(response=> {
            if (typeof response !== "undefined" && response !== "" && response !== null) {
              response.nice_date = formatDate(new Date(response.date))
              this.winelists.push(response);
              if (this.debug) console.log("WinelistsOverview.mounted: WineList ", this.winelists[i]," succesfully fetched from DB");
            } else {
              if (this.debug) console.log("WinelistsOverview.mounted: WineList ", this.user.organised_winelist_ids[i], " not found in DB");
            }
          })
          .catch(error=> {
            if (this.debug) console.log("WinelistsOverview.mounted: await WineList i=", i , " ", error);
          })
        }
        this.winelists.sort((a,b)=>(a.date>b.date)?-1:1);
      }

      this.$store.commit('setWinelistID',0)
      this.$store.commit('setWinelist',{})
      this.$store.commit('setTastingID',0)
      this.$store.commit('setTasting',{})
      this.$store.commit('setTastingnoteID',0)
      this.$store.commit('setTastingnote',{})
      this.$store.commit('setItemID',0)
      this.$store.commit('setItem',{})
      this.$store.commit('setBottleID',0)
      this.$store.commit('setBottle',{})
      this.$store.commit('setCWineID',0)
      this.$store.commit('setCWine',{})
      this.$store.commit('setWineID',0)
      this.$store.commit('setWine',{})

      this.isLoading = false;
      if (this.debug) console.log("WinelistsOverview.mounted - STOP");
    },
    methods: {
      onSelect(winelist_id){
        if ( ! this.inEdit ) {
          this.$store.commit('setPage','WineListShow')
          this.$router.push({path:'/WineListShow', query: {winelist_id:winelist_id }});
        }
      },
      onEdit(winelist_id){
        this.inEdit = true;
        this.$store.commit('setPage','WineListEdit')
        if (typeof winelist_id === "undefined") {
          this.$router.push({path:'/WineListEdit'});
        } else {
          this.$router.push({path:'/WineListEdit', query: {winelist_id:winelist_id }});
        }
      },
      
      async onDelete(i){
        this.current_index = i
        if (this.debug) console.log("WinelistsOverview.onDelete: delete pressed")
        console.log("TMP: this.winelists[i].tasting_ids.length = ", this.winelists[i].tasting_ids.length)
        if (this.winelists[i].tasting_ids.length == 0) {
          await this.$bvModal.show('deleteWineList')
        } else {
          await this.$bvModal.show('keepWineList')
        }
      },

      async deleteWineList(){
        let local_debug = false;
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("WinelistsOverview.deleteWineList: START: this.current_index = ", this.current_index)
        let this_winelist_id = this.winelists[this.current_index]._id
        if (this.debug || local_debug) console.log("WinelistsOverview.deleteWineList: about to delete winelist", this.winelists[this.current_index].name)

        await WineLists.delete(this_winelist_id)
        .then(response => {
          if (this.debug || local_debug) console.log("WinelistsOverview.deleteWineList: winelist with id ", this_winelist_id, " deleted with response : ", response)
        })
        .catch(error => {
          if (this.debug || local_debug) console.log('WinelistsOverview.deleteWineList: catch await WineLists.delete ERROR : ' + error);
        })

        for ( let i=0 ; i < this.user.winelist_ids.length ; i++) {
          if ( this.user.winelist_ids[i] == this_winelist_id ) {
            this.user.winelist_ids.splice(i,1)
          }
        }
        await Users.update(this.user_id,this.user)
        .then(response => {
          if (this.debug || local_debug) console.log("WinelistsOverview.deleteWineList: user with id ", this.user_id, " updated with response : ", response)
        })
        .catch(error => {
          if (this.debug || local_debug) console.log('WinelistsOverview.deleteWineList: catch await Users.update ERROR : ' + error);
        })

        this.winelists.splice(this.current_index,1);
        this.winelists.sort((a,b)=>(a.date>b.date)?-1:1);
        this.current_index = -1;
        if (this.debug || local_debug) console.log("WinelistsOverview.deleteWineList: STOP")
      },

      onBack() {
        this.$router.push({path:'/Home', query: {user_id:this.$route.query.user_id}});
      },

      doNothing() {
      },
    }
  }     
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.c-image-part {
  background-image: url("../assets/img/organise.jpg")
}
</style>
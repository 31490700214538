<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <WineListShowMobile  v-if="matches" />
    <WineListShowDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WineListShowMobile from "./WineListShow_Mobile.vue";
  import WineListShowDesktop from "./WineListShow_Desktop.vue";
 
  export default {
    name: 'WineListShow',
    components: {
      MatchMedia,
      WineListShowMobile,
      WineListShowDesktop,
    },
  }
</script> 
<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <div class="main" v-if="this.inSession">
        <b-row style="height:30vh;margin:0" class="welcome-picture">
          <b-col cols="1" sm="2">
          </b-col>
          <b-col cols="10" sm="8" style="height:100%">
            <div class="welcome-overlay">
              <p class="main-subtitle" style="color:white">WELCOME TO</p>
              <h1 class="main-title"><span style="color: rgb(109,122,82)">WYNE</span>TASTER</h1>
            </div> 
          </b-col>
        </b-row>
        <br>
        <br>
        <br>
        <div class="option w-cursor-click" @click="gotoOrganise">
          <div class="option-description">
            <p class="option-title">Organise</p>
          </div>
          <div class="option-picture">
            <b-avatar :src="require('@/assets/img/organise.jpg')" size="100px"></b-avatar>
          </div>
        </div>
        <div class="option w-cursor-click" @click="gotoParticipate">
          <div class="option-description">
            <p class="option-title">Participate</p>
          </div>
          <div class="option-picture">
            <b-avatar :src="require('@/assets/img/tastings.jpg')" size="100px"></b-avatar>
          </div>
        </div>
      </div>    
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue'

  export default {
    name: "Home",
    components: {
      NoAccess,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
        // return true
      },
      currentUser() {
        return this.$store.state.user
      },
      currentProfilePicture() {
        return this.$store.state.profile_picture
      },
      currentUserID() {
        return this.$store.state.user_id
      }
    },
    data() {
      return {
        debug: false,
        isLoading : true,
      }
    }, 
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("Home.mounted: START")
      // this.user_id = this.currentUserID;
      // this.user = this.currentUser;
      // this.profile_picture = await cc64(this.user.image_files[0].dataURL,3,2);
      this.isLoading = false
      if (this.debug) console.log("Home.mounted: STOP")
    },
    methods: {
      gotoOrganise() {
        this.$router.push({path:'/WineListsOverview', query: {user_id:this.$route.query.user_id}})
        this.$store.commit('setPage','WineListsOverview')
      },
      gotoParticipate() {
        this.$router.push({path:'/TastingsOverview', query: {user_id:this.$route.query.user_id}})
        this.$store.commit('setPage','TastingsOverview')
      },
      gotoWishlist() {
        this.$router.push({path:'/WishList', query: {user_id:this.$route.query.user_id,cellar_id:this.user.cellar_id }})
        this.$store.commit('setPage','WishList')
      },
      gotoProfile() {
        this.$router.push({path:'/Profile', query: {user_id:this.$route.query.user_id,cellar_id:this.user.cellar_id  }})
        this.$store.commit('setPage','Profiles')
      }
    }
  }
</script>
<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.main {
  overflow-x: hidden;
  overflow-y: hidden;
}

.option {
  display:flex;
  align-items: center;
  justify-content: center;
  margin:20px;
}


.option-picture {
  order:1;
  z-index: 1;
  height:100px;
  width:100px;
  box-shadow: 0 10px 10px -5px;
  border-radius:100%;
}
.option-description{
  order:2;
  height:90px;
  border-style:solid;
  border-width:1px;
  border-color:grey;
  position: relative;
  left:-30px;
  width: 250px;
  border-radius: 0 25px 25px 0;
  display:flex;
  align-items: center;
  padding-left:50px;
  box-shadow: 0 10px 10px -5px;
}
.option-description:hover {
  border-color: rgb(109,122,82) !important;
  border-width: 2px !important;
  background-color: White!important;
  box-shadow: 0 10px 10px -5px rgb(109 122 82 / 50%) !important;
}

.option-title {
  color:rgb(112,112,112);
  font-family: 'Segoe UI';
  font-size: 24px;
  font-weight: bold;
  position:relative;
  top: 7px;
}

.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/wynetaster.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}
</style>
<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0">
            <div class="menu-top" style="text-align:center">
            <p><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
            </div>    
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr> 
          </b-col>   
        </b-row>
        <b-row>
          <b-col style="padding:0">        
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">
              <span v-on:click="gotoTastingsOverview()" class="w-cursor-click" ><u> tastings </u></span> > 
              search
            </p>
            <br><br>
            <h1 class="main-title"><b><span style="color:rgb(109,122,82)">WYNE</span></b><span style="color: black">TASTER</span></h1>
            <br>
            <div style="margin:0 15px 0 15px">
              <b-form-group>
                <b-form-input
                  id="input-search"
                  class = "w-input"
                  style = "background-color:white !important; padding-left: 40px"
                  v-model="search_string"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.search_stringValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  v-on:keydown.enter.prevent="onSearch">
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{search_stringFeedback}}
                </b-form-invalid-feedback>                
              </b-form-group>
              <div v-if="search_stringFeedback==''" style="width:20px; position: relative; float:left; top: -42px; left: 10px; margin: 0; padding:0">
                <font-awesome-icon 
                  style="font-size:17px;color: rgb(109,122,82);" 
                  icon="search" />
              </div>
              <div v-else style="width:20px; position: relative; top: -65px; margin: 0; left: -47%;">
                <font-awesome-icon 
                  style="font-size:17px;color: rgb(109,122,82);" 
                  icon="search" />
              </div>
              <div style="position:relative;top:-10px">
                <b-button v-if="search_string.length >0" v-on:click="onClear()" class="w-button-pink-full">clear</b-button>
                <b-button v-else v-on:click="onBack()" class="w-button-pink-full">back</b-button>
              </div>
              <div v-if="ready4Search" style="position:relative;top:-10px; margin-top:10px">
                <b-button v-on:click="onSearch()" class="w-button-green-full">search</b-button>
              </div>
              <div v-else style="position:relative;top:-10px; margin-top:10px">
                <b-button class="w-button-green-full">
                  searching 
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </b-button>
              </div>
            </div>                  
            <!-- </b-form> -->

            <hr v-if="showResultZone" style="margin-top:10px">
            <!-- <b-row v-if="showResultZone" style="width:90%">
              <b-col cols="4" style="padding:0">
                <div style="display:flex; float:left">
                  <font-awesome-icon 
                  style="order:1;font-size:17px;color: rgb(109,122,82);" 
                  icon="search" />
                  <p style="order:2;margin-left:5px; position:relative; top:-5px">
                    all
                  </p>
                </div>
              </b-col>
              <b-col cols="4" style="padding:0">
                <div style="display:flex; position:fixed; left:52%">
                  <font-awesome-icon 
                  style="order:1;font-size:17px;color: rgb(109,122,82);" 
                  icon="book" />
                  <p style="order:2;margin-left:5px; position:relative; top:-5px">
                    tastings
                  </p>
                </div>
              </b-col>
              <b-col cols="4" style="padding:0">
                <div style="display:flex;float:right">
                  <font-awesome-icon 
                  style="order:1;font-size:17px;color: rgb(109,122,82);" 
                  icon="wine-bottle" />
                  <p style="order:2;margin-left:5px; position:relative; top:-5px">
                    notes
                  </p>
                </div>
              </b-col>
            </b-row> -->
            
            <b-row
              v-for="(result, index) in results"
              v-bind:item="result"
              v-bind:index="index"
              v-bind:key="result._id" 
            >
              <b-col 
                v-on:click="onSelect(result)"  
              >
                <b-row style="margin-left:15px; margin-right:15px">
                  <b-col cols="1">
                    <b-img left
                      v-if="result.image_files[0]"
                      class="fluid-grow"
                      max-width="20"
                      height="60"
                      v-bind:src="result.image_files[0].dataURL"
                    ></b-img>
                  </b-col>
                  <b-col>
                    <p class="t-title" style="text-align: left; margin-top:0">{{result.name}} <span v-if="result.type"> - {{result.type}} </span>{{result.vintage}}</p>
                    <div
                      v-for="(hit, index) in result.hits"
                      v-bind:item="hit"
                      v-bind:index="index"
                      v-bind:key="hit.center"
                    >
                      <p v-if="(hit.success == true)" class="t-text" style="text-align: left;">hit: {{hit.left}}<span><b>{{hit.center}}</b></span>{{hit.right}}</p>
                      <p v-else class="t-text" style="text-align: left;">missing: <s>{{hit.center}}</s></p>
                      <!-- <p class="t-text" style="text-align: left;">number_of_hits: {{result.number_of_hits}}</p> -->
                    </div>
                  </b-col>
                </b-row>
                <hr>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <Menu/>
          </template>
        </b-sidebar>   
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import cleanForSearch from '../assets/js/cleanForSearch.js';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuMobile.vue';

  import Tastings from '../Tastings.js'
  import TastingNotes from '../TastingNotes.js'

  import Entries from '../Entries.js'
  import Items from '../Items.js'
  import Bottles from '../Bottles.js'
  import Wines from '../Wines.js'
  import C_Wines from '../C_Wines.js'

  export default {
    name: "TastingsSearch",
    components: {
      NoAccess,
      Menu,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      searchState() {
        return this.$store.state.search_state
      },
    },
    data() {
      return {
        debug: false,
        isLoading : true,

        isValid : true,
        inEdit: false,

        user:{},
        user_id:0,

        search_string:'',
        search_stringValid:true,
        search_stringFeedback:'',
        keywords:[],

        search_state:{},

        resultsLoaded: false,

        tastings:[],
        tasting_notes:[],

        results:[],

        showResultZone: false,

        // searching
        ready4Search: true,        
      }
    }, 
    async mounted() {
      if (this.debug) console.log("");
      if (this.debug) console.log("TastingsSearch.mounted: START");

      this.user_id = this.currentUserID;
      this.user = this.currentUser;

      if (this.debug) console.log("TastingsSearch.mounted: current user = ", this.user.email);
      if (this.debug) console.log("TastingsSearch.mounted: has ", this.user.tasting_ids.length, "  tastings.");

      for (let i=0 ; i < this.user.tasting_ids.length ; i++) {
        await Tastings.getone(this.user.tasting_ids[i])
        .then(response=> {
          this.tastings.push(response);
          if (this.debug) console.log( "TastingsSearch.mounted: Tasting ", response, " succesfully fetched from DB");
        })
        .catch(error=> {
          if (this.debug) console.log("TastingsSearch.mounted: await Tastings.getone", error);
        })
      }
      if (this.debug) console.log("TastingsSearch.mounted: =======================================");

      if (this.previousPage == 'TastingShow' && this.searchState != {}) {
        if (typeof this.searchState.search_string != 'undefined') {
          this.search_string = this.searchState.search_string
          this.results = this.searchState.results
        } else {
          this.search_string = ''
          this.results = []
        }
      } else {
        this.onClear()
      }

      this.isLoading = false;
      if (this.debug) console.log("TastingsSearch.mounted: STOP");
    },
    methods: {
      validate(){
        let local_debug = false
        if (local_debug) console.log("====> TastingsSearch.validate: this.search_string.length = ", this.search_string.length)
        if(this.search_string.length >= 3) {
            this.search_stringValid = true;
            this.search_stringFeedback='';
        } else {
            this.search_stringValid = false;
            this.search_stringFeedback='search should have minimum 3 characters';
        }
        this.isValid = this.search_stringValid
      },
      async onSearch() {
        if (this.debug) console.log("")
        if (this.debug) console.log("TastingsSearch.onSearch - START")
        if (this.debug) console.log("TastingsSearch.onSearch: search_string = ", this.search_string)
        
        this.search_state = {}
        this.$store.commit('setSearchState',this.search_state)
        this.results = []

        this.validate()
        if ( !this.isValid) {
          if (this.debug) console.log("TastingsSearch.onSearch - STOP because invalid search_string")
          return
        }
        
        this.ready4Search = false

        this.keywords = this.search_string.split(/[ ,]+/);

        if (this.resultsLoaded == false) {
          if (this.debug) console.log("TastingsSearch.onSearch: NOT resultsLoaded - retrieving TastingNotes"); 
          let k = 0
          for (let i=0 ; i < this.tastings.length ; i++) {
            if (this.debug) console.log("TastingsSearch.onSearch: Tasting ", this.tastings[i].name, " has ", this.tastings[i].tasting_note_ids.length, " tasting notes.");
            for (let j=0 ; j < this.tastings[i].tasting_note_ids.length ; j++) {
              await TastingNotes.getone(this.tastings[i].tasting_note_ids[j])
              .then(response=> {
                this.tasting_notes.push(response);
                if (this.debug) console.log( "TastingsSearch.onSearch: TastingNote ", response, " succesfully fetched from DB");
              })
              .catch(error=> {
                if (this.debug) console.log("TastingsSearch.onSearch: await TastingNotes.getone", error);
              })
              if (this.tasting_notes[k].entry_id !=0 && typeof this.tasting_notes[k].entry_id != "undefined") {
                await Entries.getone(this.tasting_notes[k].entry_id)
                .then(response=> {
                  if (this.debug) console.log( "TastingsSearch.onSearch:  k = ",k," Entry ", response, " succesfully fetched from DB");
                  for (const [key, value] of Object.entries(response)) {
                    if (key != '_id') {
                      this.tasting_notes[k][key] = value
                    }
                  }   
                })
                .catch(error=> {
                  if (this.debug) console.log("TastingsSearch.onSearch: await Entries.getone", error);
                })
              } else if (this.tasting_notes[k].item_id !=0 && typeof this.tasting_notes[k].item_id != "undefined") {
                await Items.getone(this.tasting_notes[k].item_id)
                .then(response=> {
                  if (this.debug) console.log( "TastingsSearch.onSearch: k = ",k," Item ", response, " succesfully fetched from DB");
                  for (const [key, value] of Object.entries(response)) {
                    if (key == 'bottle_id') {
                      this.tasting_notes[k][key] = value
                    }
                  }
                })
                .catch(error=> {
                  if (this.debug) console.log("TastingsSearch.onSearch: await Items.getone", error);
                })
                await Bottles.getone(this.tasting_notes[k].bottle_id)
                .then(response=> {
                  if (this.debug) console.log( "TastingsSearch.onSearch:  k = ",k," Bottle ", response, " succesfully fetched from DB");
                  for (const [key, value] of Object.entries(response)) {
                    if (key == 'vintage') {
                      this.tasting_notes[k][key] = value
                    }
                    if (key == 'c_wine_id') {
                      this.tasting_notes[k][key] = value
                    }
                    if (key == 'wine_id') {
                      this.tasting_notes[k][key] = value
                    }
                  }
                })
                .catch(error=> {
                  if (this.debug) console.log("TastingsSearch.onSearch: await Items.getone", error);
                })
                if (this.tasting_notes[k].c_wine_id !=0 && typeof this.tasting_notes[k].c_wine_id != "undefined") {
                  await C_Wines.getone(this.tasting_notes[k].c_wine_id)
                  .then(response=> {
                    if (this.debug) console.log( "TastingsSearch.onSearch:  k = ",k," C_Wines ", response, " succesfully fetched from DB");
                    for (const [key, value] of Object.entries(response)) {
                    if (key != '_id') {
                      this.tasting_notes[k][key] = value
                    }
                  } 
                  })
                  .catch(error=> {
                    if (this.debug) console.log("TastingsSearch.onSearch: await C_Wines.getone", error);
                  })
                } else if (this.tasting_notes[k].wine_id !=0 && typeof this.tasting_notes[k].wine_id != "undefined") {
                  await Wines.getone(this.tasting_notes[k].wine_id)
                  .then(response=> {
                    if (this.debug) console.log( "TastingsSearch.onSearch:  k = ",k," Wines ", response, " succesfully fetched from DB");
                    for (const [key, value] of Object.entries(response)) {
                    if (key != '_id') {
                      this.tasting_notes[k][key] = value
                    }
                  } 
                  })
                  .catch(error=> {
                    if (this.debug) console.log("TastingsSearch.onSearch: await Wines.getone", error);
                  })
                } else {
                  alert("SEVERE ERROR: c_wine_id = 0 && wine_id = 0")
                }
              } else {
                if (this.debug) console.log("WARNING: entry_id = 0 && item_id = 0 : this.tasting_notes[k] = ", this.tasting_notes[k])
              }
              this.tasting_notes[k].searchZone = ''
              let sep = ':'
              if (typeof this.tasting_notes[k].name != 'undefined') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone + cleanForSearch(this.tasting_notes[k].name)
              if (typeof this.tasting_notes[k].type != 'undefined' &&  this.tasting_notes[k].type != '') this.tasting_notes[k].searchZone =  this.tasting_notes[k].searchZone + sep + cleanForSearch(this.tasting_notes[k].type)
              if (typeof this.tasting_notes[k].vintage != 'undefined') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone +  sep + cleanForSearch(this.tasting_notes[k].vintage)
              if (typeof this.tasting_notes[k].country != 'undefined') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone +  sep + cleanForSearch(this.tasting_notes[k].country)
              if (typeof this.tasting_notes[k].region != 'undefined' &&  this.tasting_notes[k].region != '') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone +  sep + cleanForSearch(this.tasting_notes[k].region)
              if (typeof this.tasting_notes[k].appellation != 'undefined' &&  this.tasting_notes[k].appellation != '') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone +  sep + cleanForSearch(this.tasting_notes[k].appellation)
              if (typeof this.tasting_notes[k].classification != 'undefined' &&  this.tasting_notes[k].classification != '') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone +  sep + cleanForSearch(this.tasting_notes[k].classification)

              if (typeof this.tasting_notes[k].grapes != 'undefined' &&  this.tasting_notes[k].grapes != '') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone +  sep + cleanForSearch(this.tasting_notes[k].grapes)
              if (typeof this.tasting_notes[k].longevity != 'undefined' && this.tasting_notes[k].longevity != '') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone +  sep + cleanForSearch(this.tasting_notes[k].longevity)
              if (typeof this.tasting_notes[k].vines != 'undefined' && this.tasting_notes[k].vines != '') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone +  sep + cleanForSearch(this.tasting_notes[k].vines)
              if (typeof this.tasting_notes[k].yields != 'undefined' && this.tasting_notes[k].yields != '') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone +  sep + cleanForSearch(this.tasting_notes[k].yields)
              if (typeof this.tasting_notes[k].vinification != 'undefined' && this.tasting_notes[k].vinification != '') this.tasting_notes[k].searchZone = this.tasting_notes[k].searchZone +  sep + cleanForSearch(this.tasting_notes[k].vinification)

              // Simple challenge pass on all keywords
              this.challenge(this.tasting_notes[k])
              k++;
            }
          }          
          this.resultsLoaded = true
        } else {
          if (this.debug) console.log("TastingsSearch.onSearch: resultsLoaded !!!"); 
          for (let k = 0; k < this.tasting_notes.length; k++) {
            this.challenge(this.tasting_notes[k])
          }
        }
        
        this.search_state.search_string = this.search_string
        this.search_state.results = this.results
        this.$store.commit('setSearchState',this.search_state)

        this.ready4Search = true
        if (this.debug) console.log("TastingsSearch.onSearch - STOP")
      },

      onClear() {
        this.search_string = ''
        this.results = []
        this.search_state = []
        this.search_stringValid = true;
        this.search_stringFeedback = '';
        this.showResultZone = false
        this.$store.commit('setSearchState',this.search_state)
      },

      onSelect(tasting_note) {
        if (this.debug) console.log("")
        if (this.debug) console.log("TastingsSearch.onSelect: START")
        if (this.debug) console.log("TastingsSearch.onSelect: tasting_note = ", tasting_note)
        this.$store.commit('setPage','TastingShow')
        this.$router.push({path:'/TastingShow', query: {tasting_id:tasting_note.tasting_id, tasting_note_id: tasting_note._id}})
        if (this.debug) console.log("TastingsSearch.onSelect: STOP")
      },
    
      onBack() {
        this.$router.push({path:'/'+this.previousPage});
        this.$store.commit('setPage',this.previousPage)
      },
      challenge(tasting_note) {
        let local_debug = false
        // Simple pass on all keywords
        if (local_debug) console.log("") 
        if (local_debug) console.log("==> TastingsSearch.challenge: START") 
        if (local_debug) console.log("==> TastingsSearch.challenge = ", tasting_note)
        tasting_note.number_of_hits = 0 
        tasting_note.hits = []
        for ( let l=0 ; l < this.keywords.length ; l++ ) {
          let clean_keyword = cleanForSearch(this.keywords[l])
          if (local_debug) console.log("==> TastingsSearch.challenge: l = ",l," clean_keyword = ", clean_keyword)
          
          tasting_note.hits.push({"left":0,"center":clean_keyword, "right":0})

          let max = Math.trunc(100/this.keywords.length)
          let success = tasting_note.searchZone.includes(clean_keyword)
          if (local_debug) console.log("==> TastingsSearch.challenge: Trying ", tasting_note.searchZone, " success = ", success)
          if(success) {
            tasting_note.number_of_hits++;
            tasting_note.success = true;
            tasting_note.hits[l].success = true;                
            if (this.debug) console.log("==> TastingsSearch.challenge: BINGO on ", tasting_note.searchZone)
            let i0 = 0
            let i1 = tasting_note.searchZone.indexOf(clean_keyword)
            if ( i1 > max/2-clean_keyword.length/2) {
              i0 = Math.trunc(i1 - max/2 - clean_keyword.length/2)
            }
            let i2 = tasting_note.searchZone.indexOf(clean_keyword) + clean_keyword.length
            let i3 = tasting_note.searchZone.length
            if ( i3 > i0 + max ) {
              i3 = i0 + max
            }
            tasting_note.hits[l].left = tasting_note.searchZone.substring(i0,i1)
            tasting_note.hits[l].center = clean_keyword
            tasting_note.hits[l].right = tasting_note.searchZone.substring(i2,i3)
            if (local_debug) console.log("==> TastingsSearch.challenge: SUCCESS: tasting_note.hits[",l,"] = ", tasting_note.hits[l])
          } else {
            tasting_note.hits[l].success = false
            tasting_note.hits[l].left = 0
            tasting_note.hits[l].center = clean_keyword
            tasting_note.hits[l].right = 0
          }
        }
        if ( tasting_note.number_of_hits > 0) {
          this.results.push( tasting_note)
          this.showResultZone = true
          this.results.sort(function(a, b) {
            if(a.number_of_hits < b.number_of_hits) { return 1; }
            if(a.number_of_hits > b.number_of_hits) { return -1; }
            return 0;
          });
        }
        if (local_debug) console.log("==> TastingsSearch.challenge: STOP")
      },

      gotoTastingsOverview() {
        this.$store.commit('setPage','TastingsOverview')
        this.$router.push({path:'/TastingsOverview'} );
      },
      
      doNothing() {
      },
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.c-image-part {
  background-image: url("../assets/img/tastings.jpg")
}
</style>
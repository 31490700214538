<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <WineListsOverviewMobile  v-if="matches" />
    <WineListsOverviewDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WineListsOverviewMobile from "./WineListsOverview_Mobile.vue";
  import WineListsOverviewDesktop from "./WineListsOverview_Desktop.vue";
 
  export default {
    name: 'WineListsOverview',
    components: {
      MatchMedia,
      WineListsOverviewMobile,
      WineListsOverviewDesktop,
    },
  }
</script> 
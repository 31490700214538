<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <WineListsSearchMobile  v-if="matches" />
    <WineListsSearchDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WineListsSearchMobile from "./WineListsSearch_Mobile.vue";
  import WineListsSearchDesktop from "./WineListsSearch_Desktop.vue";
 
  export default {
    name: 'WineListsSearch',
    components: {
      MatchMedia,
      WineListsSearchMobile,
      WineListsSearchDesktop,
    },
  }
</script> 
// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';

import axios from 'axios';
const debug = false;

// const url = WEBSERVER + ':' + PORT + '/api/generate_code/';

const url = API + '/generate_code/';

class newCode {
    static get() {
    return axios.post(url, {})
        .then(response => {
            if (debug) console.log('newCode.get: ',response.data)
            return response.data
        })
        .catch(error => {
            console.log('newCode.get: ',error)
        })
    }
}

export default newCode
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        inSession: false,

        current_page: 'Home',
        previous_page: 'Login',
        source: '',

        database:{},
        
        user:{},
        user_id:0,
        profile_picture:'',

        cellar:{},
        cellar_id:0,
        cellar_loaded:false,

        winelist:{},
        winelist_id:0,

        store_node:[],
        
        item:{},
        item_id:0,

        bottle:{},
        bottle_id:0,

        wine:{},
        wine_id:0,

        c_wine:{},
        c_wine_id:0,

        entry:{},
        entry_id:0,

        tasting:{},
        tasting_id:0,

        tasting_note:{},
        tasting_note_id:0,

        wishlist:{},
        wishlist_id:0,

        search_state: {},
    },
    getters:{
        inSession: state => {
            return state.inSession
        },
        currentDatabase: state => {
            return state.database
        },
        currentUser: state => {
            return state.user
        },
        currentUserID: state => {
            return state.user_id
        },
        profilePicture: state => {
            return state.profile_picture
        },
        currentCellar: state => {
            return state.cellar
        },
        currentCellarID: state => {
            return state.cellar_id
        },
        currentCellarLoaded: state => {
            return state.cellar_loaded
        },
        currentPage: state => {
            return state.current_page
        },
        previousPage: state => {
            return state.previous_page
        },
        getSource: state => {
            return state.source
        },
        currentSearchState: state => {
            return state.search_state
        },
        getWinelist: state => {
            return state.winelist
        },
        getWinelistID: state => {
            return state.winelist_id
        },
        getStoreNode: state => {
            return state.store_node
        },
        getItem: state => {
            return state.item
        },
        getItemID: state => {
            return state.item_id
        },                
        getBottle: state => {
            return state.bottle
        },
        getBottleID: state => {
            return state.bottle_id
        },
        getWine: state => {
            return state.wine
        },
        getWineID: state => {
            return state.wine_id
        },
        getCWine: state => {
            return state.c_wine
        },
        getCWineID: state => {
            return state.c_wine_id
        },
        getEntry: state => {
            return state.entry
        },
        getEntryID: state => {
            return state.entry_id
        },
        getTasting: state => {
            return state.tasting
        },
        getTastingID: state => {
            return state.tasting_id
        },
        getTastingnote: state => {
            return state.tasting_note
        },
        getTastingnoteID: state => {
            return state.tasting_note_id
        },
        getWishlist: state => {
            return state.wishlist
        },
        getWishlistID: state => {
            return state.wishlist_id
        },
    },
    mutations:{
        login:state=>{
            state.inSession = true
        },
        logout:state=>{
            state.inSession = false
        },
        setDatabase:(state,currentDatabase)=>{
            state.database = currentDatabase
        },
        setUser:(state,currentUser)=>{
            state.user = currentUser
        },
        setUserID:(state,userID)=>{
            state.user_id = userID
        },
        setProfilePicture:(state,profile_picture)=>{
            state.profile_picture = profile_picture
        },
        setCellar:(state,currentCellar)=>{
            state.cellar = currentCellar
        },
        setCellarID:(state,cellarID)=>{
            state.cellar_id = cellarID
        },
        setCellarLoaded:(state,cellarLoaded)=>{
            state.cellar_loaded = cellarLoaded
        },
        setPage:(state,page)=>{
            state.previous_page = state.current_page;
            state.current_page = page;
        },
        setSource:(state,source)=>{
            state.source = source
        }, 
        setSearchState:(state,currentSearchState)=>{
            state.search_state = currentSearchState
        },
        setWinelist:(state,winelist)=>{
            state.winelist = winelist
        },
        setWinelistID:(state,winelist_id)=>{
            state.winelist_id = winelist_id
        },
        setStoreNode:(state,store_node)=>{
            state.store_node = store_node
        },
        setItem:(state,item)=>{
            state.item = item
        },
        setItemID:(state,item_id)=>{
            state.item_id =item_id
        },
        setBottle:(state,bottle)=>{
            state.bottle = bottle
        },
        setBottleID:(state,bottle_id)=>{
            state.bottle_id = bottle_id
        },
        setWine:(state,wine)=>{
            state.wine = wine
        },
        setWineID:(state,wine_id)=>{
            state.wine_id = wine_id
        }, 
        setCWine:(state,c_wine)=>{
            state.c_wine = c_wine
        },
        setCWineID:(state,c_wine_id)=>{
            state.c_wine_id = c_wine_id
        },
        setEntry:(state,entry)=>{
            state.entry = entry
        },
        setEntryID:(state,entry_id)=>{
            state.entry_id = entry_id
        },        
        setTasting:(state,tasting)=>{
            state.tasting = tasting
        },
        setTastingID:(state,tasting_id)=>{
            state.tasting_id = tasting_id
        },
        setTastingnote:(state,tasting_note)=>{
            state.tasting_note = tasting_note
        },
        setTastingnoteID:(state,tasting_note_id)=>{
            state.tasting_note_id = tasting_note_id
        },
        setWishlist:(state,wishlist)=>{
            state.wishlist = wishlist
        },
        setWishlistID:(state,wishlist_id)=>{
            state.wishlist_id = wishlist_id
        },            
    }
})
<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <HomeMobile  v-if="matches" />
    <HomeDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import HomeMobile from "./Home_Mobile.vue";
  import HomeDesktop from "./Home_Desktop.vue";
 
  export default {
    name: 'Home',
    components: {
      MatchMedia,
      HomeMobile,
      HomeDesktop,
    },
  }
</script> 
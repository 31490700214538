//
// cc64 : compress and crop base64 images
//
export default async function cc64(org_b64,aspect_w,aspect_h) {
    let Max_Size = 1048576;
    let debug = false;

    let org_img;
    let loadImage = new Promise(resolve => {
        org_img = new Image();
        org_img.onload = resolve;
        org_img.src = org_b64
    });
    await loadImage;
    if (debug) console.log("TMP - loadImage - onload: original image loaded ");

    let W = org_img.width;
    let H = org_img.height;

    let scale = Math.sqrt(Max_Size/(W*H))

    if (debug) console.log("TMP - resize64 - onload: W = ", W , " H =", H);

    if (debug) console.log("TMP - resize64 - resize : START")
    if (debug) console.log("TMP - resize64 - resize : W = ", W , " H =", H);

    let W_c = W;
    let H_c = H;
    if (scale < 1) {
        W_c = Math.round(W*scale);
        H_c = Math.round(H*scale);
    } else {
        scale = 1;
    }

    if (debug) console.log("TMP - resize64 - resize : Max_Size = ", Max_Size);
    if (debug) console.log("TMP - resize64 - resize : scale = ", scale);
    if (debug) console.log("TMP - resize64 - resize : W_c = ", W_c , " H_c =", H_c);
    if (debug) console.log("TMP - resize64 - resize : STOP")

    let canvas = document.createElement('canvas');
    canvas.setAttribute('width',W_c);
    canvas.setAttribute('height',H_c);
    let ctx = canvas.getContext('2d');
    ctx.scale(scale,scale);
    
    ctx.drawImage(org_img, 0, 0);
    let resize_b64 = canvas.toDataURL();

    if (debug) console.log("TMP - resize64 - return value : resize_b64 = ", resize_b64.substring(0,100));
    
    let resize_img;
    loadImage = new Promise(resolve => {
        resize_img = new Image();
        resize_img.onload = resolve;
        resize_img.src = resize_b64
    });
    await loadImage;
    if (debug) console.log("TMP - loadImage - onload: resized loaded ");

    W = resize_img.width;
    H = resize_img.height;

    let ratio = aspect_w / aspect_h

    if (debug) console.log("TMP - crop64 - onload: W = ", W , " H =", H);

    if (debug) console.log("TMP - crop64 - crop : START")
    if (debug) console.log("TMP - crop64 - crop : aspect_w = ",aspect_w , " aspect_h =", aspect_h);
    if (debug) console.log("TMP - crop64 - crop : ratio = ", ratio);

    let x, y, w, h;
    if (W >= ratio * H) {
        h = H;
        w = ratio * H;
        x = ( w - W ) / 2;
        y = 0
    } else {
        if ( aspect_w == 1 ) {
            h = H;
            w = ratio * H;
            x = ( w - W ) / 2;
            y = 0
        } else {
            w = W;
            h = W / ratio;
            x = 0
            y = ( h - H ) / 2;
        }
    }

    if (debug) console.log("TMP - crop64 - crop : ratio = ", ratio);
    if (debug) console.log("TMP - crop64 - crop : w = ", w , " h =", h);
    if (debug) console.log("TMP - crop64 - crop : x = ", x , " y =", y);
    if (debug) console.log("TMP - crop64 - crop : STOP")

    canvas = document.createElement('canvas');
    canvas.setAttribute('width',w);
    canvas.setAttribute('height',h);
    ctx = canvas.getContext('2d');

    ctx.drawImage(resize_img, x, y);
    let crop_b64 = canvas.toDataURL();

    return crop_b64;
}
<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <TastingsSearchMobile  v-if="matches" />
    <TastingsSearchDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import TastingsSearchMobile from "./TastingsSearch_Mobile.vue";
  import TastingsSearchDesktop from "./TastingsSearch_Desktop.vue";
 
  export default {
    name: 'TastingsSearch',
    components: {
      MatchMedia,
      TastingsSearchMobile,
      TastingsSearchDesktop,
    },
  }
</script> 
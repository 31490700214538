<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <TastingsOverviewMobile  v-if="matches" />
    <TastingsOverviewDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import TastingsOverviewMobile from "./TastingsOverview_Mobile.vue";
  import TastingsOverviewDesktop from "./TastingsOverview_Desktop.vue";
 
  export default {
    name: 'TastingsOverview',
    components: {
      MatchMedia,
      TastingsOverviewMobile,
      TastingsOverviewDesktop,
    },
  }
</script> 
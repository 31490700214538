// AoA = Array of Arrays
export function isEqualAoA(array1,array2) {
    let local_debug = false
    let return_value = true

    if (local_debug) console.log("isEqualAoA: array1 = ", array1, " array2 = ", array2)
    if ( array1.length != array2.length) {
        return false
    }

    for ( let i=0 ; i < array1.length ; i++ ) {
        return_value = return_value && array1[i] == array2[i]
    }

    if (local_debug) console.log("isEqualAoA: return_value = ", return_value)
    return return_value
}

export function isInAoA(element, array) {
    let local_debug = false
    let position = -1

    if (local_debug) console.log("isInAoA: element = ", element, " array = ", array)
    if (typeof array == "object") {
        for ( let i=0 ; i < array.length ; i++) {
            if (isEqualAoA(array[i],element)) {
                position = i
                break
            }
        }
    }
    return position
}

export function removeFromAoA(element, array) {
    let local_debug = false

    if (local_debug) console.log("removeFromAoA: element = ", element, " array = ", array)

    let position  = isInAoA(element,array)
    if (local_debug) console.log("removeFromAoA: position = ", position)
    if (position != -1) {
        array.splice(position,1)
    }

    if (local_debug) console.log("removeFromAoA: NEW array = ", array)
    return array
}

export function sliceAoA(array, begin, end) {
    let local_debug = false
    let return_array  = []
    end = Math.min(end,array.length)
     if (local_debug) console.log("sliceAoA: array = ", array, " begin = ", begin, " end = ", end)
    for ( let i = begin ; i < end ; i++) {
        return_array.push(array[i])
    }
    return return_array
}

export function orderAoA(unsorted) {
    let local_debug = false
    let sorted  = []
    let smallest_i = 0

    if (local_debug) console.log("orderAoA: START unsorted = ", JSON.stringify(unsorted))
    while (unsorted.length > 0) {
        smallest_i = 0
        for ( let i = 1 ; i < unsorted.length ; i++ ) {
            if (isSmaller(unsorted[i],unsorted[smallest_i])) {
                smallest_i = i
            }
        }
        sorted.push(unsorted[smallest_i])
        unsorted = removeFromAoA(unsorted[smallest_i],unsorted)
        if (local_debug) console.log("orderAoA: START sorted = ", JSON.stringify(sorted), " unsorted = ", JSON.stringify(unsorted))
    }

    if (local_debug) console.log("orderAoA: STOP sorted = ", JSON.stringify(sorted))
    return sorted
}

function isSmaller(array1, array2) {
    if (array1.length < array2.length) {
        for ( let i = 0 ; i < array1.length ; i++) {
            if(array1[i] <  array2[i]) {
                return true
            } else if ( array1[i] > array2[i]) {
                return false
            }
        }
        return false
    } else {
        for ( let i = 0 ; i < array2.length ; i++) {
            if(array1[i] < array2[i]) {
                return true
            } else if ( array1[i] > array2[i]) {
                return false
            }
        }
        return false
    }
}
<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container v-if="this.inSession" fluid> 
        <b-row>
          <div style="width:300px;margin-left:auto;margin-right:auto">
            <b-col
              class="w-tile m-2 d-inline-flex justify-content-center"
              style ="display:block; margin:20px !important"
            >
              <b-row 
                style="width:100%"
              >
                <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                  <b-img center v-if="wine.image_files[0]"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    v-bind:src="wine.image_files[0].dataURL" 
                  ></b-img>
                  <b-img center v-else
                    style="height:100%;width:auto;position:absolute;right:0px"
                    src="../../img/bottle.png"
                  ></b-img>
                </b-col>
                <b-col cols="9" class="d-table-cell">
                  <div>
                    <p class="t-title">{{wine.name}}</p>
                    <p class="t-subtitle" v-if="wine.type">{{wine.type}}</p>
                    <hr>
                    <p class="t-text" v-if="wine.appellation">{{wine.appellation}}</p>
                    <p class="t-text" v-if="wine.classification">{{wine.classification}}</p>
                    <p class="t-smalltext" v-if="wine.grapes">{{wine.grapes}}</p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-row>
        <hr>
        <b-row>
          <b-container fluid >
            <div class="e-box">
              <b-form autocomplete="off">
                <b-row>
                  <b-col>
                    <b-form-group style="text-align:left">
                        <label class="w-label" for="input-number">number</label>
                          <b-form-spinbutton v-if="!entryExists" 
                            class="w-spinbutton" 
                            id="input-number" 
                            v-model="transaction.number"
                            v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
                            aria-describedby="input-live-help input-live-feedback" 
                            min="1" max="1000"
                          >
                          </b-form-spinbutton>
                          <b-form-spinbutton v-else 
                            class="w-spinbutton" 
                            id="input-number" 
                            v-model="transaction.number"
                            v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
                            aria-describedby="input-live-help input-live-feedback" 
                            min="1" max="1000"
                            readonly
                          >
                          </b-form-spinbutton>
                          <b-form-invalid-feedback id="input-live-feedback">
                            {{numberFeedback}}
                          </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group style="text-align:left">
                        <label class="w-label" for="input-date">date</label>
                        <b-form-datepicker 
                          class="w-datepicker mb-2" 
                          id="input-date"
                          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                          v-model="transaction.date"
                          v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
                          aria-describedby="input-live-help input-live-feedback"
                        >
                        </b-form-datepicker>
                        <b-form-invalid-feedback id="input-live-feedback">
                          {{dateFeedback}}
                        </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>                        
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="vintageList">vintage</label>         
                      <model-select 
                        id="vintageList"
                        class = "w-select"
                        :options="vintages"
                        v-model="entry.vintage"
                        v-bind:class="{'form-control':true, 'is-invalid' : !this.vintageValid}"
                        @input="inputVintage"
                      >
                      </model-select>             
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{vintageFeedback}}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-price">price</label>
                      <b-form-input
                        id="input-price"
                        class="w-input"
                        v-model="transaction.price"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-occasion">occasion</label>
                      <b-form-input
                        id="input-occasion"
                        class="w-input"
                        v-model="transaction.description"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>              
              </b-form>                                  
            </div>
          </b-container>
        </b-row>
      </b-container>
    </div>

    <b-modal id="addEntry" ref="addEntry" title="add wine to ..." @ok="onSave">
      <p class="t-title text-left">NICHE: {{this.niche.code}} - {{this.niche.name}}</p> 
      <b-row>
        <div style="width:300px;margin-left:auto;margin-right:auto">
          <b-col
            class="w-tile m-2 d-inline-flex justify-content-center"
            style ="display:block; margin:20px !important"
          >
            <b-row 
              style="width:100%"
            >
              <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                <b-img center v-if="wine.image_files[0]"
                  style="height:100%;width:auto;position:absolute;right:0px"
                  v-bind:src="wine.image_files[0].dataURL" 
                ></b-img>
                <b-img center v-else
                  style="height:100%;width:auto;position:absolute;right:0px"
                  src="../../img/bottle.png"
                ></b-img>
              </b-col>
              <b-col cols="9" class="d-table-cell">
                <div>
                  <p class="t-title">{{wine.name}}</p>
                  <p class="t-subtitle" v-if="wine.type">{{wine.type}}</p>
                  <hr>
                  <p class="t-text" v-if="wine.appellation">{{wine.appellation}}</p>
                  <p class="t-text" v-if="wine.classification">{{wine.classification}}</p>
                  <p class="t-smalltext" v-if="wine.grapes">{{wine.grapes}}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </b-row>
      <b-row>
        <b-container fluid >
          <div class="e-box">
            <b-form autocomplete="off">
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                      <label class="w-label" for="input-number">number</label>
                        <b-form-spinbutton 
                          class="w-spinbutton" 
                          id="input-number" 
                          v-model="transaction.number"
                          v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
                          aria-describedby="input-live-help input-live-feedback" 
                          min="1" max="1000"
                          readonly
                        >
                        </b-form-spinbutton>
                        <b-form-invalid-feedback id="input-live-feedback">
                          {{numberFeedback}}
                        </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group style="text-align:left">
                      <label class="w-label" for="input-date">date</label>
                      <b-form-datepicker 
                        class="w-datepicker mb-2" 
                        id="input-date"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                        v-model="transaction.date"
                        v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
                        aria-describedby="input-live-help input-live-feedback"
                        readonly
                      >
                      </b-form-datepicker>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{dateFeedback}}
                      </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>                        
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="vintageList">vintage</label>         
                    <model-select 
                      id="vintageList"
                      class = "w-select"
                      :options="vintages"
                      v-model="entry.vintage"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.vintageValid}"
                      @input="inputVintage"
                    >
                    </model-select>             
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{vintageFeedback}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-price">price</label>
                    <b-form-input
                      id="input-price"
                      class="w-input"
                      v-model="transaction.price"
                      readonly
                      ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-occasion">occasion</label>
                    <b-form-input
                      id="input-occasion"
                      class="w-input"
                      v-model="transaction.description"
                      readonly
                      ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>              
            </b-form>                                  
          </div>
        </b-container>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import { ModelSelect } from 'vue-search-select';

  import Wines from '../Wines.js';
  
  import Cellars from '../Cellars.js';
  import Niches from '../Niches.js';
  import Entries from '../Entries.js';
  import Transactions from '../Transactions.js'
  
  export default {
    name: 'WineEdit',
    components: {
      ModelSelect,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      }
    },
    data() {
      return {
        cellar_id:0,
        cellar: {},

        niche_id:0,
        niche:{},

        vintages: [],

        entry: {
            name:'',
            type:'',
            country_id:0,
            country:'',
            region_id:0,
            region:'',
            appellation_id:0,
            appellation:'',
            classification_id:0,
            classification:'',
            sparkling:false,
            color:'',
            grapes:'',
            vines:'',
            yields:'',
            vinification:'',
            image_files:[],
            wine_id:0,
            winery_id:0,
            
            number:0,
            vintage:0,

            cellar_id:0,
            niche_id:0,

            transaction_ids:[],
            tasting_note_ids:[],
        },
        entry_id:0,

        entryExists: false,

        transaction:{
          date:'',
          type:'',
          number:0,
          description:'',
          price:'',
          entry_id:0
        },
        transaction_id:0,

        // Validation Logic
        numberValid : true,
        numberFeedback : '',
        dateValid : true,
        dateFeedback : '',
        vintageValid : true,
        vintageFeedback : '',

        isValid:true,

        isLoading : true,
        wine: {
          image_files:[],
        },
        wine_id: 0
      }
    },
  async mounted(){
    if (this.debug) console.log('WineEdit in mounted: START');

    // Prepare the input fields
    var today = new Date();
    var this_year = today.getFullYear();
    for (let i = this_year; i > this_year-100; i--) {
      this.vintages.push({value:i,text:i});
    }
    
    // Get the objects from DB
    if (typeof this.$route.query.cellar_id != "undefined") {
      this.cellar_id = this.$route.query.cellar_id
      await Cellars.getone(this.cellar_id)
      .then(response => {
        this.cellar = response;
        delete this.cellar._id;
        if (this.debug) console.log("WineShow in mounted: Cellar " + this.cellar + " succesfully fetched from DB");
      })
      .catch(error => {
        if (this.debug) console.log("WineShow in mounted: await Cellars.getone ", error);
      })
    }

    if (typeof this.$route.query.niche_id != "undefined") {
      this.niche_id = this.$route.query.niche_id
      await Niches.getone(this.niche_id)
      .then(response => {
        this.niche = response;
        delete this.niche._id;
        if (this.debug) console.log("WineShow in mounted: Niche " + this.niche + " succesfully fetched from DB");
      })
      .catch(error => {
        if (this.debug) console.log("WineShow in mounted: await Niches.getone ", error);
      })
    }

    if (typeof this.$route.query.entry_id != "undefined") {
      this.entry_id = this.$route.query.entry_id
      await Entries.getone(this.entry_id)
      .then(response => {
        this.entry = response;
        delete this.entry._id;
        if (this.debug) console.log("WineShow in mounted: Entry " + this.entry + " succesfully fetched from DB");
      })
      .catch(error => {
        if (this.debug) console.log("WineShow in mounted: await Entries.getone ", error);
      })
    }

    if (this.entry.wine_id !=0) {
      this.wine_id = this.entry.wine_id;
      await Wines.getone(this.wine_id)
      .then(response => {
        this.wine = response;
        delete this.wine._id;
        if (this.debug) console.log("WineShow in mounted: Wine " + this.wine + " succesfully fetched from DB");
      })
      .catch(error => {
        console.log('WineShow in mounted: Catch await Wines.getone - ' + error);
      })
      this.transaction_id = this.entry.transaction_ids[0];
      await Transactions.getone(this.transaction_id)
      .then(response => {
        this.transaction = response;
        delete this.transaction._id;
        if (this.debug) console.log("WineShow in mounted: Transaction " + this.transaction + " succesfully fetched from DB");
      })
      .catch(error => {
        console.log('WineShow in mounted: Catch await Transaction.getone - ' + error);
      })
    }

    this.isLoading = false;
  },
  methods: {
    onBack() {
      // this.$store.commit('setPage','Library')
      // this.$router.push({path:'/Library', query: {cellar_id:this.$route.query.cellar_id }})
      let path = '/'+this.previousPage;
      console.log("TMP path = ", path)
      this.$store.commit('setPage',this.previousPage)
      this.$router.push({path:path, query: {cellar_id:this.$route.query.cellar_id, niche_id:this.$route.query.niche_id }})
    },
    async onAdd() {
      if (this.debug) console.log("Add Pressed")
      await this.$bvModal.show('addEntry')
    },
    validate_form(){
      if( this.transaction.number <= 0) {
          this.numberValid = false;
          this.numberFeedback = "Select valid number"
      } else {
          this.numberValid = true
          this.numberFeedback = ""
      }

      if(this.entry.vintage == 0) {
        this.vintageValid = false;
        this.vintageFeedback = "Select valid vintage"
      } 
      else {
        this.vintageValid = true;
        this.vintageFeedback = ""
      }
      
      this.isValid = this.numberValid && this.vintageValid;
    },
    async inputVintage(value) {
      this.entry.vintage = value;
      if (this.debug) console.log("WineShow in inputVintage - vintage = ",this.entry.vintage)
    },
    async getEntry_inNiche(wine_id,vintage){    
      if (this.debug) console.log("WineShow in getEntry_inNiche -> CHECKING");
      let this_entry_id = 0;
      let this_entry = {};
      for(let i=0; i < this.niche.entry_ids.length; i ++) {
        await Entries.getone(this.niche.entry_ids[i])
        .then(response => {
          this_entry = response;
          this_entry_id = response._id;
          delete this_entry._id
        })
        .catch(error => {
          if (this.debug) console.log("WineShow in getEntry_inNiche: await Entries.getone ", error);
        })
        console.log("TMP : i = ", i, " this_entry.wine_id=", this_entry.wine_id, "wine_id=", wine_id, " --- this_entry.vintage=", this_entry.vintage, " vintage=", vintage);
        if (this_entry.wine_id === wine_id && this_entry.vintage === vintage) {
          if (this.debug) console.log("WineShow in getEntry_inNiche ---> EXISTS");
          this.entry_id = this_entry_id;
          this.entry = this_entry;
        } else {
          if (this.debug) console.log("WineShow in getEntry_inNiche ---> DOES NOT EXISTS");
          this.entry_id = 0;
        }
      }
      return this.entry_id;
    },
    async onSave() {
        console.log("WineShow in onSave: Save Button pressed");
        //Reading the form
        this.validate_form();
        if (!this.isValid) {
          console.log("WineShow in onSave: Returning without Save because validation form failed.")
          await this.$bvModal.show('addEntry')
          return;
        }
        
        this.entry.cellar_id = this.cellar_id;
        this.entry.niche_id = this.niche_id;
        this.entry.wine_id = this.wine_id;

        // Check if entry already exists in database
        console.log("TMP1 - this.entry_id = ", this.entry_id)
        await this.getEntry_inNiche(this.entry.wine_id,this.entry.vintage);
        console.log("TMP2 - this.entry_id = ", this.entry_id)
        
        if ( this.entry_id == 0 ) {
          console.log("WineShow in onSave: Entry does NOT exist - we create it");
          let keys = Object.keys(this.wine);
          for (let i = 0; i < keys.length; i++) {
            this.entry[keys[i]] = this.wine[keys[i]];
          }
          console.log("WineShow in onSave: Copied this.wine to this.entry = ", this.entry);

          // Updating a transaction from form data
          this.entry.number = this.transaction.number;

          // Creating Entry
          await Entries.create(this.entry)
          .then(response=>{
            this.entry_id = response;
            console.log("WineShow in onSave: Entry succesfully created : id = ", response);
          })
          .catch(error=>{
            console.log("WineShow in onSave: catch await Entries.create ERROR :", error);
          }) 

          // Adding this.entry_id to the list of this.niche
          console.log("WineShow in onSave: Adding entry to entry_ids from niche ", this.niche.name)       
          this.niche.entry_ids.push(this.entry_id);
          await Niches.update(this.niche_id,this.niche)
          .then(response=>{
            console.log("WineShow in onSave: Niche succesfully added entry : id = ", response);
          })
          .catch(error=>{
            console.log("WineShow in onSave: catch await Niche.update ", error);
          })  
          
          // Adding this.entry_id to the list of this.cellar
          console.log("WineShow in onSave: Adding entry to entry_ids from cellar ", this.cellar.name)
          this.cellar.entry_ids.push(this.entry_id);
          console.log("TMP - this.cellar_id = ", this.cellar_id, " this.cellar = ", this.cellar)
          await Cellars.update(this.cellar_id,this.cellar)
          .then(response=>{
            console.log("WineShow in onSave: Cellar succesfully added niche : id = ", response);
          })
          .catch(error=>{
            console.log("WineShow in onSave: catch await Cellar.update ", error);
          })  
          } else {
            console.log("WineShow in onSave: Entry DOES exist - we update it");
            // await Entries.getone(this.entry_id)
            // .then(response=>{
            //   this.entry = response;
            //   delete this.entry_id;
            //   console.log("WineShow in onSave: Entry succesfully fetched: id = ", this.entry_id, " ", response);
            // })
            // .catch(error=>{
            //   console.log("WineShow in onSave: catch await Entries.getone ERROR :", error);
            // }) 
            // Updating a transaction from form data
            console.log("WineShow in onSave: Entry DOES exist: id = ", this.entry_id, " ", this.entry);
            this.entry.number = this.entry.number + this.transaction.number;
            console.log("WineShow in onSave: Entry DOES exist - we update it");
          }
         
          this.transaction.entry_id = this.entry_id;
          console.log("WineShow in onSave: this.transaction = ", this.transaction)
          await Transactions.create(this.transaction)
          .then(response=>{
            this.transaction_id = response;
            console.log("WineShow in onSave: Transaction succesfully created : id = ", response);
          })
          .catch(error=>{
            console.log("WineShow in onSave: catch await Transaction.create ERROR :", error);
          })
          this.entry.transaction_ids.push(this.transaction_id);
          
          console.log("WineShow in onSave: UPDATE ENTRY with id: ", this.entry_id, " ",this.entry);
          await Entries.update(this.entry_id, this.entry)
          .then(response=>{
            console.log("WineShow in onSave: Entry succesfully updated : id = ", response);
          })
          .catch(error=>{
            console.log("WineShow in onSave: catch await Entry.update ERROR :", error);
          })

          this.$router.push({path:'/NicheShow', query: {cellar_id:this.cellar_id,niche_id:this.niche_id }});
          await this.$forceUpdate();
    },
  }
}
</script>

<style scoped>

</style>
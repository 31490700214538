<template>
  <div v-if="this.isLoading" class="title-bar">
    <b-form>
      <br><br><br><br><br>
      <h5 style='color:white'>loading</h5>
      <br>
       <b-spinner style="color: rgb(109,122,82) !important"
        variant="warning"
      ></b-spinner>
    </b-form>
  </div>
  <div v-else class="main">
    <b-row style="margin:0">
      <b-col md="8" style="height:100vh;padding-left:0!important">
        <div class="login-picture">
          <div class="photography">
            <p> photography : douglas lopez </p>
          </div>
          <div class="login-overlay">
            <h1 class="main-title"><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</h1>
            <p class="main-subtitle">a simple tool to manage tasting notes</p>
            <p v-if="database.name == 'Test'" class="main-subtitle" style="font-size: 48px !important;">{{database.name}}</p>
            <!-- <p class="main-subtitle" style="color:white">TEST ONLY - IS NOT YET RELEASED</p> -->
          </div>
          <div class="social-media">
            <div style="width:30px; display:inline-block; margin-right:10px">
              <b-img
                fluid
                :src="require('@/assets/img/facebook-white.png')"
                v-on:click="gotoFacebook()"
              >
              </b-img>
            </div>
            <div style="width:30px; display:inline-block;margin-left:10px">
              <b-img
                fluid
                :src="require('@/assets/img/instagram-white.png')"
                v-on:click="gotoInstagram()"
              >
              </b-img>
            </div>

          </div>
          <div class="copyright">
            <p style="text-align:center"> funware for wine lovers developed and ©copyrighted by N'JOY Information Technology</p>
          </div>  
        </div>
      </b-col>
      <b-col md="4">
        <div v-if="!this.noServer" >
          <div style="height:25vh">
          </div>
          <!-- <div style="height:10vh"/>
          <h1 style="height:15vh;text-align:center;color: grey !important"><b>COMING SOON</b></h1>
          <div style="height:5vh"/> -->
          
          <!-- <b-form class="w-login disabled"> -->
          <b-form class="w-login">
            <b-form-group>
              <label class="w-label" for="input-username">email</label>
              <b-form-input
                id="input-username"
                class = "w-input"
                style = "background-color:white !important"
                v-model="form.email"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.emailValid}"
                aria-describedby="input-live-help input-live-feedback"
                type="email"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                  {{emailFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
              <label class="w-label" for="input-password">password</label>
              <b-form-input
                v-if="passwordVisible"
                id="input-password"
                class = "w-input"
                style = "background-color:white !important"
                v-on:keyup.enter="onLogin()"
                v-model="form.password"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.passwordValid}"
                aria-describedby="input-live-help input-live-feedback"
                type="text"
                required
              ></b-form-input>
              <b-form-input
                v-else
                id="input-password"
                class = "w-input"
                style = "background-color:white !important"
                v-on:keyup.enter="onLogin()"
                v-model="form.password"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.passwordValid}"
                aria-describedby="input-live-help input-live-feedback"
                type="password"
                required
              >
              </b-form-input>
              <font-awesome-icon
                id="eye"
                v-bind:style="{'color':eye_color, 'font-size':'16px', 'float':'right', 'position':'relative', 'bottom':'22px','right': '5px'}"
                icon="eye"
                @click="toggleVisibilityPassword"/>  
              <b-form-invalid-feedback id="input-live-feedback">
                  {{passwordFeedback}}
              </b-form-invalid-feedback>

            </b-form-group>

            <b-button v-on:click="onLogin()" class="w-button-green-full btn-block">login</b-button>
            <p style="font-size:12px; margin-top:5px" v-on:click="forgotPassword()" class="w-cursor-click"><u>forgot password?</u></p>
          </b-form>
          <div style="height:25vh">
          </div>
          <!-- <b-form class="w-login disabled"> -->
          <b-form class="w-login">
            <p style="font-size:12px;margin-bottom:5px" class="w-cursor-default">don't have a WyneLover account yet?</p>
            <b-button v-on:click="onRegister()" class="w-button-pink-full btn-block">register</b-button>
          </b-form>
        </div>
        <div v-else class="login-overlay">
          <h1 class="w-title text-center">No Server Available</h1>
          <h2 class="w-subtitle text-center">Please try again later</h2>
        </div>
        <br>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';

  import Database from '../Database.js';
  import Connection from '../Connection.js';
  import Authenticate from '../Authenticate.js';
  import Users from '../Users.js';
  import { faDatabase } from '@fortawesome/free-solid-svg-icons';

  export default {
    name: 'Login',
    data() {
      return {
        debug: true,

        form: {
          email: '',
          password: '',
        },
        user: {
          image_files:[],
        },
        user_id:0,
        profile_picture:'',
        database:{},

        emailValid : true,
        passwordValid : true,
        error : '',
        emailFeedback : '',
        passwordFeedback : '',
        isConfirmed: false,

        passwordVisible: false,
        eye_color:"lightgrey",

        isLoading: true,
        noServer: true,
      }
    },
    async mounted() {
      while (this.noServer) {
        if (this.debug) console.log("Login.mounted: this.noServer = ", this.noServer)
        await Connection.test()
        .then(response=>{
          this.noServer = !response;
          this.isLoading = false;
          if (this.debug) console.log("Login.mounted: this.noServer = ", this.noServer)
        })

        await Database.getone("657048c77872235bf4fe3e78")
        .then(response=>{
          if (this.debug) console.log("Login.mounted : await Database.get reponse = ", response);
          this.database = response;
        })
        .catch(error=>{
          if (this.debug) console.log("Login.mounted : await Database.get - ", error);
        })
      }
    },
    methods: { 
      validate() {
        // validate email 
        let hasAt = false;
        let hasDot = false;
        for (let i=0;i < this.form.email.length;i++) {
            if (this.form.email[i] === '@') {
                hasAt = true;
            }
            if (this.form.email[i] === '.') {
                hasDot = true;
            }
        }
        this.emailValid = hasAt && hasDot
        if ( ! this.emailValid) {
            this.emailFeedback='This is an invalid email format';
            this.passwordValid = true;
        }
      },  
      async onLogin() {
        if (this.debug) console.log("")
        if (this.debug) console.log("Login.onLogin - START")
        if ( this.noServer ) {
          if (this.debug) console.log("Login.onLogin: return because this.noServer = ", this.noServer)
          return;
        }
        this.validate();

        let challenge = {"email": this.form.email, "hashed_password": this.CryptoJS.SHA256(this.form.password).toString()}
        if(this.emailValid) {
          let authOK = false;
          await Authenticate.getToken(challenge)
          .then(response=>{     
            if (this.debug) console.log("Login.onLogin : response ", response, "successfully fetched from DB");
            if (response === 0) {
              this.emailValid = false;
              this.emailFeedback = 'email is unknown, maybe you need to register';
              this.passwordValid = true;
            } else if (response === 1) {
              this.passwordValid = false;
              this.passwordFeedback = 'password incorrect';
            } else if (response === 2) {
              this.emailValid = false;
              this.emailFeedback = 'email is registered, but not confirmed, please check you mail'
              this.passwordValid = true;
            } else {
              this.user_id = response;
              authOK = true;
            }
          })
          .catch(error=>{
            if (this.debug) console.log("Login.onLogin : await Authenticate.getToken - ", error);
            return
          })

          if (authOK) {
            await Users.getone(this.user_id)
            .then(response=>{
              if (this.debug) console.log("Login.onLogin : await Users.getone reponse = ", response);
              this.user = response;
              delete this.user._id;
            })
            .catch(error=>{
              if (this.debug) console.log("Login.onLogin : await Users.getone - ", error);
            })

            await Database.getone("657048c77872235bf4fe3e78")
            .then(response=>{
              if (this.debug) console.log("Login.onLogin : await Database.get reponse = ", response);
              this.database = response;
              delete this.database._id;
            })
            .catch(error=>{
              if (this.debug) console.log("Login.onLogin : await Database.get - ", error);
            })

            if (this.user.cellar_id == 0 || typeof this.user.cellar_id == 'undefined') {
              this.$router.push({path:'/AddCellar2Registration', query: {user_id: this.user_id}} );
            } else {
              // HERE YOU FIRE OF THE ROUTER TO ACCESS THE APPLICATION
              try {
                this.$store.commit('login')
                this.$store.commit('setDatabase',this.database)
                this.$store.commit('setUser',this.user)
                this.$store.commit('setUserID',this.user_id)
                if ( this.user.image_files.length > 0) {
                  this.profile_picture = await cc64(this.user.image_files[0].dataURL,1,1)
                }
                this.$store.commit('setProfilePicture',this.profile_picture)

                // Clean the store TBD

                this.$store.commit('setPage','Home')
                this.$router.push({path:'/Home'});
              } catch(err) {
                alert("ERROR : ", err)
              }
            }
          }
        }
        if (this.debug) console.log("Login.onLogin - STOP") 
      },
      onRegister() {
        this.$router.push({path:'/Register'} );  
      },
      forgotPassword() {
        this.$router.push({path:'/ForgotPassword'} );
      },
      gotoFacebook() {
        window.open("https://www.facebook.com/wynelover.eu", '_blank');
      },
      gotoInstagram() {
        window.open("https://www.instagram.com/wyne.lover/", '_blank');
      },

      async toggleVisibilityPassword() {
        if (this.passwordVisible == true) {
          this.passwordVisible = false
          this.eye_color = "lightgrey"
        } else {
          this.passwordVisible = true
          this.eye_color = "rgb(109,122,82)"
        }
      },

    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.main {
  overflow-x: hidden;
  overflow-y: hidden;
}
.login-picture {
    height:100vh !important;
    width:100% !important;
    
    background-image: url("../assets/img/wynetaster.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:absolute;
    display: flex;
    justify-content: center;
}
.login-overlay {
  position: relative;
  top: 35vh;
}
.social-media {
  position: fixed;
  bottom: 55px;
  color: white;
}
.copyright {
  position: fixed;
  font-size:clamp(8px,1.5vw, 14px)!important;
  bottom: 0px;
  color: white;
}

.title-bar {
  text-align: center;
}

.disabled
{
  pointer-events: none;
  opacity: 0.5;
}
</style>

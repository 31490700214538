<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <TastingShowMobile  v-if="matches" />
    <TastingShowDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import TastingShowMobile from "./TastingShow_Mobile.vue";
  import TastingShowDesktop from "./TastingShow_Desktop.vue";
 
  export default {
    name: 'TastingShow',
    components: {
      MatchMedia,
      TastingShowMobile,
      TastingShowDesktop,
    },
  }
</script> 
<template>
  <MatchMedia query="(max-width: 760px)" v-slot="{ matches }">
    <LoginMobile  v-if="matches" />
    <LoginDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import LoginMobile from "./Login_Mobile.vue";
  import LoginDesktop from "./Login_Desktop.vue";
 
  export default {
    name: 'Login',
    components: {
      MatchMedia,
      LoginMobile,
      LoginDesktop,
    },
  }
</script> 
<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <br>          
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">tastings > overview</p>
             <b-row class="justify-content-center">
              <p class="t-title">overview of all your tasting events you enjoyed.</p>
            </b-row>
            <b-row class="justify-content-center">
              <p class="t-subtitle">to select one, click - to add one, click on the plus sign</p>
            </b-row>
            <b-row class="justify-content-center" style="margin:0">
              <b-col 
                  class="w-tile w-cursor-click m-2 d-inline-flex justify-content-center"
                  xs="12" sm="6" md="4" lg="2" xl="2"
                  v-on:click="onEdit()"
                  style ="display:block; margin:20px !important"
              >
                <b-img
                  width=300px
                  height=200px
                  :src="require('@/assets/img/plus.png')"
                >
                </b-img>
              </b-col>
              
              <b-col
                v-for="(tasting, index) in tastings"
                v-bind:item="tasting"
                v-bind:index="index"
                v-bind:key="tasting._id"
                class="w-tile w-cursor-click m-2 d-inline-flex justify-content-center"
                xs="12" sm="6" md="4" lg="2" xl="2"
                style ="display:block; margin:20px !important"
              >  
                <b-row  
                  style="width:100%"
                  v-on:click="onSelect(tasting._id,tasting)"
                >
                  <b-col class="justify-content-center" style="height:100%;width:auto;padding:0;position:sticky">
                    <div v-if="tasting.image_files[0]" 
                      class="c-image-part" 
                      v-bind:style="{ 'background-image': 'url(' + tasting.image_files[0].dataURL +')' }"
                    >
                    </div>
                    <div v-else
                      class="c-image-part"
                    />
                    <div class="c-text-part">
                      <p class="c-title">{{tasting.name}}<span v-if="tasting.organiser" style="font-weight:normal;font-size: 11px;"> (by {{ tasting.organiser }})</span></p>
                      <p class="c-subtitle"><span v-if="tasting.date != ''">{{tasting.nice_date}}</span>  <span v-if="tasting.description">- {{tasting.description}}</span></p>
                      <p class="c-bottles">{{tasting.tasting_note_ids.length}}</p>
                    </div>
                    <b-button pill
                      class="edit-button"
                      v-on:click.stop="onEdit(tasting._id)"
                      v-b-tooltip.hover title="edit tasting"
                    >
                      <font-awesome-icon class="edit-symbol" icon="edit" />
                    </b-button>
                    <b-button pill
                      v-if="tasting.tasting_note_ids.length == 0 && !tasting.fromCellar"
                      class="delete-button"
                      v-on:click.stop="onDelete(index)"
                      v-b-tooltip.hover title="delete tasting"
                    >
                      <font-awesome-icon class="delete-symbol" icon="trash-alt" />
                    </b-button>
                  </b-col>
                </b-row> 
              </b-col>
            </b-row>              
            <br>
            <b-row>
              <b-col  class="text-left" style="margin-left:30px">
                <b-button v-on:click="onBack()" class="w-button-pink">back</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>

    <b-modal id="deleteTasting" ref="deleteTasting" title="delete tasting" @ok="deleteTasting"> 
      <b-row v-if="current_index >=0">
        <div style="width:300px;margin-left:auto;margin-right:auto">
        <b-col
          class="w-tile d-inline-flex justify-content-center"
          style ="margin:20px 0 20px 0!important"
        >
            <div v-if="tastings[current_index].image_files[0]" 
              class="c-image-part" 
              v-bind:style="{'background-image': 'url(' + tastings[current_index].image_files[0].dataURL +')' }"
            >
            </div>
            <div v-else
              class="c-image-part"
            />           
            <div class="c-text-part">
              <p class="c-title">{{tastings[current_index].name}}<span v-if="tastings[current_index].organiser" style="font-weight:normal;font-size: 11px;"> (by {{ tastings[current_index].organiser }})</span></p>
              <p class="c-subtitle">{{tastings[current_index].nice_date}}<span v-if="tastings[current_index].description"> - {{tastings[current_index].description}}</span></p>
              <p class="c-bottles">{{tastings[current_index].tasting_note_ids.length}}</p>
            </div> 
        </b-col>
        </div>
      </b-row>
      <p class="t-title text-center">ARE YOU SHURE YOU WANT TO DELETE THIS TASTING?</p>
      
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>   
  </div>
</template>

<script>
  import formatDate from '../assets/js/formatDate.js';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuDesktop.vue';

  import Tastings from '../Tastings.js'
  import WineLists from '../WineLists.js'
  import Users from '../Users.js'

  export default {
    name: "TastingsOverview",
    components: {
      NoAccess,
      Menu,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      }
    },
    data() {
      return {
        debug: false,
        isLoading : true,

        isValid : true,
        inEdit: false,

        user:{},
        user_id:0,

        tastings:[],

        current_index:-1,
      }
    }, 
    async mounted() {
      let local_debug = false
      if (local_debug || this.debug) console.log("TastingsOverview.mounted: START");

      this.user_id = this.currentUserID;
      this.user = this.currentUser;

      if (typeof this.user.tasting_ids !== "undefined" || this.user.tasting_ids.length !== 0) {
        for (let i=0; i < this.user.tasting_ids.length; i++) {
          if (local_debug || this.debug) console.log("TastingsOverview.mounted: i = ", i, " this.user.tasting_ids[",i,"] = ",this.user.tasting_ids[i])
          await Tastings.getone(this.user.tasting_ids[i])
          .then(response => {
            if (typeof response == "object") {
              response.nice_date = formatDate(new Date(response.date))
              this.tastings.push(response);
              if (local_debug || this.debug) console.log("TastingsOverview.mounted: Tasting ", response.name, " succesfully fetched from DB");
            } else {
              if (local_debug || this.debug) console.log("TastingsOverview.mounted: Tasting ", this.user.tasting_ids[i], " not found in DB");
            }
          })
          .catch(error => {
            if (local_debug || this.debug) console.log("TastingsOverview.mounted: await Tasting i=", i , " ", error);
          })
        }
        this.tastings.sort((a,b)=>(a.date>b.date)?-1:1);
      }

      this.$store.commit('setTastingID',0)
      this.$store.commit('setTasting',{})
      this.$store.commit('setWinelistID',0)
      this.$store.commit('setWinelist',{})
      this.$store.commit('setTastingnoteID',0)
      this.$store.commit('setTastingnote',{})
      this.$store.commit('setItemID',0)
      this.$store.commit('setItem',{})
      this.$store.commit('setBottleID',0)
      this.$store.commit('setBottle',{})
      this.$store.commit('setCWineID',0)
      this.$store.commit('setCWine',{})
      this.$store.commit('setWineID',0)
      this.$store.commit('setWine',{})

      this.isLoading = false;
      if (local_debug || this.debug) console.log("TastingsOverview.mounted: STOP");
      if (local_debug || this.debug) console.log("");
    },
    methods: {
      onSelect(tasting_id,tasting){
        // if ( tasting.fromCellar) {
        //   // alert("CANNOT SEE CELLAR TASTING FOR NOW - WORKING ON IT.")
        //   this.$store.commit('setPage','CellarTastingShow')
        //   this.$router.push({path:'/CellarTastingShow', query: {tasting_id:tasting_id }});
        //   return
        // }
        if ( ! this.inEdit ) {
          this.$store.commit('setPage','TastingShow')
          this.$router.push({path:'/TastingShow', query: {tasting_id:tasting_id }});
        }
      },
      onEdit(tasting_id){
        this.inEdit = false;
        this.$store.commit('setPage','TastingEdit')
        if (typeof tasting_id === "undefined") {
          this.$router.push({path:'/TastingEdit'});
        } else {
          this.$router.push({path:'/TastingEdit', query: {tasting_id:tasting_id }});
        }
      },
      async onDelete(i){
        this.current_index = i
        if (this.debug) console.log("Tasting in onDelete: delete pressed")
        await this.$bvModal.show('deleteTasting')
      },

      async deleteTasting(){
        let local_debug = false;

        let this_winelist = {};
        let this_winelist_id = 0;

        if (local_debug) console.log ("=> TastingsOverview.deleteTasting: START")

        let this_tasting_id = this.tastings[this.current_index]._id

        // 1. Check if tasting is associated with winelist and update accordingly in DB
        // if (typeof this.tastings[this.current_index].winelist_id != "undefined" && this.tastings[this.current_index].winelist_id.length == 24) {
        if (typeof this.tastings[this.current_index].winelist_id == "object") {
          if (local_debug) console.log ("=> TastingsOverview.deleteTasting: tasting apparently comes from winelist ",this.tastings[this.current_index].winelist_id," - analyzing")
          await WineLists.getone(this.tastings[this.current_index].winelist_id)
          .then(response => {
            if (local_debug) console.log ("=> TastingsOverview.deleteTasting: winelist found")
            this_winelist = response
          })
          .catch(error => {
            if (local_debug) console.log('=> TastingsOverview.deleteTasting: catch await WineLists.getone ERROR : ', error);
          })
          let index = this_winelist.tasting_ids.indexOf(this_tasting_id);
          if (index >= 0) {
            if (local_debug) console.log ("=> TastingsOverview.deleteTasting: tasting found in winelist.tasting_ids with index = ", index)
            this_winelist.tasting_ids.splice(index,1);
            if (local_debug) console.log ("=> TastingsOverview.deleteTasting: tasting removed from winelist.tasting_ids")
            this_winelist_id = this_winelist._id
            delete this_winelist._id
            await WineLists.update(this_winelist_id, this_winelist)
            .then(response => {
              if (local_debug) console.log ("=> TastingsOverview.deleteTasting: winelist ",JSON.stringify(this_winelist)," updated with response ", response)
            })
            .catch(error => {
              if (local_debug) console.log('=> TastingsOverview.deleteTasting: catch await WineLists.update ERROR : ', error);
            })
          } else {
            if (local_debug) console.log ("=> TastingsOverview.deleteTasting: tasting NOT found is winelist.tasting_ids")
          }
        } else {
          if (local_debug) console.log ("=> TastingsOverview.deleteTasting: no associated winelist to tasting.")
        }

        // 2. Delete tasting in DB
        await Tastings.delete(this_tasting_id)
        .then(response => {
          if (local_debug) console.log("=> TastingsOverview.deleteTasting: tasting with id ", this_tasting_id, " deleted with response : ", response)
        })
        .catch(error => {
          if (local_debug) console.log('=> TastingsOverview.deleteTasting: catch await Tastings.delete ERROR : ', error);
        })

        // 3. Update user in DB
        let remove_index =this.user.tasting_ids.indexOf(this_tasting_id)
        // console.log("TMP remove_index = ",remove_index," remove id = ", this.user.tasting_ids[remove_index])

        if (remove_index != -1) {
          // console.log("TMP1 this.user.tasting_ids = ",this.user.tasting_ids)
          this.user.tasting_ids.splice(remove_index,1)
          // console.log("TMP2 this.user.tasting_ids = ",this.user.tasting_ids)
          await Users.update(this.user_id,this.user)
          .then(response => {
            if (local_debug) console.log("=> TastingsOverview.deleteTasting: user ", this.user, " updated with response : ", response)
          })
          .catch(error => {
            if (local_debug) console.log('=> TastingsOverview.deleteTasting: catch await Users.update ERROR : ', error);
          })
        } else {
          alert("SEVERE ERROR: remove_index = ", remove_index)
        }
        
        // 2. Update tastings in app
        this.tastings.splice(this.current_index,1);
        this.tastings.sort(function(a, b) {
            if(a.date < b.date) { return 1; }
            if(a.date > b.date) { return -1; }
            return 0;
        });
        this.current_index = -1;

        if (local_debug) console.log ("=> TastingsOverview.deleteTasting: STOP")
      },

      onBack() {
        this.$router.push({path:'/Home', query: {user_id:this.$route.query.user_id}});
        this.$store.commit('setPage','Home')
      },    

      doNothing() {
      },
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.c-image-part {
  background-image: url("../assets/img/tastings.jpg")
}
</style>
<!--  
  This module is shared between WyneCellar and WyneTaster
  For WyneCellar set allowBuy: true
  For WyneTaster set allowBuy: false
-->
<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0" align="center">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
          </b-col>
        </b-row>        
        <b-row>
          <b-col md="10" style="padding:0" align="center">
            <br>
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">wishlist</p>
            <b-row class="justify-content-center">
              <p class="t-title">wishlist of bottles you want to buy</p>
            </b-row>
            <b-row class="justify-content-center">
              <p class="t-subtitle">to select one, click</p>
            </b-row>  
            <b-row class="justify-content-center" style="margin:0">
              <b-col
                  class="w-tile m-2 d-inline-flex justify-content-center"
                  xs="12" sm="6" md="4" lg="2" xl="2"
                  v-on:click="onEdit(0)"
                  style ="display:block; margin:20px !important"
              >
                <b-img
                  width=300px
                  height=200px
                  :src="require('@/assets/img/plus.png')"
                >
                </b-img>
              </b-col>              
              <b-col
                v-for="(bottle, index) in bottles"
                v-bind:item="bottle"
                v-bind:index="index"
                v-bind:key="bottle._id" 
                class="w-tile m-2 d-inline-flex justify-content-center w-cursor-click"
                xs="12" sm="6" md="4" lg="2" xl="2"
                style ="display:block; min-width:300px; margin:20px !important"
              >
                <b-row 
                  style="width:100%"
                  v-on:click="onSelect(index)"
                >
                  <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                    <b-img center v-if="bottle.image_files[0]"
                      style="height:100%;width:auto;position:absolute;right:0px"
                      v-bind:src="bottle.image_files[0].dataURL" 
                    ></b-img>
                    <b-img center v-else
                      style="height:100%;width:auto;position:absolute;right:0px"
                      :src="require('@/assets/img/bottle.png')"
                    ></b-img>
                  </b-col>
                  <!-- <b-col cols="9" class="d-table-cell"> -->
                  <b-col cols="9">  
                    <div style="width:75%">
                      <p class="t-title">{{bottle.vintage}}</p>
                      <p class="t-title" style="margin:0">{{bottle.name}}</p>
                      <p v-if="bottle.type" class="t-subtitle">{{bottle.type}}</p>
                      <p v-else class="t-subtitle" style="color:white">bogus text here</p>
                      <hr style="margin:5px 0 5px 0">
                      <p class="t-text" v-if="bottle.appellation">{{bottle.appellation}}</p>
                      <p class="t-text" v-if="bottle.classification">{{bottle.classification}}</p>
                    </div>
                    <b-button pill
                      style="top:10px"
                      class="delete-button"
                      v-on:click.stop="onDelete(index)"
                      v-b-tooltip.hover title="delete bottle from wishlist"
                    >
                      <font-awesome-icon class="delete-symbol" icon="trash-alt" />
                    </b-button>
                    <b-button pill
                      style="top:48px"
                      class="edit-button"
                      v-on:click.stop="onEdit(bottle._id)"
                      v-b-tooltip.hover title="edit bottle in wishlist"
                    >
                      <font-awesome-icon class="edit-symbol" icon="edit" />
                    </b-button>
                    <!-- <b-button v-if="allowBuy" pill
                      style="top:48px"
                      class="buy-button"
                      v-on:click.stop="onBuy(index)"
                      v-b-tooltip.hover title="buy bottle from wishlist"
                    >
                      <font-awesome-icon class="buy-symbol" icon="arrow-right" />
                    </b-button> -->
                    <div
                      v-if="allowBuy"
                      style="position:absolute;top:168px;right: 10px;height: 28px;width: 28px;"
                      v-on:click.stop="onBuy(index)"
                      v-b-tooltip.hover title="buy bottle from wishlist"
                    >
                      <font-awesome-icon style="font-size:18px;color:rgb(112,112,112)" icon="shopping-cart" />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <br>
          </b-col>
        </b-row> 
        <br>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <Menu/>
          </template>
        </b-sidebar>        
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
    <b-modal id="addEntry" ref="addEntry" title="add wine to ..." @ok="onSave">
      <b-row>
        <b-col
          class="w-tile m-2 d-inline-flex justify-content-center"
          style ="display:block; min-width:300px; margin:20px !important"
        >
          <b-row 
            style="width:100%"
          >
            <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
              <b-img center v-if="current_bottle.image_files.length > 0"
                style="height:100%;width:auto;position:absolute;right:0px"
                v-bind:src="current_bottle.image_files[0].dataURL" 
              ></b-img>
              <b-img center v-else
                style="height:100%;width:auto;position:absolute;right:0px"
                :src="require('@/assets/img/bottle.png')"
              ></b-img>
            </b-col>
            <b-col cols="9" class="d-table-cell">
              <div>
                <p class="t-title">{{current_bottle.name}}</p>
                <p class="t-subtitle" v-if="current_bottle.type">{{current_bottle.type}}</p>
                <hr>
                <p class="t-text" v-if="current_bottle.appellation">{{current_bottle.appellation}}</p>
                <p class="t-text" v-if="current_bottle.classification">{{current_bottle.classification}}</p>
                <p class="t-smalltext" v-if="current_bottle.grapes">{{current_bottle.grapes}}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-container fluid >
          <div style="padding:0 15px 0 15px">
            <b-form autocomplete="off">
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label w-required" for="nicheList">unit</label>        
                    <model-select
                      class="w-select"
                      :options="niches"
                      v-model="niche_id"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.nicheValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    >
                    </model-select>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{nicheFeedback}}
                    </b-form-invalid-feedback>  
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label w-required" for="input-number">number</label>
                    <b-form-spinbutton v-if="!entryExists" 
                      class="w-spinbutton" 
                      id="input-number" 
                      v-model="transaction.number"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
                      aria-describedby="input-live-help input-live-feedback" 
                      min="1" max="1000"
                    >
                    </b-form-spinbutton>
                    <b-form-spinbutton v-else 
                      class="w-spinbutton" 
                      id="input-number" 
                      v-model="transaction.number"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
                      aria-describedby="input-live-help input-live-feedback" 
                      min="1" max="1000"
                      readonly
                    >
                    </b-form-spinbutton>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{numberFeedback}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label w-required" for="input-date">date</label>
                    <b-form-datepicker 
                      class="w-datepicker mb-2" 
                      id="input-date"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                      v-model="transaction.date"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    >                  
                    </b-form-datepicker>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{dateFeedback}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>                     
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label w-required" for="vintageList">vintage</label>         
                    <model-select 
                      id="vintageList"
                      class = "w-select"
                      :options="vintages"
                      v-model="entry.vintage"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.vintageValid}"
                      @input="inputVintage"
                    >
                    </model-select>             
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{vintageFeedback}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-price">price</label>
                    <b-form-input
                      id="input-price"
                      class="w-input"
                      v-model="transaction.price"
                      ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-occasion">occasion</label>
                    <b-form-input
                      id="input-occasion"
                      class="w-input"
                      v-model="transaction.description"
                      ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group style="text-align:left;display: flex">     
                <b-form-checkbox 
                  id="wishlistToggle"
                  v-model="removeFromWishList"
                  style="flex: 1"
                >
                <label class="w-label" for="wishlistToggle">remove from wishlist</label>
                </b-form-checkbox>
              </b-form-group>              
            </b-form>                                  
          </div>
        </b-container>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">
          <b-button @click="ok()" class="w-button-green-full">save</b-button>
        </b-row>   
        <b-row style="width:100%">
          <b-button  @click="cancel()" class="w-button-pink-full">cancel</b-button>
        </b-row>
        <br>
      </template>
    </b-modal>
    <b-modal id="deleteBottle" ref="deleteBottle" title="delete bottle from wishlist" @ok="deleteBottle">
      <b-row>
        <b-col
          class="w-tile m-2 d-inline-flex justify-content-center"
          style ="display:block; margin:20px !important"
        >
          <b-row 
            style="width:100%"
          >
            <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
              <b-img center v-if="current_bottle.image_files.length > 0"
                style="height:100%;width:auto;position:absolute;right:0px"
                v-bind:src="current_bottle.image_files[0].dataURL" 
              ></b-img>
              <b-img center v-else
                style="height:100%;width:auto;position:absolute;right:0px"
                :src="require('@/assets/img/bottle.png')"
              ></b-img>
            </b-col>
            <b-col cols="9" class="d-table-cell">
              <div>
                <p class="t-title">{{current_bottle.vintage}}</p>
                <p class="t-title" style="margin:0">{{current_bottle.name}}</p>
                <p v-if="current_bottle.type" class="t-subtitle" >{{current_bottle.type}}</p>
                  <p v-else class="t-subtitle" style="color:white">bogus text here</p>
                <hr>
                <p class="t-text" v-if="current_bottle.appellation">{{current_bottle.appellation}}</p>
                <p class="t-text" v-if="current_bottle.classification">{{current_bottle.classification}}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <p class="t-title text-center" style="margin-left=20px;margin-right:20px">ARE YOU SHURE YOU WANT TO DELETE THIS BOTTLE FROM YOUR WISHLIST?</p>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">
          <b-button  @click="cancel()" class="w-button-green-full">cancel</b-button>
        </b-row>
        <b-row style="width:100%">
          <b-button @click="ok()" class="w-button-pink-full">delete</b-button>
        </b-row>   
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { ModelSelect } from 'vue-search-select';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuMobile.vue'

  import Users from '../Users.js'
  import WishLists from '../WishLists.js'
  import Bottles from '../Bottles.js'
  import Wines from '../Wines.js'
  import C_Wines from '../C_Wines.js'

  import Cellars from '../Cellars.js'
  import Niches from '../Niches.js'
  import Entries from '../Entries.js'
  import Transactions from '../Transactions.js'

  export default {
    name: "WishList",
    components: {
      NoAccess,
      Menu,
      ModelSelect,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },
    },
    data() {
      return {
        debug: true,
        isLoading : true,

        allowBuy: false,

        wishlist:{},
        wishlist_id:0,

        bottles: [],
        current_bottle: {
          name:'',
          vintage:0,

          wine_id:0,
          c_wine_id:0,

          type:'',
          appellation:'',
          classification:'',
          grapes:'',
          image_files:[],
        },
        current_bottle_id:0,

        removeFromWishList: true,

        // Stuff for the addEntry Modal - if (this.allowBuy)
        cellar_id:0,
        cellar: {},

        niche_id:0,
        niche:{},
        niches:[],

        vintages: [],

        entry: {
            name:'',
            type:'',
            country_id:0,
            country:'',
            region_id:0,
            region:'',
            appellation_id:0,
            appellation:'',
            classification_id:0,
            classification:'',
            sparkling:false,
            color:'',
            grapes:'',
            vines:'',
            yields:'',
            vinification:'',
            image_files:[],
            wine_id:0,
            winery_id:0,
            
            number:0,
            vintage:0,

            cellar_id:0,
            niche_id:0,

            transaction_ids:[],
            tasting_note_ids:[],
        },
        entry_id:0,
        entryExists: false,

        transaction:{
          date:'',
          number:0,
          description:'',
          price:'',
          entry_id:0
        },
        transaction_id:0,

        // Validation Logic
        numberValid : true,
        numberFeedback : '',
        dateValid : true,
        dateFeedback : '',
        vintageValid : true,
        vintageFeedback : '',
        nicheValid: true,
        nicheFeedback: "",

        isValid : true,
      }
    }, 
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log( "WishList.mounted: START")

      this.user_id = this.currentUserID;
      this.user = this.currentUser;

      if (this.debug) console.log( "WishList.mounted: currentUserID = ", this.user_id)
      if (this.debug) console.log( "WishList.mounted: currentUser = ", this.user.email)
      if (this.debug) console.log( "WishList.mounted: this.user.wishlist_id = ", this.user.wishlist_id)
      if (this.debug && typeof this.user.wishlist_id != "undefined") console.log( "WishList.mounted: this.user.wishlist_id.length = ", this.user.wishlist_id.length)

      if ( typeof this.user.wishlist_id != "undefined" && this.user.wishlist_id.length == 24 ){
        this.wishlist_id = this.user.wishlist_id
        await WishLists.getone(this.wishlist_id)
        .then(response=> {
          this.wishlist = response
          delete this.wishlist._id
          if (this.debug) console.log( "WishList.mounted: WishList ", this.wishlist, " with id ",this.wishlist_id," succesfully fetched from DB with response ", response);
        })
        .catch(error=> {
          if (this.debug) console.log("WishList.mounted: await WishLists.getone", error);
        })
      } else {
        if (this.debug) console.log( "WishList.mounted: User ", this.user, " has no wishlist - creating one.")
        this.wishlist.user_id = this.user_id
        this.wishlist.bottle_ids = []
        await WishLists.create(this.wishlist)
        .then(response=> {
          this.wishlist_id = response
          this.user.wishlist_id = this.wishlist_id
          if (this.debug) console.log( "WishList.mounted: WishList ", this.wishlist, " with id ",this.wishlist_id," succesfully created.");
        })
        .catch(error=> {
          if (this.debug) console.log("WishList.mounted: await WishLists.create", error);
        })
        await Users.update(this.user_id, this.user)
        .then(response=> {
          if (this.debug) console.log( "WishList.mounted: User ", this.user, " with id ",this.user_id," succesfully updated with response = ", response);
        })
        .catch(error=> {
          if (this.debug) console.log("WishList.mounted: await Users.update", error);
        })
      }

      for (let i=0 ; i < this.wishlist.bottle_ids.length ; i++) {
        await Bottles.getone(this.wishlist.bottle_ids[i])
        .then(response=> {
          this.bottles.push(response)
          if (this.debug) console.log( "WishList.mounted: Bottle ",response, " with id ",response._id," succesfully fetched from DB with response ", response);
        })
        .catch(error=> {
          if (this.debug) console.log("WishList.mounted: await Bottles.getone", error);
        })
        if (this.bottles[i].wine_id != 0) {
          if (this.debug) console.log("WishList.mounted: wine_id = ", this.bottles[i].wine_id);
          await Wines.getone(this.bottles[i].wine_id)
          .then(response=> {
            this.bottles[i].name = response.name
            this.bottles[i].color = response.color
            if (typeof response.type != "undefined") {
              this.bottles[i].type = response.type
            } else {
              this.bottles[i].type = ''
            }
            if (typeof response.appellation != "undefined") {
              this.bottles[i].appellation = response.appellation
            } else {
              this.bottles[i].appellation = ''
            }
            if (typeof response.classification != "undefined") {
              this.bottles[i].classification = response.classification
            } else {
              this.bottles[i].classification = ''
            }
            this.bottles[i].image_files = []
            if (response.image_files.length > 0) this.bottles[i].image_files[0] = response.image_files[0]
            if (this.debug) console.log( "WishList.mounted: Wine ",response, " with id ",response._id," succesfully fetched from DB with response ", response);
          })
          .catch(error=> {
            if (this.debug) console.log("WishList.mounted: await Wines.getone", error);
          })
        } else if (this.bottles[i].c_wine_id != 0) {
          if (this.debug) console.log("WishList.mounted: c_wine_id = ", this.bottles[i].c_wine_id);
          await C_Wines.getone(this.bottles[i].c_wine_id)
          .then(response=> {
            this.bottles[i].name = response.name
            this.bottles[i].color = response.color
            if (typeof response.type != "undefined") {
              this.bottles[i].type = response.type
            } else {
              this.bottles[i].type = ''
            }
            if (typeof response.appellation != "undefined") {
              this.bottles[i].appellation = response.appellation
            } else {
              this.bottles[i].appellation = ''
            }
            if (typeof response.classification != "undefined") {
              this.bottles[i].classification = response.classification
            } else {
              this.bottles[i].classification = ''
            }
            this.bottles[i].image_files = []
            if (response.image_files.length > 0) this.bottles[i].image_files[0] = response.image_files[0]
            if (this.debug) console.log( "WishList.mounted: C_Wine ",response, " with id ",response._id," succesfully fetched from DB with response ", response);
          })
          .catch(error=> {
            if (this.debug) console.log("WishList.mounted: await Wines.getone", error);
          })
        } else {
          if (this.debug) console.log("WishList.mounted: ERROR this bottle has no wine_id nor C-wine_id.");
        }
        if (this.debug) console.log("WishList.mounted: =============================== ");
        if (this.debug) console.log("WishList.mounted: bottles[",i,"] = ", this.bottles[i]);
        if (this.debug) console.log("WishList.mounted: =============================== ");
      }

      this.$store.commit('setWishlist',this.wishlist)
      this.$store.commit('setWishlistID',this.wishlist_id)

      if (this.allowBuy) {
        // Load the Cellar
        if (this.currentCellarLoaded) {
          if (this.debug) console.log("WishList.mounted: CURRENT CELLAR LOADED")
          this.cellar = this.currentCellar
          this.cellar_id = this.currentCellarID
          if (this.debug) console.log("WishList.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
        }
        else {
          if (this.debug) console.log("WishList.mounted: CURRENT CELLAR NOT LOADED - QUERYING THE DATABASE")
          this.cellar_id = this.user.cellar_id
          await Cellars.getone(this.cellar_id)
          .then(response => {
            this.cellar = response
            delete this.cellar._id
            if (this.debug) console.log("WishList.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully fetched from DB.")
          })
          .catch(error =>{
            if (this.debug) console.log("WishList.mounted: catch Cellar.getone with error: ", error)
          })

          this.$store.commit('setCellar',this.cellar)
          this.$store.commit('setCellarID',this.cellar_id)
          this.$store.commit('setCellarLoaded',true)

          if (this.debug) console.log("WishList.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully stored in store.")
        }

        // Load the niches list
        if (typeof this.cellar.niche_ids.length !== 0) {
          for (let i=0; i < this.cellar.niche_ids.length; i++) {
            await Niches.getone(this.cellar.niche_ids[i])
            .then(response=> {
              if (typeof response == "object") {
                this.niches.push({value:response._id,text:response.code + ' - ' + response.name})
                if (this.debug) console.log("WishList.mounted: Niche ", this.niches[i], " succesfully fetched from DB");
              } else {
                if (this.debug) console.log("WishList.mounted: Niches i=", i , " is not an object");
              }
            })
            .catch(error=> {
              if (this.debug) console.log("WishList.mounted: await Niches i=", i , " ", error);
            })
          }
          if (this.niches.length > 0) {
            this.niches.sort((a,b)=>(a.text>b.text)?1:-1);
          }   
        }

        // Prepare the input fields
        this.init_transaction()
      }

      this.isLoading = false
      if (this.debug) console.log( "WishList.mounted: STOP")
    },
    methods: {
      async onEdit(bottle_id) {
        if (this.debug) console.log("")
        if (this.debug) console.log("WishList.onEdit - START")
        if (this.debug) console.log("WishList.onEdit - bottle_id = ", bottle_id)
        this.current_bottle_id = bottle_id;
        if (bottle_id == 0) {
          this.init();
        } else {
          this.bottle_id = bottle_id
          await Bottles.getone(this.bottle_id)
          .then(response => {
            this.bottle = response
            delete this.bottle._id
            if (this.debug) console.log("WishList.onEdit: Bottle ", this.bottle, " succesfully fetched from DB")
          })
          .catch(error =>{
            if (this.debug) console.log("WishList.onEdit: Bottles.getone failed with ERROR ", error)
          })
          this.c_wine_id = this.bottle.c_wine_id
          this.wine_id = this.bottle.wine_id
          if ( this.c_wine_id !=0 ) {
            await C_Wines.getone(this.c_wine_id)
            .then(response => {
              this.c_wine = response
              delete this.c_wine._id
              if (typeof this.c_wine.sweet == "undefined") {
                this.c_wine.sweet = false
              }              
              if (this.debug) console.log("WishList.onEdit: C_Wine ", this.c_wine, " succesfully fetched from DB")
            })
            .catch(error =>{
              if (this.debug) console.log("WishList.onEdit: C_Wines.getone failed with ERROR ", error)
            })
          }
          if ( this.wine_id !=0 ) {
            alert("This is a wine from the DB.")
            return
          }
        }
        this.$store.commit('setCWine',this.c_wine)
        this.$store.commit('setCWineID',this.c_wine_id)
        this.$store.commit('setBottle',this.bottle)
        this.$store.commit('setBottleID',this.bottle_id)

        this.$store.commit('setPage','BottleEdit')
        this.$router.push({path:'/BottleEdit'});

        if (this.debug) console.log("WishList.onEdit - STOP")
      },

      async onSelect(index) {
        if (this.debug) console.log("")
        if (this.debug) console.log("WishList.onSelect - START")
        if (this.debug) console.log("WishList.onSelect - bottle_id = ", bottle_id)
        if (this.debug) console.log("WishList.onSelect - STOP")
      },

      async onDelete(index) {
        if (this.debug) console.log("")
        if (this.debug) console.log("WishList.onDelete - START")
        if (this.debug) console.log("WishList.onDelete - index = ", index)
        if (this.debug) console.log("WishList.onDelete - bottles[",index,"] = ", this.bottles[index])
        this.current_bottle = this.bottles[index]
        this.current_bottle_id = this.bottles[index]._id;
        await this.$bvModal.show('deleteBottle')        
        if (this.debug) console.log("WishList.onDelete - STOP")
      },

      async deleteBottle() {
        if (this.debug) console.log("")
        if (this.debug) console.log("WishList.deleteBottle - START")
        let i = this.wishlist.bottle_ids.indexOf(this.current_bottle_id)
        this.wishlist.bottle_ids.splice(i,1)
        await WishLists.update(this.wishlist_id,this.wishlist)
        .then(response=>{
          if (this.debug) console.log("========> WishList.deleteBottle: current_bottle_id ",this.current_bottle_id," succesfully removed to wishlist ", this.wishlist, " with wishlist_id ",this.wishlist_id," with response ", response);
        })
        .catch(error=>{
          if (this.debug) console.log("========> WishList.deleteBottle: catch await Wishlist.update ", error);
        })  
        this.bottles.splice(i,1)
        if (this.debug) console.log("WishList.deleteBottle - STOP")
      },

      async onBuy(index) {
        if (this.debug) console.log("")
        if (this.debug) console.log("WishList.onBuy - START")
        if (this.debug) console.log("WishList.onBuy - index = ", index)
        if (this.debug) console.log("WishList.onBuy - bottles[",index,"] = ", this.bottles[index])
        this.current_bottle = this.bottles[index]
        this.current_bottle_id = this.bottles[index]._id;
        this.entry.vintage = this.current_bottle.vintage
        this.niche_id = 0
        this.removeFromWishList = true
        if (this.debug) console.log("WishList.onBuy - current_bottle = ", this.current_bottle)
        this.init_transaction()
        await this.$bvModal.show('addEntry')
        if (this.debug) console.log("WishList.onBuy - STOP")
      },

      async onSave(event){
        if (this.debug) console.log("")
        if (this.debug) console.log("WishList.onSave - START")
        if (this.debug) console.log("WishList.onSave - event = ", event)
        //Reading the form
        this.validate_form()
        if (!this.isValid) {
          if (this.debug) console.log("WineList.onSave: Returning without Save because validation form failed.")
          event.preventDefault()
          return 
        }
        if (this.debug) console.log("WishList.onSave - current_bottle = ", this.current_bottle)

        // Load niche
        await Niches.getone(this.niche_id)
        .then(response => {
          this.niche = response
          delete this.niche._id
          if (this.debug) console.log("WishList.onSave: Niche ",this.niche, " with id ",this.niche_id," succesfully fetched from DB with response ", response);
        })
        .catch(error => {
          if (this.debug) console.log("WishList.onSave: await Niches.getone", error);
        })        

        // Create entry
        if (this.current_bottle.wine_id !=0) {
          await Wines.getone(this.current_bottle.wine_id)
          .then(response => {
            if (this.debug) console.log("WishList.onSave: Wine ",response, " with id ",response._id," succesfully fetched from DB.");
            this.entry.name = response.name
            this.entry.type = response.type
            this.entry.country_id = response.country_id
            this.entry.country = response.country
            this.entry.region_id = response.region_id
            this.entry.region = response.region
            this.entry.appellation_id = response.appellation_id
            this.entry.appellation = response.appellation
            this.entry.classification_id = response.classification_id
            this.entry.classification = response.classification
            this.entry.sparkling = response.sparkling
            this.entry.color = response.color
            this.entry.grapes = response.grapes
            this.entry.vines = response.vines
            this.entry.yields = response.yields
            this.entry.vinification = response.vinification
            this.entry.image_files = response.image_files
            this.entry.wine_id = response._id
            this.entry.c_wine_id = 0
            this.entry.winery_id = response.winery_id
            this.entry.number = this.transaction.number
            // vintage read from form
            this.entry.cellar_id = this.cellar_id
            this.entry.niche_id = this.niche_id
          })
          .catch(error => {
            if (this.debug) console.log("WishList.onSave: await Wines.getone", error);
          })
        } else if (this.current_bottle.c_wine_id !=0) {
          await C_Wines.getone(this.current_bottle.c_wine_id)
          .then(response => {
            if (this.debug) console.log("WishList.onSave: C_Wine ",response, " with id ",response._id," succesfully fetched from DB.");
            this.entry.name = response.name
            this.entry.type = response.type
            this.entry.country_id = response.country_id
            this.entry.country = response.country
            this.entry.region_id = response.region_id
            this.entry.region = response.region
            this.entry.appellation_id = response.appellation_id
            this.entry.appellation = response.appellation
            this.entry.classification_id = response.classification_id
            this.entry.classification = response.classification
            this.entry.sparkling = response.sparkling
            this.entry.color = response.color
            this.entry.grapes = response.grapes
            this.entry.vines = response.vines
            this.entry.yields = response.yields
            this.entry.vinification = response.vinification
            this.entry.image_files = response.image_files
            this.entry.wine_id = 0
            this.entry.winery_id = 0
            this.entry.c_wine_id = response._id
            this.entry.number = this.transaction.number
            // vintage read from form
            this.entry.cellar_id = this.cellar_id
            this.entry.niche_id = this.niche_id
          })
          .catch(error => {
            if (this.debug) console.log("WishList.onSave: await C_Wines.getone", error);
          })
        } else {
          alert("SEVERE ERROR - current_bottle has no wine_id nor c_wine_id!")
          return
        }

        // Check if entry already exists in database 
        await this.getEntry_inNiche(this.entry);
        if (this.debug) console.log("WishList.onSave: entry_id = ", this.entry_id);
        if (this.debug) console.log("WishList.onSave: entry = ", this.entry);
        if ( this.entry_id == 0 ) {
          if (this.debug) console.log("WishList.onSave: Entry does NOT exist - we create it");

          // Updating a transaction from form data
          this.entry.number = this.transaction.number;
          
          // Creating Entry
          await Entries.create(this.entry)
          .then(response=> {
            this.entry_id = response
            if (this.debug) console.log( "WishList.onSave: Entry ", this.entry, " with id ",this.entry_id," succesfully created.");
          })
          .catch(error=> {
            if (this.debug) console.log("WishList.onSave: await Entries.create", error);
          })

          // Adding this.entry_id to the list of this.niche
          if (this.debug) console.log("WishList.onSave: Adding entry to entry_ids from niche ", this.niche.name)       
          this.niche.entry_ids.push(this.entry_id);
          await Niches.update(this.niche_id,this.niche)
          .then(response=>{
            if (this.debug) console.log("WishList.onSave: Niche succesfully added entry : id = ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("WishList.onSave: catch await Niche.update ", error);
          })  
          
          // Adding this.entry_id to the list of this.cellar
          if (this.debug) console.log("WishList.onSave: Adding entry to entry_ids from cellar ", this.cellar.name)
          this.cellar.entry_ids.push(this.entry_id);
          await Cellars.update(this.cellar_id,this.cellar)
          .then(response=>{
            if (this.debug) console.log("WishList.onSave: Cellar succesfully added niche : id = ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("WishList.onSave: catch await Cellar.update ", error);
          })  
        } else {
          if (this.debug) console.log("WishList.onSave: Entry DOES exist - we update it");
          if (this.debug) console.log("WineShow.onSave: Entry DOES exist: id = ", this.entry_id, " ", this.entry);
          this.entry.number = this.entry.number + this.transaction.number
        }

        this.transaction.entry_id = this.entry_id
        await Transactions.create(this.transaction)
        .then(response=> {
          this.transaction_id = response
          if (this.debug) console.log( "WishList.onSave: Transaction ", this.transaction, " with id ",this.transaction_id," succesfully created.");
        })
        .catch(error=> {
          if (this.debug) console.log("WishList.onSave: await Transactions.create", error);
        })

        this.entry.transaction_ids.push(this.transaction_id)
        await Entries.update(this.entry_id, this.entry)
        .then(response=> {
          if (this.debug) console.log( "WishList.onSave: Entry ", this.entry, " with id ",this.entry_id," succesfully updated with response = ", response);
        })
        .catch(error=> {
          if (this.debug) console.log("WishList.onSave: await Entries.update", error);
        })

        this.niche.number_of_bottles = this.niche.number_of_bottles + this.transaction.number
        await Niches.update(this.niche_id, this.niche)
        .then(response=> {
          if (this.debug) console.log( "WishList.onSave: Niche ", this.niche, " with id ",this.niche_id," succesfully updated with response = ", response);
        })
        .catch(error=> {
          if (this.debug) console.log("WishList.onSave: await Niches.update", error);
        })

        await Cellars.update(this.cellar_id, this.cellar)
        .then(response=> {
          if (this.debug) console.log( "WishList.onSave: Cellar ", this.cellar, " with id ",this.cellar_id," succesfully updated with response = ", response);
        })
        .catch(error=> {
          if (this.debug) console.log("WishList.onSave: await Cellars.update", error);
        })

        if (this.removeFromWishList) { 
          let i = this.wishlist.bottle_ids.indexOf(this.current_bottle_id)
          this.wishlist.bottle_ids.splice(i,1)
          await WishLists.update(this.wishlist_id,this.wishlist)
          .then(response=>{
            if (this.debug) console.log("========> WishList.onSave: current_bottle_id ",this.current_bottle_id," succesfully removed to wishlist ", this.wishlist, " with wishlist_id ",this.wishlist_id," with response ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("========> WishList.onSave: catch await Wishlist.update ", error);
          })  
          this.bottles.splice(i,1)
          if (this.debug) console.log("WishList.onSave: i = ",i,"Removed from Wishlist");
        } else {
          if (this.debug) console.log("WishList.onSave: Stays on Wishlist");
        }

        if (this.debug) console.log("WishList.onSave - STOP")
      },

      validate_form(){
        if ( this.transaction.date == '') {
          this.dateValid=false;
          this.dateFeedback='Choose a valid date';
        } else {
          this.dateValid=true;
          this.dateFeedback='';
          // format date yyyy-mm-dd
          let dateArray = this.transaction.date.split("-");
          if (dateArray[1].length == 1) {
            dateArray[1] = "0" + dateArray[1]
          }
          if (dateArray[2].length == 1) {
            dateArray[2] = "0" + dateArray[2]
          }
          this.transaction.date = dateArray[0] + "-" + dateArray[1] + "-" + dateArray[2]
        }

        if ( this.niche_id == 0 ) {
          this.nicheValid = false
          this.nicheFeedback = "select valid niche"
        } else {
          this.nicheValid = true
          this.nicheFeedback = ""
        }

        if( this.transaction.number <= 0) {
            this.numberValid = false;
            this.numberFeedback = "Select valid number"
        } else {
            this.numberValid = true
            this.numberFeedback = ""
        }

        if(this.entry.vintage == 0) {
          this.vintageValid = false;
          this.vintageFeedback = "Select valid vintage"
        } 
        else {
          this.vintageValid = true;
          this.vintageFeedback = ""
        }
        
        this.isValid = this.nicheValid && this.numberValid && this.vintageValid;
      },
      async inputVintage(value) {
        this.entry.vintage = value;
        if (this.debug) console.log("WishList in inputVintage - vintage = ",this.entry.vintage)
      },

      async getEntry_inNiche(entry){    
        let local_debug = false
        if (local_debug) console.log("");
        if (local_debug) console.log("====> WishList.getEntry_inNiche: START");
        if (local_debug) console.log("====> WishList.getEntry_inNiche: entry = ", entry);
        let test_entry_id = 0;
        let test_entry = {};
        for(let i=0; i < this.niche.entry_ids.length; i ++) {
          await Entries.getone(this.niche.entry_ids[i])
          .then(response => {
            test_entry = response;
            test_entry_id = response._id;
            delete test_entry._id
          })
          .catch(error => {
            if (local_debug) console.log("====> WishList.getEntry_inNiche: await Entries.getone ", error);
          })
          if (local_debug) console.log("====> WishList.getEntry_inNiche: testing entry with id = ", test_entry_id);
          if (entry.wine_id != 0) {
            if (test_entry.wine_id === entry.wine_id && test_entry.vintage === entry.vintage) {
              if (local_debug) console.log("====> WishList.getEntry_inNiche: entry.wine_id=",entry.wine_id," entry.vintage=",entry.vintage, " EXISTS");
              this.entry_id = test_entry_id;
              this.entry = test_entry;
              if (local_debug) console.log("====> WishList.getEntry_inNiche: STOP");
              return test_entry_id;
            }
          } else if (entry.c_wine_id != 0) {
            if (test_entry.c_wine_id === entry.c_wine_id && test_entry.vintage === entry.vintage) {
              if (local_debug) console.log("====> WishList.getEntry_inNiche: entry.c_wine_id=",entry.c_wine_id," entry.vintage=",entry.vintage, " EXISTS");
              this.entry_id = test_entry_id;
              this.entry = test_entry;
              if (local_debug) console.log("====> WishList.getEntry_inNiche: STOP");
              return test_entry_id;
            }
          } else {
            if (local_debug) console.log("====> WishList.getEntry_inNiche: SEVERE ERROR - no wine_id or c_wine_id");
            if (local_debug) console.log("====> WishList.getEntry_inNiche: STOP");
            return 0;
          }
        }
        if (local_debug) console.log("====> WishList.getEntry_inNiche: entry = ",entry," entry.vintage=",entry.vintage, " DOES NOT EXISTS");
        if (local_debug) console.log("====> WishList.getEntry_inNiche: STOP");
        return 0;
      },

      init_transaction() {
        var today = new Date();
        var this_year = today.getFullYear();
        for (let i = this_year; i > this_year-100; i--) {
          this.vintages.push({value:i,text:i});
        }
        this.transaction.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        this.transaction.number = 0;
        this.transaction.price = '';
        this.transaction.description = ''
      },

      init() {
        this.bottle = {
          name:'',
          wine_id:0,
          c_wine_id:0,
          vintage:0,
          winelist_ids:[],
          tasting_note_ids:[]
        };
        this.bottle_id=0;

        this.c_wine = {
            name:'',
            type:'',
            country_id:0,
            country:'',
            region_id:0,
            region:'',
            appellation_id:0,
            appellation:'',
            classification_id:0,
            classification:'',
            sparkling:false,
            color:'',
            grapes:'',
            vines:'',
            yields:'',
            vinification:'',
            image_files:[],
        };
        this.c_wine_id = 0;
      },

      doNothing() {
      },
    },
  }       
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

import { API } from './API.js';

import axios from 'axios';

const url = API + '/items/';
const debug = false;

class Items {
    // get items
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(entry=> ({
                        ...entry,
                        name: entry.name
                    }))
                );
                if (debug) console.log("Items.get: data = " + data)
            } catch(err) {
                reject(err);
            }

        })
    }

    // get one entry
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Items.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                console.log('Items.getone: ',error)
            })
    }

    // create entry
    static create(postdata) {
        if (debug) console.log('Items.create - url: ',url)
        if (debug) console.log('Items.create - postdata: ',postdata)
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('Items.create: ',response)
                return response.data._id
            })
            .catch(error => {
                console.log('Items.create: ',error)
            })
    }

    static update(id,postdata) {
        if (debug) console.log('Items.update: id=',id)
        if (debug) console.log('Items.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('Items.update: ',response)
                return id
            })
            .catch(error => {
                console.log('Items.update: ',error)
            })
    }

    // delete entry
    static delete(id) {
        return axios.delete(`${url}${id}`)
    } 
}

export default Items
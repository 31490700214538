<template>
  <div>
    <h1>Testing stuff</h1>
    <p>{{niche}}</p>
    <p>{{transaction}}</p>
    <b-row>
      <b-col sm="4">
        <b-list-group class="align-items-center">
          <b-list-group-item active
          >
            Some text here 1
          </b-list-group-item>
          <b-list-group-item
          >
            Some text text here 2
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col sm="8" style="background-color:red">
        <p class="main-title">Something here</p>
      </b-col>
    </b-row>  
  </div>
</template>

<script>
  import Niches from '../Niches.js'
  import Transactions from '../Transactions.js' 
  export default {
    name: "Test",
    components: {
    },
    computed : {
    },
    data() {
      return {
        debug : true,
        
        transaction:{
          name:'1234',
          field:'Something',
        },
        transaction_id:0,

        niche: {
            name:'1234',
            code:'',
            description:'',
            cellar_id:0,
            entry_ids:[],
            image_files: [],
            number_of_bottles:0,
        },
        niche_id:0,
      }
    }, 
    async mounted() {
      //Creating a niche from form data
      console.log("TMP this.niche = ", this.niche)
      await Niches.create(this.niche)
      .then(response=>{
        this.niche_id = response;
        console.log("Test in mounted() : Niche succesfully created : id = ", response);
      })
      .catch(error=>{
        console.log("Test in mounted(): catch await Niche.create ERROR :", error);
      })


      //Creating a transaction from form data
      console.log("TMP this.transaction = ", this.transaction)
      await Transactions.create(this.transaction)
      .then(response=>{
        this.transaction_id = response;
        console.log("Test in mounted() : Transaction succesfully created : id = ", response);
      })
      .catch(error=>{
        console.log("Test in mounted(): catch await Transaction.create ERROR :", error);
      })

    },
    methods: {
    },
  }    
</script>
<style>
@import url("../assets/styles/wynestyle.css");
</style>
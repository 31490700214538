import { render, staticRenderFns } from "./TastingsOverview_Desktop.vue?vue&type=template&id=62d6fb76&scoped=true&"
import script from "./TastingsOverview_Desktop.vue?vue&type=script&lang=js&"
export * from "./TastingsOverview_Desktop.vue?vue&type=script&lang=js&"
import style0 from "./TastingsOverview_Desktop.vue?vue&type=style&index=0&id=62d6fb76&prod&lang=css&"
import style1 from "./TastingsOverview_Desktop.vue?vue&type=style&index=1&id=62d6fb76&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62d6fb76",
  null
  
)

export default component.exports
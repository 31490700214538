<template>
   <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
      <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>

      <b-container fluid v-if="this.inSession">
        <div class="e-box" style="height:740px;padding:0 !important;margin-left:0 !important;margin-right:0 !important">
          <b-row>
            <b-col cols="2" class="text-left" style="padding-right:0">
              <b-img v-if="leftVisible"
                class="w-cursor-click"
                width=30px
                height=30px
                :src="require('@/assets/img/left.png')"
                @click="onLeft"
              >
              </b-img>
            </b-col>                
            <b-col cols="8" class="text-center" style="padding-right:0;padding-left:0">
              <p v-if="this.pageCount == 0" 
                class="current-page w-cursor-default"
              >
                <b>wine</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onWine"
              >
                wine -
              </p>

              <p v-if="this.pageCount == 1" 
                class="current-page w-cursor-default"
              >
                <b>purchase</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onPurchase"
              >
                purchase -
              </p>

              <p v-if="this.pageCount == 2" 
                class="current-page w-cursor-click"
              >
                <b>detail</b> -
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onDetail"
              >
                detail -
              </p>

              <p v-if="this.pageCount == 3" 
                class="current-page w-cursor-click"
              >
                <b>pictures</b>
              </p>
              <p v-else 
                class="w-page w-cursor-click"
                @click="onPictures"
              >
                pictures
              </p>

              <p class="w-smalltext" style="text-align:center;">page {{this.pageCount+1}}/{{this.maxPage+1}}</p>
            </b-col>
            <b-col cols="2" class="text-right" style="padding-left:0">
              <b-img v-if="rightVisible"
                class="w-cursor-click"
                width=30px
                height=30px
                :src="require('@/assets/img/right.png')"
                @click="onRight"
              >
              </b-img>
            </b-col>
            <br>                
          </b-row> 
          <b-form autocomplete="off">
            <div v-if="pageCount == 0" class="page">
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <b-row>
                      <b-col>  
                        <label class="w-label w-required" for="input-name">name</label>
                      </b-col>
                      <b-col class="text-right">
                        <label class="w-error" v-if="!this.nameValid">{{nameFeedback}}</label>
                      </b-col>
                    </b-row>
                    <b-form-input
                      id="input-name"
                      class="w-input"
                      v-model="entry.name"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.nameValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-type">type</label>
                    <b-form-input
                      id="input-type"
                      class="w-input"
                      v-model="entry.type"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.typeValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                    {{typeFeedback}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <b-row>
                      <b-col>  
                        <label class="w-label w-required" for="vintageList">vintage</label>
                      </b-col>
                      <b-col class="text-right">
                        <label class="w-error" v-if="!this.vintageValid">{{vintageFeedback}}</label>
                      </b-col>
                    </b-row>   
                    <model-select 
                      id="vintageList"
                      class="w-select"
                      :options="vintages"
                      v-model="entry.vintage"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.vintageValid}"
                      @input="inputVintage"
                    >
                    </model-select>             
                  </b-form-group>
                </b-col>
              </b-row>
              <div v-if="!freeFormat">
                <b-row>
                  <b-col>
                    <b-form-group style="text-align:left">
                      <b-row>
                        <b-col>  
                          <label class="w-label w-required" for="countryList">country</label>
                        </b-col>
                        <b-col class="text-right">
                          <label class="w-error" v-if="!this.countryValid">{{countryFeedback}}</label>
                        </b-col>
                      </b-row>      
                      <model-select
                        class="w-select"
                        :options="countries"
                        v-model="entry.country_id"
                        v-bind:class="{'form-control':true, 'is-invalid' : !this.countryValid}"
                        @input="inputCountry"
                      >
                      </model-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="regionList">region</label>        
                      <model-select
                        class="w-select"
                        :options="regions"
                        :isDisabled= !region_isActive
                        v-model="entry.region_id"
                        @input="inputRegion"
                      >
                      </model-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="appellationList" v-if="c_wine.country=='Italia'">denominazione</label>
                      <label class="w-label" for="appellationList" v-else-if="c_wine.country=='Espagna'">denominación</label>
                      <label class="w-label" for="appellationList" v-else-if="c_wine.country=='Deutschland'">einzellage	- großlage</label>
                      <label class="w-label" for="appellationList" v-else-if="c_wine.country=='USA'">AVA</label>
                      <label class="w-label" for="appellationList" v-else>appellation</label>        
                      <model-select
                        class="w-select"
                        :options="appellations"
                        :isDisabled = !appellation_isActive
                        v-model="entry.appellation_id"
                        @input="inputAppellation"
                      >
                      </model-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="classificationList">classification</label>        
                      <model-select
                        class="w-select"
                        :options="classifications"
                        :isDisabled = !classification_isActive
                        v-model="entry.classification_id"
                        @input="inputClassification"
                      >
                      </model-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <b-row>
                  <b-col style="position:relative">
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-country" style="font-weight:bold; color:rgb(109,122,82) !important">country - free format</label>
                      <b-form-input
                        id="input-country"
                        class="w-input"
                        v-model="entry.country"
                        v-bind:class="{'form-control':true, 'is-invalid' : !this.countryValid}"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{countryFeedback}}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <div style="position:absolute;top:32px;right:16px">
                      <b-button 
                        class="inside-button"
                        v-on:click="noFreeFormat()"
                      >
                        back
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-region" style="font-weight:bold; color:rgb(109,122,82) !important">region - free format</label>
                      <b-form-input
                        id="input-region"
                        class="w-input"
                        v-model="entry.region"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-appellation" style="font-weight:bold; color:rgb(109,122,82) !important">appellation - free format</label>
                      <b-form-input
                        id="input-appellation"
                        class="w-input"
                        v-model="entry.appellation"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                    </b-form-group>       
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>                
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-classification" style="font-weight:bold; color:rgb(109,122,82) !important">classification - free format</label>
                      <b-form-input
                        id="input-classification"
                        class="w-input"
                        v-model="entry.classification"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                    </b-form-group>     
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col cols="6">
                  <b-form-group style="text-align:left">
                    <b-row>
                      <b-col cols="4" style="padding-right:0">  
                        <label class="w-label w-required" for="colorList">color</label>
                      </b-col>
                      <b-col cols="8" class="text-right" style="padding-left:0">
                        <label class="w-error" v-if="!this.colorValid">{{colorFeedback}}</label>
                      </b-col>
                    </b-row>  
                    <model-select
                      class="w-select"
                      :options="colors"
                      v-model="color_id"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.colorValid}"
                      @input="inputColor"
                    >
                    </model-select>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group style="text-align:left;display: flex;position:relative;top:15px">   
                    <b-form-checkbox 
                      id="sparklingToggle"
                      v-model="c_wine.sparkling"
                      style="flex: 1;top:10px"
                    >
                    <label class="w-label" for="sparklingToggle">sparkling</label>
                    </b-form-checkbox>
                    <b-form-checkbox 
                      id="sweetToggle"
                      v-model="c_wine.sweet"
                      style="flex: 2"
                    >
                    <label class="w-label" for="sweetToggle">sweet</label>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div v-if="pageCount == 1" class="page">
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-occasion">occasion</label>
                    <b-form-input
                      id="input-occasion"
                      class="w-input"
                      v-model="transaction.description"
                      ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <b-row>
                      <b-col>  
                        <label class="w-label w-required" for="input-number">number</label>
                      </b-col>
                      <b-col class="text-right">
                        <label class="w-error" v-if="!this.numberValid">{{numberFeedback}}</label>
                      </b-col>
                    </b-row>
                    <b-form-spinbutton v-if="this.entry_id==0" 
                      class="w-spinbutton" 
                      id="input-number" 
                      v-model="transaction.number"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
                      aria-describedby="input-live-help input-live-feedback" 
                      min="1" max="1000"
                    >
                    </b-form-spinbutton>
                    <b-form-spinbutton v-else 
                      class="w-spinbutton" 
                      id="input-number" 
                      v-model="transaction.number"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.numberValid}"
                      aria-describedby="input-live-help input-live-feedback" 
                      min="1" max="1000"
                      readonly
                    >
                    </b-form-spinbutton>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <b-row>
                      <b-col>  
                        <label class="w-label w-required" for="input-date">date</label>
                      </b-col>
                      <b-col class="text-right">
                        <label class="w-error" v-if="!this.dateValid">{{dateFeedback}}</label>
                      </b-col>
                    </b-row>
                    <b-form-datepicker 
                      class="w-datepicker mb-2" 
                      id="input-date"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }" 
                      v-model="transaction.date"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
                      aria-describedby="input-live-help input-live-feedback"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>                     
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group style="text-align:left">
                    <label class="w-label" for="input-price">price</label>
                    <b-form-input
                      id="input-price"
                      class="w-input"
                      v-model="transaction.price"
                      ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>                            
            </div>
            <div v-if="pageCount == 2" class="page">
              <b-form-group style="text-align:left">
                <label class="w-label" for="input-grapes">grapes</label>
                <b-form-input
                  id="input-grapes"
                  class="w-input"
                  v-model="entry.grapes"
                  aria-describedby="input-live-help input-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                {{grapesFeedback}}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group style="text-align:left">
                <label class="w-label" for="input-longevity">longevity</label>
                <b-form-input
                  id="input-longevity"
                  class="w-input"
                  v-model="entry.longevity"
                ></b-form-input>
              </b-form-group>

              <b-form-group style="text-align:left">
                <label class="w-label" for="input-vines">vines</label>
                <b-form-input
                  id="input-vines"
                  class="w-input"
                  v-model="entry.vines"
                ></b-form-input>
              </b-form-group>

              <b-form-group style="text-align:left">
                <label class="w-label" for="input-yields">yields</label>
                <b-form-input
                  id="input-yields"
                  class="w-input"
                  v-model="entry.yields"
                ></b-form-input>
              </b-form-group>

              <b-form-group style="text-align:left">
                <label class="w-label" for="input-vinification">vinification</label>
                <b-form-input
                  id="input-vinification"
                  class="w-input"
                  v-model="entry.vinification"
                ></b-form-input>
              </b-form-group>
            </div>
            <div v-if="pageCount == 3" class="page" style="height:350px">
              <div>
                <b-row class="justify-content-center">
                  <b-col cols="4" style="height:48vw;width:16vw">                
                    <div v-if="entry.image_files[0]" class="bottle-box" style="height:99%;border-style:none;position:relative"> 
                      <b-img  
                        v-bind:src="entry.image_files[0].dataURL"
                        style="height:100%"
                      >
                      </b-img>
                      <div v-on:click="onDeletePicture(0)" class="delete-picture">
                        <b-img
                          :src="require('@/assets/img/delete-picture.png')"
                          style="width:100%;height:100%;border-radius:50%;border-style:none"
                        ></b-img>
                      </div>
                    </div>
                    <div v-else class="bottle-box" style="height:99%;border-style:none;position:relative">
                      <b-img
                        :src="require('@/assets/img/bottle.png')"
                        style="height:100%"
                      >
                      </b-img>
                    </div>
                  </b-col>
                  <b-col cols="4" style="height:48vw;width:16vw">               
                    <div v-if="entry.image_files[1]" class="bottle-box" style="height:99%;border-style:none;position:relative"> 
                      <b-img  
                        v-bind:src="entry.image_files[1].dataURL"
                        style="height:100%"
                      >
                      </b-img>
                      <div v-on:click="onDeletePicture(1)" class="delete-picture">
                        <img
                          :src="require('@/assets/img/delete-picture.png')"
                          style="height:100%;border-radius:50%;border-style:none"
                        />
                      </div>
                    </div>
                    <div v-else class="bottle-box" style="height:99%;border-style:none;position:relative">
                      <b-img
                        :src="require('@/assets/img/bottle.png')"
                        style="height:100%"
                      >
                      </b-img>
                    </div>
                  </b-col>
                  <b-col cols="4" style="height:48vw;width:16vw">             
                    <div v-if="entry.image_files[2]" class="bottle-box" style="height:99%;border-style:none;position:relative"> 
                      <b-img  
                        v-bind:src="entry.image_files[2].dataURL"
                        style="height:100%"
                      >
                      </b-img>
                      <div v-on:click="onDeletePicture(2)" class="delete-picture">
                        <img
                          :src="require('@/assets/img/delete-picture.png')"
                          style="width:100%;height:100%;border-radius:50%;border-style:none"
                        />
                      </div>
                    </div>
                    <div v-else class="bottle-box" style="height:99%;border-style:none;position:relative">
                      <b-img
                        :src="require('@/assets/img/bottle.png')"
                        style="height:100%"
                      >
                      </b-img>
                    </div>
                  </b-col>  
                </b-row>
                <vue-dropzone 
                  id="imgDropzone" ref="imgDropzone" class="winedropzone" 
                  :options="dropzoneOptions"
                  @vdropzone-thumbnail="vfileAdded" 
                >
                </vue-dropzone>  
              </div>
            </div>
          </b-form>
          <b-row v-if="ready4Save"> 
            <b-col class="text-right" style="position:absolute; right: 5px; bottom:-10px; padding-left:0;padding-right:0">
              <b-button v-on:click="onSave()" class="w-button-green-full">save</b-button>
            </b-col>
          </b-row>
          <b-row v-else> 
            <b-col class="text-right" style="position:absolute; right: 5px; bottom:-10px; padding-left:0;padding-right:0">
              <b-button class="w-button-green-full">
                saving 
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </b-button>
            </b-col>
          </b-row>              
        </div>  
      </b-container>
    </div>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';

  import { ModelSelect } from 'vue-search-select';
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';

  import Countries from '../Countries.js'
  import Regions from '../Regions.js'
  import Geographies from '../Geographies.js';
  import Classifications from '../Classifications.js'

  import WishLists from '../WishLists.js';
  import Bottles from '../Bottles.js';
  import C_Wines from '../C_Wines.js'; 

  export default {
    name: "FullDefine",
    components: {
      ModelSelect,
      vueDropzone: vue2Dropzone
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },  
      WishlistID() {
        return this.$store.state.wishlist_id
      },
      Wishlist() {
        return this.$store.state.wishlist
      },
      Bottle() {
        return this.$store.state.bottle
      },
      BottleID() {
        return this.$store.state.bottle_id
      },
      CWine() {
        return this.$store.state.c_wine
      },
      CWineID() {
        return this.$store.state.c_wine_id
      },
    },
    data() {
      return {
        debug: false,
        
        isLoading: true,

        countries: [],
        regions: [],
        appellations:[],
        classifications:[],
        vintages: [],
        colors:[{text:'Red',value:0},{text:'White',value:1},{text:'Rosé',value:2}],
        color_id:'',

        geographies: [],
        current_geography:{},

        region_isActive: false,
        appellation_isActive: true,
        classification_isActive: false,

        freeFormat:false,
        
        // Validation Logic
        nameValid : true,
        nameFeedback : '',
        typeValid : true,
        typeFeedback : '',
        vintageValid : true,
        vintageFeedback : '',
        vinesValid : true,
        vinesFeedback : '',
        grapesValid : true,
        grapesFeedback : '',
        numberValid : true,
        numberFeedback : '',
        dateValid : true,
        dateFeedback : '',
        countryValid : true,
        countryFeedback : '',
        colorValid : true,
        colorFeedback : '',

        isValid: true,

        // Local objects
        wishlist: {},
        wishlist_id: 0,

        bottle:{
          name:'',
          wine_id:0,
          c_wine_id:0,
          vintage:0,
          winelist_ids:[],
          tasting_note_ids:[]
        },
        bottle_id:0,

        c_wine: {
            name:'',
            type:'',
            country_id:0,
            country:'',
            region_id:0,
            region:'',
            appellation_id:0,
            appellation:'',
            classification_id:0,
            classification:'',
            sparkling:false,
            sweet:false,
            color:'',
            grapes:'',
            vines:'',
            longevity:'',
            yields:'',
            vinification:'',
            image_files:[],
            user_id:0,
        },
        c_wine_id:0,

        // dropzone
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : '',
          maxFilesize: 20,
        },

        // paging
        pageCount:0,
        maxPage:2,
        leftVisible:false,
        rightVisible:true,

        // saving
        ready4Save : true,
      }
    }, 

    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("FullDefine.mounted: START")

      // Read the STORE
      this.wishlist = this.Wishlist;
      this.wishlist_id = this.WishlistID;
      if (this.debug) console.log("=====> FullDefine.mounted: store read wishlist =", this.wishlist, "wishlist_id =", this.wishlist_id);

      this.bottle = this.Bottle;
      this.bottle_id = this.BottleID;
      if (this.debug) console.log("=====> FullDefine.mounted: store read bottle =", this.bottle, "bottle_id =", this.bottle_id);

      this.c_wine = this.CWine;
      this.c_wine_id = this.CWineID;
      if (this.debug) console.log("=====> FullDefine.mounted: store read c_wine =", this.c_wine, "c_wine_id =", this.c_wine_id);

      // Fill the vintage list
      var today = new Date();
      var this_year = today.getFullYear();
      for (let i = this_year; i > this_year-100; i--) {
        this.vintages.push({value:i,text:i.toString()});
      }
      this.vintages.push({value:"-",text:"no vintage"});
      
      await Geographies.get()
      .then(response=>{
        for (let i = 0 ; i < response.length ; i++) {
          this.geographies.push(response[i])
        }
        if (this.debug) console.log("FullDefine.mounted: Geographies ", response, " succesfully fetched from DB");
      })
      .catch(error=>{
        if (this.debug) console.log("FullDefine.mounted: catch await Geographies.get() - error : ", error);
      })
      
      this.region_isActive = false;
      this.appellation_isActive = false;
      this.classification_isActive = false;      
            
      if (this.debug) console.log('FullDefine.mounted: Checking Bottle');
      if (typeof this.c_wine != 'undefined' && this.c_wine_id != 0) {
        //c_wine EXISTS
        if (this.debug) console.log('=====> FullDefine.mounted: c_wine ', this.c_wine, ' with c_wine_id ' + this.c_wine_id + ' found in STORE');
        try {
            if (this.c_wine.appellation_id !=0) {
              await Geographies.getone(this.c_wine.appellation_id)
              .then(response => {
                this.current_geography = response
                if (this.debug) console.log("=====> FullDefine.mounted: appellation current_geography is ", response)
              })
              .catch(error =>{
                if (this.debug) console.log("=====> FullDefine.mounted: Geography.get failed with ERROR ", error)
              })
            } else  if (this.c_wine.region_id !=0) {
              await Geographies.getone(this.c_wine.region_id)
              .then(response => {
                this.current_geography = response
                if (this.debug) console.log("=====> FullDefine.mounted: region current_geography is ", response)
              })
              .catch(error =>{
                if (this.debug) console.log("=====> FullDefine.mounted: Geography.get failed with ERROR ", error)
              })
            } else  if (this.c_wine.country_id !=0) {
              await Geographies.getone(this.c_wine.country_id)
              .then(response => {
                this.current_geography = response
                if (this.debug) console.log("=====> FullDefine.mounted: country  current_geography is ", response)
              })
              .catch(error =>{
                if (this.debug) console.log("=====> FullDefine.mounted: Geography.get failed with ERROR ", error)
              })
            } else {
              if (this.debug) console.log('=====> FullDefine.mounted: Freeformat is on!');
              this.freeFormat = true;
            }

            // Country of existing c_wine
            for (let i = 0 ; i < this.geographies.length ; i++) {
              if (this.geographies[i].level == 1) {
                this.countries.push({value:this.geographies[i]._id,text:this.geographies[i].name})
              }
            }
            if (this.countries.length > 0) {
              this.countries.sort((a,b)=>(a.text>b.text)?1:-1);
            }

            this.countries.push({value:"Another Country",text:"Another Country"})

            // Region of existing c_wine
            this.regions = [];
            for ( let i = 0 ; i < this.geographies.length ; i++ ) {
              if (this.geographies[i].level == 2) {
                if ( this.geographies[i].country_id == this.c_wine.country_id ) {
                  if (this.debug) console.log("=====> FullDefine.mounted: regions of current country - ", this.geographies[i].name)
                  this.regions.push({value:this.geographies[i]._id,text:this.geographies[i].name})
                }
              }
            }
            if (this.regions.length > 0) {
              this.region_isActive = true;
              this.regions.sort((a,b)=>(a.text>b.text)?1:-1);
            }           

            // Appellation of existing c_wine
            this.appellations = [];
            for ( let i = 0 ; i < this.geographies.length ; i++ ) {
              if (this.geographies[i].level == 3) {
                if ( this.c_wine.region_id !=0 ) {
                  if ( this.geographies[i].region_id == this.c_wine.region_id ) {
                    this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
                  }
                } else {
                  if ( this.geographies[i].country_id == this.c_wine.country_id ) {
                    this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
                  }
                }
              }     
            }    
            if (this.appellations.length > 0) {
              this.appellation_isActive = true;
              this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
            }              

            // Classification of existing c_wine
            this.classifications = [];
            if (this.current_geography.classification_ids.length > 0) {
              for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i++ ) {
                await Classifications.getone(this.current_geography.classification_ids[i])
                .then(response => {
                  if (typeof response != "undefined") {
                    if (this.debug) console.log("=====> FullDefine.mounted: classification exists with response ", response)
                    this.classifications.push({value:response._id,text:response.name})
                  } else {
                    if (this.debug) console.log("=====> FullDefine.mounted: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
                  }
                })
                .catch(error => {
                  if (this.debug) console.log("=====> FullDefine.mounted: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
                })
              }
            }
            this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);

            if (this.classifications.length > 0) {
              this.classification_isActive = true
              if ( this.classifications.length == 1) {
                this.c_wine.classification_id = this.classifications[0].value
              }
            } else {
              this.classification_isActive = false
            }
            
            for (let i = 0 ; i < this.colors.length ; i ++) {
              if ( this.colors[i].text == this.entry.color) {
                this.color_id = i
              }
            }              
        } catch(error) {
          if (this.debug) console.log('=====> FullDefine.mounted: c_wine ' +  this.c_wine_id + ' NOT found in DB - ERROR ', error);
        }
      } else {
        // this.init_crac();
        this.initCountries();
      }

      this.isLoading = false;

      if (this.debug) console.log("=====> FullDefine.mounted: STOP this.bottle = ", this.bottle)
      if (this.debug) console.log("=====> FullDefine.mounted: STOP")
    },

    methods:{
      initCountries() {
        this.countries=[]
        this.regions=[]
        this.appellations=[]
        this.classifications=[]
        for (let i = 0 ; i < this.geographies.length ; i++) {
          if (this.geographies[i].level == 1) {
            this.countries.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
        }
        this.region_isActive = false;
        this.appellation_isActive = false;
        this.classification_isActive = false;  
        
        this.countries.push({value:"Another Country",text:"Another Country"})        

      },
      // init_crac() {
      //   /* Init Countries - Regions - Applelations - Classifications */
      //   this.countries=[]
      //   this.regions=[]
      //   this.appellations=[]
      //   this.classifications=[]
      //   for (let i = 0 ; i < this.geographies.length ; i++) {
      //     if (this.geographies[i].level == 1) {
      //       this.countries.push({value:this.geographies[i]._id,text:this.geographies[i].name})
      //     }
  
      //     if (this.geographies[i].level == 3) {
      //       this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
      //     }
      //   }

      //   if (this.countries.length > 0) {
      //     this.countries.sort((a,b)=>(a.text>b.text)?1:-1);
      //   }

      //   this.region_isActive = false;
      //   this.appellation_isActive = true;
      //   this.classification_isActive = false;  
        
      //   this.countries.push({value:"Another Country",text:"Another Country"})
      // },

      async inputVintage(value) {
        this.bottle.vintage = value;
        if (this.debug) console.log("=====> FullDefine.inputVintage - vintage = ",this.bottle.vintage)
      },

      async inputCountry(value) {
        if (this.debug) console.log("");        
        if (this.debug) console.log("=====> FullDefine.inputCountry: START");

        this.c_wine.country_id = value;
        if (this.c_wine.country_id == "Another Country") {
          this.freeFormat = true;
          this.c_wine.country_id = 0
          this.c_wine.country = ""
          this.c_wine.region_id = 0
          this.c_wine.region = ""
          this.c_wine.appellation_id = 0
          this.c_wine.appellation = ""
          this.c_wine.classification_id = 0
          this.c_wine.classification = ""
          return
        }  

        for (let i=0; i< this.countries.length; i++) {
          if (this.countries[i].value === value) {
            this.c_wine.country = this.countries[i].text;
          }
        }

        if (this.debug) console.log("=====> FullDefine.inputCountry: country_id = ",this.c_wine.country_id);
        if (this.debug) console.log("=====> FullDefine.inputCountry: country = ",this.c_wine.country);

        this.regions =[];
        this.c_wine.region_id='';

        this.appellations =[];
        this.c_wine.appellation_id='';

        // Adapt regions
        this.regions = [];
        this.appellations =[];
        for(let i = 0; i < this.geographies.length ; i++ ) {
          if (this.geographies[i]._id == value) {
            this.current_geography = this.geographies[i];
          }
          if (this.geographies[i].level == 2 && this.geographies[i].country_id == value)  {
            this.regions.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
          if (this.geographies[i].level == 3 && this.geographies[i].country_id == value)  {
            this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
        }

        if (this.debug) console.log( "=====> FullDefine.inputCountry: Regions from Country ", this.c_wine.country," ", this.regions, " succesfully found");
        if (this.debug) console.log( "=====> FullDefine.inputCountry: Appellations from Country ", this.c_wine.country," ", this.appellations, " succesfully found");
      
        if (this.regions.length > 0 ) {
          this.regions.sort((a,b)=>(a.text>b.text)?1:-1);
          this.region_isActive = true
        } else {
          this.region_isActive = false
        }

        if (this.appellations.length > 0) {
          this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
          this.appellation_isActive = true;
        } else {
          this.appellation_isActive = false;
        }

        this.classifications =[];
        this.c_wine.classification_id='';

        // Adapt classifications
        this.classifications = []
        for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i ++) {
          await Classifications.getone(this.current_geography.classification_ids[i])
          .then(response => {
            if (typeof response != "undefined") {
              if (this.debug) console.log("=====> FullDefine.inputCountry: classification exists with response ", response)
              this.classifications.push({value:response._id,text:response.name})
            } else {
              if (this.debug) console.log("=====> FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
            }

          })
          .catch(error => {
            if (this.debug) console.log("=====> FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
          })
        }
        this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);
            
        if (this.classifications.length > 0) {
          this.classification_isActive = true
        } else {
          this.classification_isActive = false
        }        
        
        if (this.debug) console.log("=====> FullDefine.inputCountry: STOP");
      },

      async inputRegion(value) {
        let local_debug = false
        if (this.debug) console.log("")
        if (this.debug) console.log("FullDefine in inputRegion: START")

        this.c_wine.region_id = value;
        for (let i=0; i< this.regions.length; i++) {
          if (this.regions[i].value === value) {
            this.c_wine.region = this.regions[i].text;
          }
        }

        if (this.debug) console.log("FullDefine in inputRegion: region_id = ",this.c_wine.region_id)
        if (this.debug) console.log("FullDefine in inputRegion: region = ",this.c_wine.region)

        this.appellations = [];
        this.c_wine.appellation_id = '';

        if(typeof this.c_wine.region_id !== 'undefined') {  
          // Adapt regions
          for(let i = 0; i < this.geographies.length ; i++ ) {
            if (this.geographies[i]._id == value) {
              this.current_geography = this.geographies[i];
            }
            if (this.geographies[i].level == 3 && this.geographies[i].region_id == value)  {
              this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
            }
          }

          if (this.debug) console.log( "FullDefine in inputRegion: Appellations from Region ", this.c_wine.region," ", this.appellations, " succesfully found");
      
          if (this.appellations.length > 0 ) {
            this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
            this.appellation_isActive = true
          } else {
            this.appellation_isActive = false
          }

          this.classifications = [];
          this.c_wine.classification_id = 0;

          // Add classifications
          this.classifications = []
          if ((typeof this.current_geography.classification_ids != "undefined") && (this.current_geography.classification_ids.length > 0)) {
            for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i ++) {
              await Classifications.getone(this.current_geography.classification_ids[i])
              .then(response => {
                if (typeof response != "undefined") {
                  if (this.debug || local_debug) console.log("=====> FullDefine.inputCountry: classification exists with response ", response)
                  this.classifications.push({value:response._id,text:response.name})
                } else {
                  if (this.debug || local_debug) console.log("=====> FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
                }

              })
              .catch(error => {
                if (this.debug || local_debug) console.log("=====> FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
              })
            }
            this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);
          }
              
          if (this.classifications.length > 0) {
            this.classification_isActive = true
          } else {
            this.classification_isActive = false
          }          

          if (this.debug) console.log("FullDefine in inputRegion: STOP")
        }  
      },

      async inputAppellation(value) {
        let local_debug = false
        if (this.debug) console.log("")
        if (this.debug) console.log("FullDefine in inputAppellation: START")

        this.c_wine.appellation_id = value;
        // for (let i = 0; i < this.appellations.length; i++) {
        //   if (this.appellations[i].value == value ) {
        //     this.c_wine.appellation = this.appellations[i].text
        //   }
        // }

        for(let i = 0; i < this.geographies.length ; i++ ) {
          if (this.geographies[i]._id == value) {
            this.current_geography = this.geographies[i];
          }
        }

        if (this.debug) console.log("FullDefine in inputAppellation: appellation_id = ",this.c_wine.appellation_id)
        if (this.debug) console.log("FullDefine in inputAppellation: appellation = ",this.c_wine.appellation)
        
        if (this.c_wine.country_id == 0) {
          this.c_wine.country_id = this.current_geography.country_id
          for(let i = 0; i < this.geographies.length ; i++ ) {
            if (this.geographies[i].level == 2 && this.geographies[i].country_id == this.c_wine.country_id)  {
              this.regions.push({value:this.geographies[i]._id,text:this.geographies[i].name})
            }
          }
        }

        if (this.c_wine.region_id == 0) {
           this.c_wine.region_id = this.current_geography.region_id
           this.region_isActive = true;
        }

        // Add classificationss
        this.classifications = []
        this.classification_id = '';
        for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i ++) {
          await Classifications.getone(this.current_geography.classification_ids[i])
          .then(response => {
            if (typeof response != "undefined") {
              if (this.debug) console.log("=====> FullDefine.inputCountry: classification exists with response ", response)
              this.classifications.push({value:response._id,text:response.name})
            } else {
              if (this.debug) console.log("=====> FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
            }

          })
          .catch(error => {
            if (this.debug) console.log("=====> FullDefine.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
          })
        }
        this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);
            
        if (this.classifications.length > 0) {
          this.classification_isActive = true
          if ( this.classifications.length == 1) {
            this.c_wine.classification_id = this.classifications[0].value
          }
        } else {
          this.classification_isActive = false
        }
        
        // New 2022-08-03 Adapt Appellationlist according to selected region
        this.appellations = []
        for(let i = 0; i < this.geographies.length ; i++ ) {
          if (this.geographies[i]._id == value) {
            this.current_geography = this.geographies[i];
          }
          if (this.geographies[i].level == 3 && this.geographies[i].region_id == this.c_wine.region_id)  {
            this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
        }
        if (this.appellations.length > 0 ) {
          this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
          this.appellation_isActive = true
        } else {
          this.appellation_isActive = false
        }
        for (let i = 0; i < this.appellations.length; i++) {
          if (this.appellations[i].value == value ) {
            this.c_wine.appellation = this.appellations[i].text
          }
        } 

        if (this.debug) console.log("FullDefine in inputAppellation: STOP")
      },

      inputClassification(value) {
        this.c_wine.classification_id = value;
        if (this.debug) console.log("FullDefine in inputClassification - classification_id = ",this.c_wine.classification_id)
        for (let i = 0; i < this.classifications.length; i++) {
          if (this.classifications[i].value == value ) {
            this.c_wine.classification = this.classifications[i].text
          }
        }
        if (this.debug) console.log("FullDefine in inputClassification - classification = ",this.c_wine.classification)
      },

      inputColor(value) {
        if (this.debug) if (this.debug) console.log("=====> FullDefine.inputColor - value = ",value) 
        for (let i = 0; i < this.colors.length; i++) {
          if (this.colors[i].value == value ) {
            this.c_wine.color = this.colors[i].text;
            this.color_id = i;
          }
        }
        if (this.debug) if (this.debug) console.log("=====> FullDefine.inputColor - color = ",this.c_wine.color)
      },   

      onDeletePicture(i){
        if (this.debug) console.log("========> FullDefine.onDeletePicture START")
        this.c_wine.image_files.splice(i, 1);
        this.hasChanged = true;
        // this.onSave();
        if (this.debug) console.log("========> FullDefine.onDeletePicture STOP")
      },

      validate(){
        if(this.c_wine.name.length < 3) {
          this.nameValid = false;
          this.nameFeedback = "minimum 3 characters"
        }  
        else {
          this.nameValid = true;
          this.nameFeedback = ""
        }

        if(this.bottle.vintage == 0) {
          this.vintageValid = false;
          this.vintageFeedback = "select valid vintage"
        } 
        else {
          this.vintageValid = true;
          this.vintageFeedback = ""
        }

        if (!this.freeFormat) {
          if(this.c_wine.country_id == 0) {
            this.countryValid = false;
            this.countryFeedback = "select valid country"
          } 
          else {
            this.countryValid = true;
            this.countryFeedback = ""
          }
        } else {
          if(this.c_wine.country.length < 3) {
            this.countryValid = false;
            this.countryFeedback = "minimum 3 characters"
          } 
          else {
            this.countryValid = true;
            this.countryFeedback = ""
          }
        }   

        if(this.c_wine.color == "") {
          this.colorValid = false;
          this.colorFeedback = "select valid color"
        } 
        else {
          this.colorValid = true;
          this.colorFeedback = ""
        } 

        this.isValid = this.nameValid && this.vintageValid && this.countryValid && this.colorValid;
      },

      async onSave() {
        if (this.debug) console.log("");
        if (this.debug) console.log("========> FullDefine.onSave: START");
        delete this.bottle._id
        if (typeof this.bottle._id != "undefined") {
          delete this.bottle._id
        }
        
        //READING THE FORMS
        this.validate();
        if (this.debug) console.log("========> FullDefine.onSave: this.isValid = ", this.isValid);
        if (!this.isValid) {
          if (this.nameValid && this.vintageValid && this.countryValid && this.colorValid) {
            this.pageCount = 1
            this.leftVisible = true;
            this.rightVisible = true;
          } else {
            this.pageCount = 0;
            this.leftVisible = false;
            this.rightVisible = true;
          }
          if (this.debug) console.log("========> FullDefine.onSave: Returning without Save because validation form failed.")
          return;
        }


        if (this.c_wine.country_id !=0) await Countries.getone(this.c_wine.country_id)
        .then(response => {
          this.c_wine.country = response.name
          if (this.debug) console.log("========> FullDefine.onSave: country_id ",this.c_wine.country_id," found as ", this.c_wine.country, " - updating.");
        })
        .catch(error => {
          if (this.debug) console.log("========> FullDefine.onSave: catch await Countries.getone ERROR :", error);
        })

        if (this.c_wine.region_id !=0) await Regions.getone(this.c_wine.region_id)
        .then(response => {
          this.c_wine.region = response.name
          if (this.debug) console.log("========> FullDefine.onSave: region_id ",this.c_wine.region_id," found as ", this.c_wine.region, " - updating.");
        })
        .catch(error => {
          if (this.debug) console.log("========> FullDefine.onSave: catch await Regions.getone ERROR :", error);
        })

        if (this.c_wine.classification_id !=0) await Classifications.getone(this.c_wine.classification_id)
        .then(response => {
          this.c_wine.classification = response.name
          if (this.debug) console.log("========> FullDefine.onSave: classification_id ",this.c_wine.classification_id," found as ", this.c_wine.classification, " - updating.");
        })
        .catch(error => {
          if (this.debug) console.log("========> FullDefine.onSave: catch await Classifications.getone ERROR :", error);
        })

        this.c_wine.user_id = this.currentUserID
        if ( this.c_wine_id == 0 ) {
          if (this.debug) console.log("========> FullDefine.onSave: c_wine does NOT exist - we create it");
          
          // Creating c_wine
          await C_Wines.create(this.c_wine)
          .then(response=>{
            this.c_wine_id = response;
            if (this.debug) console.log("========> FullDefine.onSave: c_wine ", this.c_wine, " succesfully created : id = ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("========> FullDefine.onSave: catch await C_Wines.create ERROR :", error);
          })
          
          // Creating bottle
          if (this.c_wine.type !== '') {
            this.bottle.name = this.c_wine.name + ' (' + this.c_wine.type + ') ' 
          } else {
            this.bottle.name = this.c_wine.name
          }
          if (this.bottle.vintage != '-') {
            this.bottle.name = this.bottle.name + ' '+ this.bottle.vintage
          }
          this.bottle.c_wine_id = this.c_wine_id
          this.bottle.winelist_ids.push(this.winelist_id)
          
          await Bottles.create(this.bottle)
          .then(response => {
            this.bottle_id = response;
            if (this.debug) console.log("========> FullDefine.onSave: bottle ",this.bottle," succesfully created : id = ", response);
          })
          .catch(error => {
            if (this.debug) console.log("========> FullDefine.onSave: catch await Bottles.create ERROR :", error);
          })
          
          // Adding this.bottle_id to the bottle_ids list of this.wishlist
          if (this.debug) console.log("========> FullDefine.onSave: Adding bottle_id ",this.bottle_id," to bottle_ids of wishlist ", this.wishlist, " with wishlist_id ",this.wishlist_id)  
          this.wishlist.bottle_ids.push(this.bottle_id)

          await WishLists.update(this.wishlist_id,this.wishlist)
          .then(response=>{
            if (this.debug) console.log("========> FullDefine.onSave: bottle_id ",this.bottle_id," succesfully added to wishlist ", this.wishlist, " with wishlist_id ",this.wishlist_id," with response ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("========> FullDefine.onSave: catch await Wishlist.update ", error);
          })  
          this.init();
        } else {
          if (this.debug) console.log("========> FullDefine.onSave: c_wine DOES exist - we update it");
          if (this.debug) console.log("========> FullDefine.onSave: c_wine DOES exist: c_wine_id = ", this.c_wine_id, " c_wine = ", this.c_wine);

          // Updating c_wine
          await C_Wines.update(this.c_wine_id,this.c_wine)
          .then(response=>{
            if (this.debug) console.log("========> FullDefine.onSave: c_wine ", this.c_wine, " succesfully updated with response ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("========> FullDefine.onSave: catch await C_Wines.update ERROR :", error);
          })
          
          // Updating bottle
          if (this.c_wine.type !== '') {
            this.bottle.name = this.c_wine.name + ' (' + this.c_wine.type + ') ' 
          } else {
            this.bottle.name = this.c_wine.name
          }
          if (this.bottle.vintage != '-') {
            this.bottle.name = this.bottle.name + ' '+ this.bottle.vintage
          }
          
          if (this.bottle_id != 0 ) {
            await Bottles.update(this.bottle_id,this.bottle)
            .then(response => {
              if (this.debug) console.log("========> FullDefine.onSave: bottle ",this.bottle," succesfully updated with response ", response);
            })
            .catch(error => {
              if (this.debug) console.log("========> FullDefine.onSave: catch await Bottles.update ERROR :", error);
            })
          } else {
            if (this.debug) console.log("========> FullDefine.onSave: ERROR bottle_id = ", this.bottle_id);
          }

          // Updating winelist ?

        }
         
        //Update Parent List
        this.$emit('refreshRequest');

        this.$store.commit('setPage','WishList')
        this.$router.push({path:'/WishList'});

        if (this.debug) console.log("========> FullDefine.onSave: STOP");
      },

      init() {
        this.bottle = {
          name:'',
          wine_id:0,
          c_wine_id:0,
          vintage:0,
          winelist_ids:[],
          tasting_note_ids:[]
        };
        this.bottle_id=0;

        this.c_wine = {
            name:'',
            type:'',
            country_id:0,
            country:'',
            region_id:0,
            region:'',
            appellation_id:0,
            appellation:'',
            classification_id:0,
            classification:'',
            sparkling:false,
            color:'',
            grapes:'',
            vines:'',
            yields:'',
            vinification:'',
            image_files:[],
        };
        this.c_wine_id = 0;
        this.color_id = '';
      },

      noFreeFormat() {
        this.init_crac()
        this.freeFormat = false;
      },

      onBack() {
        this.$store.commit('setPage','WishList')
        this.$router.push({path:'/WishList'});
      },

      async vfileAdded(new_image_file) {
        if ( !new_image_file.manuallyAdded ) {
          if (this.debug) console.log("wine picture URL: ", new_image_file);
          let org_base64 = new_image_file.dataURL;
          let cc_base64 = await cc64(org_base64,1,3);
          new_image_file.dataURL = cc_base64;
          this.c_wine.image_files.push(new_image_file);
          if (this.c_wine.image_files.length > 3) {
            this.c_wine.image_files.shift();
          }
          this.hasChanged = true;
          this.$refs.imgDropzone.removeFile(new_image_file);
          //this.onSave();
        }
      },

      vfileRemoved(image_file) {
        // this.c_wine.image_files.pop(image_file);
        for( var i = 0; i < this.c_wine.image_files.length; i++){
          if ( this.c_wine.image_files[i].dataURL === image_file.dataURL) { 
            this.c_wine.image_files.splice(i, 1); 
          }
        }
        this.hasChanged = true
      },

      template: function () {
         return `<div class="dz-preview">
                </div>
        `;
      },

      onLeft() {
        this.pageCount--;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },

      onRight() {
        this.pageCount++;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },

      async onWine() {
        this.pageCount = 0;
        this.leftVisible = false;
        this.rightVisible = true;
        // await this.onSave()
      }, 
      async onDetail() {
        this.pageCount = 1;
        this.leftVisible = true;
        this.rightVisible = true;
        // await this.onSave()
      },
      async onPictures() {
        this.pageCount = 2;
        this.leftVisible = true;
        this.rightVisible = false;
        // await this.onSave()
      },
    }
  }    
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
/* Custom Dropzone */
.winedropzone {
  position: relative;
  top:-12.5vh;
  left:0;
  width:100%;
  height:100%;

  background-color: transparent !important;

  border-style: none;
  border-radius: 5px;

  color:#696969;

  transition: background-color .2s linear;
}
</style>
import { API } from './API.js';
import axios from 'axios';

const url = API + '/wishlists/'
const debug = false;

class WishLists {
    // get wishlists
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(wishlist=> ({
                        ...wishlist,
                        user_id: wishlist.user_id
                    }))
                );
                if (debug) console.log("WishLists.get: data = ", data)
            } catch(err) {
                reject(err);
            }
        })
    }

    // get one wishlist
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('WishLists.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                if (debug) console.log('WishLists.getone: ',error)
            })
    }

    // create wishlist
    static create(postdata) {
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('WishLists.create: ',response)
                return response.data._id
            })
            .catch(error => {
                if (debug) console.log('WishLists.create: ',error)
            })
    }

    static update(id,postdata) {
        if (debug) console.log('WishLists.update: id=',id)
        if (debug) console.log('WishLists.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('WishLists.update: ',response)
            })
            .catch(error => {
                if (debug) console.log('WishLists.update: ',error)
            })
    }

    // delete wishlist
    static delete(id) {
        return axios.delete(`${url}${id}`)
    }
}

export default WishLists
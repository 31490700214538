import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/components/Login'
import Register from '@/components/Register'
import RegistrationReceived from '@/components/RegistrationReceived'
import Confirmation from '@/components/Confirmation'

import AddCellar2Registration from '@/components/AddCellar2Registration'

import Profile from '@/components/Profile'

import Home from '@/components/Home'
import WineListsOverview from '@/components/WineListsOverview'
import WineListsSearch from '@/components/WineListsSearch'
import TastingsOverview from '@/components/TastingsOverview'
import TastingsSearch from '@/components/TastingsSearch'

import TastingShow from '@/components/TastingShow'
import TastingEdit from '@/components/TastingEdit'

import WineListShow from '@/components/WineListShow'
import WineListEdit from '@/components/WineListEdit'

import WishList from '@/components/WishList'
import BottleEdit from '@/components/BottleEdit'

import Catalogue from '@/components/Catalogue'

import AboutUs from '@/components/AboutUs'
import ForgotPassword from '@/components/ForgotPassword'
import ResetPassword from '@/components/ResetPassword'

import Terms from '@/components/Terms'

import Test from '@/components/Test'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/Register',
            name: 'Register',
            component: Register
        },
        {
            path: '/RegistrationReceived',
            name: 'RegistrationReceived',
            component: RegistrationReceived
        },
        {
            path: '/Confirmation',
            name: 'Confirmation',
            component: Confirmation
        },
        {
            path: '/AddCellar2Registration',
            name: 'AddCellar2Registration',
            component: AddCellar2Registration
        },
        {
            path: '/Home',
            name: 'Home',
            component: Home
        },
        {
            path: '/WineListsOverview',
            name: 'WineListsOverview',
            component: WineListsOverview
        },
        {
            path: '/WineListsSearch',
            name: 'WineListsSearch',
            component: WineListsSearch
        },
        {
            path: '/TastingsOverview',
            name: 'TastingsOverview',
            component: TastingsOverview
        },
        {
            path: '/TastingsSearch',
            name: 'TastingsSearch',
            component: TastingsSearch
        },
        {
            path: '/Profile',
            name: 'Profile',
            component: Profile
        },
        {
            path: '/WineListEdit',
            name: 'WineListEdit',
            component: WineListEdit
        },
        {
            path: '/WineListShow',
            name: 'WineListShow',
            component: WineListShow
        },
        {
            path: '/TastingEdit',
            name: 'TastingEdit',
            component: TastingEdit
        },
        {
            path: '/TastingShow',
            name: 'TastingShow',
            component: TastingShow
        },    
        {
            path: '/WishList',
            name: 'WishList',
            component: WishList
        },
        {
            path: '/BottleEdit',
            name: 'BottleEdit',
            component: BottleEdit
        },
        {
            path: '/Catalogue',
            name: 'Catalogue',
            component: Catalogue
        },
        {
            path: '/AboutUs',
            name: 'AboutUs',
            component:AboutUs
        },  
        {   
            path: '/ForgotPassword', 
            name: 'ForgotPassword',
            component: ForgotPassword 
        },
        {   
            path: '/ResetPassword', 
            name: 'ResetPassword',
            component: ResetPassword 
        },
        {
            path: '/Terms',
            name: 'Terms',
            component: Terms
        }, 
        {
            path: '/Test',
            name: 'Test',
            component: Test
        },
    ],
    mode: 'history'
})
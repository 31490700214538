// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';

import axios from 'axios';

const debug = false;

// const url = WEBSERVER + ':' + PORT + '/api/winelist_from_Code/';

const url = API + '/winelist_from_Code/';

class fromWineList {
    static getToken(postdata) {
    return axios.post(url, postdata)
        .then(response => {
            if (debug) console.log('fromWineList.getToken: ',response.data)
            return response.data
        })
        .catch(error => {
            console.log('fromWineList.getToken: ',error)
        })
    }
}

export default fromWineList
export default function clearForSearch(str) {
    let clean
    if (/[a-zA-Z]/.test(str)) {
        clean = str.toLowerCase();
        clean = clean.replace(new RegExp("[àáâãäå]", 'g'),"a");
        clean = clean.replace(new RegExp("æ", 'g'),"ae");
        clean = clean.replace(new RegExp("ç", 'g'),"c");
        clean = clean.replace(new RegExp("[èéêë]", 'g'),"e");
        clean = clean.replace(new RegExp("[ìíîï]", 'g'),"i");
        clean = clean.replace(new RegExp("ñ", 'g'),"n");                            
        clean = clean.replace(new RegExp("[òóôõö]", 'g'),"o");
        clean = clean.replace(new RegExp("œ", 'g'),"oe");
        clean = clean.replace(new RegExp("[ùúûü]", 'g'),"u");
        clean = clean.replace(new RegExp("[ýÿ]", 'g'),"y");
    } else {
        clean = str
    }
    return clean;
}
// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';

import axios from 'axios';

const url = API + '/c_wines/';
const debug = false;

class C_Wines {
    // get c_wines
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(c_wine=> ({
                        ...c_wine,
                        name: c_wine.name
                    }))
                );
                // console.log("data = ", data)
            } catch(err) {
                reject(err);
            }

        })
    }

    // get one c_wine
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('C_Wines.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                console.log('C_Wines.getone: ',error)
            })
    }

    // create c_wine
    static create(postdata) {
        if (debug) console.log('C_Wines.create: postdata = ',postdata)
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('C_Wines.create: ',response)
                return response.data._id
            })
            .catch(error => {
                console.log('C_Wines.create: ',error)
            })
    }

    static update(id,postdata) {
        if (debug) console.log('C_Wines.update: id=',id)
        if (debug) console.log('C_Wines.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('C_Wines.update: ',response)
                return id
            })
            .catch(error => {
                console.log('C_Wines.update: ',error)
            })
    }

    // delete c_wine
    static delete(id) {
        return axios.delete(`${url}${id}`)
    }
}

export default C_Wines
import { API } from './API.js';
import axios from 'axios';

const debug = false;
const url = API + '/tasting_from_Code/';

class fromTasting {
    static getToken(postdata) {
    return axios.post(url, postdata)
        .then(response => {
            if (debug) console.log('fromTasting.getToken: ',response.data)
            return response.data
        })
        .catch(error => {
            console.log('fromTasting.getToken: ',error)
        })
    }
}

export default fromTasting
<template>
  <!-- <div v-on:contextmenu.prevent="doNothing"> -->
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession"> 
        <b-row>
          <b-col style="padding:0">
            <div class="menu-top" style="text-align:center">
            <p><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
            </div>    
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
          </b-col>   
        </b-row>      
        <b-row>
          <b-col style="padding-left:0;padding-right:0">
            <div
              v-if="tasting.image_files.length >0"
              class="w-background"
              v-bind:style="{ 'background-image': 'url(' + tasting.image_files[0].dataURL +')' }"
            >
              <p class="w-pagetitle" style="color:white;margin-right:15px; padding-top: 10px; text-align:right;">
                <span v-on:click="gotoTastingsOverview()" class="w-cursor-click" ><u> tastings </u></span> > 
                <span v-on:click="gotoTastingsOverview()" class="w-cursor-click" ><u> overview </u></span> > 
                {{tasting.name}} 
              </p>
              <b-row style="margin:0">
                <b-col cols="8">
                  <p class="preview-title" style="color:white !important;text-align:left"> {{tasting.name}} </p>
                </b-col>
                <b-col cols="4" style="padding-left:0">
                  <p class="preview-title" style="color:white !important;text-align:right">{{code}}</p>
                </b-col>
              </b-row>
              <b-row style="margin:0">
                <b-col cols="6">
                  <p class="preview-subtitle" style="color:white !important;text-align:left"> {{formatTastingDate}} </p>
                </b-col>
                <b-col cols="6">
                  <p class="preview-subtitle" style="color:white !important;text-align:right"> {{tasting.organiser}} </p>
                </b-col>
              </b-row>
            </div>
            <div
              v-else
              class="no-background"
            >
              <p class="w-pagetitle" style="color:white;margin-right:15px; padding-top: 10px; text-align:right;">tastings > overview > show tasting</p>
              <b-row style="margin:0">
                <b-col cols="8">
                  <p class="preview-title" style="color:white !important;text-align:left"> {{tasting.name}} </p>
                </b-col>
                <b-col cols="4">
                  <p class="preview-title" style="color:white !important;text-align:right">{{code}} </p>
                </b-col>
              </b-row>
              <b-row style="margin:0">
                <b-col cols="6">
                  <p class="preview-subtitle" style="color:white !important;text-align:left"> {{formatTastingDate}} </p>
                </b-col>
                <b-col cols="6">
                  <p class="preview-subtitle" style="color:white !important;text-align:right"> {{tasting.organiser}} </p>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <br>
        <div style="padding:0" :key="refreshList">
          <!-- <p>tasting_id = {{tasting_id}}</p> -->
          <b-row>
            <b-col>
              <div style="display:flex">
                <p class="w-level0" style="order:1;">{{tasting.name}}</p>
                <font-awesome-icon
                  style="order:2; margin-left: auto"
                  v-if="tasting.isOpen && catalogVisible"
                  icon="book"
                  size="lg"
                  v-on:click.stop="showModal_openCatalog"
                  v-b-tooltip.hover title="click to open catalogue"
                />
                <font-awesome-icon
                  v-else-if="tasting.isBlind && this.tasting.user_id != this.tasting.organiser_id"
                  class="w-cursor-click"  
                  style="order:2; margin-left: auto; position:relative; bottom:-5px"
                  icon="sync-alt"
                  v-on:click.stop="refreshTasting"
                  v-b-tooltip.hover title="click to refresh tasting"
                />  
              </div>

              <div 
                v-for="(tasting_note,index) in tasting_notes"
                v-bind:index="index" 
                :key="tasting_note._id"
              >
                <b-row>
                  <div
                  class="w-node w-level1 w-cursor-click"
                    v-on:click="selectNote(tasting_note._id)"
                    v-bind:class="{ 'w-active' : isActiveNote(tasting_note._id)}"
                  >
                    <p style="order:1" v-if="tasting_note.color=='Red'" class="w-red-bottle w-cursor-click"></p>
                    <p style="order:1" v-else-if="tasting_note.color=='White'" class="w-white-bottle w-cursor-click"></p>
                    <p style="order:1" v-else-if="tasting_note.color=='Rosé'" class="w-rose-bottle w-cursor-click"></p>
                    <p style="order:1" v-else class="w-item w-cursor-click"></p>
                    <p style="order:2; margin:0 5px 0 0" v-if="tasting_note.isDisclosed == false">Wine {{index+1}}</p>
                    <p style="order:2; margin:0 5px 0 0" v-else><span v-if="tasting.isOrdered == true">{{index+1}}. </span>{{tasting_note.name}}</p>
                    <div
                      style="order:3; display:flex; margin-left:auto" 
                      v-if="isActiveNote(tasting_note._id) && (!tasting.isOrganised || tasting.isOpen)"
                    >
                      <p
                        v-if="index != 0"
                        style="text-align:right;margin-left:5px;flex-basis: 33%"
                        class="w-item-end w-cursor-click"
                        v-on:click.stop="upNote(tasting_note._id)"
                      >
                        <font-awesome-icon style="font-size:17px" icon="caret-up" />
                      </p>
                      <p v-else
                        style="text-align:right;margin-left:5px;flex-basis: 33%"
                        class="w-item-end"
                      >
                        <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                      </p>
                      <p
                      v-if="index != tasting_notes.length-1"
                        style="text-align:right;margin-left:5px;flex-basis: 33%"
                        class="w-item-end w-cursor-click"
                        v-on:click.stop="downNote(tasting_note._id)"
                      >
                        <font-awesome-icon style="font-size:17px" icon="caret-down" />
                      </p>
                      <p v-else
                        style="text-align:right;margin-left:5px;flex-basis: 33%"
                        class="w-item-end"
                      >
                        <font-awesome-icon style="font-size:17px;color:white" icon="caret-up" />
                      </p>
                      <p 
                        v-if="!tasting.isOrganised && !tasting.fromCellar"
                        style="text-align:right;margin-left:5px;margin-right:10px;flex-basis: 33%"
                        class="w-item-end w-cursor-click"
                        v-on:click.stop="showModal_deleteNote()"
                      >
                        <font-awesome-icon icon="trash-alt" />
                      </p>
                      <p
                        v-else
                        style="color:white;text-align:right;margin-left:5px;margin-right:10px;flex-basis: 33%"
                        class="w-item-end w-cursor-click"
                      >
                        <font-awesome-icon icon="trash-alt" />
                      </p>
                    </div>
                  </div>
                </b-row> 
              </div>
              <b-row v-if="!tasting.isOrganised && !tasting.fromCellar">
                <p
                  class="w-new w-level1 w-cursor-click"
                  v-on:click="createNote"
                  v-bind:class="{ 'w-active' : isActiveNote(0)}"
                >
                new
                </p>
              </b-row>                      
            </b-col>
          </b-row>
        </div> 
        <b-row v-if="!tasting.fromCellar">
          <b-col style="padding-left:0px;padding-right:0px">
            <br>    
            <div v-if="tasting_note_id != 0 || !tasting.isOrganised">
              <b-tabs v-if="renderChild" class="w-tabs" v-model="tabIndex" fill>
                <b-tab class="w-tab" title="wine"> 
                  <div v-if="this.item_id==0 && this.tasting_note_id==0">
                    <Item
                      @refreshRequest="refresh_addList" 
                      :key="refreshChild"/>
                  </div>
                  <div v-else >
                    <Item
                      @refreshRequest="refresh_List" 
                      :key="refreshChild"/>
                  </div>
                </b-tab>
                <b-tab class="w-tab" title="notes">
                  <br>
                  <div v-if="this.item_id==0 && this.tasting_note_id==0">
                    <TastingNote
                      @refreshRequest="refresh_addList"
                      :key="refreshChild"/>
                  </div>
                  <div v-else >
                    <TastingNote
                      @refreshRequest="refresh_List"
                      :key="refreshChild"/>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-col>
        </b-row>
        <b-row v-else style="padding:0">
          <br>
          <div v-if="tasting_note_id != 0 || !tasting.isOrganised">
            <b-tabs v-if="renderChild" class="w-tabs" v-model="tabIndex" fill>
              <b-tab class="w-tab" title="wine">
                <div >
                  <Entry
                    @refreshRequest="refresh_addList" 
                  :key="refreshChild"/>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </b-row>
        <hr>
        <div>
          <b-button v-on:click="onBack()" class="w-button-pink-full">back</b-button>
        </div>        
        <br>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(109,122,82)">WYNE</span></b>TASTER</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <Menu/>
          </template>
        </b-sidebar>            
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
    <b-modal id="Catalogue" title="catalogue" @ok="saveCatalogue">
      <b-row style="margin-left:0;margin-right:0">
        <b-col style="margin-top: 14px" :key="refreshList">
          <b-row>
            <b-col>
              <p
                class="w-level0"
                style="position:relative;left:-15px"
              >
                {{winelist.name}} 
              </p>
              <div 
                v-for="(node_level1,index_level1) in winelist.nodes"
                v-bind:index="index_level1" 
                :key="node_level1._id"
              >                    
                <b-row v-if="!node_level1.nodes">
                  <div 
                    class="w-node w-level1 w-cursor-click"  
                  >
                    <p style="order:1" class="w-item w-cursor-click"></p>
                    <p style="order:2; margin:0 5px 0 0">{{node_level1.value.name}}</p>
                    <div
                      style="order:3; display:flex; margin-left:auto" 
                    >
                      <b-form-group style="text-align:left; margin:0">
                        <b-form-checkbox 
                          v-model="node_level1.select"
                         @change="clickCheckbox(node_level1)"
                        >
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                </b-row>
                <div v-else>
                  <b-row>
                    <div 
                      class="w-node w-level1 w-cursor-click"
                    >
                      <p style="order:1" class="w-group w-cursor-click"></p>
                      <p style="order:2; margin:0 5px 0 0">{{node_level1.value.name}}</p>
                      <div
                        style="order:3; display:flex; margin-left:auto" 
                      >
                      </div>
                    </div>
                  </b-row>
                  <div>
                    <div
                      v-for="(node_level2,index_level2) in node_level1.nodes"
                      v-bind:index="index_level2" 
                      :key="node_level2._id"
                    >
                      <b-row v-if="!node_level2.nodes">
                        <div 
                          class="w-node w-level2 w-cursor-click"
                        >
                          <p style="order:1" class="w-item w-cursor-click"></p>
                          <p style="order:2; margin:0 5px 0 0">{{node_level2.value.name}}</p>
                          <div
                            style="order:3; display:flex;margin-left:auto" 
                          >
                            <b-form-group style="text-align:left; margin:0">
                              <b-form-checkbox 
                                v-model="node_level2.select"
                               @change="clickCheckbox(node_level2)"
                              >
                              </b-form-checkbox>
                            </b-form-group>
                          </div>
                        </div>                            
                      </b-row>
                      <div v-else>
                        <b-row>
                          <div 
                            class="w-node w-level2 w-cursor-click"
                          >
                            <p style="order:1" class="w-group w-cursor-click"></p>
                            <p style="order:2; margin:0 5px 0 0">{{node_level2.value.name}}</p>
                            <div
                              style="order:3; display:flex; margin-left:auto" 
                            >
                            </div>
                          </div>                          
                        </b-row>
                        <div>
                          <div
                            v-for="(node_level3,index_level3) in node_level2.nodes"
                            v-bind:index="index_level3" 
                            :key="node_level3._id"
                          >
                            <b-row v-if="!node_level3.nodes">
                              <div 
                                class="w-node w-level3 w-cursor-click"
                              >
                                <p style="order:1" class="w-item w-cursor-click"></p>
                                <p style="order:2; margin:0 5px 0 0">{{node_level3.value.name}}</p>
                                <div
                                  style="order:3; display:flex; margin-left:auto" 
                                >
                                  <b-form-group style="text-align:left; margin:0">
                                    <b-form-checkbox 
                                      v-model="node_level3.select"
                                     @change="clickCheckbox(node_level3)"
                                    >
                                    </b-form-checkbox>
                                  </b-form-group>    
                                </div>
                              </div>                                   
                            </b-row>
                            <div v-else>
                              <b-row>
                                <div 
                                  class="w-node w-level3 w-cursor-click"
                                >
                                  <p style="order:1" class="w-group w-cursor-click"></p>
                                  <p style="order:2; margin:0 5px 0 0">{{node_level3.value.name}}</p>
                                  <div
                                    style="order:3; display:flex; margin-left:auto" 
                                  >
                                  </div>
                                </div>                                      
                              </b-row>
                              <div v-if="node_level3.isVisible">
                                <div
                                  v-for="(node_level4,index_level4) in node_level3.nodes"
                                  v-bind:index="index_level4" 
                                  :key="node_level4._id"
                                >
                                  <b-row>
                                    <div 
                                      class="w-node w-level4 w-cursor-click"
                                    >
                                      <p style="order:1" class="w-item w-cursor-click"></p>
                                      <p style="order:2; margin:0 5px 0 0">{{node_level4.value.name}}</p>
                                      <div
                                        style="order:3; display:flex; margin-left:auto" 
                                      >
                                        <b-form-group style="text-align:left; margin:0">
                                          <b-form-checkbox 
                                            v-model="node_level4.select"
                                           @change="clickCheckbox(node_level4)"
                                          >
                                          </b-form-checkbox>
                                        </b-form-group> 
                                      </div>
                                    </div>       
                                  </b-row>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>    
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>   
    </b-modal>
    <b-modal id="deleteNote" title="delete tasting note" @ok="deleteNote">
      <b-row>
        <div style="width:300px;margin-left:auto;margin-right:auto">
          <b-col
            class="w-tile m-2 d-inline-flex justify-content-center"
            style ="display:block; margin:20px !important"
          >
            <b-row 
              style="width:100%"
              v-if="c_wine_id !=0 || wine_id !=0"
            >
              <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                <div v-if="wine_id != 0">
                  <b-img center 
                    v-if="wine.image_files.length >0"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    v-bind:src="this.wine.image_files[0].dataURL" 
                  ></b-img>
                  <b-img center v-else
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle.png')"
                  ></b-img>
                </div>

                <div v-if="c_wine_id != 0">
                  <b-img center 
                    v-if="c_wine.image_files.length >0"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    v-bind:src="c_wine.image_files[0].dataURL" 
                  ></b-img>
                  <b-img center v-else
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle.png')"
                  ></b-img>
                </div>
              </b-col>

              <b-col cols="9" class="d-table-cell">
                <div v-if="wine_id !=0">
                  <p class="t-title">{{wine.vintage}}</p>
                  <p class="t-title2">{{wine.name}}</p>
                  <p class="t-subtitle" v-if="wine.type">{{wine.type}}</p>
                  <hr>
                  <p class="t-text" v-if="wine.appellation">{{wine.appellation}}</p>
                  <p class="t-text" v-if="wine.classification">{{wine.classification}}</p>
                  <p class="c-bottles">{{wine.number}}</p>
                </div>
                <div v-if="c_wine_id !=0">
                  <p class="t-title">{{c_wine.vintage}}</p>
                  <p class="t-title2">{{c_wine.name}}</p>
                  <p class="t-subtitle" v-if="c_wine.type">{{c_wine.type}}</p>
                  <hr>
                  <p class="t-text" v-if="c_wine.appellation">{{c_wine.appellation}}</p>
                  <p class="t-text" v-if="c_wine.classification">{{c_wine.classification}}</p>
                  <p class="c-bottles">{{c_wine.number}}</p>
                </div>                
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                  <b-img center
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle.png')"
                  ></b-img>
              </b-col>
              <b-col cols="9" class="d-table-cell">
                <p class="t-title">No wine associated to this tasting note</p>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </b-row>
      <b-row>
        <b-col>
          <p class="w-text" v-if="tasting_note.appearance"><b>appearance: </b>{{tasting_note.appearance}}</p>
          <p class="w-text" v-if="tasting_note.nose"><b>appearance: </b>{{tasting_note.nose}}</p>
          <p class="w-text" v-if="tasting_note.palate"><b>palate: </b>{{tasting_note.palate}}</p>
          <p class="w-text" v-if="tasting_note.aftertaste"><b>aftertaste: </b>{{tasting_note.aftertaste}}</p>
          <p class="w-text" v-if="tasting_note.conclusion"><b>conclusion: </b>{{tasting_note.conclusion}}</p>
          <p class="w-text" v-if="tasting_note.score"><b>score: </b>{{tasting_note.score}}</p>
        </b-col>
      </b-row>
      <hr>
      <p class="t-subtitle text-center">deleting the tasting note is permatent</p>
      <p class="t-title text-center">ARE YOU SHURE YOU WANT TO DELETE THIS TASTING NOTE?</p>
     
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left" style="padding:0">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding:0">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>  
    </b-modal>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue'
  import Menu from './MenuMobile.vue'

  import TastingNote from './TastingNote_Mobile.vue'
  import Item from './Item_Mobile.vue'
  import Entry from './Entry.vue'

  import Tastings from '../Tastings.js'
  import WineLists from '../WineLists.js'
  import TastingNotes from '../TastingNotes.js'
  import Items from '../Items.js'
  import Bottles from '../Bottles.js'
  import Wines from '../Wines.js';
  import C_Wines from '../C_Wines.js';
  import Entries from '../Entries.js'


  export default {
    name: "TastingShow",
    components: {
      NoAccess,
      Menu,
      TastingNote,
      Item,
      Entry
    },
    computed : {
      inSession() {
        return this.$store.state.inSession;
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      previousPage() {
        return this.$store.state.previous_page
      },
      searchState() {
        return this.$store.state.search_state
      },
      formatTastingDate() {
        let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        let y = this.tasting.date.substring(0,4);
        let n1 = this.tasting.date.indexOf("-");
        let n2 = this.tasting.date.lastIndexOf("-");
        let m = this.tasting.date.substring(n1+1,n2);
        let s = this.tasting.date.length;
        let d = this.tasting.date.substring(n2+1,s);

        let format_date = ''
        if (typeof this.tasting.date != "undefined") { 
          format_date = months[m-1] + ", " + d + "th " + y;
        } else {
          format_date = "TMP"
        }
        return format_date;
      },
      noteName() {
        if (this.bottle_id != 0) {
          if (this.bottle.wine_id != 0) {
              name = this.wine.name;
              if (this.wine.type != '') {
                name =  name + " (" + this.wine.type + ")";
              }
          } else if (this.bottle.c_wine_id != 0) {
              name = this.c_wine.name;
              if (this.c_wine.type != '') {
                name =  name + " (" + this.c_wine.type + ")";
              }
          } else {
            alert("Houston, we have a problem. this.bottle.c_wine_id = ", this.bottle.c_wine_id)
          }
          name = name + " " + this.bottle.vintage;
        } else {
          name = "NO NAME"
        }
        return name;
      },
    },
    data() {
      return {
        debug: false,
        isLoading: true,

        tasting: {
          user_id:0,
          
          name:'',
          organiser:'',
          date:'',
          image_files: [],
          isOpen: true,
          isBlind: false,
          isOrdered: false,
          code:'',

          winelist_id:0,

          tasting_note_ids:[],
        },
        tasting_id:0,

        tasting_notes: [],
        selectedNote: 0,

        tasting_note:{
          name:'',
          date:'',
          owner:'',
          user_id:'',
          appearance:'',
          nose:'',
          palate:'',
          aftertaste:'',
          conclusion:'',
          score:0,

          entry_id:0,
          item_id:0,

          tasting_id:0,

          onWishList: false,
        },
        tasting_note_id:0,

        up_visible: true,
        down_visible: true,

        item:{
          name:'',
          bottle_id:0,
          origin:'',
          price:'',
          comment:'',
          winelist_ids:[],
        },
        item_id:0,

        bottle:{
          name:'',
          wine_id:0,
          c_wine_id:0,
          vintage:0,
          winelist_ids:[],
          tasting_note_ids:[]
        },
        bottle_id:0,

        wine: {
          name:'',
          type:'',
          country_id:'',
          region:'',
          appellation_id:'',
          appellation:'',
          classification_id:'',
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          vines:'',
          longevity:'',
          yields:'',
          vinification:'',
          image_files:[],
          winery_id:''
        },
        wine_id :0,

        c_wine:{},
        c_wine_id:0,

        code:'',

        winelist: {},
        winelist_id:0,

        //Tabs
        tabIndex : 0,

        // Catalog
        catalogVisible:true,

        //Refresh
        refreshList: 0,
        refreshChild: 0,
        renderChild: false,

        // catalogue_list:[],

        isBusy:false,

        isValid : true,
      }
    },
    async mounted() {
      let local_debug = false
      if (this.debug || local_debug) console.log("")
      if (this.debug || local_debug) console.log("TastingShow.mounted: START")

      this.$store.commit('setSource',"TastingNote")

      if (this.debug || local_debug) console.log( "TastingShow.mounted: tasting_id = ",this.$route.query.tasting_id);
      if (this.debug || local_debug) console.log( "TastingShow.mounted: tasting_note_id = ",this.$route.query.tasting_note_id);

      if (typeof this.$route.query.tasting_id !== "undefined") {
        await Tastings.getone(this.$route.query.tasting_id)
        .then(response=> {
          if ( typeof response == "object") {
            this.tasting = response;
            this.tasting_id = this.tasting._id;
            delete this.tasting._id;

            if (typeof this.tasting.cellar_id == "string" && this.tasting.cellar_id.length == 24) this.tasting.fromCellar = true

            this.$store.commit('setTasting',this.tasting)
            this.$store.commit('setTastingID',this.tasting_id)
            if (this.debug || local_debug) console.log( "TastingShow.mounted: Tasting "+ this.tasting.name+ " succesfully fetched from DB");
          } else {
            if (this.debug || local_debug) console.log("TastingShow.mounted: ERROR retrieved tasting is not an object");
          }
        })
        .catch(error=> {
          if (this.debug || local_debug) console.log("TastingShow.mounted: await Tastings.getone", error);
        })
      } else {
        if (this.debug || local_debug) console.log("TastingShow.mounted: ERROR this.$route.query.tasting_id = ", this.$route.query.tasting_id);
      }

      this.code = this.printCode(this.tasting.code);

      if (this.debug || local_debug) console.log("TastingShow.mounted: START to fill tasting_notes : ", this.tasting.tasting_note_ids.length)
      for (let i=0; i < this.tasting.tasting_note_ids.length; i++) {
        if (this.debug || local_debug) console.log("TastingShow.mounted: this.tasting.tasting_note_ids[",i,"]=", this.tasting.tasting_note_ids[i] )
        if (this.tasting.tasting_note_ids[i] !== null) {
          await TastingNotes.getone(this.tasting.tasting_note_ids[i])
          .then(response=> {
            if ( typeof response == "object") {
              if (this.debug || local_debug) console.log("TastingShow.mounted: tasting_note = ", response )
              this.tasting_notes.push(response)
            } else {
              if (this.debug || local_debug) console.log("TastingShow.mounted: ERROR retrieved tasting_note is not an object");
            }
          })
          .catch(error=> {
            if (this.debug || local_debug) console.log("TastingShow.mounted: await TastingNotes.getone i=", i , " : ", error);
          })
        } else {
          if (this.debug || local_debug) console.log("TastingShow.mounted: ERROR this.tasting.tasting_note_ids[",i,"]=", this.tasting.tasting_note_ids[i], " removing it from tasting.");
          this.tasting.tasting_note_ids.splice(i,1)
          i = i-1
          this.$store.commit('setTasting',this.tasting)
          await Tastings.update(this.tasting_id,this.tasting)
          .then(response => {
            if (this.debug || local_debug) console.log("TastingShow.mounted: Tasting ", this.tasting," succesfully updated : id = ", response);
          })
          .catch(error => {
            if (this.debug || local_debug) console.log("TastingShow.mounted: catch await Tastings.update ", error);
          })
        }
      }

      if (this.tasting.isOpen) {
        await WineLists.getone(this.tasting.winelist_id)
        .then(response => {
          if ( typeof response == "object") {
            if (this.debug || local_debug) console.log("TastingShow.mounted: winelist ", response, " succesfully fetched from DB");
            this.winelist._id = response._id;
            delete response._id;
            this.winelist = response;
          } else {
            if (this.debug || local_debug) console.log("TastingShow.mounted: ERROR retrieved item is not an object");
          }
        })
        .catch(error => {
          if (this.debug || local_debug) console.log("TastingShow.mounted: await WineLists - ", error);
        })

       
        this.winelist.selected_bottles = 0  
        for (let i = 0 ; i < this.winelist.nodes.length ; i++) {  
          if (this.winelist.nodes[i].isLeaf) {
            this.winelist.nodes[i].select = this.inList(this.winelist.nodes[i]._id)
            if (this.winelist.nodes[i].select) {
              this.winelist.selected_bottles++ 
            } 
          } else {
            this.winelist.nodes[i].selected_bottles = 0
            for (let j = 0 ; j < this.winelist.nodes[i].nodes.length ; j++) {
              if (this.winelist.nodes[i].nodes[j].isLeaf) {
                this.winelist.nodes[i].nodes[j].select = this.inList(this.winelist.nodes[i].nodes[j]._id)
                if (this.winelist.nodes[i].nodes[j].select) {
                  this.winelist.selected_bottles++ 
                  this.winelist.nodes[i].selected_bottles++ 
                }
              } else {
                this.winelist.nodes[i].nodes[j].selected_bottles = 0
                for (let k = 0 ; k < this.winelist.nodes[i].nodes[j].nodes.length ; k++) {
                  if (this.winelist.nodes[i].nodes[j].nodes[k].isLeaf) {
                    this.winelist.nodes[i].nodes[j].nodes[k].select = this.inList(this.winelist.nodes[i].nodes[j].nodes[k]._id)
                    if (this.winelist.nodes[i].nodes[j].nodes[k].select) {
                      this.winelist.selected_bottles++ 
                      this.winelist.nodes[i].selected_bottles++ 
                      this.winelist.nodes[i].nodes[j].selected_bottles++ 
                    }
                  } else {
                    this.winelist.nodes[i].nodes[j].nodes[k].selected_bottles = 0 
                    for (let l = 0 ; l < this.winelist.nodes[i].nodes[j].nodes[k].nodes.length ; l++) { 
                      if (this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].isLeaf) {
                        this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].select = this.inList(this.winelist.nodes[i].nodes[j].nodes[k].nodes[l]._id)
                        if (this.winelist.nodes[i].nodes[j].nodes[k].nodes[l].select) {
                          this.winelist.selected_bottles++ 
                          this.winelist.nodes[i].selected_bottles++ 
                          this.winelist.nodes[i].nodes[j].selected_bottles++ 
                          this.winelist.nodes[i].nodes[j].nodes[k].selected_bottles++ 
                        }
                      } else {
                        alert("ERROR - level 4 is NOT a Leaf")
                      }
                    }
                  }
                }
              }
            }
          }
        }

        if (this.debug || local_debug) console.log("CellarTastingShow.mounted: INFO tasting_id = ", this.tasting_id);
      }
      
      if (typeof this.$route.query.tasting_note_id !== "undefined") {
        await this.isActiveNote(this.$route.query.tasting_note_id)
        await this.selectNote(this.$route.query.tasting_note_id)
      } else {
        if (this.debug || local_debug) console.log("TastingShow.mounted: INFO this.$route.query.tasting_note_id = ", this.$route.query.tasting_note_id);
        if (this.tasting.isOrganised || this.tasting.fromCellar) {
          if (this.tasting.tasting_note_ids.length > 0) {
            await this.isActiveNote(this.tasting.tasting_note_ids[0])
            await this.selectNote(this.tasting.tasting_note_ids[0])
          }
        } else {
          if (this.tasting.tasting_note_ids.length > 0) {
            await this.isActiveNote(this.tasting.tasting_note_ids[0])
            await this.selectNote(this.tasting.tasting_note_ids[0])
          } else {
            await this.isActiveNote(0)
            await this.selectNote(0)
          }
        }
      }

      this.$store.commit('setWinelist',this.winelist)
      this.$store.commit('setWinelistID',this.winelist_id)
      
      this.isLoading = false
      if (this.debug || local_debug) console.log("TastingShow.mounted: STOP")
    },
    methods: {
      printCode(this_code) {
        let print_code
        if (typeof this_code == "undefined") {
          return ""
        }
        if (!this.tasting.fromCellar) {
          print_code = this_code.toUpperCase()
          print_code = print_code.replace(/\s+/g, '')  
          print_code = print_code.substring(0,2) + ' ' + print_code.substring(2,4) + ' ' + print_code.substring(4,6)
        } else {
          print_code = ""
        }
        
        return print_code;
      },

      async onClickNode(index) {
        let local_debug = false
        if (this.debug) console.log ("TastingShow.onClickNode: START")
        if (this.debug) console.log ("TastingShow.onClickNode: index = ", index)
        if (index.length == 0) {
          if (this.debug) console.log ("TastingShow.onClickNode: node not valid")
        } else if (index.length == 1) {
          if ( this.winelist.nodes[index[0]].isLeaf) {     
              if (local_debug) console.log("LOCAL onClickNode: node level 1 is leaf - NOTHING DONE")
          } else {   
            if (local_debug) console.log("LOCAL onClickNode: node level 1 has subnodes: ",this.winelist.nodes[index[0]].nodes)
            await this.toggleVisibility(this.winelist.nodes[index[0]])
          }
          // await this.selectNode(node)
        } else if (index.length == 2) {
          if ( this.winelist.nodes[index[0]].nodes[index[1]].isLeaf) {     
            if (local_debug) console.log("LOCAL onClickNode: node level 2 is leaf - NOTHING DONE")
          } else {  
            if (local_debug) console.log("LOCAL onClickNode: node level2 has subnodes: ",this.winelist.nodes[index[0]].nodes[index[1]].nodes)
            await this.toggleVisibility(this.winelist.nodes[index[0]].nodes[index[1]])
          }
          // await this.selectNode(node)   
        } else if (index.length == 3) {
          if ( this.winelist.nodes[index[0]].nodes[index[1]].nodes[index[2]].isLeaf) {     
            if (local_debug) console.log("LOCAL onClickNode: node level 3 is leaf - NOTHING DONE")
          } else {   
            if (local_debug) console.log("LOCAL onClickNode: node level 3 has subnodes: ",this.winelist.nodes[index[0]].nodes[index[1]].nodes[index[2]].nodes)
            await this.toggleVisibility(this.winelist.nodes[index[0]].nodes[index[1]].nodes[index[2]])
          }
          // await this.selectNode(node)
        } else if (index.length == 4) {
          if ( this.winelist.nodes[index[0]].nodes[index[1]].nodes[index[2]].nodes[index[3]].isLeaf) {     
            if (local_debug) console.log("LOCAL onClickNode: node level 4 is leaf - NOTHING DONE")
          } else {   
            if (this.debug) console.log ("TastingShow.onClickNode: ERROR maximum node depth is 4.")
          }
          // await this.selectNode(node)        
        } else {
          if (this.debug) console.log ("TastingShow.onClickNode: ERROR maximum node depth is 4.")
        }
        if (this.debug) console.log ("TastingShow.onClickNode: STOP")  
      },

      async toggleVisibility(node) {
        let local_debug = false
        if (local_debug) console.log("=====> LOCAL toggleVisibility: START - node = ", node)
        if (node.isVisible) {
          node.isVisible = false
          if (local_debug) console.log("=====> LOCAL toggleVisibility: toggle isVisible to false")
        } else {
          node.isVisible = true
          if (local_debug) console.log("=====> LOCAL toggleVisibility: toggle isVisible to true")
        }
        if (local_debug) console.log("=====> LOCAL toggleVisibility: STOP")    
      },

      async onSaveNote() {
        if (this.debug) console.log("TastingShow.onSaveNote - START")
        await this.validate();

        if (! this.isValid) {
          if (this.debug) console.log("TastingShow.onSaveNote : Invalid Input - Nothing Done.")
          return
        }

        this.tasting_note.name = this.noteName;
        if (this.tasting_note_id == 0) {
          if (this.debug) console.log("TastingShow.onSaveNote : TastingNote does not exist yet. Let's created it");
          await TastingNotes.create(this.tasting_note)
            .then(response=>{
              this.tasting_note_id = response;
              this.tasting_note._id = response;
              if (this.debug) console.log("TastingShow.onSaveNote : TastingNote succesfully created : id = ", this.tasting_note_id);
            })
            .catch(error=>{
              if (this.debug) console.log("TastingShow.onSave: catch await TastingShow.create ", error);
            })

          this.tasting_notes.push(this.tasting_note);
          this.tasting.tasting_note_ids.push(this.tasting_note_id)
          await Tastings.update(this.tasting_id,this.tasting)
            .then(response=>{
              if (this.debug) console.log("TastingShow.onSaveNote : Tastings succesfully updated : id = ", response);
            })
            .catch(error=>{
              if (this.debug) console.log("TastingShow.onSave: catch await Tastings.update ", error);
            })
        } else {
          if (this.debug) console.log("TastingShow.onSaveNote : TastingNote does exist. It will be updated.");
          await TastingNotes.update(this.tasting_note_id,this.tasting_note)
          .then(response=>{
            this.tasting_note_id = response;
            if (this.debug) console.log("TastingShow.onSaveNote : TastingNote succesfully updated : this.tasting_note = ", this.tasting_note);
          })
          .catch(error=>{
            if (this.debug) console.log("TastingShow.onSaveNote : catch await TastingShow.update ", error);
          })
        }

        if (this.tasting_id == 0 ) {
          if (this.debug) console.log("TastingShow.onSaveNote : ERROR this.tasting_id = 0 ");
        } else {
          for (let i=0; i < this.tasting.tasting_note_ids.length; i++) {
            if (this.tasting.tasting_note_ids[i] == this.tasting_note_id) {
              this.tasting_notes[i] = JSON.parse(JSON.stringify(this.tasting_note));
              this.tasting_notes[i]._id = this.tasting_note_id;
              if (this.debug) console.log("TastingShow.onSaveNote : this.tasting_notes[",i,"] updated.");
            }
          }
        }

        if (this.debug) console.log("TastingShow.onSaveNote - STOP")

        this.$forceUpdate();
      },

      onBack() {
        let local_debug = false
        if (local_debug) console.log("TastingShow.onBack: previousPage = ", this.previousPage)
        if ( this.previousPage == 'TastingsSearch' ) {
          if (local_debug) console.log("TastingShow.onBack: going back to TastingsSearch.")
          this.$router.push({path:'/TastingsSearch'})
          this.$store.commit('setPage','TastingsSearch')
        } else {
          if (local_debug) console.log("TastingShow.onBack: going back to ", this.previousPage,"!")
          this.$router.push({path:'/'+this.previousPage})
          this.$store.commit('setPage',this.previousPage)
        }
      },

      async createNote() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingShow.createNote: START")
        
        await this.init_tasting_note();
        await this.init_item();
        await this.init_bottle();
        await this.init_wine();
        await this.init_c_wine();
        await this.init_entry();
          
        this.tasting_note.name = this.createTastingNote_Name()
        
        await TastingNotes.create(this.tasting_note)
          .then(response=>{
            this.tasting_note_id = response;
            this.tasting_note._id = response;
            if (this.debug) console.log("TastingShow.createNote : TastingNote succesfully created : id = ", this.tasting_note_id);
          })
          .catch(error=>{
            if (this.debug) console.log("TastingShow.createNote: catch await TastingShow.create ", error);
          })

        this.tasting_notes.push(this.tasting_note);
        this.tasting.tasting_note_ids.push(this.tasting_note_id)
        await Tastings.update(this.tasting_id,this.tasting)
          .then(response=>{
            if (this.debug) console.log("TastingShow.onSaveNote : Tastings succesfully updated : id = ", response);
          })
          .catch(error=>{
            if (this.debug) console.log("TastingShow.onSave: catch await Tastings.update ", error);
          })

        this.refreshList++;
        this.tabIndex = 1;
        await this.selectNote(this.tasting_note_id)
        if (this.debug || local_debug) console.log("TastingShow.createNote: STOP")
      },

      createTastingNote_Name() {
        let number = this.tasting_notes.length + 1
        return this.try(number)
      },

      try(number) {
        let test_name = "Wine "+ number.toString()
        let exists = false
        for (let i=0 ; i < this.tasting_notes.length ; i++) {
          if (this.tasting_notes[i].name == test_name) {
            exists = true
          }
        }
        if (exists == true) {
          return this.try(number+1)
        } else {
          return test_name
        }
      },

      async selectNote(note_id) {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("TastingShow.selectNote: START")
        if (this.debug || local_debug) console.log("TastingShow.selectNote: note_id = ", note_id)
        this.tasting_note_id = note_id;
        this.selectedNote = note_id;
        this.renderChild = false 
        if (this.debug || local_debug) console.log("TastingShow.selectNote: this.tasting_note_id = ", this.tasting_note_id)
        if (this.tasting_note_id != 0) {
          if (this.debug || local_debug) console.log("TastingShow.selectNote: Fetching TastingNote with id = ",this.tasting_note_id," from DB ");
          await TastingNotes.getone(this.tasting_note_id)
          .then(response=>{
            if ( typeof response == "object") {
              this.tasting_note = response;
              this.tasting_note_id = response._id;
              delete this.tasting_note._id;

              this.$store.commit('setTastingnote',this.tasting_note)
              this.$store.commit('setTastingnoteID',this.tasting_note_id)
              if (this.debug || local_debug) console.log("TastingShow.selectNote: note ", this.tasting_note, " succesfully fetched from DB");
            } else {
              if (this.debug || local_debug) console.log("TastingShow.selectNote: ERROR retrieved item is not an object");
            }
          })
          .catch(error=>{
            if (this.debug || local_debug) console.log("TastingShow.selectNote: catch await TastingNotes.getone()", error);
            alert("ERROR tasting_note " + tasting_note_id + " NOT found.")
            return
          })

          if (this.debug || local_debug) console.log("TastingShow.selectNote: INFO tasting ", this.tasting);

          if (!this.tasting.fromCellar) {
            if (this.selectedNote == this.tasting_notes[0]._id) {
              this.up_visible = false
            } else {
              this.up_visible = true
            }

            if (this.selectedNote == this.tasting_notes[this.tasting_notes.length-1]._id) {
              this.down_visible = false
            } else {
              this.down_visible = true
            }

            if (this.tasting_note.item_id != 0) {
              await Items.getone(this.tasting_note.item_id)
              .then(response => {
                if ( typeof response == "object") {
                  this.item = response;
                  this.item_id = this.item._id;
                  delete this.item._id;

                  this.$store.commit('setItem',this.item)
                  this.$store.commit('setItemID',this.item_id)
                  if (this.debug || local_debug) console.log("TastingShow.selectNote: item ", this.item, " succesfully fetched from DB");
                } else {
                  if (this.debug || local_debug) console.log("TastingShow.selectNote: ERROR retrieved item is not an object");
                }
              })
              .catch(error => {
                if (this.debug || local_debug) console.log("TastingShow.selectNote: catch await Items.getone()", error);
              })

              let pos = -1
              for ( let i = 0 ; i < this.tasting_notes.length ; i++ ) {
                if (this.tasting_notes[i]._id ==  this.tasting_note_id) {
                  pos = i 
                }
              }
              if (this.tasting.isBlind == true && this.tasting_note.isDisclosed == false) {
                this.tasting_note.name = "Wine " + (pos+1)
              } else {
                this.tasting_note.name = this.item.name
              }
              this.tasting_notes[pos].name = this.tasting_note.name
            } else {
                if (this.debug || local_debug) console.log("TastingShow.selectNote: No associated item for this note.");
                this.init_item();
                if (this.debug || local_debug) console.log("TastingShow.selectNote: item ", this.item);
            }

            if (this.item.bottle_id != 0) {
              await Bottles.getone(this.item.bottle_id)
              .then(response => {
                if ( typeof response == "object") {
                  this.bottle = response;
                  this.bottle_id = this.bottle._id;
                  delete this.bottle._id;

                  this.$store.commit('setBottle',this.bottle)
                  this.$store.commit('setBottleID',this.bottle_id)
                  if (this.debug || local_debug) console.log("TastingShow.selectNote: bottle ", this.bottle, " succesfully fetched from DB");
                } else {
                  if (this.debug || local_debug) console.log("TastingShow.selectNote: ERROR retrieved bottle is not an object");
                }
              })
              .catch(error => {
                if (this.debug || local_debug) console.log("TastingShow.selectNote: catch await Bottles.getone()", error);
              })
            } else {
                if (this.debug || local_debug) console.log("TastingShow.selectNote: No associated bottle for this note.");
                this.init_bottle();
                if (this.debug || local_debug) console.log("TastingShow.selectNote: bottle ", this.bottle);
            }

            if (this.bottle.wine_id !=0) {
              this.c_wine_id =0;
              this.c_wine = {}
              await Wines.getone(this.bottle.wine_id)
              .then(response => {
                if ( typeof response == "object") {
                  this.wine = response;
                  this.wine_id = this.wine._id;
                  delete this.wine._id;

                  this.$store.commit('setWine',this.wine)
                  this.$store.commit('setWineID',this.wine_id)
                  this.$store.commit('setCWine',this.c_wine)
                  this.$store.commit('setCWineID',this.c_wine_id)
                  if (this.debug || local_debug) console.log("TastingShow.selectNote: wine ", this.wine, " succesfully fetched from DB");
                  this.showItemZone = true;
                  this.showTabelZone = false;
                  this.showSearchZone = true;
                  this.selected_radio_button = "db";
                } else {
                  if (this.debug || local_debug) console.log("TastingShow.selectNote: ERROR retrieved wine is not an object");
                }
              })
              .catch(error => {
                if (this.debug || local_debug) console.log("TastingShow.selectNote: catch await Wines.getone()", error);
              })
            } else if (this.bottle.c_wine_id !=0)  {
              this.wine_id =0;
              this.wine = {}
              await C_Wines.getone(this.bottle.c_wine_id)
              .then(response => {
                if ( typeof response == "object") {
                  this.c_wine = response;
                  this.c_wine_id = this.c_wine._id;
                  delete this.c_wine._id;
                  if (typeof this.c_wine.sweet == "undefined") {
                    this.c_wine.sweet = false
                  }

                  this.$store.commit('setCWine',this.c_wine)
                  this.$store.commit('setCWineID',this.c_wine_id)
                  this.$store.commit('setWine',this.wine)
                  this.$store.commit('setWineID',this.wine_id)
                  if (this.debug || local_debug) console.log("TastingShow.selectNote: c_wine ", this.c_wine, " succesfully fetched from DB");
                  this.showItemZone = false;
                  this.showTabelZone = false;
                  this.showSearchZone = true;
                  this.selected_radio_button = "scratch"
                } else {
                  if (this.debug || local_debug) console.log("TastingShow.selectNote: ERROR retrieved c_wine is not an object");
                }
              })
              .catch(error => {
                if (this.debug || local_debug) console.log("TastingShow.selectNote: catch await C_Wines.getone()", error);
              })
            } else {
              if (this.debug || local_debug) console.log("TastingShow.selectNote: No associated wine or c_wine for this bottle.");
                this.showItemZone = false;
                this.showTabelZone = false;
                this.showSearchZone = true;
                this.init_wine();
                this.init_c_wine();
                this.selected_radio_button = "db";
            }
          } else {
            if (this.tasting_note.entry_id != 0) {
              await Entries.getone(this.tasting_note.entry_id)
              .then(response => {
                if ( typeof response == "object") {
                  this.entry = response;
                  this.entry_id = this.entry._id;
                  delete this.entry._id;

                  this.$store.commit('setEntry',this.entry)
                  this.$store.commit('setEntryID',this.entry_id)
                  if (this.debug || local_debug) console.log("CellarTastingShow.selectNote: entry ", this.entry, " succesfully fetched from DB");
                } else {
                  if (this.debug || local_debug) console.log("TastingShow.selectNote: ERROR retrieved entry is not an object");
                }
              })
              .catch(error => {
                if (this.debug || local_debug) console.log("CellarTastingShow.selectNote: catch await Entries.getone()", error);
              })
              } else {
                  if (this.debug || local_debug) console.log("CellarTastingShow.selectNote: No associated entry for this note.");
                  this.init_item();
                  if (this.debug || local_debug) console.log("CellarTastingShow.selectNote: entry ", this.entry);
            }            
          }
          this.renderChild = true
        } else {
          // await this.init_tasting_note();
          // await this.init_item();
          // await this.init_bottle();
          // await this.init_wine();
          // await this.init_c_wine();
          // await this.init_entry();
          this.renderChild = false
        }

        this.refreshChild++;
        
        if (this.debug || local_debug) console.log("TastingShow.selectNote: STOP")
      },

      isActiveNote(note_id) {
        let local_debug = false
        if (local_debug) console.log("TastingShow.isActiveNote: note_id = ", note_id, " this.selectedNote = ", this.selectedNote)
        return note_id === this.selectedNote
      },
      
      async upNote(note_id) {
        if (this.debug) console.log ("")
        if (this.debug) console.log ("TastingShow.upNote: START")
        if (this.debug) console.log ("TastingShow.upNote: note_id =",note_id)

        // 1. swap ids in this.tasting.tasting_note_ids && this.tasting_notes
        for (let i=0 ; i < this.tasting.tasting_note_ids.length ; i++) {
          if (this.tasting.tasting_note_ids[i] == note_id) {
            if (this.tasting.tasting_note_ids[i] != this.tasting_notes[i]._id ) {
              alert("FATAL ERROR - this.tasting.tasting_note_ids[i] != this.tasting_notes[i]._id")
            }
            let tmp_id = this.tasting.tasting_note_ids[i-1]
            let tmp_object = this.tasting_notes[i-1]

            this.tasting.tasting_note_ids[i-1] = this.tasting.tasting_note_ids[i]
            this.tasting_notes[i-1] = this.tasting_notes[i]

            this.tasting.tasting_note_ids[i] = tmp_id
            this.tasting_notes[i] = tmp_object
 
            this.selectedNote = note_id
            break
          }
        }

        // 2. update Tasting in DB
        await Tastings.update(this.tasting_id,this.tasting)
        .then(response => {
          if (this.debug) console.log( "TastingShow.upNode: Tasting ", this.tasting, " succesfully updated with response ", response);
        })
        .catch(error => {
          if (this.debug) console.log("TastingShow.upNode: ERROR await Tastings.update - ", error);
        })

        await this.refresh_List();

        if (this.debug) console.log ("TastingShow.upNote: STOP")
      },

      async downNote(note_id) {
        if (this.debug) console.log ("")
        if (this.debug) console.log ("TastingShow.downNote: START")
        if (this.debug) console.log ("TastingShow.downNote: note_id =",note_id)

        // 1. swap ids in this.tasting.tasting_note_ids && this.tasting_notes
        for (let i=0 ; i < this.tasting.tasting_note_ids.length ; i++) {
          if (this.tasting.tasting_note_ids[i] == note_id) {
            if (this.tasting.tasting_note_ids[i] != this.tasting_notes[i]._id ) {
              alert("FATAL ERROR - this.tasting.tasting_note_ids[i] != this.tasting_notes[i]._id")
            }
            let tmp_id = this.tasting.tasting_note_ids[i]
            let tmp_object = this.tasting_notes[i]

            this.tasting.tasting_note_ids[i] = this.tasting.tasting_note_ids[i+1]
            this.tasting_notes[i] = this.tasting_notes[i+1]

            this.tasting.tasting_note_ids[i+1] = tmp_id
            this.tasting_notes[i+1] = tmp_object
 
            this.selectedNote = note_id
            break
          }
        }

        // 2. update Tasting in DB
        await Tastings.update(this.tasting_id,this.tasting)
        .then(response => {
          if (this.debug) console.log( "TastingShow.downNote: Tasting ", this.tasting, " succesfully updated with response ", response);
        })
        .catch(error => {
          if (this.debug) console.log("TastingShow.downNote: ERROR await Tastings.update - ", error);
        })

        await this.refresh_List();

        if (this.debug) console.log ("TastingShow.downNote: STOP")
      },

      async deleteNote() {
        if (this.debug) console.log ("")
        if (this.debug) console.log ("TastingShow.deleteNote: START")
        if (this.debug) console.log ("TastingShow.deleteNote: tasting_note_id =",this.tasting_note_id)

        for (let i = 0 ; i < this.tasting_notes.length ; i++ ) {
          if (this.debug) console.log("TastingShow.deleteNote: trying this.tasting_notes[",i,"].item_id = ", this.tasting_notes[i].item_id)
          if (this.tasting_notes[i]._id == this.tasting_note_id) {
            // delete the tasting note
            await TastingNotes.delete(this.tasting_note_id)
            .then(response => {
              if (this.debug) console.log("TastingShow.deleteNote: TastingNote succesfully deleted with response ", response);
            })
            .catch(error => {
              if (this.debug) console.log("TastingShow.deleteNote: catch await TastingShow.delete ", error);
            })
            // delete the tasting note for tasting_notes array
            this.tasting_notes.splice(i, 1);
            if (this.debug) console.log("TastingShow.deleteNote: TastingNote succesfully deleted from tasting_notes array");
          }
        }

        // delete the tasting note id for in tasting.tasting_note_ids
        let index = this.tasting.tasting_note_ids.indexOf(this.tasting_note_id)
        this.tasting.tasting_note_ids.splice(index,1)
        if (this.debug) console.log("TastingShow.deleteNote: TastingNote id succesfully deleted from tasting.tasting_note_ids array");
        await Tastings.update(this.tasting_id,this.tasting)
        .then(response => {
          if (this.debug) console.log("TastingShow.deleteNote: Tasting ", this.tasting," succesfully updated with response ", response);
        })
        .catch(error => {
          if (this.debug) console.log("TastingShow.deleteNote: catch await Tastings.update ", error);
        })
        
        if (this.tasting_notes.length == 0) {
          this.isActiveNote(0)  
          this.selectNote(0)
        } else {
          this.isActiveNote(this.tasting_notes[this.tasting_notes.length -1]._id)  
          this.selectNote(this.tasting_notes[this.tasting_notes.length -1]._id)
        }

        this.refreshList++;
        this.refreshChild++;

        if (this.debug) console.log ("TastingShow.deleteNote: STOP")
      },

      async init_tasting_note() {
        this.tasting_note={
          name : this.noteName,
          date : this.tasting.date,
          owner : this.currentUser.firstname + ' ' + this.currentUser.lastname,
          appearance:'',
          nose:'',
          palate:'',
          aftertaste:'',
          conclusion:'',
          score:0,
          color:'',

          entry_id:0,
          item_id:0,

          tasting_id:0,
        },
        this.tasting_note_id = 0;

        this.$store.commit('setTastingnote',this.tasting_note)
        this.$store.commit('setTastingnoteID',this.tasting_note_id)
      },

      async init_item() {
        this.item={
          name:'',
          bottle_id:0,
          origin:'',
          price:'',
          comment:'',
          winelist_ids:[],
        },
        this.item_id = 0;

        this.$store.commit('setItem',this.item)
        this.$store.commit('setItemID',this.item_id)
      },

      async init_bottle() {
        this.bottle={
          name:'',
          wine_id:0,
          c_wine_id:0,
          vintage:0,
          winelist_ids:[],
          tasting_note_ids:[]
        },
        this.bottle_id = 0;

        this.$store.commit('setBottle',this.bottle)
        this.$store.commit('setBottleID',this.bottle_id)
      },

      async init_wine() {
        this.wine = {
          name:'',
          type:'',
          country_id:'',
          region:'',
          appellation_id:'',
          appellation:'',
          classification_id:'',
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          vines:'',
          yields:'',
          vinification:'',
          image_files:[],
          winery_id:''
        };
        this.wine_id = 0;

        this.$store.commit('setWine',this.wine)
        this.$store.commit('setWineID',this.wine_id)
      },

      async init_c_wine() {
        this.c_wine={
          name:'',
          type:'',
          country_id:0,
          country:'',
          region_id:0,
          region:'',
          appellation_id:0,
          appellation:'',
          classification_id:0,
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          vines:'',
          longevity:'',
          yields:'',
          vinification:'',
          image_files:[],
        };
        this.c_wine_id = 0;

        this.$store.commit('setCWine',this.c_wine)
        this.$store.commit('setCWineID',this.c_wine_id)
      },

      async init_entry() {
        this.entry={
          name:'',
          type:'',
          country_id:0,
          country:'',
          region_id:0,
          region:'',
          appellation_id:0,
          appellation:'',
          classification_id:0,
          classification:'',
          sparkling:false,
          color:'',
          grapes:'',
          vines:'',
          longevity:'',
          yields:'',
          vinification:'',
          image_files:[],
          wine_id:0,
          winery_id:0,
          number:0,
          vintage:0,
          cellar_id:0,
          niche_id:0,
          transaction_ids:[]
        };
        this.entry_id = 0;        
      },

      async refresh_addList() {
        let local_debug = false
        if (local_debug) console.log("====> TastingShow.refresh_addList: START")
        await TastingNotes.getone(this.tasting.tasting_note_ids[this.tasting.tasting_note_ids.length-1])
        .then(response=> {
          if ( typeof response == "object") {
            if (local_debug) console.log("====> TastingShow.refresh_addList: response = ", response )
            this.tasting_notes.push(response)
          } else {
            if (this.debug || local_debug) console.log("====> TastingShow.refresh_addList: ERROR retrieved tasting_note is not an object");
          }
        })
        .catch(error=> {
          if (local_debug) console.log("====> TastingShow.refresh_addList: await TastingNotes.getone i=", this.tasting.tasting_note_ids.length-1 , " : ", error);
        })
        
        // if (this.tabIndex > 0) this.tabIndex = 0
        this.refreshList++;
        this.refreshChild++;

        await this.selectNote(this.tasting_notes[this.tasting_notes.length-1]._id)
        if (local_debug) console.log("====> TastingShow.refresh_addList: STOP this.refreshList = ", this.refreshList)
      },

      async refresh_List() {
        let local_debug = false
        if (local_debug) console.log("")
        if (local_debug) console.log("====> TastingShow.refresh_List: START")
        if (local_debug) console.log("====> TastingShow.refresh_List: this.tasting_note_id = ", this.tasting_note_id)
        if (local_debug) console.log("====> TastingShow.refresh_List: this.tasting_note = ", this.tasting_note)
        for (let i = 0; i < this.tasting_notes.length; i++) {
          if (this.tasting_notes[i]._id == this.tasting_note_id) {
            let new_tasting_note = this.tasting_note 
            new_tasting_note._id = this.tasting_note_id
            this.tasting_notes[i] = JSON.parse(JSON.stringify(new_tasting_note))
            if (local_debug) console.log("====> TastingShow.refresh_List: found in tasting_notes[",i,"]")
            break
          }
        }

        if (this.tasting_note_id == this.tasting_notes[0]._id) {
          this.up_visible = false
        } else {
          this.up_visible = true
        }

        if (this.tasting_note_id == this.tasting_notes[this.tasting_notes.length-1]._id) {
          this.down_visible = false
        } else {
          this.down_visible = true
        }

        // if (this.tabIndex > 0) this.tabIndex = 0
        this.refreshList++;
        if (local_debug) console.log("====> TastingShow.refresh_List: selectNote with id = ", this.tasting_note_id)
        await this.selectNote(this.tasting_note_id)

        if (local_debug) console.log("====> TastingShow.refresh_List: STOP this.refreshList = ", this.refreshList)
      },

      async changeTab() {
        let local_debug = false
        if (local_debug) console.log("")
        if (local_debug) console.log("=====> changeTab: START")
        if (local_debug) console.log("=====> old tabIndex = ", this.tabIndex)
        if (this.tabIndex > 0) this.tabIndex = 0
        if (local_debug) console.log("=====> new tabIndex = ", this.tabIndex)
        if (local_debug) console.log("=====> changeTab: STOP")
      },

      async showModal_openCatalog() {
        if (this.debug) console.log("")
        if (this.debug) console.log("TastingShow.showModal_openNewCatalog: START")
        this.catalogVisible = false
        this.$forceUpdate
        this.$root.$emit("bv::show::modal", "Catalogue");
        this.catalogVisible = true
        if (this.debug) console.log("TastingShow.showModal_openNewCatalog: STOP")
      },

      async showModal_openNewCatalog() {
        if (this.debug) console.log("")
        if (this.debug) console.log("TastingShow.showModal_openNewCatalog: START")
        this.catalogVisible = false
        this.$forceUpdate
        this.$root.$emit("bv::show::modal", "NewCatalogue");
        this.catalogVisible = true
        if (this.debug) console.log("TastingShow.showModal_openNewCatalog: STOP")
      },

      async showModal_deleteNote() {
        if (this.debug) console.log("")
        if (this.debug) console.log("TastingShow.showModal_deleteNote: START")
        
        this.$root.$emit("bv::show::modal", "deleteNote");

        if (this.debug) console.log("TastingShow.showModal_deleteNote: STOP")
      },

      saveCatalogue() {
        if (this.debug) console.log("")
        if (this.debug) console.log("TastingShow.saveCatalogue: START")

        if (this.debug) console.log("TastingShow.saveCatalogue: tasting_notes = ", this.tasting_notes)
        if (this.debug) console.log("TastingShow.saveCatalogue: refreshList = ", this.refreshList)

        if (this.debug) console.log("TastingShow.saveCatalogue: STOP")
      },

      async clickCheckbox(node,index) {
        if (this.debug) console.log("")
        if (this.debug) console.log("TastingShow.clickCheckbox: START")

        // this.setClickInActive()
        this.isBusy = true

        if (this.debug) console.log("TastingShow.clickCheckbox: node = ", node)
        if (this.debug) console.log("TastingShow.clickCheckbox: index = ", index)
        if (this.debug) console.log("TastingShow.clickCheckbox: node.select = ", node.select)

        if (node.select == true) {
          // create tasting_note for this item
          if (this.debug) console.log("TastingShow.clickCheckbox: creating tasting_note")
          let tmp_tasting_note = this.tasting_note
          tmp_tasting_note.name = node.value.name
          tmp_tasting_note.date = this.tasting.date
          tmp_tasting_note.user_id = this.currentUserID
          tmp_tasting_note.owner = this.currentUser.firstname + ' ' + this.currentUser.lastname
          
          tmp_tasting_note.appearance = ""
          tmp_tasting_note.nose = ""
          tmp_tasting_note.palate = ""
          tmp_tasting_note.aftertaste = ""
          tmp_tasting_note.conclusion = ""
          tmp_tasting_note.score = 0
          tmp_tasting_note.color = node.value.color
          tmp_tasting_note.onWishList = false

          tmp_tasting_note.entry_id = 0
          tmp_tasting_note.item_id = node._id
        
          tmp_tasting_note.tasting_id = this.tasting_id

          await TastingNotes.create(tmp_tasting_note)
          .then(response => {
            tmp_tasting_note._id = response
            this.tasting.tasting_note_ids.push(response)
            this.tasting_notes.push(tmp_tasting_note)
            if (this.debug) console.log("TastingShow.clickCheckbox: TastingNote ", tmp_tasting_note, " succesfully created : id = ", response);
          })
          .catch(error => {
            if (this.debug) console.log("TastingShow.clickCheckbox: catch await TastingShow.create ", error);
          })

          /* Try Out Ocy 14th */
          if (index.length == 1) {
            this.winelist.selected_bottles++
          } else if (index.length == 2 ) {
            this.winelist.selected_bottles++
            this.winelist.nodes[index[0]].selected_bottles++
          } else if (index.length == 3) {
            this.winelist.selected_bottles++
            this.winelist.nodes[index[0]].selected_bottles++
            this.winelist.nodes[index[0]].nodes[index[1]].selected_bottles++
          } else if (index.length == 4) {
            this.winelist.selected_bottles++
            this.winelist.nodes[index[0]].selected_bottles++
            this.winelist.nodes[index[0]].nodes[index[1]].selected_bottles++
            this.winelist.nodes[index[0]].nodes[index[1]].nodes[index[2]].selected_bottles++
          } else {
            console.log("TastingShow.clickCheckbox: ERROR - index.length = ", index.length)
          }
          /* Try Out Ocy 14th */

          await this.isActiveNote(this.tasting.tasting_note_ids[this.tasting.tasting_note_ids.length-1])
          await this.selectNote(this.tasting.tasting_note_ids[this.tasting.tasting_note_ids.length-1])
        } else {
          if (this.debug) console.log("TastingShow.clickCheckbox: tasting_note exists - we delete")
          if (this.debug) console.log("TastingShow.clickCheckbox: node._id = ", node._id)
          let id
          for (let i = 0 ; i < this.tasting_notes.length ; i++ ) {
            if (this.debug) console.log("TastingShow.clickCheckbox: trying this.tasting_notes[",i,"].item_id = ", this.tasting_notes[i].item_id)
            if (this.tasting_notes[i].item_id == node._id) {
              id = this.tasting_notes[i]._id
              // delete the tasting note
              await TastingNotes.delete(id)
              .then(response => {
                if (this.debug) console.log("TastingShow.clickCheckbox: TastingNote succesfully deleted with response ", response);
              })
              .catch(error => {
                if (this.debug) console.log("TastingShow.clickCheckbox: catch await TastingShow.delete ", error);
              })
              // delete the tasting note for tasting_notes array
              this.tasting_notes.splice(i, 1);
              if (this.debug) console.log("TastingShow.clickCheckbox: TastingNote succesfully deleted from tasting_notes array");
            }
          }

          // delete the tasting note id for in tasting.tasting_note_ids
          let i = this.tasting.tasting_note_ids.indexOf(id)
          this.tasting.tasting_note_ids.splice(i,1)
          if (this.debug) console.log("TastingShow.clickCheckbox: TastingNote id succesfully deleted from tasting.tasting_note_ids array");
          
          if (index.length == 1) {
            this.winelist.selected_bottles--
          } else if (index.length == 2 ) {
            this.winelist.selected_bottles--
            this.winelist.nodes[index[0]].selected_bottles--
          } else if (index.length == 3) {
            this.winelist.selected_bottles--
            this.winelist.nodes[index[0]].selected_bottles--
            this.winelist.nodes[index[0]].nodes[index[1]].selected_bottles--
          } else if (index.length == 4) {
            this.winelist.selected_bottles--
            this.winelist.nodes[index[0]].selected_bottles--
            this.winelist.nodes[index[0]].nodes[index[1]].selected_bottles--
            this.winelist.nodes[index[0]].nodes[index[1]].nodes[index[2]].selected_bottles--
          } else {
            console.log("TastingShow.clickCheckbox: ERROR - index.length = ", index.length)
          }

          // set active note
          if (this.tasting.tasting_note_ids.length > 0) {
            await this.isActiveNote(this.tasting.tasting_note_ids[0])
            await this.selectNote(this.tasting.tasting_note_ids[0])
          } else {
            await this.isActiveNote(0)
            await this.selectNote(0)
          }
        }

        await Tastings.update(this.tasting_id,this.tasting)
        .then(response => {
          if (this.debug) console.log("TastingShow.clickCheckbox: Tasting ", this.tasting," succesfully updated with response ", response);
        })
        .catch(error => {
          if (this.debug) console.log("TastingShow.clickCheckbox: catch await Tastings.update ", error);
        })

        this.refreshList++;
        this.refreshChild++;

        // this.setClickActive()
        this.isBusy = false

        if (this.debug) console.log("TastingShow.clickCheckbox: tasting_notes = ", this.tasting_notes)
        if (this.debug) console.log("TastingShow.clickCheckbox: refreshList = ", this.refreshList)
        if (this.debug) console.log("TastingShow.clickCheckbox: STOP")
      },

      setClickActive() {
        if (this.debug) console.log("==> TastingShow.setClickActive: START")
        let result = document.querySelectorAll('.custom-control-label');
        for (let i=0; i < result.length; i++) {
            // result[i].style.cursor = "auto";
            // result[i].style.pointer-events = "auto";
            result[i].setAttribute("class", "custom-control-label-busy");
        }
        if (this.debug) console.log("==> TastingShow.setClickActive: STOP")
      },

      setClickInActive() {
        if (this.debug) console.log("==> TastingShow.setClickInActive: START")
        let result = document.querySelectorAll('.custom-control-label-busy');
        for (let i=0; i < result.length; i++) {
            // result[i].style.cursor = "wait";
            // result[i].style.pointer-events = "none";
            result[i].setAttribute("class", "custom-control-label");
        }
        if (this.debug) console.log("==> TastingShow.setClickInActive: STOP")
      },

      inList(item_id) {
        let local_debug = false
        if (local_debug) console.log("=====> inList:")
        if (local_debug) console.log("=====> inList: START")
        if (local_debug) console.log("=====> inList: item_id = ", item_id)
        let return_value = false
        for (let i = 0 ; i < this.tasting_notes.length ; i++) {
          if (local_debug) console.log("=====> inList: testing this.tasting_notes[",i,"].item_id = ", this.tasting_notes[i].item_id)
          if (this.tasting_notes[i].item_id == item_id) {
            if (local_debug) console.log("=====> inList: BINGO")
            return_value = true
            break
          }
        }
        if (local_debug) console.log("=====> inList: return_value = ", return_value)
        if (local_debug) console.log("=====> inList: typeof return_value = ", typeof return_value)
        if (local_debug) console.log("=====> inList: STOP")
        return return_value
      },

      showCatalogue() { 
        this.$router.push({path:'/Catalogue'})
      },

      async refreshTasting() {
        let local_debug = false
        if (this.debug || local_debug) console.log("")
        if (this.debug || local_debug) console.log("=====> TastingShow.refreshTasting: START")
        // 1. referesh tasting
        if (typeof this.tasting_id !== "undefined" && this.tasting_id !== 0 ) {
          await Tastings.getone(this.tasting_id)
          .then(response=> {
            if ( typeof response == "object") {
              this.tasting = response;
              delete this.tasting._id;

              if (typeof this.tasting.cellar_id == "string" && this.tasting.cellar_id.length == 24) this.tasting.fromCellar = true

              this.$store.commit('setTasting',this.tasting)
              this.$store.commit('setTastingID',this.tasting_id)
              if (this.debug || local_debug) console.log( "=====> TastingShow.refreshTasting: refreshTasting "+ this.tasting.name+ " succesfully fetched from DB");
            } else {
              if (this.debug || local_debug) console.log("=====> TastingShow.refreshTasting: ERROR retrieved tasting is not an object");
            }
          })
          .catch(error=> {
            if (this.debug || local_debug) console.log("=====> TastingShow.refreshTasting: await Tastings.getone", error);
          })
        } else {
          if (this.debug || local_debug) console.log("=====> TastingShow.refreshTasting: FATAL ERROR this.tasting_id = ", this.tasting_id);
          return
        }
        //2. Refresh tasting_notes
        this.tasting_notes = []
        for (let i=0; i < this.tasting.tasting_note_ids.length; i++) {
          if (this.debug || local_debug) console.log("=====> TastingShow.refreshTasting: this.tasting.tasting_note_ids[",i,"]=", this.tasting.tasting_note_ids[i] )
          await TastingNotes.getone(this.tasting.tasting_note_ids[i])
          .then(response=> {
            if ( typeof response == "object") {
              this.tasting_notes.push(response)
              if (this.debug || local_debug) console.log("====> TastingShow.refreshTasting: tasting_notes[",i,"] = ", JSON.stringify(this.tasting_notes[i]))
              if ( this.tasting_notes[i]._id == this.tasting_note_id) {
                // this.tasting_note = this.tasting_notes[i] does NOT work - needs deep cloning
                this.tasting_note = JSON.parse(JSON.stringify(this.tasting_notes[i]))
                delete this.tasting_note._id
              }
            } else {
              if (this.debug || local_debug) console.log("====> TastingShow.refreshTasting:  ERROR retrieved tasting_note is not an object");
            }
          })
          .catch(error=> {
            if (this.debug || local_debug) console.log("====> TastingShow.refreshTasting: await TastingNotes.getone i=", i , " : ", error);
          })
          await Items.getone(this.tasting_notes[i].item_id)
          .then(response =>{
            if (this.debug || local_debug) console.log("====> TastingShow.refreshTasting: corresponding item with id = ", this.tasting_notes[i].item_id , " and name ", response.name)
            if (this.tasting.isBlind == true && this.tasting_notes[i].isDisclosed ==  false) {
              this.tasting_notes[i].name = "Wine " + (i+1)
            } else {
              this.tasting_notes[i].name = response.name
            }
          })
          .catch(error => {
            if (this.debug || local_debug) console.log("====> TastingShow.refreshTasting:  ERROR retrieved item is not an object");
          })

          let tmp_tasting_note = JSON.parse(JSON.stringify(this.tasting_notes[i]))
          delete tmp_tasting_note._id

          await TastingNotes.update(this.tasting.tasting_note_ids[i],tmp_tasting_note)
          .then(response => {
            if (this.debug || local_debug) console.log("====> TastingShow.refreshTasting: TastingNotes.update with id = ", this.tasting.tasting_note_ids[i] , " SUCCESSFUL with response ", response.name)
          })
          .catch(error => {
            if (this.debug || local_debug) console.log("====> TastingShow.refreshTasting: ERROR in update TastingNotes =", error);
          })
        }
        this.refreshList++;
        this.refreshChild++;
        await this.selectNote(this.tasting_note_id)
        if (this.debug || local_debug) console.log("=====> TastingShow.refreshTasting: STOP")
      },

      gotoTastingsOverview() {
        this.$store.commit('setPage','TastingsOverview')
        this.$router.push({path:'/TastingsOverview'} );
      },

      doNothing() {
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.w-background {
  background-position: center;
  background-size: cover;
}
.no-background {
  /* background-color:rgb(112,112,112); */
  background-position: center;
  background-size: cover;
  background-image: url("../assets/img/tastings.jpg");
}

.custom-control-label-busy{
  position: relative; 
  margin-bottom: 0; 
  vertical-align: top; 
  cursor:wait ; 
  pointer-events:none
}
</style>
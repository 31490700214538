<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>    
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col xs="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col sm="10" style="padding:0">
            <br>
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;" v-if="winelist_id == 0" >organise > winelists > add winelist</p>
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;" v-else>organise > winelists > edit winelist</p>
            <b-row>
              <b-col sm="5" class="d-flex justify-content-center" style="padding:0">
                <div class="w-tile text-center" style="width:300px;height:200px"> 
                  <div v-if="winelist.image_files[0]" 
                    class="c-image-part" 
                    v-bind:style="{ 'background-image': 'url(' + winelist.image_files[0].dataURL +')' }"
                  >
                  </div>
                  <div v-else
                    class="c-image-part"
                  />
                  <div class="c-text-part">
                    <p class="c-title">{{winelist.name}}<span v-if="winelist.organiser" style="font-weight:normal;font-size: 11px;"> (by {{ winelist.organiser }})</span></p>
                    <p class="c-subtitle">{{winelist.nice_date}}<span v-if="winelist.description"> - {{winelist.description}}</span></p>
                    <p class="c-bottles">{{winelist.number_of_bottles}}</p>
                  </div>
                  <div class="dropbox-overlay text-center" style="width:300px;height:200px">
                    <vue-dropzone 
                      ref="imgDropzone" id="profiledropzone" 
                      :options="dropzoneOptions"
                      @vdropzone-thumbnail="vfileAdded" 
                    >
                    </vue-dropzone>    
                  </div>
                </div>
              </b-col>
              <b-col sm="7" style="margin-left:0;padding-left:0" class="d-table">
                <div class="w-box">
                  <b-form autocomplete="false">
                    <b-form-group>
                      <label class="w-label w-required" for="input-name">name</label>
                      <b-form-input
                        id="input-firstname"
                        class = "w-input"
                        v-model="winelist.name"
                        v-bind:class="{'is-invalid' : !this.nameValid}"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{nameFeedback}}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group>
                      <label class="w-label" for="input-organiser">organiser</label>
                      <b-form-input
                        id="input-organiser"
                        class = "w-input"
                        v-model="winelist.organiser"
                        v-bind:class="{'is-invalid' : !this.organiserValid}"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{organiserFeedback}}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group>
                      <label class="w-label" for="input-date">date</label>
                      <b-form-datepicker 
                        class="w-datepicker mb-2" 
                        id="input-date" 
                        v-model="winelist.date"
                        @input="changeDate()"
                        v-bind:class="{'form-control':true, 'is-invalid' : !this.dateValid}"
                      >
                      </b-form-datepicker>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{dateFeedback}}
                      </b-form-invalid-feedback>
                    </b-form-group>                    
                              
                    <b-form-group>
                      <label class="w-label" for="input-description">description</label>
                      <b-form-input
                        id="input-description"
                        class = "w-input"
                        v-model="winelist.description"
                        v-bind:class="{'is-invalid' : !this.descriptionValid}"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{descriptionFeedback}}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <hr>

                    <b-form-group>
                      <b-form-checkbox 
                        class="w-checkbox d-inline" 
                        style="margin-left:0; padding-left:25px !important" 
                        v-model="winelist.isOpen" name="open-check"
                        @change="onClick_Open()"
                      >
                      </b-form-checkbox>
                      <label 
                        class="w-checkbox d-inline" 
                        style="margin-left:0 !important; padding-left:0 !important" 
                        for="open-check"
                        v-b-tooltip.hover title="an open tasting is when the organiser offers a catalogue of bottles out of which you can freely choose which bottle to drink."
                      >
                        open
                      </label>
                    </b-form-group>   

                    <b-form-group v-if="!winelist.isOpen">
                      <b-form-checkbox 
                        class="w-checkbox d-inline" 
                        style="margin-left:0; padding-left:25px !important" 
                        v-model="winelist.isBlind"
                        @change="changeBlind()" 
                        name="blind-check"
                      >
                      </b-form-checkbox>
                      <label 
                        class="w-checkbox d-inline" 
                        style="margin-left:0 !important; padding-left:0 !important" 
                        for="bind-check"
                        v-b-tooltip.hover title="a blind tasting is when the organiser offers a limited number of bottles which are wrapped in paper."
                      >
                        blind
                      </label>
                    </b-form-group>                    

                    <b-form-group v-if="!winelist.isOpen">
                      <b-form-checkbox 
                        class="w-checkbox d-inline" 
                        style="margin-left:0; padding-left:25px !important" 
                        v-model="winelist.isOrdered" 
                        name="ordered-check"
                      >
                      </b-form-checkbox>
                      <label 
                        class="w-checkbox d-inline" 
                        style="margin-left:0 !important; padding-left:0 !important" 
                        for="ordered-check"
                        v-b-tooltip.hover title="an ordered tasting is when the organiser invites you to taste the bottles in a particular order."
                      >
                        ordered
                      </label>
                    </b-form-group>

                    <hr>

                    <b-form-group>
                      <b-row style="margin-left:0">
                        <b-col md="4" class="text-left">
                            <label for="code">code</label>
                        </b-col>
                        <b-col md="8">
                          <p class="t-title" style="margin-left:0 !important; padding-left:0 !important" >{{code}}</p>
                        </b-col>
                      </b-row>
                    </b-form-group>

                  </b-form>
                </div>
              <b-row>
                <b-col  class="text-left">
                  <b-button class="w-button-pink" style="margin-left:15px" v-on:click="onBack()" >back</b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button class="w-button-green" style="margin-right:15px" v-on:click="onSave()">save</b-button>
                </b-col>
              </b-row>
              </b-col>
              <b-col sm="1">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>    
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';
  import formatDate from '../assets/js/formatDate.js';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuDesktop.vue'

  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  
  import Users from '../Users.js';
  import newCode from '../newCode.js';
  import WineLists from '../WineLists.js';

  export default {
    name: 'WineListEdit',
    components:{
      NoAccess,
      Menu,
      vueDropzone: vue2Dropzone
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      currentPage() {
        return this.$store.state.page
      },
    },
    data() {
      return {
        debug: false,
        isLoading: true,
        
        winelist: {
          name:'',
          organiser:'',
          date:'',
          description:'',
          image_files: [],
          isOpen: false,
          isBlind: false,
          isOrdered: false,
          code:'',
          user_id:0,
          nodes:[],
          tasting_ids:[],
          number_of_bottles:0,
        },
        winelist_id:0,

        code:'',

        user:{},
        user_id:0,

        isValidCode:true,
        codeFeedback:'',

        isValid: true,
        nameValid: true,
        nameFeedback:'',
        organiserValid: true,
        organiserFeedback: '',
        dateValid: true,
        dateFeedback: '',
        descriptionValid: true,
        descriptionFeedback: '',

        winelistExists : false,

        // dropzone data
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          addRemoveLinks: true,
          thumbnailWidth: 100,
          thumbnailHeight: 100,
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : 'Drop your WineList Picture here.',
          maxFilesize: 10,
          headers: { "My-Awesome-Header": "header value" }
        },
        dropzoneFilled: false,

        submitted: false,
      }
    },
    async mounted() {
      if (this.debug) console.log("WineListEdit.mounted: START")
      var today = new Date();
      this.winelist.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      // format date yyyy-mm-dd
      let dateArray = this.winelist.date.split("-");
      if (dateArray[1].length == 1) {
        dateArray[1] = "0" + dateArray[1]
      }
      if (dateArray[2].length == 1) {
        dateArray[2] = "0" + dateArray[2]
      }
      this.winelist.date = dateArray[0] + "-" + dateArray[1] + "-" + dateArray[2]
      this.winelist.nice_date = formatDate(new Date(this.winelist.date))

      if (typeof this.$route.query.winelist_id !== "undefined") {
        await WineLists.getone(this.$route.query.winelist_id)
        .then(response=> {
          this.winelist = response;
          this.winelist_id = this.winelist._id;
          delete this.winelist._id;
          this.winelist.nice_date = formatDate(new Date(this.winelist.date))
          this.winelistExists = true;
          if (this.debug) console.log( "WineListEdit.mounted: WineList ", this.winelist, " succesfully fetched from DB");
        })
        .catch(error=> {
          if (this.debug) console.log("WineListEdit.mounted: await WineLists.getone", error);
        })
      } else {
        if (this.debug) console.log("WineListEdit.mounted: this.$route.query.winelist_id = ", this.$route.query.winelist_id);
        this.winelist.code = await this.generateCode();
        if (this.debug) console.log("WineListEdit.mounted: this.winelist.code = ", this.winelist.code);
      }
      this.code = this.printCode(this.winelist.code);
      
      if (this.debug) console.log("WineListEdit.mounted: INFO this.code = ", this.code);
      if (this.debug) console.log("WineListEdit.mounted: INFO this.winelist_id = ", this.winelist_id);
      if (this.debug) console.log("WineListEdit.mounted: STOP")
    
      this.isLoading = false;
    },
    methods: {
      validate(){
        if( this.winelist.name.length >= 3) {
            this.nameValid = true;
            this.nameFeedback = ''
        } else {
            this.nameValid = false
            this.nameFeedback = 'name must contain minimum 3 characters'
        }
        // format date yyyy-mm-dd
        let dateArray = this.winelist.date.split("-");
        if (dateArray[1].length == 1) {
          dateArray[1] = "0" + dateArray[1]
        }
        if (dateArray[2].length == 1) {
          dateArray[2] = "0" + dateArray[2]
        }
        this.winelist.date = dateArray[0] + "-" + dateArray[1] + "-" + dateArray[2]
        this.isValid = this.nameValid
      },

      async onSave() {
        if(this.debug) console.log("WineListEdit.onSave : START");
        this.validate();
        this.user_id = this.currentUserID;
        this.user = this.currentUser;

        this.winelist.user_id = this.user_id;

        if(this.isValid){
          if (this.winelistExists) {
            if(this.debug) console.log("WineListEdit.onSave : WineList exists, and has id=", this.winelist_id)
            console.log("TMP - this.winelist = ", this.winelist)
            await WineLists.update(this.winelist_id,this.winelist)
            .then(response=>{
              if(this.debug) console.log("WineListEdit.onSave : WineList '",this.winelist,"' succesfully updated with response ", response);
            })
            .catch(error=>{
              if(this.debug) console.log("WineListEdit.onSave: catch await WineList.update ", error);
            })
          } else {
            if(this.debug) console.log("WineListEdit.onSave: WineList does NOT exists, but will be created.")
            if(this.debug) console.log("WineListEdit.onSave: ", this.winelist)
            await WineLists.create(this.winelist)
            .then(response=>{
              this.winelist_id = response;
              if(this.debug) console.log("WineListEdit.onSave: WineList ", this.winelist," succesfully created with response ", response);
            })
            .catch(error=>{
              if(this.debug) console.log("WineListEdit.onSave: catch await WineList.create ", error);
            })
            
            if (typeof this.user.winelist_ids === 'undefined') {
              this.user.winelist_ids = [];
            }
            this.user.winelist_ids.push(this.winelist_id);

            await Users.update(this.user_id,this.user)
            .then(response=>{
              if(this.debug) console.log("WineListEdit.onSave: WineList succesfully added to user : id = ", this.user);
            })
            .catch(error=>{
              if(this.debug) console.log("WineListEdit.onSave: catch await WineList.update ", error);
            }) 
            this.$store.commit('setUser',this.user)
          }
       
          this.submitted = true;
          this.$router.push({path:'/WinelistsOverview', query: {user_id:this.$route.query.user_id}});
          this.$store.commit('setPage','WinelistsOverview')
          await this.$forceUpdate();
        } 
      },

      onBack() {
        this.$router.push({path:'/WinelistsOverview', query: {user_id:this.$route.query.user_id}});
        this.$store.commit('setPage','WinelistsOverview')
      },

      async generateCode() {
        let code = '';
        await newCode.get()
        .then(response => {
          if (this.debug) console.log("WineListEdit.generateCode: response = ", response);
          code = response; 
        })
        .catch(error => {
          if (this.debug) console.log("WineListEdit.generateCode: await newCode.get:", error);
          code = 'ERROR';
        }) 
        return code;
      },

      printCode(this_code) {
        let print_code = this_code.toUpperCase()
        print_code = print_code.replace(/\s+/g, '')  
        print_code = print_code.substring(0,2) + ' ' + print_code.substring(2,4) + ' ' + print_code.substring(4,6)
        return print_code;
      },

      onClick_Open() {
        let local_debug = false
        if (local_debug) console.log("")
        if (local_debug) console.log("==> WineListEdit.onClick_Open: START")

        let containsList = false
        this.winelist.isOpen = !this.winelist.isOpen // inverse the default action of checkbox
        
        for (let i=0; i < this.winelist.nodes.length; i++) {
          if (!this.winelist.nodes[i].isLeaf) {
            containsList = true
            break
          }
        }
        if (local_debug) console.log("==> WineListEdit.onClick_Open: containsList = ", containsList)
        if (local_debug) console.log("==> WineListEdit.onClick_Open: PRE isOpen = ", this.winelist.isOpen)
        if (local_debug) console.log("==> WineListEdit.onClick_Open: PRE isBlind = ", this.winelist.isBlind)
        if (local_debug) console.log("==> WineListEdit.onClick_Open: PRE isOrdered = ", this.winelist.isOrdered)
        if (!containsList) {
          if (this.winelist.isOpen) {
            this.winelist.isOpen = false
          } else {
            this.winelist.isOpen = true
            this.winelist.isBlind = false
            this.winelist.isOrdered = false
          }
        } else {
          alert("This wine list already contains lists of bottles. So we cannot unset 'open'. Goto the tasting and make sure the wine list only contains bottles.")
          this.winelist.isOpen = true
          this.winelist.isBlind = false
          this.winelist.isOrdered = false
        }
        if (local_debug) console.log("==> WineListEdit.onClick_Open: POST isOpen = ", this.winelist.isOpen)
        if (local_debug) console.log("==> WineListEdit.onClick_Open: POST isBlind = ", this.winelist.isBlind)
        if (local_debug) console.log("==> WineListEdit.onClick_Open: POST isOrdered = ", this.winelist.isOrdered)
        if (local_debug) console.log("==> WineListEdit.onClick_Open: STOP")
      },

      changeDate() {
        this.winelist.nice_date = formatDate(new Date(this.winelist.date))
      },

      async changeBlind() {
        let local_debug = false

        if (local_debug) console.log("")
        if (local_debug) console.log("==> WineListEdit.changeBlind: START")
        if (this.winelist.isBlind) {
          if (local_debug) console.log("==> WineListEdit.changeBlind: winelist is blind")
          for (let i=0; i < this.winelist.nodes.length; i++) {
            if (this.winelist.nodes[i].isLeaf) {
              this.winelist.nodes[i].value.isDisclosed = false
              if (local_debug) console.log("==> WineListEdit.changeBlind: changing this.winelist.nodes[i].value.isDisclosed of ",this.winelist.nodes[i].value.name," to false.")
            } else {
              if (local_debug) console.log("==> WineListEdit.changeBlind: ERROR blind winelist item is NOT a Leaf.")
            }
          }
        } else {
          if (local_debug) console.log("==> WineListEdit.changeBlind: winelist is NOT blind")
        }
        if (this.winelist_id.length == 24) { 
          await WineLists.update(this.winelist_id,this.winelist)
          .then(response=>{
            if(local_debug) console.log("==> WineListEdit.changeBlind: WineList '",this.winelist,"' succesfully updated with response ", response);
          })
          .catch(error=>{
            if(local_debug) console.log("==> WineListEdit.changeBlind: catch await WineList.update ", error);
          })
        }
        if (local_debug) console.log("==> WineListEdit.changeBlind: STOP")
      },

      async vfileAdded(new_image_file) {
        if ( !new_image_file.manuallyAdded ) {
          console.log("winelist picture URL: ", new_image_file);
          if (this.winelist.image_files.length > 0) {
            var old_image_file = this.winelist.image_files.pop();
            this.$refs.imgDropzone.removeFile(old_image_file);
          }
          let org_base64 = new_image_file.dataURL;
          let cc_base64 = await cc64(org_base64,3,2);
          new_image_file.dataURL = cc_base64;
          this.winelist.image_files.push(new_image_file);
          this.hasChanged = true;
        }
      },

      vfileRemoved(image_file) {
        // this.winelist.image_files.pop(image_file);
        for( var i = 0; i < this.winelist.image_files.length; i++){
          if ( this.winelist.image_files[i].dataURL === image_file.dataURL) { 
            this.winelist.image_files.splice(i, 1); 
          }
        }
        this.hasChanged = true;
      },

      template: function () {
         return `<div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                      <div class="dz-thumbnail" data-dz-thumbnail-bg></div>
                      <div class="thumbnail-overlay">
                        <div class="text" style="text-align: center; margin-top: 30px" data-dz-remove>remove</div>
                      </div>
                  </div>
                  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                  <div class="dz-error-message"><span data-dz-errormessage></span></div>
                  <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                  <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                </div>`;
      },

      thumbnail: function(file, dataUrl) {
        var j, len, ref, thumbnailElement;
        if (file.previewElement) {
            file.previewElement.classList.remove("dz-file-preview");
            ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
            for (j = 0, len = ref.length; j < len; j++) {
                thumbnailElement = ref[j];
                thumbnailElement.alt = file.name;
                thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
            }
            return setTimeout(((function() {
                return function() {
                    return file.previewElement.classList.add("dz-image-preview");
                };
            })(this)), 1);
        }
      },

      doNothing() {
      }, 
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
#profiledropzone {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;

  background-color: transparent !important;
  border-style: none;
  border-radius: 5px;

  color:white;

  transition: background-color .2s linear;
}

.c-image-part {
  /* background-image: url("../assets/img/organise.jpg") */
  background-image: url("../assets/img/winelist.png")
}
</style>
<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <br>
      <b-row style="height:40vh;margin:0" class="welcome-picture">
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" style="height:100%">
          <div class="welcome-overlay">
            <h1 class="main-title"><span style="color: rgb(109,122,82)">WYNE</span>TASTER</h1>
            <p class="main-subtitle">NO ACCESS</p>
          </div> 
        </b-col>
      </b-row> 
      <b-row>
         <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8"> 
          <br>
          <p class="w-text">You are not properly logged in - you have no access to this page.</p>
          <p class="w-text">Please note this in a one page application.</p>
          <p class="w-text">ONLY use the navigation of the application.</p>
          <p class="w-text">If you use the navigation of the browser, you will be logged off.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" class="text-right"> 
          <b-button v-on:click="gotoStart()" class="w-button-green">start</b-button>
        </b-col>
      </b-row>  
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NoAccess',
    data() {
      return {
        isLoading : true,
      }
    }, 
    mounted() {
      this.isLoading = false
    },
    methods: {
      gotoStart() {
        this.$router.push({path:'/'});
      }
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/wynetaster.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}
</style> 
